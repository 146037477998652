// @flow

import type { AuthChallenge } from '@api/cognito';
import { authenticate, signOutCurrentUser } from '@api/cognito';

const login = async (email: string, password: string): Promise<AuthChallenge | string> => {
  const result: AuthChallenge | string = await authenticate(email, password);

  return result;
};

const logout = async (): Promise<void> => {
  await signOutCurrentUser();
};

export { login, logout };
