// @flow

import type { PayloadAction } from '@reduxjs/toolkit';
import type { Saga } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import type { Entities, Entity } from '@state/ducks/bots/types';
import * as cognito from '@api/cognito';
import {
  getHttpStatusTypeByStatusCode,
  HTTP_STATUS_TYPES_MAP,
  type HttpStatusType,
} from '@assets/js/calldesk-app-util/format';
import logger from '@assets/js/calldesk-app-util/logger';
import { operations as accountsOperations } from '@state/ducks/accounts';
import {
  api as botsApi,
  operations as botsOperations,
  selectors as botsSelectors,
} from '@state/ducks/bots';
import { operations as assetsOperations } from '@state/ducks/customers-assets';
import {
  api as onboardingApi,
  operations as onboardingOperations,
  selectors as onboardingSelectors,
} from '@state/ducks/onboarding';
import { EXERCISE_KEY } from '@state/ducks/onboarding/types';
import { operations as uiOperations } from '@state/ducks/ui';
import { monitoringCatchException } from '@state/monitoring-enhancer';
import { tagEntitiesReferentialSaga } from '@state/sagas/customers-assets';

/** This saga is used in order to remove current bot
 *
 * @param {string} accountId - Account where bot is located
 * @param {string} botName - Bot will be deleted
 * @param {string[]} bots - bots list from account selected
 */
function* deleteBotSaga({
  payload: { accountId, botName, bots },
}: PayloadAction<{
  accountId: string,
  botName: string,
  bots: string[],
}>): Saga<any> {
  try {
    yield put(botsOperations.setSpecificBotsStatus({ name: 'delete', value: 'IN_PROGRESS' }));
    const botEntities: Entities = yield select(botsSelectors.getEntities, botName);
    // $FlowFixMe: Object.values(botEntities) is only Entity type
    const customEntitiesToDelete: Entity[] = Object.values(botEntities);

    yield put(accountsOperations.updateStatus('IN_PROGRESS'));
    yield call(botsApi.deleteBotOnApi, accountId, botName);
    yield call(
      tagEntitiesReferentialSaga,
      assetsOperations.tagEntitiesReferentialSaga(accountId, botName, customEntitiesToDelete),
    );
    const botIds: string[] = bots.filter((bot: string) => bot !== botName);
    yield put(accountsOperations.updateBots({ accountId, botIds }));
    const botHasOnboarding: boolean = yield select(onboardingSelectors.selectBotHasOnboarding);
    if (botHasOnboarding) {
      const user: string = yield call(cognito.getUserSub);
      yield call(onboardingApi.deleteUserOnboarding, user, accountId, botName, EXERCISE_KEY);
      yield put(onboardingOperations.clearOnboarding());
    }
    yield put(botsOperations.setSpecificBotsStatus({ name: 'delete', value: 'SUCCESS' }));
  } catch (error) {
    logger.error(error);
    yield put(botsOperations.updateError(error.message));
    yield put(botsOperations.setSpecificBotsStatus({ name: 'delete', value: 'ERROR' }));
    /**
     * [Reference point]
     * Reference to generalize across all sagas but also thunks the management of error
     * sendings on Sentry according to their type:
     */
    const resType: HttpStatusType = getHttpStatusTypeByStatusCode(error.status);
    if (resType === HTTP_STATUS_TYPES_MAP.serverError) {
      monitoringCatchException({
        exception: error,
        location: 'state/sagas/bots/deleteBotSaga',
        functionType: 'saga',
        params: { bots },
        accountId,
        botName,
      });
    }
    yield put(uiOperations.openErrorModal());
  }
}

export default deleteBotSaga;
