// @flow

import _set from 'lodash/set';

/**
 * StudioApi error on API request
 */
class StudioApiRequestError extends Error {
  name: string; // Error type

  status: number;

  solution: string;

  response: Object;

  /**
   *
   */
  constructor(status: number, response?: Object) {
    let message: string = 'An unknown server error occured.'; // default message
    let solution: string = 'Contact an administrator.'; // default solution
    if (response) {
      if (response.errors && response.errors.length) {
        // If bot-api returned an array of error
        message = response.errors[0].message || message;
        solution = response.errors[0].solution || solution;
      } else {
        // If bot-api returned a flat error message
        message = response.message || message;
        solution = response.solution || solution;
      }
    }
    super(message);
    this.name = 'StudioApiRequestError';
    this.status = status;
    this.solution = solution;
    if (!(response && response.errors)) {
      _set(response, 'errors', [{ message, solution }]);
    }
    this.response = response;
  }

  /**
   * Returns a string representation of an object.
   * @returns the string representation of the error
   */
  toString() {
    return `[${this.name}] status: "${this.status.toString()}", message: "${
      this.message
    }", solution: "${this.solution}"`;
  }
}
export default StudioApiRequestError;
