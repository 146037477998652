// @flow

import type { CognitoUser } from '@aws-amplify/auth';

import completeNewPassword from '@amplify/auth/complete-new-password';
import currentSession from '@amplify/auth/current-session';
import forgotPasswordRequest from '@amplify/auth/forgot-password';
import forgotPasswordSubmit from '@amplify/auth/forgot-password-submit';
import getCurrentAuthenticatedUser from '@amplify/auth/get-current-user';
import signIn from '@amplify/auth/sign-in';
import signOut from '@amplify/auth/sign-out';
import logger from '@assets/js/calldesk-app-util/logger';

type AuthChallenge = {
  challenge: string,
  user?: CognitoUser,
};

/**
 * Return the current logged user if any
 */
const getCurrentUser = (): Promise<?CognitoUser> => getCurrentAuthenticatedUser();

const getUserSub = async (): Promise<?string> => {
  const session = await currentSession();
  const token = session.getIdToken();
  return token.decodePayload()?.sub;
};

/**
 * Return a Promise with the current user's token if any
 */
const getUserToken = async (): Promise<?string> => {
  try {
    const session = await currentSession();
    const token = session.getIdToken();
    return Promise.resolve(token.getJwtToken());
  } catch (error) {
    logger.error('api/cognito [getUserToken] - error while getting the user token');
    return Promise.reject(error);
  }
};

/**
 * Resolve token for user found in current session.
 */
const checkSessionToken = async (): Promise<string> => {
  const currentUser: ?CognitoUser = await getCurrentUser();
  if (!currentUser) {
    return Promise.reject(
      new Error('api/cognito [checkSessionToken] no user found in current session'),
    );
  }

  return getUserToken().then((userToken: ?string) => {
    if (!userToken) {
      return Promise.reject(
        new Error('api/cognito [checkSessionToken] no token found for current user'),
      );
    }

    logger.info('cognito [checkSessionToken] - Got a correct session token for the user');
    return Promise.resolve(userToken);
  });
};

/**
 * Authenticate user
 */
const authenticate = async (
  username: string,
  password: string,
): Promise<AuthChallenge | string> => {
  const user: CognitoUser = await signIn({ username, password });
  switch (user.challengeName) {
    case 'NEW_PASSWORD_REQUIRED': {
      return Promise.resolve({
        user,
        challenge: user.challengeName,
      });
    }
    case 'SMS_MFA': {
      return Promise.reject(new Error('MFA required (double authentication)'));
    }
    default: {
      return user.getSession((err, session) => Promise.resolve(session.getIdToken().getJwtToken()));
    }
  }
};

const forgotPassword = (email: string): Promise<?string> => forgotPasswordRequest(email);

const confirmForgotPassword = (email: string, password: string, code: string): Promise<void> =>
  forgotPasswordSubmit(email, code, password);

const confirmNewPassword = (
  user: CognitoUser,
  newPassword: string,
  additionnalAttributes: Object,
): Promise<void> => completeNewPassword(user, newPassword, additionnalAttributes);

/**
 * Sign out user from current session
 */
const signOutCurrentUser = async (): Promise<void> => {
  await signOut();
  return Promise.resolve();
};

export {
  authenticate,
  checkSessionToken,
  confirmForgotPassword,
  confirmNewPassword,
  forgotPassword,
  getCurrentUser,
  getUserSub,
  getUserToken,
  signOutCurrentUser,
};
export type { AuthChallenge };
