// @flow

import type { BotAction, Bots as BotsState, Graphs } from './types';
import type { PayloadAction } from '@reduxjs/toolkit';

import { deleteIntentFromTransitions } from './utils';

type IntentBotAction<T> = { ...BotAction<T> };

const reducers = {
  addIntent: (
    state: BotsState,
    { payload: { bot, intentId } }: PayloadAction<BotAction<$Exact<{ intentId: string }>>>,
  ) => {
    state.entities[bot].config.intents.push(intentId);
  },
  deleteIntent: (
    state: BotsState,
    { payload: { bot, intentId } }: PayloadAction<BotAction<$Exact<{ intentId: string }>>>,
  ) => {
    const intentIndex = state.entities[bot].config.intents.findIndex(id => id === intentId);
    const currGraphs: Graphs = state.entities[bot].config?.graphs;

    state.entities[bot].config.intents.splice(intentIndex, 1);
    state.entities[bot].config.graphs = deleteIntentFromTransitions(currGraphs, intentId);
  },
  upsertIntents: (
    state: BotsState,
    { payload: { bot, intents } }: PayloadAction<IntentBotAction<$Exact<{ intents: string[] }>>>,
  ) => {
    const set = new Set(state.entities[bot].config.intents.concat(intents));

    state.entities[bot].config.intents = [...set];
  },
};

export default reducers;
