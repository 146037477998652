// @flow

import type { RootState } from '../../store';
import type { UI as State } from './types';
import type { PayloadAction } from '@reduxjs/toolkit';
// $FlowFixMe
import { createSlice } from '@reduxjs/toolkit';

import type { ElementOnScriptSelected } from '@assets/js/calldesk-app-util/types';

const initialState: State = {
  elementZoomOn: null,
  selectedAccount: '',
  selectedDomain: '',
  selectedNodeId: null,
  showBuilder: true,
  showErrorModal: false,
  showOnboardingModal: false,
  showSavePopover: false,
};

/* ACTIONS & REDUCERS */
const { actions, reducer } = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    closeErrorModal(state: State) {
      state.showErrorModal = false;
    },
    openErrorModal(state: State) {
      state.showErrorModal = true;
    },
    setElementZoomOn(state: State, { payload }: PayloadAction<?ElementOnScriptSelected>) {
      state.elementZoomOn = payload;
    },
    setSelectedNodeId(state: State, { payload }: PayloadAction<?string>) {
      state.selectedNodeId = payload;
    },
    showErrorModal(state: State, { payload }: PayloadAction<boolean>) {
      state.showErrorModal = payload;
    },
    showSavePopover(state: State, { payload }: PayloadAction<boolean>) {
      state.showSavePopover = payload;
    },
    showOnboardingModal(state: State, { payload }: PayloadAction<boolean>) {
      state.showOnboardingModal = payload;
    },
    updateSelectedAccount(state: State, { payload }: PayloadAction<string>) {
      state.selectedAccount = payload;
    },
    updateSelectedDomain(state: State, { payload }: PayloadAction<string>) {
      state.selectedDomain = payload;
    },
  },
});

/* SELECTORS */
const getElementZoomOn = (state: RootState): $PropertyType<State, 'elementZoomOn'> =>
  state.ui.elementZoomOn;
const getSelectedAccount = (state: RootState): $PropertyType<State, 'selectedAccount'> =>
  state.ui.selectedAccount;
const getSelectedDomain = (
  state: RootState,
  domains: string[],
): $PropertyType<State, 'selectedDomain'> =>
  state.ui.selectedDomain || (domains && domains[0]) || '';
const getSelectedNodeId = (state: RootState): $PropertyType<State, 'selectedNodeId'> =>
  state.ui.selectedNodeId;
const getShowBuilder = (state: RootState): $PropertyType<State, 'showBuilder'> =>
  state.ui.showBuilder;
const getShowErrorModal = (state: RootState): $PropertyType<State, 'showErrorModal'> =>
  state.ui.showErrorModal;
const getShowSavePopover = (state: RootState): $PropertyType<State, 'showSavePopover'> =>
  state.ui.showSavePopover;
const getShowOnboardingModal = (state: RootState): $PropertyType<State, 'showOnboardingModal'> =>
  state.ui.showOnboardingModal;

/* EXPORTS */
const selectors = {
  getElementZoomOn,
  getSelectedAccount,
  getSelectedDomain,
  getSelectedNodeId,
  getShowBuilder,
  getShowErrorModal,
  getShowSavePopover,
  getShowOnboardingModal,
};

export { actions, selectors };
export default reducer;
