// @flow

import _merge from 'lodash/merge';

import type { BotConfig } from '@state/ducks/bots/types';
import type { Exercise, Quest, Quests, ValidatorData } from '@state/ducks/onboarding/types';
import type { AvailableOptions } from '@state/ducks/options/types';

import sorting from '../sorting';
import validateQuest from './validate-quest';

/**
 * Run validators functions on each quests
 */
function validateQuests(
  exerciseId: string,
  category: string,
  step: string,
  botConfig: BotConfig,
  progression: Exercise,
  validatorData: ValidatorData,
  availableOptions: AvailableOptions,
): Quests {
  const quests: Quests = progression.categories[category]?.steps[step]?.quests || {};

  return Object.keys(quests)
    .sort(sorting(quests))
    .reduce((newQuests: Quests, quest: string) => {
      const currentQuestPath: string = `categories.${category}.steps.${step}.quests.${quest}`;
      const currentProgression: Exercise = _merge({}, progression, {
        categories: {
          [category]: {
            steps: {
              [step]: {
                quests: newQuests,
              },
            },
          },
        },
      });

      const updatedQuestProgression: Quest = validateQuest(
        exerciseId,
        currentQuestPath,
        botConfig,
        currentProgression,
        validatorData,
        availableOptions,
      );

      return {
        ...newQuests,
        [quest]: updatedQuestProgression,
      };
    }, {});
}

export default validateQuests;
