// @flow

import type { Node, Transition } from '@state/ducks/bots/types';

const nodesAreLinkedByTransitions = (
  startingNode: string,
  endingNode: string,
  graph: { nodes: Node[], transitions: Transition[] },
): boolean => {
  const { nodes, transitions } = graph;

  const isDirectTransition: boolean = transitions.some(
    (transition: Transition) =>
      transition.startingNode === startingNode && transition.endingNode === endingNode,
  );

  if (isDirectTransition) {
    // is nodes are linked without hook between
    return isDirectTransition;
  }
  const hookNodeIds: string[] = nodes.reduce(
    (ids: string[], node: Node) => (node.action === 'hook' ? [...ids, node.id] : ids),
    [],
  );

  // is nodes are linked with hook between
  return (
    transitions.filter((transition: Transition) => {
      const isTransitionBeforeHook: boolean =
        transition.startingNode === startingNode && hookNodeIds.includes(transition.endingNode);
      const isTransitionAfterHook: boolean =
        hookNodeIds.includes(transition.startingNode) && transition.endingNode === endingNode;

      return isTransitionBeforeHook || isTransitionAfterHook;
    }).length > 1
  );
};

export default nodesAreLinkedByTransitions;
