// @flow
import type { BotAction, Bots as State, GraphDomain, NodeActionGeneric, Transition } from './types';
import type { PayloadAction } from '@reduxjs/toolkit';
import _remove from 'lodash/remove';

type BotActionGraph<K, V> = BotAction<{ domain: GraphDomain, [K]: V }>;

const reducers = {
  createNode: (
    state: State,
    { payload }: PayloadAction<BotActionGraph<'node', NodeActionGeneric>>,
  ) => {
    state.entities[payload.bot].config.graphs[payload.domain].data.nodes.push(payload.node);
  },
  updateNode: (
    state: State,
    { payload }: PayloadAction<BotActionGraph<'node', NodeActionGeneric>>,
  ) => {
    const { nodes } = state.entities[payload.bot].config.graphs[payload.domain].data;
    const nodeIndexToUpdate = nodes.findIndex(previousNode => previousNode.id === payload.node.id);

    nodes[nodeIndexToUpdate] = payload.node;
  },
  deleteNode: (state: State, { payload }: PayloadAction<BotActionGraph<'nodeId', string>>) => {
    const { nodes, transitions } = state.entities[payload.bot].config.graphs[payload.domain].data;
    const indexNodeToDelete = nodes.findIndex(node => node.id === payload.nodeId);

    if (indexNodeToDelete !== -1) nodes.splice(indexNodeToDelete, 1);
    _remove(
      transitions,
      ({ startingNode, endingNode }) =>
        startingNode === payload.nodeId || endingNode === payload.nodeId,
    );
  },
  addNodes: (
    state: State,
    { payload }: PayloadAction<BotActionGraph<'nodes', NodeActionGeneric[]>>,
  ) => {
    state.entities[payload.bot].config.graphs[payload.domain].data.nodes.push(...payload.nodes);
  },
  addTransitions: (
    state: State,
    { payload }: PayloadAction<BotActionGraph<'transitions', Transition[]>>,
  ) => {
    state.entities[payload.bot].config.graphs[payload.domain].data.transitions.push(
      ...payload.transitions,
    );
  },
  addTransition: (
    state: State,
    { payload }: PayloadAction<BotActionGraph<'transition', Transition>>,
  ) => {
    // NOTE: This check should be temporary. It comes from the fact that the edge:connected event is
    // fired multiple times in the Canvas leading to one transition being created multiple times
    const transitionExist: boolean = !!state.entities[payload.bot].config.graphs[
      payload.domain
    ].data.transitions.find(
      (transition: Transition) =>
        transition.id === payload.transition.id &&
        transition.startingNode === payload.transition.startingNode &&
        transition.endingNode === payload.transition.endingNode,
    );

    if (!transitionExist) {
      state.entities[payload.bot].config.graphs[payload.domain].data.transitions.push(
        payload.transition,
      );
    }
  },
  updateTransition: (
    state: State,
    { payload }: PayloadAction<BotActionGraph<'transition', Transition>>,
  ) => {
    const { transition, domain } = payload;
    const { transitions } = state.entities[payload.bot].config.graphs[domain].data;

    const transitionIndexToUpdate = transitions.findIndex(
      previousTransition => previousTransition.id === transition.id,
    );
    if (transitionIndexToUpdate > -1) {
      transitions[transitionIndexToUpdate] = transition;
      if (!transition.intents?.length) delete transitions[transitionIndexToUpdate].intents;
      if (!transition.conditions?.length) delete transitions[transitionIndexToUpdate].conditions;
    }
  },
  deleteTransition: (
    state: State,
    { payload }: PayloadAction<BotActionGraph<'transitionId', string>>,
  ) => {
    state.entities[payload.bot].config.graphs[payload.domain].data.transitions = state.entities[
      payload.bot
    ].config.graphs[payload.domain].data.transitions.filter(
      transition => transition.id !== payload.transitionId,
    );
  },
};

export default reducers;
