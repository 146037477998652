// @flow

import type { BotConfig, Node, Transition, TransitionIntentContent } from '@state/ducks/bots/types';
import type { Exercise, ValidateFnReturn } from '@state/ducks/onboarding/types';

const systemIntentsMasks: string[] = ['Confirm', 'Infirm'];

type AddTransitionsToIntentsValidate = ValidateFnReturn<{
  nodesSayActionPositiveNegative: ?(Node[]),
  transitionsInfirmConfirm: ?(Transition[]),
}>;

/**
 * Validate `addTransitionsToIntents` quest
 * NOTE: Blocked by `linkSayActionClosedQuestionToNegativeAndPositive` quest
 */
function validate(
  botConfig: BotConfig,
  {
    categories: {
      builder: {
        steps: {
          createIntroduction: {
            quests: { linkSayActionClosedQuestionToNegativeAndPositive },
          },
        },
      },
    },
  }: Exercise,
): AddTransitionsToIntentsValidate {
  const nodesSayActionPositiveNegative: ?(Node[]) =
    linkSayActionClosedQuestionToNegativeAndPositive?.others?.nodesSayActionPositiveNegative;
  const transitions: Transition[] =
    linkSayActionClosedQuestionToNegativeAndPositive?.others?.transitions ?? [];
  const transitionsInfirmConfirm: ?(Transition[]) = systemIntentsMasks.reduce(
    (acc: Transition[], systemIntent: string) => {
      const transitionFound: ?Transition = transitions.find(
        (transition: Transition) =>
          transition &&
          transition.intents &&
          transition.intents.length > 0 &&
          transition.intents.some(
            (transitionIntent: TransitionIntentContent[]) =>
              transitionIntent.length === 1 && transitionIntent[0].id === systemIntent,
          ),
      );

      if (transitionFound) return [...acc, transitionFound];
      return acc;
    },
    [],
  );

  return {
    others: {
      transitionsInfirmConfirm,
      nodesSayActionPositiveNegative,
    },
    validated:
      !!nodesSayActionPositiveNegative &&
      !!transitionsInfirmConfirm &&
      transitionsInfirmConfirm.length === systemIntentsMasks.length &&
      nodesSayActionPositiveNegative.every((node: Node) =>
        transitionsInfirmConfirm.some(
          (transition: Transition) => transition.endingNode === node.id,
        ),
      ),
  };
}

export default validate;
