// @flow
import type { SerializedError } from '../app/types';
// $FlowFixMe
import type { EntityState } from '@reduxjs/toolkit';

import type { Status } from '@state/ducks/app/types';

// -------------- ACTION TYPES -------------- //

const FETCH_SNAPSHOTS = 'snapshots/FETCH_SNAPSHOTS';
const FETCH_SNAPSHOTS_ERROR = 'snapshots/FETCH_SNAPSHOTS_ERROR';
const FETCH_SNAPSHOTS_SUCCESS = 'snapshots/FETCH_SNAPSHOTS_SUCCESS';

// --------------- FLOW TYPES --------------- //

type Snapshot = {
  description: string | null,
  isLatest: boolean,
  snapshotId: string,
  state: string,
  time: string, // formatted like 2018-04-27T12:49:19.000Z
  userEmail: string,
  userSub: string,
};

type Snapshots = EntityState<Snapshot> & {
  error: SerializedError,
  status: Status,
};

export type { Snapshot, Snapshots };
export { FETCH_SNAPSHOTS, FETCH_SNAPSHOTS_ERROR, FETCH_SNAPSHOTS_SUCCESS };
