// @flow
import { Auth, CognitoUser } from '@aws-amplify/auth';

/**
 * Confirm a new password request
 *
 * @param {CognitoUser} user the user
 * @param {string} newPassword the new password to set
 * @param {Object} additionnalAttributes the properties required to set the new password
 * @return {Promise<void>}
 */
function completeNewPassword(
  user: CognitoUser,
  newPassword: string,
  additionnalAttributes: Object,
): Promise<void> {
  return Auth.completeNewPassword(user, newPassword, additionnalAttributes);
}

export default completeNewPassword;
