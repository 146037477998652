// @flow

import i18n from 'i18next';

import type { Entities } from '@state/ducks/bots/types';
import type {
  Asset,
  EntityAssetContent,
  EntityContentType,
} from '@state/ducks/customers-assets/types';
import type {
  Answer,
  AnswerChanged,
  EntityTurn,
  IntentTurn,
  IntentTurnExpression,
} from '@state/ducks/training/types';

const entitiesToListForTurn = (
  entities: Entities,
): // $Shape applied because versionId is not available from Entity type
$Shape<EntityTurn>[] =>
  entities && Object.keys(entities).length > 0
    ? Object.keys(entities).map((entityId: string) => {
        const {
          referential: { id, name, type },
          entityMask,
        } = entities[entityId];
        return {
          id,
          name,
          contentType: type,
          entityMask,
          content: {},
          changed: false,
          assetType: 'entities',
          fileType: 'application/json',
          scope: 'account',
        };
      })
    : [];

const entityTurnsListToEntitiesAssetsContentList = (
  entityTurnsList: EntityTurn[],
): $Shape<Asset<EntityAssetContent, EntityContentType>>[] =>
  entityTurnsList.map(({ id, name, contentType, content }: EntityTurn) => ({
    id,
    name,
    contentType,
    content,
    assetType: 'entities',
    scope: 'account',
    fileType: 'application/json',
  }));

const answersListToAnswersChangedList = (answersList: Answer[]): AnswerChanged[] =>
  answersList
    .filter(answer => !!answer.result?.newStatus)
    // $FlowFixMe
    .map(({ answerId, status: oldStatus, nbOccurences, result: { newStatus }, lastUpdatedAt }) => ({
      answerId,
      oldStatus,
      // $FlowFixMe
      newStatus,
      nbOccurences,
      lastUpdatedAt,
    }));

const getExpressionsByIntentTurn = (intentTurn: IntentTurn): string[] => {
  const expressions: IntentTurnExpression[] = intentTurn?.expressions || [];
  return expressions.map(
    (expression: IntentTurnExpression) => expression?.withEntityMasks || expression.origin,
  );
};

const getNotifMessageSaveTrainingAnswers = (answersChangedList: AnswerChanged[]): string => {
  const countAnswersChangedDiscarded: number = answersChangedList.filter(
    (answerChanged: AnswerChanged) => answerChanged?.newStatus === 'DISCARDED',
  ).length;
  const countAnswersChangedTrained: number = answersChangedList.filter(
    (answerChanged: AnswerChanged) => answerChanged?.newStatus === 'TRAINED',
  ).length;

  return `${
    countAnswersChangedTrained > 0
      ? i18n.t('Bot.Builder.views.Training.AnswerTraining.save.answersTrained', {
          ns: 'views',
          count: countAnswersChangedTrained,
        })
      : ''
  }`
    .concat(`${countAnswersChangedTrained > 0 && countAnswersChangedDiscarded > 0 ? ' ' : ''}`)
    .concat(
      `${
        countAnswersChangedDiscarded > 0
          ? i18n.t('Bot.Builder.views.Training.AnswerTraining.save.answersDiscarded', {
              ns: 'views',
              count: countAnswersChangedDiscarded,
            })
          : ''
      }` || '',
    );
};

export {
  answersListToAnswersChangedList,
  entitiesToListForTurn,
  entityTurnsListToEntitiesAssetsContentList,
  getExpressionsByIntentTurn,
  getNotifMessageSaveTrainingAnswers,
};
