/* eslint-disable max-lines */
// @flow

import type { Bot, BotAction, BotApiError, BotConfig, Bots as BotsState } from './types';
import type { PayloadAction } from '@reduxjs/toolkit';
import _omit from 'lodash/omit';

import type { Status } from '@state/ducks/app/types';
import type { IntentsMap } from '@state/ducks/intents/types';

import { botsAdapter } from './slice';
import { getCheckpointsFromNodes, getDefaultBot } from './utils';

const reducers = {
  /*  LOADING BOT CONFIG */
  botLoad: (state: BotsState, { payload: bot }: PayloadAction<string>) => {
    botsAdapter.addOne(state, getDefaultBot(bot));
    state.entities[bot].status.load = 'IN_PROGRESS';
  },
  botLoadError: (
    state: BotsState,
    { payload: { bot, errors } }: PayloadAction<BotAction<$Exact<{ errors: string[] }>>>,
  ) => {
    state.entities[bot].status.load = 'ERROR';
    state.entities[bot].errors.load.list = errors;
  },
  botLoadSuccess: (
    state: BotsState,
    {
      payload: { bot, botConfig, snapshotId },
    }: PayloadAction<BotAction<$Exact<{ botConfig: BotConfig, snapshotId: string }>>>,
  ) => {
    const botConfigWithoutChatAPIKey: BotConfig = _omit(botConfig, ['chatAPIKey']);

    // NOTE: Use type casting here because these lines are the only ones where
    // the `intents` key in BotConfig is still an `IntentsMap` type
    const botConfigIntents: IntentsMap = ((botConfig.intents: any): IntentsMap);
    const botConfigIntentsIds: string[] = Object.keys(botConfigIntents);
    const botConfigFormatted: BotConfig = {
      ...botConfigWithoutChatAPIKey,
      ...(!botConfigWithoutChatAPIKey.statsCheckpoints
        ? { statsCheckpoints: getCheckpointsFromNodes(botConfigWithoutChatAPIKey.graphs) }
        : {}),
      intents: botConfigIntentsIds,
    };

    state.entities[bot].status.load = 'SUCCESS';
    state.entities[bot].loadedSnapshotId = snapshotId;
    state.entities[bot].config = botConfigFormatted;
    state.entities[bot].lastConfigSaved = botConfigFormatted;

    delete state.entities[bot].evaluation;
  },
  /* CHECK BOT */
  checkBot: (state: BotsState, { payload: bot }: PayloadAction<string>) => {
    state.entities[bot].status.check = 'IN_PROGRESS';
  },
  checkBotSuccess: (state: BotsState, { payload: bot }: PayloadAction<string>) => {
    state.entities[bot].status.check = 'SUCCESS';
    state.entities[bot].errors.check = { list: [] };
  },
  checkBotError: (
    state: BotsState,
    { payload: { bot, errors } }: PayloadAction<BotAction<$Exact<{ errors: BotApiError[] }>>>,
  ) => {
    state.entities[bot].status.check = errors.every(({ severity }) => severity === 'warning')
      ? 'WARNING'
      : 'ERROR';
    state.entities[bot].errors.check.list = errors;
  },
  createNewBot: (
    state: BotsState,
    { payload: { bot, data } }: PayloadAction<BotAction<$Exact<{ data: Bot }>>>,
  ) => {
    const newBot = {
      id: bot,
      ...data,
    };
    botsAdapter.upsertOne(state, newBot);
  },

  /* DEPLOYING BOT */
  deployBot: (state: BotsState, { payload: bot }: PayloadAction<string>) => {
    state.entities[bot].status.deploy = 'IN_PROGRESS';
  },
  deployBotSuccess: (state: BotsState, { payload: bot }: PayloadAction<string>) => {
    state.entities[bot].status.deploy = 'SUCCESS';
    state.entities[bot].errors.publish = { list: [] };
  },
  deployBotError: (
    state: BotsState,
    { payload: { bot, errors } }: PayloadAction<BotAction<$Exact<{ errors: BotApiError[] }>>>,
  ) => {
    state.entities[bot].status.deploy = 'ERROR';
    state.entities[bot].errors.publish.list = errors;
  },
  /* IMPORT INTENTS */
  importIntents: (state: BotsState, { payload }: PayloadAction<BotAction<string>>) => {
    state.entities[payload].status.importIntents = 'IN_PROGRESS';
  },
  importIntentsFailure: (
    state: BotsState,
    { payload: { bot, error } }: PayloadAction<BotAction<$Exact<{ error: string }>>>,
  ) => {
    state.entities[bot].status.importIntents = 'ERROR';
    state.entities[bot].errors.importIntents = error;
  },
  importIntentsSuccess: (state: BotsState, { payload: bot }: PayloadAction<string>) => {
    state.entities[bot].status.importIntents = 'SUCCESS';
  },
  resetCheckBotStatus: (state: BotsState, { payload: bot }: PayloadAction<string>) => {
    state.entities[bot].status.check = 'NONE';
  },
  resetDeployBotStatus: (state: BotsState, { payload: bot }: PayloadAction<string>) => {
    state.entities[bot].status.deploy = 'NONE';
  },
  resetImportIntentsStatus: (state: BotsState, { payload: bot }: PayloadAction<string>) => {
    state.entities[bot].status.importIntents = 'NONE';
  },
  resetSaveBotStatus: (state: BotsState, { payload: bot }: PayloadAction<string>) => {
    state.entities[bot].status.save = 'NONE';
    state.entities[bot].errors.save.list = [];
  },
  /* SAVE BOT */
  saveBot: (state: BotsState, { payload: bot }: PayloadAction<string>) => {
    state.entities[bot].status.save = 'IN_PROGRESS';
  },
  saveBotError: (
    state: BotsState,
    { payload: { bot, errors } }: PayloadAction<BotAction<$Exact<{ errors: BotApiError[] }>>>,
  ) => {
    state.entities[bot].status.save = 'ERROR';
    state.entities[bot].errors.save.list = errors;
  },
  saveBotSuccess: (
    state: BotsState,
    { payload: { bot, snapshotId } }: PayloadAction<BotAction<$Exact<{ snapshotId: string }>>>,
  ) => {
    state.entities[bot].status.save = 'SUCCESS';
    state.entities[bot].loadedSnapshotId = snapshotId;
    state.entities[bot].lastConfigSaved = state.entities[bot].config;
  },
  setSpecificBotsStatus: (
    state: BotsState,
    {
      payload: { name, value },
    }: PayloadAction<{ name: 'fetching' | 'delete' | 'create', value: Status }>,
  ) => {
    state.status[name] = value;
  },

  /* DUPLICATE BOT */
  updateDuplicateBotStatus: (
    state: BotsState,
    { payload: { bot, status } }: PayloadAction<BotAction<$Exact<{ status: Status }>>>,
  ) => {
    // In case we want to set the status for the created bot, we might want to set the status of an non-existing key
    if (!state.entities[bot]) {
      state.entities[bot] = getDefaultBot(bot);
    }
    state.entities[bot].status.duplicate = status;
  },
  updateError: (state: BotsState, { payload: error }: PayloadAction<string>) => {
    state.error = error;
  },
  /* VALIDATE BOT CONFIG */
  validateBot: (state: BotsState, { payload: bot }: PayloadAction<string>) => {
    state.entities[bot].status.validate = 'IN_PROGRESS';
  },
  validateBotError: (
    state: BotsState,
    { payload }: PayloadAction<BotAction<$Exact<{ errors: BotApiError[] }>>>,
  ) => {
    const { bot, errors } = payload;

    state.entities[bot].status.validate = 'ERROR';
    state.entities[bot].errors.validate.list = errors;
  },
  validateBotSuccess: (
    state: BotsState,
    { payload }: PayloadAction<BotAction<$Exact<{ hints: BotApiError[] }>>>,
  ) => {
    const { bot, hints } = payload;

    state.entities[bot].status.validate = 'SUCCESS';
    state.entities[bot].errors.validate.buildingErrors = hints;
  },
};

export default reducers;
