/* eslint-disable max-lines */
// @flow

import type { PayloadAction } from '@reduxjs/toolkit';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import _differenceBy from 'lodash/differenceBy';
import _trim from 'lodash/trim';

import type { SerializedError, State as RootState, Status } from '@state/ducks/app/types';
import type {
  Assessments as State,
  Dataset,
  DatasetContent,
  DatasetResult,
  DatasetResults,
  DatasetUtterance,
  NominalThunkArgs,
} from '@state/ducks/assessments/types';
import type { BotConfig, LoadedBotConfig, Nlu } from '@state/ducks/bots/types';
import type { AssessmentContentType, AssetBase } from '@state/ducks/customers-assets/types';
import type {
  IntentsEvaluations,
  IntentsMap,
  UtteranceEvaluation,
} from '@state/ducks/intents/types';
import { api as assessmentsAPI } from '@state/ducks/assessments';
import { formatBotConfig, formatIntentsForBotConfig } from '@state/ducks/bots/utils';
import { api as customersAssetsAPI } from '@state/ducks/customers-assets';
import { selectors as intentsSelectors } from '@state/ducks/intents';

const STATUS_REGEX = '(pending|fulfilled|rejected)';
const OPERATIONS_REGEX =
  '(create|evaluate|fetch|update|delete)(Dataset|DatasetsBase|DatasetResultsBase|DatasetResult[s]?|Utterance[s]?)';
const TIMESTAMP_REGEX = /\d{13}/;

const assessmentsAdapter = createEntityAdapter({ selectId: assessment => assessment.dataset.id });

const initialState = assessmentsAdapter.getInitialState({
  status: {
    create: 'NONE',
    evaluate: 'NONE',
    fetch: 'NONE',
    update: 'NONE',
  },
  errors: {
    create: null,
    evaluate: null,
    fetch: null,
    update: null,
  },
});

/* API HANDLERS */

// Dataset //
const fetchDatasetsBase = createAsyncThunk(
  'assessments/fetchDatasetsBase',
  async ({ accountId, botId }: NominalThunkArgs) =>
    customersAssetsAPI.getAllAssets({
      accountId,
      assetType: 'assessments',
      scope: 'bot',
      botId,
    }),
);
const fetchDataset = createAsyncThunk(
  'assessments/fetchDataset',
  async ({
    id,
    accountId,
    botId,
    versionId,
  }: NominalThunkArgs & { id: string, versionId?: string }) =>
    customersAssetsAPI.getAsset({
      assetId: id,
      accountId,
      botId,
      assetType: 'assessments',
      scope: 'bot',
      versionId,
    }),
  {
    condition: ({ id }, { getState }) => {
      const { assessments } = getState();
      return !assessments.entities[id]?.dataset.content;
    },
  },
);
const createDataset = createAsyncThunk(
  'assessments/createDataset',
  async ({
    accountId,
    botId,
    name,
    contentType,
    datasetContent,
  }: NominalThunkArgs & {
    name: string,
    contentType: AssessmentContentType,
    datasetContent: DatasetContent,
  }) =>
    customersAssetsAPI.createAsset({
      accountId,
      botId,
      scope: 'bot',
      name,
      contentType,
      content: datasetContent,
      assetType: 'assessments',
    }),
);
const updateDataset = createAsyncThunk(
  'assessments/updateDataset',
  async ({
    id,
    accountId,
    botId,
    name,
    contentType,
    datasetContent,
  }: NominalThunkArgs & {
    id: string,
    name: string,
    contentType: AssessmentContentType,
    datasetContent: DatasetContent,
  }) =>
    customersAssetsAPI.updateAsset({
      assetId: id,
      accountId,
      botId,
      id,
      scope: 'bot',
      name,
      contentType,
      content: datasetContent,
      assetType: 'assessments',
    }),
);
const deleteDataset = createAsyncThunk(
  'assessments/deleteDataset',
  async ({ id, accountId, botId }: NominalThunkArgs & { id: string }) => {
    const results = await customersAssetsAPI.getAllAssets({
      accountId,
      assetType: 'assessments',
      scope: 'bot',
      botId,
      subPath: `/${id}/results/`,
    });

    await Promise.all([
      customersAssetsAPI.deleteAsset({
        assetId: id,
        accountId,
        botId,
        assetType: 'assessments',
        scope: 'bot',
      }),
      results
        .map(result =>
          customersAssetsAPI.deleteAsset({
            assetId: result.id,
            accountId,
            botId,
            assetType: 'assessments',
            scope: 'bot',
            subPath: `/${id}/results/`,
          }),
        )
        .flat(),
    ]);
  },
);

// Dataset results //
const fetchDatasetResultsBase = createAsyncThunk(
  'assessments/fetchDatasetResultsBase',
  async ({ accountId, datasetId, botId }: NominalThunkArgs & { datasetId: string }) =>
    customersAssetsAPI.getAllAssets({
      accountId,
      assetType: 'assessments',
      scope: 'bot',
      botId,
      subPath: `/${datasetId}/results/`,
    }),
);
const fetchDatasetResult = createAsyncThunk(
  'assessments/fetchDatasetResult',
  async ({
    id,
    accountId,
    datasetId,
    botId,
  }: NominalThunkArgs & { id: string, datasetId: string }) =>
    customersAssetsAPI.getAsset({
      assetId: id,
      botId,
      accountId,
      assetType: 'assessments',
      scope: 'bot',
      subPath: `/${datasetId}/results/`,
    }),
  {
    condition: ({ datasetId, id }, { getState }) => {
      const { assessments } = getState();
      return !assessments.entities[datasetId]?.results[id].content && !TIMESTAMP_REGEX.test(id);
    },
  },
);
const createDatasetResult = createAsyncThunk(
  'assessments/createDatasetResult',
  async (
    {
      accountId,
      botId,
      scope = 'bot',
      timestampId,
      datasetId,
    }: NominalThunkArgs & {
      datasetId: string,
      timestampId: string,
    },
    { getState },
  ) => {
    const { assessments }: RootState = getState();
    const { name, contentType, content, matchingScore, nluEngine, nluConfidenceCutoff } =
      assessments.entities[datasetId].results[timestampId];

    return customersAssetsAPI.createAsset({
      accountId,
      botId,
      scope,
      name,
      contentType,
      content,
      assetType: 'assessments',
      subPath: `/${datasetId}/results/`,
      metadata: {
        'matching-score': matchingScore,
        'nlu-engine': nluEngine,
        'nlu-confidence-cutoff': nluConfidenceCutoff,
      },
    });
  },
);
const evaluateUtterances = createAsyncThunk(
  'assessments/evaluateUtterances',
  async (
    {
      account,
      bot,
      datasetId,
      parameters: { nlu },
    }: {
      account: string,
      bot: string,
      datasetId: string,
      parameters: { nlu: Nlu },
    },
    { getState },
  ) => {
    const { assessments, bots, intents }: RootState = getState();
    const botConfig: BotConfig = bots.entities[bot].config;
    const intentsMap: IntentsMap = intentsSelectors.selectEntities({ intents });

    const formattedIntentsMap: IntentsMap = formatIntentsForBotConfig({
      intentsListFromBotsDuck: botConfig.intents,
      intentsMapFromIntentsDuck: intentsMap,
      additionalPropsForAccountScope: ['entities', 'utterances', 'label'],
    });
    const formattedBotConfig: LoadedBotConfig = formatBotConfig(botConfig);
    const botConfigWithNluParams: LoadedBotConfig = {
      ...formattedBotConfig,
      intents: formattedIntentsMap,
      nlu,
    };
    const utterances = (
      assessments.entities?.[datasetId]?.dataset.content?.utterances || []
    ).filter(({ label }) => label);

    return assessmentsAPI.evaluateUtterances({
      account,
      bot,
      botConfig: botConfigWithNluParams,
      utterances,
    });
  },
);
const deleteResult = createAsyncThunk(
  'assessments/deleteResult',
  async ({
    id,
    datasetId,
    accountId,
    botId,
  }: NominalThunkArgs & { id: string, datasetId: string }) => {
    await customersAssetsAPI.deleteAsset({
      assetId: id,
      accountId,
      botId,
      assetType: 'assessments',
      scope: 'bot',
      subPath: `/${datasetId}/results/`,
    });
  },
);

/* ACTIONS & REDUCERS */

const WHITESPACE_REGEXP: RegExp = /\s\s+/g;
const NEW_LINE_REGEXP: RegExp = /\n/g;

const { actions, reducer } = createSlice({
  name: 'assessments',
  initialState,
  reducers: {
    deleteUtterances(
      state: State,
      {
        payload: { datasetId, utterances },
      }: PayloadAction<{ datasetId: string, utterances: DatasetUtterance[] }>,
    ) {
      const newUtterances = _differenceBy(
        state.entities[datasetId].dataset.content.utterances,
        utterances,
        'utterance',
      );

      state.entities[datasetId].dataset.content.utterances = newUtterances;
    },
    updateUtterance(
      state: State,
      {
        payload: { datasetId, utterances: newUtterances },
      }: PayloadAction<{
        datasetId: string,
        utterances: { newUtterance: DatasetUtterance, index: number }[],
      }>,
    ) {
      const currUtterances: DatasetUtterance[] =
        state.entities[datasetId].dataset.content.utterances;

      newUtterances.forEach(({ newUtterance: { utterance, label }, index }) => {
        if (index > -1 && utterance) {
          currUtterances[index] = {
            utterance: utterance.replace(WHITESPACE_REGEXP, '').replace(NEW_LINE_REGEXP, ' '),
            ...(label && { label }),
          };
        }
      });
    },
    updateDatasetBasicInfo(
      state: State,
      {
        payload: { id, name, description },
      }: PayloadAction<{ id: string, name: string, description: string }>,
    ) {
      state.entities[id].dataset.name = name;
      state.entities[id].dataset.content.description = description;
    },
    addUtterance(
      state: State,
      {
        payload: { datasetId, utterances },
      }: PayloadAction<{ datasetId: string, utterances: DatasetUtterance[] }>,
    ) {
      const stateUtterances = state.entities[datasetId].dataset.content.utterances;
      const formattedUtterances = utterances.reduce(
        (acc, { utterance: newUtterance, label }) =>
          !stateUtterances.some(({ utterance }) => newUtterance === utterance)
            ? [...acc, { utterance: _trim(newUtterance), ...(label && { label }) }]
            : acc,
        [],
      );

      state.entities[datasetId].dataset.content.utterances = [
        ...stateUtterances,
        ...formattedUtterances,
      ];
    },
    setDatasetResultName(
      state: State,
      {
        payload: { timestampId, name, datasetId },
      }: PayloadAction<{ id: string, datasetId: string, name: string }>,
    ) {
      state.entities[datasetId].results[timestampId].name = name;
    },
    setSpecificStatus(
      state: State,
      {
        payload: { statusKey, statusValue },
      }: PayloadAction<{ statusKey: string, statusValue: Status }>,
    ) {
      state.status[statusKey] = statusValue;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        fetchDatasetsBase.fulfilled,
        (state: State, { payload }: PayloadAction<Dataset[]>) => {
          assessmentsAdapter.setMany(
            state,
            payload
              .flat()
              // Don't want to store fetch base since the dataset is not yet saved
              .filter(dataset => !state.entities[dataset.id]?.dataset.content)
              .map(dataset => ({ dataset })),
          );
        },
      )
      .addCase(
        fetchDatasetResultsBase.fulfilled,
        (
          state: State,
          { payload, meta }: PayloadAction<Dataset[]> & { meta: { arg: { datasetId: string } } },
        ) => {
          const { arg } = meta;
          const formattedDatasetResults = Object.fromEntries(
            payload.map(datasetResult => [datasetResult.id, datasetResult]),
          );
          state.entities[arg.datasetId].results = formattedDatasetResults;
        },
      )
      .addCase(
        deleteDataset.fulfilled,
        (state: State, { meta }: { meta: { arg: { id: string } } }) =>
          assessmentsAdapter.removeOne(state, meta.arg.id),
      )
      .addCase(
        deleteResult.fulfilled,
        (state: State, { meta }: { meta: { arg: { id: string, datasetId: string } } }) => {
          const { id, datasetId } = meta.arg;

          delete state.entities[datasetId].results[id];
        },
      )
      .addCase(
        evaluateUtterances.fulfilled,
        (
          state: State,
          {
            payload: { scores, classifiedSamples },
            meta: {
              arg: { datasetId, parameters },
            },
          }: PayloadAction<{
            classifiedSamples: UtteranceEvaluation[],
            scores: IntentsEvaluations,
          }> & {
            meta: { arg: { datasetId: string }, datasetId: string, parameters: { nlu: Nlu } },
          },
        ) => {
          const timestamp: number = Date.now();
          // TODO: to improve when we will save new test results in customer-assets bucket
          state.entities[datasetId].results = {
            ...state.entities[datasetId].results,
            [timestamp]: {
              assetType: 'assessments',
              content: { classifiedSamples, scores, parameters, dataset: { id: datasetId } },
              contentType: 'intents',
              fileType: 'application/json',
              id: timestamp,
              lastModified: timestamp,
              name: `result-${Math.round(timestamp / 1000)}`,
              scope: 'bot',
              nluConfidenceCutoff: parameters.nlu.confidenceCutoff.toString(),
              nluEngine: parameters.nlu.engine,
              ...('matchingScore' in scores
                ? { matchingScore: scores.matchingScore.toString() }
                : {}),
            },
          };
        },
      )
      .addCase(
        createDatasetResult.fulfilled,
        (state: State, { meta }: { meta: { arg: { timestampId: string, datasetId: string } } }) => {
          const { arg } = meta;
          // delete the temporary result when is persist in DB to replace with the "real" asset
          delete state.entities[arg.datasetId].results[arg.timestampId];
        },
      )
      .addMatcher(
        action => /assessments\/(create|fetch|update)Dataset\/fulfilled/.test(action.type),
        (state: State, { payload }: PayloadAction<Dataset>) => {
          assessmentsAdapter.upsertOne(state, { dataset: payload });
        },
      )
      .addMatcher(
        action => /assessments\/(create|fetch)DatasetResult\/fulfilled/.test(action.type),
        (
          state: State,
          {
            payload,
            meta,
          }: PayloadAction<DatasetResult[]> & { meta: { arg: { datasetId: string } } },
        ) => {
          const { arg } = meta;
          const { assetSignedUrl, ...datasetResult } = payload;
          state.entities[arg.datasetId].results[payload.id] = datasetResult;
        },
      )
      .addMatcher(
        action => RegExp(`assessments/${OPERATIONS_REGEX}/${STATUS_REGEX}`, 'g').test(action.type),
        (state, { error, type }: PayloadAction<>) => {
          const statusMatrix = {
            pending: 'IN_PROGRESS',
            fulfilled: 'SUCCESS',
            rejected: 'ERROR',
          };
          const [operations] = type.match(/(create|evaluate|fetch|update|delete)/g);
          const [status] = type.match(/(pending|fulfilled|rejected)/g);

          state.errors[operations] = status === 'rejected' ? error : null;
          state.status[operations] = statusMatrix[status];
        },
      );
  },
});

Object.assign(actions, {
  createDataset,
  createDatasetResult,
  deleteDataset,
  deleteResult,
  evaluateUtterances,
  fetchDataset,
  fetchDatasetResult,
  fetchDatasetResultsBase,
  fetchDatasetsBase,
  updateDataset,
});

/* SELECTORS */
const genericSelectors = assessmentsAdapter.getSelectors((state: RootState) => state.assessments);

const selectResultsByDatasetId: AssetBase<AssessmentContentType>[] = createSelector(
  (state: RootState, datasetId: string) => state.assessments.entities[datasetId]?.results,
  (results: ?DatasetResults) => Object.values(results || {}),
);

const selectDatasetResult = (state: RootState, { datasetId, id }): DatasetResult =>
  state.assessments.entities[datasetId].results[id];

const selectSpecificStatus = (state: State, status: string): Status =>
  state.assessments.status[status];

const selectSpecificError = (state: RootState, errorType: string): ?SerializedError =>
  state.assessments.errors[errorType];

const selectors = {
  ...genericSelectors,
  selectSpecificError,
  selectSpecificStatus,
  selectResultsByDatasetId,
  selectDatasetResult,
};

export { actions, selectors };
export default reducer;
