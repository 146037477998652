// @flow

import type { Account } from './types';
import _union from 'lodash/union';

const getDefaultAccount = (): Account => ({
  accountId: '',
  bots: [],
  authorizations: {},
  credentials: {
    status: 'NONE',
    error: null,
    data: [],
  },
});

const getDefaultAccounts = (accounts: string[]): Account[] =>
  accounts.map(accountId => ({ ...getDefaultAccount(), accountId }));

const updateBots = (currentBots: string[], newBots: string[]): string[] =>
  currentBots.length > newBots.length
    ? /*
       * Case if we want update with a deleted bot => the entry bots array is
       * less big than existing array so we don't need to union this two array
       */
      // Without copy, the error "Cannot assign to read only property of object" occurs
      [...newBots].sort()
    : _union(currentBots, newBots).sort();

export { getDefaultAccount, getDefaultAccounts, updateBots };
