// @flow

import type { TranscriptApiPayload, TranscriptApiResource, TranscriptApiResponse } from './types';

import type { StorageSettings } from '@state/ducks/app/types';
import request from '@amplify/api/request';
import { formatStorageSettingsHeaders } from '@assets/js/calldesk-app-util/format';
import logger from '@assets/js/calldesk-app-util/logger';

const fetchTranscript = async (
  selectedAccount: string,
  bot: string,
  conversationId: string,
  discussionStartTime: number,
  storageSettings: StorageSettings,
): Promise<TranscriptApiResource | TranscriptApiPayload> => {
  logger.info('state/accounts/api [fetchTranscript] Retrieving transcript for a conversation');

  let response: TranscriptApiResource | TranscriptApiPayload;

  try {
    const { payload }: TranscriptApiResponse = await request.conversationsApi({
      path: `/accounts/${selectedAccount}/bots/${bot}/conversations/${conversationId}/transcript`,
      queryStringParameters: { discussionStartTime },
      method: 'GET',
      headers: formatStorageSettingsHeaders(storageSettings),
    });

    response = payload;
  } catch (error) {
    logger.info(
      'state/transcripts/api [fetchTranscript] Error while retrieving transcript',
      error.message,
    );
    throw error;
  }

  return response;
};

export { fetchTranscript };
