/* eslint-disable max-lines */
// @flow

import type { RouterHistory } from 'react-router-dom';
import type { Meta } from 'antd-form-builder';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { WarningTwoTone } from '@ant-design/icons';
import _entries from 'lodash/entries';
import _groupBy from 'lodash/groupBy';

import {
  CDKButton,
  CDKCascader,
  CDKDraggerUpload,
  CDKForm,
  CDKFormBuilder,
  CDKInput,
  CDKLoadingSpin,
  CDKModal,
  cdkNotification,
  CDKRadio,
  CDKSelect,
  CDKSkeleton,
  CDKSwitch,
  showCDKInfo,
  useForm,
} from '@calldesk/components';

import type { Status, StorageSettings } from '@state/ducks/app/types';
import type { Bot, BotLanguage } from '@state/ducks/bots/types';
import type { BotTemplate, Languages } from '@state/ducks/options/types';
import { normalizeNewBotName } from '@assets/js/calldesk-app-util/format';
import CDKRadioCard from '@assets/js/calldesk-components/molecules/RadioCard';
import { getFlagForRegion } from '@localization/utils';
import { selectors as accountsSelectors } from '@state/ducks/accounts';
import { operations as botsOperations, selectors as botsSelectors } from '@state/ducks/bots';
import { fetchBotsForAccount } from '@state/ducks/bots/api';
import {
  operations as optionsOperations,
  selectors as optionsSelectors,
} from '@state/ducks/options';
import { selectors as uiSelectors } from '@state/ducks/ui';

import {
  BASIC_BOT_NAME_REGEXP,
  botTemplateContent,
  getDraggerUploadProps,
  NEGATE_BOT_ERROR,
  RESERVED_BOT_NAME_ERROR,
  RESERVED_BOT_NAME_REGEXP,
} from './utils';

import './new-bot-modal.css';

type Props = {
  close: () => void,
};

type FormProps = {
  name: string,
  language: BotLanguage,
  timezone: string,
  transcripts: File[],
  botTemplate?: {
    name: string,
    id: string,
  },
  storageSettings: [string, string],
};

/**
 * NewBotModal function (for lifecycle hooks)
 * TODO : Split this component logic into smaller parts
 */
function NewBotModal({ close }: Props) {
  /** HOOKS */
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history: RouterHistory = useHistory();
  const [form] = useForm();
  const forceUpdate = CDKFormBuilder.useForceUpdate();

  /** STATE */
  const [availableTemplates, setAvailableTemplates] = useState<string[]>([]);
  const [fetchingStatus, setFetchingStatus] = useState<Status>('NONE');
  const [uploadError, setUploadError] = useState<string | null>('');

  /** SELECTORS */
  const selectedAccount: string = useSelector(uiSelectors.getSelectedAccount);
  const newBot: Bot | null = useSelector(state =>
    botsSelectors.getBot(state, form.getFieldValue('name') || ''),
  );
  const createStatus: Status = useSelector(state =>
    botsSelectors.getSpecificBotsStatus(state, 'create'),
  );
  const bots: string[] = useSelector(state => accountsSelectors.getBots(state, selectedAccount));
  const createBotError: string | null = useSelector(botsSelectors.getError);
  const {
    'bot-templates': botTemplatesOptions,
    languages: languagesOptions,
    timezones: timezonesOptions,
    'storage-regions': storageRegions,
  }: {
    'bot-templates': BotTemplate[],
    languages: Languages,
    timezones: string[],
    'storage-regions': StorageSettings[],
  } = useSelector(state =>
    optionsSelectors.selectEntitiesOptions(state, [
      'bot-templates',
      'languages',
      'timezones',
      'storage-regions',
    ]),
  );

  /** DISPATCHES */
  const fetchAvailableOptions = () =>
    dispatch(
      optionsOperations.fetchAvailableOptions({
        selectedAccount,
        optionsNameFilter: ['bot-templates', 'languages', 'timezones', 'storage-regions'],
      }),
    );

  const submitNewBotForm = (
    botName: string,
    botLanguage: BotLanguage,
    botTimezone: string,
    autoPilotFiles: File[],
    botTemplate: null | { account: string, botName: string, id: string },
    storageSettings: [string, string],
  ): void =>
    dispatch(
      botsOperations.createBotSaga({
        accountId: selectedAccount,
        botName,
        botLanguage,
        botTimezone,
        autoPilotFiles,
        bots,
        botTemplate,
        storageSettings: { region: storageSettings[1], provider: storageSettings[0] },
      }),
    );

  /** CONSTANTS */
  const languageFieldValue: BotLanguage = form.getFieldValue('language');
  const useTemplateFieldValue: boolean = form.getFieldValue('useTemplate');
  const isLoading: boolean = createStatus === 'IN_PROGRESS';
  const ACCOUNT_TEMPLATES = `calldesk-templates-${(languageFieldValue || 'en-US').toLowerCase()}`;

  const draggerUploadField = form.getFieldValue('uploadTranscript')
    ? [
        {
          colSpan: 2,
          key: 'transcripts',
          widget: CDKDraggerUpload,
          initialValue: [],
          widgetProps: {
            contentText: t('NewBotModal.uploadTranscript.dragger.text', {
              ns: 'views',
            }),
            hintText: t('NewBotModal.uploadTranscript.dragger.hint', { ns: 'views' }),
            uploadError,
            uploadProps: getDraggerUploadProps(
              form.getFieldValue('transcripts') || [],
              selectedAccount,
              uploadedFile => form.setFieldValue('transcripts', uploadedFile),
              setUploadError,
            ),
          },
        },
      ]
    : [];

  const botTemplatesField = useTemplateFieldValue
    ? [
        {
          colSpan: 2,
          key: 'botTemplate',
          widget: () => (
            <div className="template-type-cards-container">
              {botTemplatesOptions?.map((template: BotTemplate) => {
                const isAvailable: boolean = availableTemplates?.includes(template.name);
                const botTemplateFieldValue: BotTemplate = form.getFieldValue('botTemplate');
                const selectOnboardingTemplateByDefault =
                  !botTemplateFieldValue && isAvailable && template.name === 'onboarding';

                if (selectOnboardingTemplateByDefault)
                  form.setFieldValue('botTemplate', {
                    name: template.name,
                    id: template.id,
                  });

                return (
                  <CDKRadioCard
                    key={template.id}
                    content={botTemplateContent(template.name)}
                    customClassName={
                      isAvailable ? 'template-type-card' : 'template-type-card disabled'
                    }
                    isSelected={
                      botTemplateFieldValue?.id === template.id || selectOnboardingTemplateByDefault
                    }
                    isDisabled={!isAvailable}
                    isLoading={fetchingStatus === 'IN_PROGRESS'}
                    label={t(`NewBotModal.templates.${template.name}.title`, {
                      ns: 'views',
                    })}
                    onClickTarget={() =>
                      form.setFieldValue('botTemplate', {
                        name: template.name,
                        id: template.id,
                      })
                    }
                    extra={
                      <CDKRadio
                        checked={
                          botTemplateFieldValue?.id === template.id ||
                          selectOnboardingTemplateByDefault
                        }
                        disabled={!isAvailable}
                      />
                    }
                  />
                );
              })}
            </div>
          ),
        },
      ]
    : [];

  const metaBasicForm: Meta = {
    formItemLayout: [3, 20],
    fields: [
      {
        key: 'name',
        label: t(`NewBotModal.Form.Name.label`, {
          ns: 'views',
        }),
        widget: CDKInput,
        placeholder: t('NewBotModal.Form.Name.placeholder', {
          ns: 'views',
        }),
        hasFeedback: true,
        normalize: normalizeNewBotName,
        required: true,
        rules: [
          {
            validator: async (rule, value) => {
              if (!BASIC_BOT_NAME_REGEXP.test(value)) throw new Error(NEGATE_BOT_ERROR);

              if (RESERVED_BOT_NAME_REGEXP.test(value)) throw new Error(RESERVED_BOT_NAME_ERROR);

              if (bots.includes(value))
                throw new Error(
                  t(`NewBotModal.Form.Name.Errors.name-conflict`, {
                    ns: 'views',
                  }),
                );
            },
          },
        ],
      },
      {
        key: 'language',
        label: t(`NewBotModal.Form.Language.label`, {
          ns: 'views',
        }),
        widget: CDKSelect,
        initialValue: 'en-US',
        required: true,
        widgetProps: {
          options: Object.entries(languagesOptions || {}).map(([code, language]) => ({
            /* $FlowFixMe Object.entries returns mixed type https://github.com/facebook/flow/issues/5838 */
            label: language?.name,
            value: code,
          })),
        },
        disabled: !languagesOptions,
      },
      {
        key: 'timezone',
        label: t(`NewBotModal.Form.Timezone.label`, {
          ns: 'views',
        }),
        initialValue: 'Europe/Paris',
        widget: CDKSelect,
        required: true,
        widgetProps: {
          options: (timezonesOptions || []).map(timezone => ({
            label: timezone,
            value: timezone,
          })),
        },
        disabled: !timezonesOptions,
      },
      {
        key: 'storageSettings',
        label: t(`NewBotModal.Form.StorageSettings.label`, {
          ns: 'views',
        }),
        initialValue: ['aws', 'eu-west-1'],
        widget: CDKCascader,
        help: (
          <span className="hint-with-icon">
            <WarningTwoTone twoToneColor="orange" />
            {t(`NewBotModal.Form.StorageSettings.hint`, {
              ns: 'views',
            })}
          </span>
        ),
        extra: t(`NewBotModal.Form.StorageSettings.extra`, {
          ns: 'views',
        }),
        required: true,
        tooltip: t(`NewBotModal.Form.StorageSettings.tooltip`, { ns: 'views' }),
        widgetProps: {
          options: _entries(_groupBy(storageRegions || [], 'provider')).map(([key, value]) => ({
            value: key,
            label: key.toUpperCase(),
            children: value.map(({ label, region }: { label: string, region: string }) => ({
              label: `${getFlagForRegion(region)} ${label
                .split('/')
                .slice(-1)[0]
                ?.replace('_', ' ')}`,
              value: region,
            })),
          })),
          displayRender: (path: string[]) => path.slice(-1)[0],
          allowClear: false,
        },
        disabled: !storageRegions,
      },
    ],
  };
  const metaAdditionalForm: Meta = {
    columns: 2,
    formItemLayout: [14, 32],
    fields: [
      {
        colSpan: 2,
        render() {
          return (
            <fieldset>
              <legend>
                {t('NewBotModal.subTitle', {
                  ns: 'views',
                })}
              </legend>
            </fieldset>
          );
        },
      },
      {
        colSpan: 1,
        key: 'useTemplate',
        label: t('NewBotModal.templates.switchButtonLabel', {
          ns: 'views',
        }),
        widget: CDKSwitch,
        tooltip: t('NewBotModal.templates.tooltip', {
          ns: 'views',
        }),
        onChange: () => form.setFieldValue('uploadTranscript', false),
        valuePropName: 'checked',
      },
      {
        colSpan: 1,
        key: 'uploadTranscript',
        label: t('NewBotModal.uploadTranscript.switchButtonLabel', {
          ns: 'views',
        }),
        widget: CDKSwitch,
        onChange: () => form.setFieldValue('useTemplate', false),
        valuePropName: 'checked',
      },
      ...draggerUploadField,
      ...botTemplatesField,
    ],
  };

  /** HANDLERS */
  const fetchAvailableTemplatesFromApi = useCallback(async () => {
    setAvailableTemplates([]);
    setFetchingStatus('NONE');

    try {
      setFetchingStatus('IN_PROGRESS');
      const localAvailableTemplates: string[] = await fetchBotsForAccount(ACCOUNT_TEMPLATES);

      const onboardingTemplate = localAvailableTemplates.includes('onboarding')
        ? botTemplatesOptions &&
          botTemplatesOptions.find(botTemplate => botTemplate.name === 'onboarding')
        : null;

      if (onboardingTemplate) form.setFieldValue('botTemplate', onboardingTemplate);
      else form.setFieldValue('botTemplate', null);

      setAvailableTemplates(localAvailableTemplates);

      setFetchingStatus('SUCCESS');
    } catch (error) {
      setFetchingStatus('ERROR');
      // in case an account doesn't exist yet
      setAvailableTemplates([]);
    }
  });

  const onFormSubmit = async (newBotSubmitted: FormProps) => {
    submitNewBotForm(
      newBotSubmitted.name,
      newBotSubmitted.language,
      newBotSubmitted.timezone,
      newBotSubmitted.transcripts,
      newBotSubmitted.botTemplate
        ? {
            account: ACCOUNT_TEMPLATES,
            botName: newBotSubmitted.botTemplate.name,
            id: newBotSubmitted.botTemplate.id,
          }
        : null,
      newBotSubmitted.storageSettings,
    );
  };

  /** EFFECT HOOKS */
  useEffect(() => {
    fetchAvailableOptions();
    const useBotTemplateItem = localStorage.getItem('useBotTemplate');
    const parsedUseBotTemplateItem: boolean = useBotTemplateItem
      ? JSON.parse(useBotTemplateItem)
      : false;

    if (parsedUseBotTemplateItem || !useBotTemplateItem) form.setFieldValue('useTemplate', true);
  }, []);

  useEffect(() => {
    // Wait for the success of the saga and redirect to the created bot
    if (['SUCCESS'].includes(createStatus) && selectedAccount && form.getFieldValue('name')) {
      if (form.getFieldValue('botTemplate')?.name === 'onboarding' && useTemplateFieldValue)
        localStorage.setItem('useBotTemplate', 'true');
      else localStorage.setItem('useBotTemplate', 'false');

      // Display stats modal if new bot is created in autopilot mode
      if (!!newBot && !!newBot.autoPilotStats) {
        const { coverage, nodes, intents } = newBot.autoPilotStats;
        showCDKInfo({
          title: t('NewBotModal.uploadTranscript.stats.title', {
            ns: 'views',
          }),
          content: (
            <div>
              <p>
                {t('NewBotModal.uploadTranscript.stats.coverage', {
                  ns: 'views',
                })}
                {Math.floor(coverage * 100)}%
              </p>
              <p>
                {t('NewBotModal.uploadTranscript.stats.nodesCreated', {
                  ns: 'views',
                })}
                {nodes}
              </p>
              <p>
                {t('NewBotModal.uploadTranscript.stats.intentsCreated', {
                  ns: 'views',
                })}
                {intents}
              </p>
            </div>
          ),
        });
      }
      history.push(
        `/accounts/${selectedAccount}/bots/${form.getFieldValue('name')}/builder/script`,
      );
      close();
    }
    if (createStatus === 'ERROR') {
      cdkNotification.error({
        message: 'Create new bot error',
        description: createBotError,
        top: 75,
      });
    }
  }, [createStatus, createBotError]);

  useEffect(() => {
    if (useTemplateFieldValue) {
      fetchAvailableTemplatesFromApi();
    }
  }, [languageFieldValue, useTemplateFieldValue]);

  return (
    <CDKModal
      className="NewBotModal"
      open
      title={t('NewBotModal.title', {
        ns: 'views',
      })}
      onCancel={() => !isLoading && close()}
      width={900}
      maskClosable={!isLoading}
      footer={[
        <CDKButton
          key="new-bot-modal-cancel-button"
          disabled={isLoading}
          onClick={close}
          color="secondary"
        >
          {t('Cancel')}
        </CDKButton>,
        <CDKButton
          key="new-bot-modal-submit-button"
          className="submit-button"
          htmlType="submit"
          type="primary"
          onClick={() => form.submit()}
          disabled={!form.getFieldValue('name') || isLoading}
          data-test-id="submit-create-bot"
          loading={isLoading}
        >
          {t('NewBotModal.title', {
            ns: 'views',
          })}
        </CDKButton>,
      ]}
    >
      <CDKSkeleton
        active
        loading={
          fetchingStatus === 'IN_PROGRESS' &&
          !botTemplatesOptions &&
          !languagesOptions &&
          !timezonesOptions
        }
      >
        <CDKLoadingSpin spinning={isLoading} size="large" tip={t('notif.loadingYourBot')}>
          <CDKForm
            className="new-bot-dialog-content"
            colon={false}
            form={form}
            onFinish={onFormSubmit}
            onValuesChange={forceUpdate}
            labelAlign="left"
          >
            <CDKFormBuilder meta={metaBasicForm} />
            <CDKFormBuilder meta={metaAdditionalForm} />
          </CDKForm>
        </CDKLoadingSpin>
      </CDKSkeleton>
    </CDKModal>
  );
}

export default NewBotModal;
