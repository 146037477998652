// @flow

import type { RadioCardProps } from '../../RadioCard.component';
import React from 'react';

import { CDKCol, CDKRadio, CDKRow } from '@calldesk/components';

import RadioCard from '../../RadioCard.component';

type RadiosGroupItemCard = RadioCardProps & { value: string };

type RadiosGroupCardProps = {
  options: RadiosGroupItemCard[],
  onChange: (newValue: string) => void,
  value: string,
};

function RadiosGroupCard({ options, onChange, value }: RadiosGroupCardProps) {
  return (
    <CDKRow gutter={[16, 16]}>
      {options.map(({ value: valItem, ...radioCardProps }: RadiosGroupItemCard) => (
        <CDKCol key={valItem} span={24 / options.length}>
          <RadioCard
            {...radioCardProps}
            isSelected={valItem === value}
            isDisabled={valItem === value}
            onClickTarget={() => onChange(valItem)}
            extra={<CDKRadio checked={valItem === value} disabled={valItem !== value} />}
          />
        </CDKCol>
      ))}
    </CDKRow>
  );
}

export default RadiosGroupCard;
