// @flow

import React, { type Element as ReactElement } from 'react';
import { Trans } from 'react-i18next';

import { CDKButton, CDKResult } from '@calldesk/components';

import EmptyIcon from '@assets/js/icons/EmptyIcon';

import ErrorBoundaryMaker from './components/ErrorBoundaryMaker';

import serviceWorker from '@/serviceWorkerRegistration';

type Props = {
  children: ReactElement<any>,
  reloadPage?: Function,
};

const ErrorBoundary = ErrorBoundaryMaker((props: Props, error?: Error) => {
  if (error) {
    return (
      <CDKResult
        extra={
          <CDKButton type="primary" onClick={serviceWorker.refreshCacheAndReload}>
            Refresh page
          </CDKButton>
        }
        icon={<EmptyIcon style={{ width: 1000 }} />}
        subTitle={<Trans i18nKey="navbar.ErrorBoundary.message" ns="commons" />}
        title={<Trans i18nKey="navbar.ErrorBoundary.title" ns="commons" />}
      />
    );
  }
  return <>{props.children}</>;
});

export default ErrorBoundary;
