// @flow

import type { Snapshot } from './types';

import type { BotApiRequestError } from '@state/ducks/app/types';
import request from '@amplify/api/request';
import logger from '@assets/js/calldesk-app-util/logger';

const retrieveSnapshots = (selectedAccount: string, bot: string): Promise<Snapshot[]> =>
  request
    .studioApi({
      method: 'GET',
      path: `/accounts/${selectedAccount}/bots/${bot}/snapshots`,
    })
    .then((snapshotsResult: Object) => {
      logger.info(
        'state/snapshots/api [retrieveSnapshots] Retrieved bot snapshots',
        snapshotsResult,
      );
      return Promise.resolve(snapshotsResult.snapshots);
    })
    .catch((error: BotApiRequestError) => {
      logger.info(
        'state/snapshots/api [retrieveSnapshots] Error while retrieving snapshots',
        error,
      );
      return Promise.reject(error);
    });

export { retrieveSnapshots };
