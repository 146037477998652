// @flow

import type { SubMenuProps } from 'antd/lib/menu';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import ArrowIcon from '@material-ui/icons/ArrowDropDown';
import { Menu } from 'antd';

const { SubMenu, Item } = Menu;

type Props = {
  selectedAccount?: string,
  correctAccount?: string,
  accountIds: string[],
  botFromRoute?: string,
  bots: string[],
  create: boolean,
  handleSelectAccount: (account: string) => void,
} & SubMenuProps;

function AccountsSubMenuComponent(props: Props) {
  const {
    correctAccount,
    accountIds,
    handleSelectAccount,
    botFromRoute,
    bots,
    selectedAccount,
    create,
    ...restProps
  } = props;
  const { t } = useTranslation();
  const correctPathname = (accountName: string): string => {
    const botPathname: string = botFromRoute ? `/${botFromRoute}` : '';
    const botIsInAccount: boolean = bots && bots.includes(accountName);
    return `/${(accountName && botIsInAccount && botPathname) || ''}`;
  };
  return (
    <SubMenu
      key="accounts"
      title={
        (
          <>
            {correctAccount}&nbsp;
            <ArrowIcon />
          </>
        ) ||
        accountIds[0] ||
        t('navbar.AccountsSubMenu.workspace')
      }
      theme="light"
      popupClassName="navbar-submenu"
      data-test-id="select-account"
      {...restProps}
    >
      {create && (
        <Item
          className="create"
          key="accounts:create"
          icon={<AddIcon className="anticon cdkicon" />}
        >
          <span>{t('navbar.AccountsSubMenu.newAccount')}</span>
        </Item>
      )}
      {accountIds.map((accountName: string) => (
        <Item key={accountName} className={accountName === selectedAccount && 'selected'}>
          <Link
            onClick={() => handleSelectAccount(accountName)}
            key={accountName}
            to={correctPathname(accountName)}
          >
            {accountName}
          </Link>
        </Item>
      ))}
    </SubMenu>
  );
}

export default AccountsSubMenuComponent;
