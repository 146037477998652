// @flow

/**
 * Transform a text from camelCase to Sentence Case
 */
function camelToSentenceCase(text: string, lowercaseResult: boolean = false): string {
  const result = text.replace(/([A-Z])/g, ' $1');
  const formattedResult = result.charAt(0).toUpperCase() + result.slice(1);

  return lowercaseResult ? formattedResult.toLowerCase() : formattedResult;
}

export default camelToSentenceCase;
