// @flow

import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { CDKButton, CDKInput } from '@calldesk/components';

import * as cognito from '@api/cognito';
import LoadingSpinner from '@assets/js/calldesk-components/molecules/LoadingSpinner';

import './forgot-password.css';

/**
 * ForgotPassword component
 */
function ForgotPassword() {
  const [canSubmit, setCanSubmit] = useState(false);
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    if (isLoading) {
      setErrorMessage(null);
      setSuccessMessage(null);
    }
  }, [isLoading]);

  useEffect(() => {
    setCanSubmit(email && email.trim());
  }, [email]);

  const handleSubmit = () => {
    setIsLoading(true);

    cognito
      .forgotPassword(email)
      .then(() => {
        setIsLoading(false);
        setSuccessMessage(
          "An email has just been sent to you. If you still haven't received anything in a few minutes, check your junk mail folder.",
        );
      })
      .catch(err => {
        setIsLoading(false);
        setErrorMessage(err.message);
      });
  };

  return (
    <div className="ForgotPasswordForm">
      <h2>Reset password</h2>
      <p>Indicate your email address, you will receive a link to reset your password.</p>
      <div className="ForgotPasswordFormField">
        <CDKInput
          placeholder="Email"
          onChange={event => setEmail(event.target.value)}
          value={email}
        />
      </div>
      <div className="ForgotPasswordFormActions">
        {successMessage && <div className="success-message">{successMessage}</div>}
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <CDKButton type="primary" onClick={handleSubmit} disabled={!canSubmit}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {isLoading ? <LoadingSpinner size={20} /> : successMessage ? 'Resend' : 'Validate'}
        </CDKButton>
        <NavLink to="/login">
          <CDKButton>Cancel</CDKButton>
        </NavLink>
      </div>
    </div>
  );
}

export default ForgotPassword;
