// @flow

const VALIDATION_BY_TYPEOF: { [typeof: string]: (currValue: any) => boolean } = {
  string: (currValue: string) => !!currValue && typeof currValue === 'string' && !!currValue.trim(),
  number: (currValue: number) => !!currValue && /^[+-]?\d*(?:[.,]\d*)?$/.test(currValue.toString()),
  boolean: (currValue: boolean) =>
    !!currValue && !!currValue.toString() && ['true', 'false'].includes(currValue.toString()),
  object: (currValue: Object) => {
    const isAlreadyAnObject: boolean = typeof currValue === 'object';
    if (isAlreadyAnObject) return isAlreadyAnObject;
    try {
      return typeof JSON.parse(currValue) === 'object';
    } catch (error) {
      return false;
    }
  },
  default: () => false,
};

export default VALIDATION_BY_TYPEOF;
