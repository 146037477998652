// @flow

import type { Exercise, Onboarding, OnboardingState as State, ValidatorData } from './types';
import type { PayloadAction } from '@reduxjs/toolkit';
// $FlowFixMe
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { ActionError, ActionMeta, State as RootState, Status } from '@state/ducks/app/types';
import type { BotConfig } from '@state/ducks/bots/types';
import type { AvailableOptions } from '@state/ducks/options/types';
import * as cognito from '@api/cognito';
import logger from '@assets/js/calldesk-app-util/logger';

import { getUserOnboarding as getUserOnboardingAPI } from './api';
import {
  CREATE_NEW_ONBOARDING_SAGA,
  EXERCISE_KEY,
  RUN_VALIDATOR_ONBOARDING_SAGA,
  SAVE_AND_CHECK_USER_ONBOARDING_SAGA,
} from './types';
import { ONBOARDING_STATE_BY_DEFAULT, validateExercise } from './utils';

/* APIs Handlers */
const createNewOnboardingSaga = createAction(CREATE_NEW_ONBOARDING_SAGA);
const runValidatorOnboardingSaga = createAction(RUN_VALIDATOR_ONBOARDING_SAGA);
const saveAndCheckUserOnboardingSaga = createAction(SAVE_AND_CHECK_USER_ONBOARDING_SAGA);
const getUserOnboarding = createAsyncThunk(
  'onboarding/GET_USER_ONBOARDING',
  async ({ account, bot }: { account: string, bot: string }) => {
    const user: ?string = await cognito.getUserSub();

    /**
     * Adding error in rejectWithValue payload throws an error in console:
     * A non-serializable value was detected in an action, in the path: `payload.error`. Value: Error: User not found.
     *
     * More details see: https://redux.js.org/faq/actions#why-should-type-be-a-string-or-at-least-serializable-why-should-my-action-types-be-constants)
     * To allow non-serializable values see: https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data)
     */
    // if (!user) return rejectWithValue({ error: new Error('User not found.') });
    if (!user) throw new Error('User not found.');

    const onboarding: ?Onboarding = await getUserOnboardingAPI(user, account, bot, EXERCISE_KEY);

    return onboarding;
  },
);

/* ACTIONS & REDUCERS */
const { actions, reducer } = createSlice({
  name: 'onboarding',
  initialState: ONBOARDING_STATE_BY_DEFAULT,
  reducers: {
    clearOnboarding(state: State) {
      state.currentOnboarding = null;
      state.validatorData = {};
    },
    setOnboarding(state: State, { payload }: PayloadAction<Onboarding>) {
      state.currentOnboarding = payload;
    },
    setSpecificStatus(
      state: State,
      {
        payload,
      }: PayloadAction<{
        statusName: 'fetching' | 'validator' | 'create',
        statusValue: Status,
        error?: string,
      }>,
    ) {
      const { statusValue, error, statusName } = payload;
      const statusNameKey: string = statusName.toString();

      state.status[statusNameKey].value = statusValue;
      state.status[statusNameKey].error = error || null;
    },
    setValidatorData(state: State, { payload: newValidatorData }: PayloadAction<ValidatorData>) {
      state.validatorData = {
        ...state.validatorData,
        ...newValidatorData,
      };
    },
    validatingProgression(
      state: State,
      {
        payload,
      }: PayloadAction<{
        availableOptions: AvailableOptions,
        botConfig: BotConfig,
        pathToSpecificQuest?: string,
        progression: Exercise,
        validatorData: ValidatorData,
      }>,
    ) {
      if (state.currentOnboarding) {
        const { exerciseId }: { exerciseId: string } = state.currentOnboarding;
        const { botConfig, pathToSpecificQuest, progression, validatorData, availableOptions } =
          payload;

        const progressionValidated: Exercise = validateExercise(
          exerciseId,
          pathToSpecificQuest,
          botConfig,
          progression,
          validatorData,
          availableOptions,
        );

        // $FlowFixMe doesn't understand we already checked state.currentOnboarding...
        state.currentOnboarding.progression = progressionValidated;
      }
    },
  },
  extraReducers: builder => {
    builder
      /**
       * TODO: In case createBotSaga is migrated to extraReducers using asyncThunk, we could
       * add a case here in order to replace `createNewOnboardingSaga`
       */
      // .addCase(createNewBotAsyncThunk.fulfilled, (state: State) => {
      //   // Put content of `createNewOnboardingSaga` here and adapt it
      // })
      .addCase(getUserOnboarding.pending, (state: State) => {
        state.currentOnboarding = null;
        state.status.fetching.value = 'IN_PROGRESS';
        state.status.fetching.error = null;
        state.validatorData = {};
      })
      .addCase(
        getUserOnboarding.fulfilled,
        (
          state: State,
          { payload: onboarding, meta }: PayloadAction<?Onboarding> & { meta: ActionMeta },
        ) => {
          const { account, bot } = meta.arg;

          if (onboarding) {
            state.currentOnboarding = onboarding;
            logger.info(`getUserOnboarding/fulfilled - onboarding data found`, {
              account,
              bot,
              onboarding,
            });
          } else {
            logger.info(
              `getUserOnboarding/fulfilled] - onboarding data not found, clear previous data`,
              { account, bot },
            );
          }
          state.status.fetching.value = 'SUCCESS';
          state.status.fetching.error = null;
        },
      )
      .addCase(getUserOnboarding.rejected, (state: State, { error }: ActionError) => {
        state.status.fetching.value = 'ERROR';
        state.status.fetching.error = error.message;
      });
  },
});

/* SELECTORS */
const selectProgression = (state: RootState): ?Exercise =>
  state.onboarding.currentOnboarding?.progression;
const selectBotHasOnboarding = (state: RootState): boolean => {
  const progression: ?Exercise = selectProgression(state);

  return (
    !!progression && !!progression.categories && Object.keys(progression.categories).length > 0
  );
};
const selectExerciseId = (state: RootState): ?string =>
  state.onboarding.currentOnboarding?.exerciseId;
const selectValidatorData = (state: RootState): ValidatorData => state.onboarding.validatorData;
const selectValidatorError = (state: RootState): ?string => state.onboarding.status.validator.error;
const selectValidatorStatus = (state: RootState): Status => state.onboarding.status.validator.value;

/* EXPORTS */
const selectors = {
  selectBotHasOnboarding,
  selectExerciseId,
  selectProgression,
  selectValidatorData,
  selectValidatorError,
  selectValidatorStatus,
};

actions.getUserOnboarding = getUserOnboarding;
actions.createNewOnboardingSaga = createNewOnboardingSaga;
actions.runValidatorOnboardingSaga = runValidatorOnboardingSaga;
actions.saveAndCheckUserOnboardingSaga = saveAndCheckUserOnboardingSaga;

export { actions, selectors };
export default reducer;
