// @flow

import type { BotConfig, Entities } from './types';
// $FlowFixMe
import { createSelector } from '@reduxjs/toolkit';

import { getBotConfig } from './bots.selectors';

const getEntities = createSelector(
  getBotConfig,
  (config: ?BotConfig): Entities => config?.entities || {},
);

export { getEntities };
