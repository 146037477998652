// @flow

import type { Node } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LoadingOutlined, MoreOutlined } from '@ant-design/icons';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import _capitalize from 'lodash/capitalize';

import { CDKButton, CDKDropdown, CDKList, CDKMenu, cdkNotification } from '@calldesk/components';

import type { User, UserUI } from '@state/ducks/administration/types';

type Props = {
  currentUserEmail: string | null,
  deleteUser: (user: UserUI) => void,
  resendInvitation: (user: UserUI) => void,
  selectedUser: ?User,
  selectUser: (user: UserUI) => void,
  user: UserUI,
};

const confirmedUser = (item: UserUI) =>
  item.cognitoStatus !== '' && item.cognitoStatus !== 'FORCE_CHANGE_PASSWORD';
const unconfirmedUser = (item: UserUI) => item.cognitoStatus === 'FORCE_CHANGE_PASSWORD';

const getAvatar = (user: UserUI): Node => {
  if (confirmedUser(user)) {
    return <CheckCircleIcon style={{ color: '#6CCC86' }} />;
  }
  if (unconfirmedUser(user)) {
    return <PauseCircleFilledIcon style={{ color: '#FCBE00' }} />;
  }
  if (user.error) {
    return <CancelIcon style={{ color: '#FF7D6E' }} />;
  }
  // user is loading
  if (!user.id) {
    return <LoadingOutlined />;
  }
  return <RadioButtonUncheckedIcon />;
};

/**
 * Roles Manager component
 */
function UserListItem({
  currentUserEmail,
  deleteUser,
  resendInvitation,
  selectedUser,
  selectUser,
  user,
}: Props) {
  const { t } = useTranslation();

  const selectTemporaryPasswordSent = useSelector(
    state => state.administration.temporaryPasswordSent,
  );

  const sendInvitation = async () => {
    await resendInvitation(user);
    if (selectTemporaryPasswordSent === null) return;

    if (selectTemporaryPasswordSent) {
      cdkNotification.success({
        message: t('operations.success.sendInvitation', {
          ns: 'administration',
        }),
        top: 75,
      });
    } else {
      cdkNotification.error({
        message: t('operations.errors.sendInvitation', {
          ns: 'administration',
        }),
        top: 75,
      });
    }
  };

  return (
    <CDKList.Item
      className={selectedUser?.id === user.id ? 'selected' : ''}
      onClick={() => selectUser(user)}
      key={user.id}
    >
      <CDKList.Item.Meta
        avatar={getAvatar(user)}
        title={<b>{`${_capitalize(user.firstName)} ${_capitalize(user.lastName)}`}</b>}
        description={user.email}
      />
      {user.email !== currentUserEmail && (
        <CDKDropdown
          overlayStyle={{ zIndex: 99999 }}
          overlay={
            <CDKMenu>
              <CDKMenu.Item key="0" onClick={() => deleteUser(user)}>
                {t('UsersManager.Users.Remove', { ns: 'administration' })}
              </CDKMenu.Item>
              <CDKMenu.Item
                key="1"
                disabled={user.cognitoStatus !== 'FORCE_CHANGE_PASSWORD'}
                onClick={sendInvitation}
              >
                {t('UsersManager.Users.ResendEmail', { ns: 'administration' })}
              </CDKMenu.Item>
            </CDKMenu>
          }
          trigger={['click']}
        >
          <CDKButton
            icon={<MoreOutlined />}
            size="small"
            shape="circle"
            onClick={event => event.stopPropagation()}
          />
        </CDKDropdown>
      )}
    </CDKList.Item>
  );
}

export default UserListItem;
