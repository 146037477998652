// @flow
// $FlowFixMe there is
import { createListenerMiddleware, miniSerializeError } from '@reduxjs/toolkit';

import type { IntentsMap } from '@state/ducks/intents/types';
import logger from '@assets/js/calldesk-app-util/logger';
import { selectors as botsSelectors } from '@state/ducks/bots';
import { formatBotConfig } from '@state/ducks/bots/utils';
import {
  operations as intentsOperations,
  selectors as intentsSelectors,
} from '@state/ducks/intents';
import { fetchIntentsEvaluationForBot } from '@state/ducks/intents/api';

// Create the middleware instance and methods
const listenerMiddleware = createListenerMiddleware();

// Add one or more listener entries that look for specific actions.
// They may contain any sync or async logic, similar to thunks.
listenerMiddleware.startListening({
  actionCreator: intentsOperations.fetchBotIntentsEvaluations,
  effect: async (action, { cancelActiveListeners, dispatch, getState, take }) => {
    const { payload } = action;
    // Run whatever additional side-effect-y logic you want here
    logger.log('Intent evaluation asked: ', payload);

    // Can cancel other running instances, takeLatest equivalent from redux-saga
    cancelActiveListeners();

    const state = getState();
    const intentsInConfig = botsSelectors.getBotConfigIntents(state, payload.bot);
    const botConfigIntents = intentsSelectors.selectAllFromIds(state, intentsInConfig);
    const assetsToFetch = botConfigIntents
      .filter(
        intent =>
          intentsInConfig.includes(intent.id) && !intent.utterances && intent.scope === 'account',
      )
      .map(intent => intent.id);
    dispatch(
      intentsOperations.fetchIntents({
        intentsIds: assetsToFetch,
        account: payload.account,
      }),
    );

    // Run async logic
    await take(intentsOperations.fetchIntents.fulfilled);

    dispatch(intentsOperations.intentEvaluation());
    const newState = getState();
    const formattedIntentsMap: IntentsMap = intentsSelectors.selectIntentsMapByIds(
      newState,
      intentsInConfig,
    );

    try {
      const data = await fetchIntentsEvaluationForBot(payload.account, payload.bot, {
        ...formatBotConfig(newState.bots.entities[payload.bot].config),
        intents: formattedIntentsMap,
      });
      dispatch(intentsOperations.intentEvaluationSuccess(data));
    } catch (error) {
      dispatch(intentsOperations.intentEvaluationError(miniSerializeError(error)));
    }
  },
});

export default listenerMiddleware;
