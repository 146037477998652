// @flow

import type { StatsCheckpointsConfig, StatsConfigData } from './types';
import { createSelector } from '@reduxjs/toolkit';

import type { BotConfig } from '@state/ducks/bots/types';
import { selectors as optionsSelectors } from '@state/ducks/options';

import { getBotConfig } from './bots.selectors';

const getBotStatsData = createSelector(
  getBotConfig,
  (config: ?BotConfig): ?StatsConfigData => config?.stats.data,
);

const getStatsCheckpoints = createSelector(
  getBotConfig,
  (config: ?BotConfig): StatsCheckpointsConfig => config?.statsCheckpoints || {},
);

const getAllStatsCheckpoints = createSelector(
  getStatsCheckpoints,
  optionsSelectors.getStatsCheckpointsOptions,
  (
    statsCheckpointsConfig: StatsCheckpointsConfig,
    statsCheckpointsOptions: StatsCheckpointsConfig,
  ): StatsCheckpointsConfig => ({
    steps: {
      ...statsCheckpointsOptions.steps,
      ...statsCheckpointsConfig.steps,
    },
    statuses: {
      ...statsCheckpointsOptions.statuses,
      ...statsCheckpointsConfig.statuses,
    },
    reasons: {
      ...statsCheckpointsOptions.reasons,
      ...statsCheckpointsConfig.reasons,
    },
  }),
);

export { getAllStatsCheckpoints, getBotStatsData, getStatsCheckpoints };
