// @flow

import type { PayloadAction } from '@reduxjs/toolkit';
import type { Saga } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import type { Onboarding } from '@state/ducks/onboarding/types';
import * as cognito from '@api/cognito';
import logger from '@assets/js/calldesk-app-util/logger';
import { api as onboardingApi, operations as onboardingOperations } from '@state/ducks/onboarding';
import { EXERCISE_KEY } from '@state/ducks/onboarding/types';
import { operations as uiOperations } from '@state/ducks/ui';

/**
 * Create a new onboarding for user and bot
 */
function* createNewOnboardingSaga(
  action: PayloadAction<{ account: string, bot: string }>,
): Saga<any> {
  let onboardingCreated: ?Onboarding;

  const { account, bot } = action.payload;

  yield put(
    onboardingOperations.setSpecificStatus({ statusName: 'create', statusValue: 'IN_PROGRESS' }),
  );
  yield put(uiOperations.showOnboardingModal(false));
  try {
    const userId: string = yield call(cognito.getUserSub);

    onboardingCreated = yield call(
      onboardingApi.createUserOnboarding,
      userId,
      account,
      bot,
      // TODO: get exerciseId dynamically
      EXERCISE_KEY,
    );

    localStorage.setItem('flashingFirstPlayQuest', JSON.stringify(true));
    yield put(
      onboardingOperations.setSpecificStatus({ statusName: 'create', statusValue: 'SUCCESS' }),
    );
  } catch (error) {
    yield put(
      onboardingOperations.setSpecificStatus({
        statusName: 'create',
        statusValue: 'ERROR',
        error: error.message,
      }),
    );
    logger.error(
      `onboarding/operations::createNewOnboardingSaga(userId, account, bot): - ERROR :`,
      error.message,
    );
  }

  if (onboardingCreated) {
    yield put(onboardingOperations.setOnboarding(onboardingCreated));
  }
}

export default createNewOnboardingSaga;
