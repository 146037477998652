/* eslint-disable max-lines */
// @flow

/* eslint-disable import/no-duplicates */
// otherwise we loose flow coverage because createSlice and createSelector are not flow-typed but createAction is

import type {
  Answer,
  AnswerStatus,
  ChangeCurrentEntityTurnAnswerPayload,
  ChangeCurrentEntityTurnPayload,
  ChangeTurnPayload,
  ChunkTrained,
  EntityTurn,
  FetchTurnAndEntitiesRequestPayload,
  FetchTurnRequestPayload,
  IntentTurn,
  IntentTurnExpression,
  Notification,
  SaveTrainingPayload,
  Training,
  Turn,
  TurnSelected,
} from './types';
import type { PayloadAction } from '@reduxjs/toolkit';
// $FlowFixMe
import { createAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import type { TagValueType } from '@assets/js/calldesk-components/molecules/UserInputTag';
import type { BotIdentity, State, Status } from '@state/ducks/app/types';

import { fetchTrainingScore } from './api';
import {
  CHANGE_TURN_SAGA,
  FETCH_ANSWERS_BY_TURN_SAGA,
  FETCH_ENTITIES_AND_ANSWERS_BY_TURN_SAGA,
  FETCH_TURNS_SAGA,
  INIT_TRAINING_SAGA,
  SAVE_TRAINING_SAGA,
  SET_CURRENT_ENTITY_TURN_CURRENT_ANSWER_SAGA,
  SET_CURRENT_ENTITY_TURN_SAGA,
} from './types';
import {
  changeExpressionInTurnIntents,
  changeResultInAnswersAccordingIntentsTurn,
  DATE_RANGES_MAP,
} from './util';

/* HELPERS */
const computeTrainingScore = (toTrain: number, trained: number, discarded: number) => {
  const totalProcessedUnknowns: number = trained + discarded;
  const totalOccuredUnknowns: number = toTrain + trained + discarded;

  // NOTE: In case there're nothing to train and nothing has been trained, maximum score is returned
  if (totalOccuredUnknowns === 0) return 100;

  const trainingScore: number = totalProcessedUnknowns / totalOccuredUnknowns;
  // NOTE: Floor the percentage so we make sure 100 is not reached until all toTrain has been processed
  const trainingScorePercentage: number = Math.floor(trainingScore * 100);

  return trainingScorePercentage;
};
const filterChangedEntities = (entityTurn: EntityTurn) => entityTurn.changed;

const changeTurn = createAction<ChangeTurnPayload, typeof CHANGE_TURN_SAGA>(CHANGE_TURN_SAGA);
const fetchAnswersByTurn = createAction<FetchTurnRequestPayload, typeof FETCH_ANSWERS_BY_TURN_SAGA>(
  FETCH_ANSWERS_BY_TURN_SAGA,
);
const fetchEntitiesAndAnswersByTurn = createAction<
  FetchTurnAndEntitiesRequestPayload,
  typeof FETCH_ENTITIES_AND_ANSWERS_BY_TURN_SAGA,
>(FETCH_ENTITIES_AND_ANSWERS_BY_TURN_SAGA);
const fetchTurns = createAction<BotIdentity, typeof FETCH_TURNS_SAGA>(FETCH_TURNS_SAGA);
const initTraining = createAction<BotIdentity, typeof INIT_TRAINING_SAGA>(INIT_TRAINING_SAGA);
const setCurrentEntityTurn = createAction<
  ChangeCurrentEntityTurnPayload,
  typeof SET_CURRENT_ENTITY_TURN_SAGA,
>(SET_CURRENT_ENTITY_TURN_SAGA);
const setCurrentEntityTurnCurrentAnswer = createAction<
  ChangeCurrentEntityTurnAnswerPayload,
  typeof SET_CURRENT_ENTITY_TURN_CURRENT_ANSWER_SAGA,
>(SET_CURRENT_ENTITY_TURN_CURRENT_ANSWER_SAGA);
const saveTraining = createAction<SaveTrainingPayload, typeof SAVE_TRAINING_SAGA>(
  SAVE_TRAINING_SAGA,
);
const getTrainingScore = createAsyncThunk(
  'training/FETCH_TRAINING_SCORE',
  async (
    {
      account,
      bot,
    }: {
      account: string,
      bot: string,
    },
    { getState },
  ) => {
    const currentState = getState();
    const { from } = currentState.training.range;

    const { discarded, trained }: { discarded: number, trained: number } = await fetchTrainingScore(
      account,
      bot,
      from,
    );

    return { discarded, trained };
  },
);

/**
 * TODO :
 * This duck need to be rebuilt entirely with a normalized data structure
 * This includes
 * * Creating a turn slice
 * * Creating an answer slice
 * * Possible use of a normalization lib to help us maintain relationship between the entities
 *
 * @see https://redux.js.org/usage/structuring-reducers/normalizing-state-shape#normalizing-nested-data
 * @see https://redux-toolkit.js.org/usage/usage-guide#using-createentityadapter-with-normalization-libraries
 * */
const initialState: Training = {
  currentTurn: {},
  errorMessage: '',
  notification: {
    message: '',
    type: '',
  },
  range: {
    from: DATE_RANGES_MAP.lastMonth,
    minOccurences: 0,
  },
  score: {
    deltas: {},
    discarded: 0,
    processed: {},
    status: 'NONE',
    toTrain: 0,
    trained: 0,
  },
  status: 'NONE',
  turns: [],
};

/*  SAGA ACTIONS */

const { actions, reducer } = createSlice({
  name: 'training',
  initialState,
  reducers: {
    // TODO: Convert to thunk
    fetchTurnsStart(state: Training) {
      state.status = 'IN_PROGRESS';
      state.score.deltas = {};
      state.score.discarded = 0;
      state.score.processed = {};
      state.score.toTrain = 0;
      state.score.trained = 0;
    },
    fetchTurnsSuccess(state: Training, { payload }: PayloadAction<Turn[]>) {
      state.status = 'SUCCESS';
      state.turns = payload;
      state.score.toTrain = payload.reduce(
        (totalCount: number, turn: Turn) => totalCount + turn.unknowns,
        0,
      );
    },
    fetchTurnsFailure(state: Training, { payload }: PayloadAction<string>) {
      state.status = 'ERROR';
      state.errorMessage = payload;
    },
    clearTurns(state: Training) {
      state.status = 'NONE';
      state.errorMessage = '';
      state.turns = [];
    },
    // TODO: Convert to thunk
    fetchAnswersByTurnStart(state: Training) {
      state.currentTurn.fetchingAnswers = 'IN_PROGRESS';
    },
    fetchAnswersByTurnSuccess(
      state: Training,
      { payload }: PayloadAction<{ after: string, items: Answer[] }>,
    ) {
      state.currentTurn.fetchingAnswers = 'SUCCESS';
      state.currentTurn.answers = payload.items;
    },
    fetchAnswersByTurnFailure(state: Training, { payload }: PayloadAction<string>) {
      state.currentTurn.fetchingAnswers = 'ERROR';
      state.currentTurn.errorMessageAnswers = payload;
    },
    discardTrainedIntent(
      state: Training,
      { payload }: PayloadAction<{ answerIndex: number, intentName: string, expression: string }>,
    ) {
      // Remove new expression from current turn intent's utterances
      const trainedIntentIndex: number = state.currentTurn.intents.findIndex(
        (intentTurn: IntentTurn) => intentTurn.intentName === payload.intentName,
      );

      if (trainedIntentIndex >= 0) {
        const trainedIntentExpressions: IntentTurnExpression[] =
          state.currentTurn.intents[trainedIntentIndex].expressions || [];
        const trainedExpressionIndex: number = trainedIntentExpressions.findIndex(
          (expression: IntentTurnExpression) => expression.origin === payload.expression,
        );

        trainedIntentExpressions.splice(trainedExpressionIndex, 1);

        // NOTE: In case this was the only expression added to intent, put back `changed` boolean to false
        if (!trainedIntentExpressions.length) {
          state.currentTurn.intents[trainedIntentIndex].changed = false;
        }
      }

      // Remove trained intent chunk from turn's result
      if (state.currentTurn.answers[payload.answerIndex].result?.intentsTurnTrained) {
        const turnTrainedIntentsChunks: ChunkTrained[] =
          state.currentTurn.answers[payload.answerIndex].result.intentsTurnTrained;

        const trainedChunkIndex: number = turnTrainedIntentsChunks.findIndex(
          (chunk: ChunkTrained) =>
            chunk.mask === payload.intentName && chunk.utterance === payload.expression,
        );

        if (trainedChunkIndex >= 0) {
          turnTrainedIntentsChunks.splice(trainedChunkIndex, 1);

          if (
            !turnTrainedIntentsChunks.length &&
            !state.currentTurn.answers[payload.answerIndex].result?.entitiesTurnTrained?.length
          ) {
            state.currentTurn.answers[payload.answerIndex].result = null;
          }
        }
      }
    },
    setAnswerCustomUtterance(
      state: Training,
      { payload }: PayloadAction<{ answerIndex: number, newUtterance: string }>,
    ) {
      state.currentTurn.answers[payload.answerIndex].customUtterance = payload.newUtterance;
    },
    selectAnswer(
      state: Training,
      { payload }: PayloadAction<{ answerIndex: number, newAnswer: Answer }>,
    ) {
      state.currentTurn.currentAnswerIndex = payload.answerIndex;
      state.currentTurn.answers[payload.answerIndex] = payload.newAnswer;
    },
    selectNextAnswerToTrain(state: Training) {
      let nextAnswerIndex: number = (state.currentTurn.currentAnswerIndex || 0) + 1;
      let nextAnswer: Answer = state.currentTurn.answers[nextAnswerIndex];

      while (nextAnswer?.result) {
        nextAnswerIndex += 1;
        nextAnswer = state.currentTurn.answers[nextAnswerIndex];
      }

      if (nextAnswer) {
        state.currentTurn.currentAnswerIndex = nextAnswerIndex;
      } else {
        state.notification = {
          message: 'No more answers to train',
          type: 'success',
        };
      }
    },
    matchAnswerWithNewIntent(
      state: Training,
      { payload }: PayloadAction<{ intentName: string, utterance: IntentTurnExpression }>,
    ) {
      // NOTE: Update intents in currentTurn for 2 reasons
      // 1. Update list in UI in order to display the new intent
      // 2. When submiting Training, currentTurn.intents is used as reference so if missing, new intents will not be created
      state.currentTurn.intents.push({
        intentName: payload.intentName,
        type: 'other',
        domain: 'none',
        entities: [],
        expressions: [payload.utterance],
        changed: true,
        // Intent creation through Training are scoped to bot
        scope: 'bot',
      });

      if (
        state.currentTurn.currentAnswerIndex !== null &&
        state.currentTurn.currentAnswerIndex >= 0 &&
        state.currentTurn.answers[state.currentTurn.currentAnswerIndex]
      ) {
        state.currentTurn.answers[state.currentTurn.currentAnswerIndex].result = {
          newStatus: 'TRAINED',
          intentsTurnTrained: [
            ...(state.currentTurn.answers[state.currentTurn.currentAnswerIndex].result
              ?.intentsTurnTrained || []),
            {
              mask: payload.intentName,
              utterance: payload.utterance.origin,
              type: 'INTENT',
            },
          ],
        };
      }
    },
    matchAnswerWithExistingIntent(
      state: Training,
      { payload }: PayloadAction<{ intentName: string, utterance: IntentTurnExpression }>,
    ) {
      const intentIndex: number = state.currentTurn.intents.findIndex(
        (intent: IntentTurn) => intent.intentName === payload.intentName,
      );

      if (intentIndex >= 0) {
        state.currentTurn.intents[intentIndex].changed = true;
        state.currentTurn.intents[intentIndex].expressions = [
          ...(state.currentTurn.intents[intentIndex].expressions || []),
          payload.utterance,
        ];
      }

      if (
        state.currentTurn.currentAnswerIndex !== null &&
        state.currentTurn.currentAnswerIndex >= 0 &&
        state.currentTurn.answers[state.currentTurn.currentAnswerIndex]
      ) {
        state.currentTurn.answers[state.currentTurn.currentAnswerIndex].result = {
          newStatus: 'TRAINED',
          intentsTurnTrained: [
            ...(state.currentTurn.answers[state.currentTurn.currentAnswerIndex].result
              ?.intentsTurnTrained || []),
            {
              mask: payload.intentName,
              utterance: payload.utterance.origin,
              type: 'INTENT',
            },
          ],
        };
      }
    },
    setCurrentTurn(state: Training, { payload }: PayloadAction<TurnSelected>) {
      state.currentTurn = {
        ...state.currentTurn,
        ...payload,
      };
    },
    clearCurrentTurn(state: Training) {
      state.currentTurn = {};
    },
    updateEntityMasksInUtteranceForIntentsTurn(
      state: Training,
      { payload }: PayloadAction<string>,
    ) {
      const turnChangedEntities: EntityTurn[] = (state.currentTurn.entities ?? []).filter(
        filterChangedEntities,
      );

      const turnIntents: IntentTurn[] = state.currentTurn.intents ?? [];

      const answersList: Answer[] = state.currentTurn.answers ?? [];

      const turnIntentsWithUpdatedExpressions: IntentTurn[] = changeExpressionInTurnIntents(
        turnIntents,
        turnChangedEntities,
      );

      const answersWithUpdatedResults: Answer[] = changeResultInAnswersAccordingIntentsTurn(
        payload,
        answersList,
        turnIntentsWithUpdatedExpressions,
        turnChangedEntities,
      );
      state.currentTurn.intents = turnIntentsWithUpdatedExpressions;
      state.currentTurn.answers = answersWithUpdatedResults;
    },
    changeAnswerStatus(
      state: Training,
      {
        payload,
      }: PayloadAction<{
        answerId: string,
        status: AnswerStatus,
      }>,
    ) {
      const answerIndex: number = state.currentTurn.answers.findIndex(
        (answer: Answer) => answer.answerId === payload.answerId,
      );

      if (answerIndex >= 0) {
        state.currentTurn.answers[answerIndex].result =
          payload.status !== 'TO_TRAIN'
            ? {
                ...state.currentTurn.answers[answerIndex].result,
                newStatus: payload.status,
              }
            : null;
      }
    },
    setNotification(state: Training, { payload }: PayloadAction<?Notification>) {
      state.notification = payload;
    },
    updateTrainingScore(
      state: Training,
      {
        payload,
      }: PayloadAction<{
        amount: number,
        answerId: string,
        oldStatus: AnswerStatus,
        newStatus: AnswerStatus,
        turnId: string,
      }>,
    ) {
      const { amount, answerId, oldStatus, newStatus, turnId } = payload;
      const { trained, discarded, toTrain } = state.score;
      const oldTrainingScore: number = computeTrainingScore(toTrain, trained, discarded);

      if (oldStatus === 'TRAINED') state.score.trained -= amount;
      else if (oldStatus === 'DISCARDED') state.score.discarded -= amount;
      else state.score.toTrain -= amount;

      if (newStatus === 'TRAINED') state.score.trained += amount;
      else if (newStatus === 'DISCARDED') state.score.discarded += amount;
      else state.score.toTrain += amount;

      // Increase processed unknowns and compute new training score delta only when answer is from a TO_TRAIN status
      if (oldStatus === 'TO_TRAIN' && (newStatus === 'TRAINED' || newStatus === 'DISCARDED')) {
        state.score.processed[turnId] = (state.score.processed[turnId] || 0) + amount;
        // Compute new training score delta and save it
        state.score.deltas[answerId] =
          computeTrainingScore(state.score.toTrain, state.score.trained, state.score.discarded) -
          oldTrainingScore;
      } else if (newStatus === 'TO_TRAIN') {
        state.score.processed[turnId] = (state.score.processed[turnId] || 0) - amount;
      }
    },
    setRangeFrom(state: Training, { payload }: PayloadAction<number>) {
      state.range.from = payload;
    },
    setRangeMinOccurences(state: Training, { payload: minOccurences }: PayloadAction<number>) {
      state.range.minOccurences = minOccurences;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getTrainingScore.pending, (state: Training) => {
        state.score.status = 'IN_PROGRESS';
        state.score.discarded = 0;
        state.score.trained = 0;
      })
      .addCase(
        getTrainingScore.fulfilled,
        (state: Training, { payload }: PayloadAction<{ discarded: number, trained: number }>) => {
          state.score.status = 'SUCCESS';
          state.score.discarded = payload.discarded;
          state.score.trained = payload.trained;
        },
      )
      .addCase(getTrainingScore.rejected, (state: Training) => {
        state.score.status = 'ERROR';
      });
  },
});

/* SELECTORS */
const selectCurrentTurn = (state: State): TurnSelected => state.training.currentTurn;
const selectAnswersList = createSelector(
  selectCurrentTurn,
  (currentTurn: TurnSelected): Answer[] => currentTurn.answers ?? [],
);
const selectAnswerByIndex = createSelector(
  selectAnswersList,
  (state: State, answerIndex: number) => answerIndex,
  (answers: Answer[], answerIndex: number): ?Answer => answers[answerIndex],
);
const selectAnswersChangedList = createSelector(
  selectAnswersList,
  (answersList: Answer[]): Answer[] =>
    answersList.filter((answer: Answer) => answer.result && answer.result.newStatus !== 'TO_TRAIN'),
);
const selectCurrentAnswerIndex = createSelector(
  selectCurrentTurn,
  (currentTurn: TurnSelected): ?number => currentTurn.currentAnswerIndex,
);
const selectCurrentAnswer = createSelector(
  selectCurrentTurn,
  (currentTurn: TurnSelected): ?Answer => {
    if (currentTurn.answers?.length > 0 && typeof currentTurn.currentAnswerIndex === 'number') {
      const { answers, currentAnswerIndex } = currentTurn;
      return answers[currentAnswerIndex];
    }
    return null;
  },
);
const selectCurrentAnswerStatus = createSelector(
  selectCurrentAnswer,
  (currentAnswer: Answer): AnswerStatus => currentAnswer.result?.newStatus || currentAnswer.status,
);
const selectCurrentAnswerTrainedEntities = createSelector(
  selectCurrentAnswer,
  (selectedAnswer: Answer): ChunkTrained[] => selectedAnswer.result?.entitiesTurnTrained || [],
);
const selectCurrentAnswerTrainedEntitiesByType = createSelector(
  selectCurrentAnswerTrainedEntities,
  (state: State, entityType: TagValueType) => entityType,
  (trainedEntities: ChunkTrained[], entityType: TagValueType) =>
    trainedEntities.filter((chunk: ChunkTrained) => chunk.type === entityType),
);
const selectFrom = (state: State): number => state.training.range.from;
const selectMinOccurences = (state: State): number => state.training.range.minOccurences;
const selectIsFetchingAnswers = (state: State): boolean =>
  state.training.currentTurn.fetchingAnswers === 'IN_PROGRESS';
const selectIsFetchingEntityReferential = (state: State): boolean =>
  state.training.currentTurn.fetchingEntities ?? false;
const selectIsFetchingTurns = (state: State): boolean => state.training.status === 'IN_PROGRESS';
const selectIsTrainingLoading = createSelector(
  selectIsFetchingTurns,
  selectIsFetchingAnswers,
  (isFetchingTurns: boolean, isFetchingAnswers: boolean): boolean =>
    isFetchingTurns || isFetchingAnswers,
);
const selectIsUpdated = createSelector(
  selectAnswersChangedList,
  (answersChangedList: Answer[]): boolean => answersChangedList.length > 0,
);
const selectNotification = (state: State): ?Notification => state.training.notification;
const selectTrainedAnswerDelta = (state: State, answerId: string): number =>
  state.training.score.deltas[answerId] || 0;
const selectTrainingScorePercentage = (state: State): number =>
  computeTrainingScore(
    state.training.score.toTrain,
    state.training.score.trained,
    state.training.score.discarded,
  );
const selectTrainingScoreStatus = (state: State): Status => state.training.score.status;
const selectTurnEntitiesList = createSelector(
  selectCurrentTurn,
  (currentTurn: TurnSelected): EntityTurn[] => currentTurn.entities ?? [],
);
const selectTurnIntentsList = createSelector(
  selectCurrentTurn,
  (currentTurn: TurnSelected): IntentTurn[] => {
    const sortScoreByIntentType = {
      turn: 0,
      other: 1,
      fallback: 2,
    };

    return [...(currentTurn.intents ?? [])].sort(
      (intentA: IntentTurn, intentB: IntentTurn) =>
        sortScoreByIntentType[intentA.type] - sortScoreByIntentType[intentB.type],
    );
  },
);
const selectTurnChangedEntitiesList = createSelector(
  selectTurnEntitiesList,
  (turnEntities: EntityTurn[]): EntityTurn[] => turnEntities.filter(filterChangedEntities),
);
const selectTurnChangedIntentsList = createSelector(
  selectTurnIntentsList,
  (turnIntents: IntentTurn[]): IntentTurn[] =>
    turnIntents.filter((turnIntent: IntentTurn) => turnIntent.changed),
);
const selectTurnCurrentEntity = createSelector(
  selectCurrentTurn,
  (currentTurn: TurnSelected): ?EntityTurn => {
    if (currentTurn.entities?.length > 0 && typeof currentTurn.currentEntityIndex === 'number') {
      const { entities, currentEntityIndex } = currentTurn;
      return entities[currentEntityIndex];
    }
    return null;
  },
);
const selectTurnsList = (state: State): Turn[] => state.training.turns ?? [];
const selectTurnUnknowns = (state: State, turnIndex: number, turnId: string): number =>
  state.training.turns[turnIndex].unknowns - (state.training.score.processed[turnId] || 0);

const selectors = {
  selectAnswerByIndex,
  selectAnswersChangedList,
  selectAnswersList,
  selectCurrentAnswer,
  selectCurrentAnswerIndex,
  selectCurrentAnswerStatus,
  selectCurrentAnswerTrainedEntities,
  selectCurrentAnswerTrainedEntitiesByType,
  selectCurrentTurn,
  selectFrom,
  selectIsFetchingAnswers,
  selectIsFetchingEntityReferential,
  selectIsFetchingTurns,
  selectIsTrainingLoading,
  selectIsUpdated,
  selectMinOccurences,
  selectNotification,
  selectTrainedAnswerDelta,
  selectTrainingScorePercentage,
  selectTrainingScoreStatus,
  selectTurnChangedEntitiesList,
  selectTurnChangedIntentsList,
  selectTurnCurrentEntity,
  selectTurnEntitiesList,
  selectTurnIntentsList,
  selectTurnsList,
  selectTurnUnknowns,
};

/* ACTIONS & REDUCERS */

/* EXPORTS */
actions.changeTurn = changeTurn;
actions.fetchAnswersByTurn = fetchAnswersByTurn;
actions.fetchEntitiesAndAnswersByTurn = fetchEntitiesAndAnswersByTurn;
actions.fetchTurns = fetchTurns;
actions.getTrainingScore = getTrainingScore;
actions.initTraining = initTraining;
actions.saveTraining = saveTraining;
actions.setCurrentEntityTurn = setCurrentEntityTurn;
actions.setCurrentEntityTurnCurrentAnswer = setCurrentEntityTurnCurrentAnswer;

export { actions, selectors };
export default reducer;
