// @flow

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _deburr from 'lodash/deburr';

import { CDKForm, CDKInput, CDKModal, cdkNotification, useForm } from '@calldesk/components';

import {
  operations as accountsOperations,
  selectors as accountsSelectors,
} from '@state/ducks/accounts';

const BASIC_ACCOUNT_NAME_REGEXP: RegExp = /(^[a-zA-Z0-9-]*$)/;
type Props = {
  close: () => void,
};

function NewAccountModal({ close }: Props) {
  const [form] = useForm();
  const dispatch = useDispatch();

  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const { resetFields, setFields } = form;

  const isLoading: boolean = useSelector(accountsSelectors.isLoading);
  const success: boolean = useSelector(accountsSelectors.isSuccess);
  const error: null | string = useSelector(accountsSelectors.getError);

  const createNewAccount = (account: string) => dispatch(accountsOperations.createAccount(account));
  const callback = () => {
    dispatch(accountsOperations.updateStatus('NONE'));
    dispatch(accountsOperations.updateError(null));
  };

  useEffect(() => {
    if (error) setFields({ accountName: { errors: [new Error(error)] } });
  }, [error]);

  useEffect(() => {
    if (success) {
      const account = form.getFieldValue('accountName');
      cdkNotification.success({
        message: 'Creation complete',
        description: `${account} created successfully`,
        top: 75,
      });
      resetFields();
      close();
      callback();
    }
  }, [success]);

  const normalizeAccountName: string => string = value =>
    _deburr(value).replace(' ', '-').toLowerCase();

  const handleFormSubmit = async (event: Event) => {
    event.preventDefault();
    const values = await form.validateFields();
    createNewAccount(values.accountName);
  };

  return (
    <CDKModal
      visible
      title="Create new account"
      confirmLoading={isLoading}
      onOk={handleFormSubmit}
      okButtonProps={{ disabled: disabledButton }}
      onCancel={() => {
        resetFields();
        close();
      }}
    >
      <CDKForm
        form={form}
        onFieldsChange={() => {
          setDisabledButton(form.getFieldsError().some(field => field.errors.length > 0));
        }}
        layout="inline"
        name="newAccountForm"
      >
        <CDKForm.Item
          label="Account name"
          name="accountName"
          rules={[{ required: true, whitespace: false, pattern: BASIC_ACCOUNT_NAME_REGEXP }]}
          normalize={normalizeAccountName}
        >
          <CDKInput />
        </CDKForm.Item>
      </CDKForm>
    </CDKModal>
  );
}

export default NewAccountModal;
