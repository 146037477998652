/* eslint-disable max-lines */
// @flow

import type { Status } from '../app/types';
// $FlowFixMe
import type { EntityState } from '@reduxjs/toolkit';

import type { NextTriggers } from '@state/ducks/bots/types';

// ---------- FLOW TYPES ---------- //

type EndPointStub = {|
  action: string,
  statusCode: number,
  set: Array<{ path: string, value: string }>,
  unset: Array<{ path: string }>,
|};

type EndPointCall = {|
  requestBody: string,
  responseBody: string,
  statusCode: number,
  duration: number,
|};

type Turn<T> = {|
  utterance: string | null,
  response: string,
  computedResponse?: string,
  actionCalls: T[],
|};

type HeadersData = {|
  [key: string]: string,
|};

type Headers = {|
  ...HeadersData,
  sessionId: string,
|};

type ChatConversation<T> = {|
  turns: Turn<T>[],
  headers: HeadersData,
  // NOTE: this is legacy type from v3
  // it's not necessarily a good thing to keep it, but for now it works like that in bot-api
  // should be good to switch to success: ?boolean or even boolean | null ?
  // in this case, it will be necessary to update formatters of bot (Builder/services/bot-api)
  success?: boolean | null,
|};

type ConversationLog = ChatConversation<EndPointCall>;

type Node<T> = {|
  id: string,
  action: string,
  ...T,
|};

type SayContent = $Exact<{ message: string, templates: string[], bestTemplate: string }>;
type PlayContent = $Exact<{ content: string, filename: string, url: string }>;

type SayAndPlayContent = SayContent | PlayContent;

type TraceItem<T: Object = Object> = {
  domain?: string,
  nodeId: string,
  timestamp: number,
  nodeName?: string,
  path?: string, // if item come from module
  node: Node<T>,
};

// Generic ?
type ActionNode = {|
  params: Object,
  name?: string, // only for generic action
|};

type SayActionNode = {|
  params: SayAndPlayContent,
|};

type Context = Object;

type Meta = {
  headers: Headers,
  last?: {
    message: string,
    mode: string,
  },
  nConsecutiveErrors: number,
};

type Checkpoint = {|
  reason?: string,
  step: string,
  status: string,
|};

type MessageBase = {|
  id: string,
  timestamp: number,
  nodeId: string,
  domain?: string,
  module?: string,
|};

type SayMessageContentItem = {
  item: string,
  bold: boolean,
  highlight: boolean,
  italic: boolean,
  tooltip?: string,
};

type SayMessage = {
  ...MessageBase,
  sender: 'user' | 'bot',
  domain?: string,
  content: SayMessageContentItem[],
};

type ErrorMessage = {
  ...MessageBase,
  solution: string,
  nodeId: string,
  content: string,
  sender: 'error',
  location?: string,
};

type ActionMessageType = 'classic' | 'generic' | 'ghost' | 'init' | 'specific' | 'system';

type ActionMessage = {
  ...MessageBase,
  nodeId: string,
  params?: Object, // used for "ghost" ActionMessage type
  sender: 'action',
  content: string,
  state: {
    context: Context,
    meta: Meta,
  },
  type: ActionMessageType,
};

type ConfidenceIntent = { intent: string, confidence: number };

type Intent = {
  parsed: string,
  intent: string,
  entities: { [entityName: string]: any[] },
  confidence: number,
};

type Outcome = {
  text: string,
  confidence: number,
  entitiesConfidence: number,
  intents: Intent[],
  entities?: { [entityName: string]: any[] },
};

type IntentMatch = {
  parsedNotUnderstood?: string,
  text: string | null,
  intents: Intent[],
  eligibleOutcomes?: Outcome[],
  domain: string,
  firstTranscriptTimestampMs?: number,
};

type ThinkDebugResult = {
  confidence: number,
  entitiesConfidence: number,
  intents: Intent[],
  text: string,
};

type ThinkDebug = {
  outcomesWide?: {
    mainResults: ThinkDebugResult[],
    otherResults: ThinkDebugResult[],
  },
};

type ThinkMessage = {
  ...MessageBase,
  confidencesList: ConfidenceIntent[],
  content: string,
  sender: 'think',
  state?: ThinkDebug,
};

type StatsCheckpointMessage = {
  ...MessageBase,
  sender: 'stats',
  content: Checkpoint,
};

type Message = ActionMessage | StatsCheckpointMessage | ErrorMessage | SayMessage | ThinkMessage;

type Stats = {|
  checkpoints: Checkpoint[],
  lastCheckpoint: Checkpoint,
|};
type ConversationState = {
  context: Context,
  domainToNodeId: {
    [domain: string]: string,
  },
  discussionLog: ConversationLog,
  meta: Meta,
  stats?: Stats,
  trace: TraceItem<any>[],
  nextTriggers: ?NextTriggers,
};

type MessageToSend = {
  sender: string,
  content: { sender: 'user' | 'bot', content: string },
  timestamp: number,
};

type ThinkParams = {
  query?: string,
  state: ConversationState,
};

/* Associate a timestamp with request ids, and their parameters for instant rollback */
type RollBackMap = {
  [timestamp: number]: {
    [sayNodeId: string]: ?ThinkParams,
  },
};

type ChatFilters = {
  showAllDetails?: boolean,
  showStatsCheckpoints: boolean,
  showThinkAndActions: boolean,
  showIntentMatch: boolean,
};

type ChatOptions = {
  generateRandomEntityValue: boolean,
};

type Chat = EntityState & {
  errors: {
    last: string | null,
    list: string[],
  },
  conversationState: ConversationState,
  rollbackMap: RollBackMap,
  status: Status,
  settings: {
    filters: ChatFilters,
    options: ChatOptions,
  },
};

type Error = {
  message: string,
  path: string,
  solution: string,
};

type ChatResponseError = {
  duration: number,
  apiVersion: string,
  errors: Error[],
};

type BotAction = { id: string, action: string, params?: Object, name?: string };

type BotSayAction = {
  ...BotAction,
  templates: string[],
  bestTemplate: string,
  message: string,
};

type BotPlayAction = {
  ...BotAction,
  url: string,
  uri: string,
  filename: string,
  content: string,
};

type ChatResponseSuccess = {
  newState: ConversationState,
  nextTriggers?: NextTriggers,
  thinkResponse?: {
    debug: ThinkDebug,
    intentMatch: IntentMatch,
    timestamp: number,
  },
};

type ThinkApiResponse = {
  actions: BotAction[],
  apiVersion: string,
  debug: ThinkDebug,
  duration: number,
  intentMatch: IntentMatch,
  resultTimestamp: number,
};

type ExecuteBody = {
  state: ConversationState,
  intentMatch: IntentMatch,
  actions: BotAction[],
  actionCalls?: EndPointStub[],
  apiCallId?: string,
};

type ExecuteApiResponse = {
  actions: BotAction[],
  apiVersion: string,
  duration: number,
  intentMatch: IntentMatch,
  state: ConversationState,
  botResponseMessage: string,
};

type ContextUpdate = {
  set: Array<{ path: string, value: any }>,
  unset: Array<{ path: string }>,
};

interface TraceItemFactory<T> {
  formatter(traceItem: TraceItem<T>, state: $Shape<ConversationState>, language: string): Message;
  validator(traceItem: TraceItem<any>): boolean;
}

type AddTraceItemsPayload = {
  trace: TraceItem<any>[],
  context: Context,
  meta: Meta,
  language: string,
  stats?: Stats,
  lastSayNodeId?: string,
  lastSayTimestamp?: number,
  thinkParams?: ?ThinkParams,
};

type AddThinkItemPayload = {
  intentMatch: IntentMatch,
  language: string,
  timestamp: number,
  debug: ThinkDebug,
};

type ChatSagaPayloadType = {
  accountId: string,
  bot: string,
  botVersion?: string,
  domains: string[],
  headers?: Object,
};

const DEFAULT_HEADERS: HeadersData = {
  actionsStage: 'dev',
  sessionId: 'CDKCHAT',
};

const DEFAULT_CONVERSATION_STATE: ConversationState = {
  meta: {
    headers: DEFAULT_HEADERS,
    last: { message: '', mode: 'default' },
    nConsecutiveErrors: 0,
  },
  nextTriggers: {},
  context: {},
  discussionLog: { turns: [], headers: {}, success: null },
  domainToNodeId: {},
  trace: [],
};

const START_CHAT_SAGA = 'chat/START_CHAT_SAGA';

export type {
  ActionMessage,
  ActionMessageType,
  ActionNode,
  AddThinkItemPayload,
  AddTraceItemsPayload,
  BotAction,
  BotPlayAction,
  BotSayAction,
  Chat,
  ChatConversation,
  ChatFilters,
  ChatOptions,
  ChatResponseError,
  ChatResponseSuccess,
  ChatSagaPayloadType,
  Checkpoint,
  ConfidenceIntent,
  Context,
  ContextUpdate,
  ConversationLog,
  ConversationState,
  EndPointCall,
  EndPointStub,
  ErrorMessage,
  ExecuteApiResponse,
  ExecuteBody,
  Headers,
  HeadersData,
  Intent,
  IntentMatch,
  Message,
  MessageToSend,
  Meta,
  Outcome,
  PlayContent,
  RollBackMap,
  SayActionNode,
  SayAndPlayContent,
  SayContent,
  SayMessage,
  SayMessageContentItem,
  Stats,
  StatsCheckpointMessage,
  ThinkApiResponse,
  ThinkDebug,
  ThinkDebugResult,
  ThinkMessage,
  ThinkParams,
  TraceItem,
  TraceItemFactory,
  Turn,
};

export { DEFAULT_CONVERSATION_STATE, DEFAULT_HEADERS, START_CHAT_SAGA };
