// @flow

import type { CognitoUser } from '@aws-amplify/auth';
import React, { useEffect, useState } from 'react';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

import { CDKButton, CDKInput } from '@calldesk/components';

import * as cognito from '@api/cognito';

import './new-password-required.css';

type Props = $Exact<{
  user: CognitoUser,
  onSuccess: () => void,
  onCancel: () => void,
}>;

/**
 * @description ResetPassword component
 * @param {Object} props - Properties
 */
function NewPasswordRequired(props: Props) {
  const [canSubmit, setCanSubmit] = useState(false);
  const [confirmationPassword, setConfirmationPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [showConfirmationPassword, setShowConfirmationPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (isLoading) {
      setErrorMessage(null);
    }
  }, [isLoading]);

  useEffect(() => {
    setCanSubmit(
      password &&
        password.trim() &&
        confirmationPassword &&
        confirmationPassword.trim() &&
        password === confirmationPassword,
    );
  }, [password, confirmationPassword]);

  const handleSubmit = () => {
    setIsLoading(true);
    cognito
      .confirmNewPassword(props.user, password, {})
      .then(() => {
        setIsLoading(false);
        props.onSuccess();
      })
      .catch(err => {
        setIsLoading(false);
        setErrorMessage(err.message);
      });
  };

  return (
    <div className="NewPasswordForm">
      <h2>This is your first time here !</h2>
      <h5>In order to access the studio, you must set a new password</h5>
      <div className="NewPasswordFormField">
        <CDKInput
          autoComplete="new-password"
          type={showPassword ? 'text' : 'password'}
          placeholder="Password"
          onChange={event => setPassword(event.target.value)}
          value={password}
          suffix={
            showPassword ? (
              <EyeInvisibleOutlined onClick={() => setShowPassword(false)} />
            ) : (
              <EyeOutlined onClick={() => setShowPassword(true)} />
            )
          }
        />
      </div>
      <div className="NewPasswordFormField">
        <CDKInput
          type={showConfirmationPassword ? 'text' : 'password'}
          placeholder="Confirm password"
          onChange={event => setConfirmationPassword(event.target.value)}
          value={confirmationPassword}
          suffix={
            showConfirmationPassword ? (
              <EyeInvisibleOutlined onClick={() => setShowConfirmationPassword(false)} />
            ) : (
              <EyeOutlined onClick={() => setShowConfirmationPassword(true)} />
            )
          }
        />
      </div>
      <div className="NewPasswordFormActions">
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <CDKButton type="default" onClick={props.onCancel}>
          Cancel
        </CDKButton>
        <CDKButton type="primary" onClick={handleSubmit} disabled={!canSubmit} loading={isLoading}>
          Validate
        </CDKButton>
      </div>
    </div>
  );
}

export default NewPasswordRequired;
