/* eslint-disable max-lines */
// @flow

import type { State as RootState, Status } from '../app/types';
import type {
  Conversation,
  Conversations as ConversationsState,
  ConversationsApiListPayload,
  FilterItem,
  Filters,
  FiltersByType,
  FiltersField,
  FiltersFieldsByType,
} from './types';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  // $FlowFixMe
  createAsyncThunk,
  // $FlowFixMe
  createEntityAdapter,
  // $FlowFixMe
  createSelector,
  // $FlowFixMe
  createSlice,
} from '@reduxjs/toolkit';
import moment from 'moment';

import type { StorageSettings } from '@state/ducks/app/types';
import {
  DEFAULT_CONVERSATIONS_FILTERS,
  DEFAULT_FILTERS_FIELDS,
  DEFAULT_STORAGE_SETTINGS,
} from '@state/ducks/app/types';
import { operations as uiOperations } from '@state/ducks/ui';

import {
  fetchConversation as fetchConversationAPI,
  fetchConversations as fetchConversationsAPI,
  fetchConversationsFields as fetchConversationsFieldsAPI,
} from './api';

type ConversationsPayload = {
  data: ConversationsApiListPayload,
  fromTimeMs: number,
  toTimeMs: number,
  count: number,
  storageSettings: StorageSettings,
};

const conversationsAdapter = createEntityAdapter();

/* APIs Handlers */

/**
 * Fetch API and return a promise with fetched conversations.
 */
const initialState: ConversationsState = conversationsAdapter.getInitialState({
  botVersion: null,
  debugMode: false,
  error: null,
  filters: DEFAULT_CONVERSATIONS_FILTERS,
  filtersModalOpen: false,
  logicalOperator: 'AND',
  quickFilters: DEFAULT_CONVERSATIONS_FILTERS,
  filtersFields: DEFAULT_FILTERS_FIELDS,
  fromTimeMs: Infinity,
  toTimeMs: -Infinity,
  count: 0,
  selectedConversation: null,
  status: 'NONE',
  storageSettings: DEFAULT_STORAGE_SETTINGS,
});

const getConversations = createAsyncThunk(
  'conversations/FETCH_CONVERSATIONS',
  async (
    {
      account,
      bot,
      fromTimeMs,
      toTimeMs,
      filters = null,
      limit = 10,
      offset = 0,
    }: {
      account: string,
      bot: string,
      fromTimeMs: number,
      toTimeMs: number,
      filters?: Filters | null,
      limit?: number,
      offset?: number,
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      // TEMPORARY: filters to avoid in progress conversations (duration = 0)
      const filtersFormatted: Filters = {
        filters: [...(filters?.filters || []), { path: ['duration'], operator: 'gt', value: '0' }],
        operator: filters?.operator || 'AND',
      };

      const {
        conversations: data,
        count,
        storageSettings,
      }: ConversationsApiListPayload = await fetchConversationsAPI(
        account,
        bot,
        fromTimeMs,
        toTimeMs,
        filtersFormatted,
        limit,
        offset,
      );

      return { data, fromTimeMs, toTimeMs, count, storageSettings };
    } catch (error) {
      dispatch(uiOperations.openErrorModal());
      return rejectWithValue({ fromTimeMs, toTimeMs });
    }
  },
  {
    condition: (_, { getState }) => {
      const { status } = getState().conversations;
      if (status === 'IN_PROGRESS') {
        // Already fetched or in progress, don't need to re-fetch
        return false;
      }
      return true;
    },
  },
);

/**
 * Fetch API and return a promise with fetched conversations by ids.
 */
const getConversationsByIds = createAsyncThunk(
  'conversations/FETCH_CONVERSATIONS_BY_IDS',
  async (
    {
      account,
      bot,
      bySessionId,
      conversations,
      fromTimeMs,
      toTimeMs,
      filters = null,
      limit = 10,
      offset = 0,
    }: {
      account: string,
      bot: string,
      bySessionId?: boolean,
      conversations: $Shape<Conversation>[],
      fromTimeMs: number,
      toTimeMs: number,
      filters?: Filters | null,
      limit?: number,
      offset?: number,
    },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const conversationsSettled = await Promise.allSettled(
        conversations.map(conversation =>
          fetchConversationAPI(
            conversation.accountId ?? account,
            conversation.botId ?? conversation.botName ?? bot,
            bySessionId ? conversation?.sessionId : conversation.id,
            fromTimeMs,
            toTimeMs,
            filters,
            limit,
            offset,
          ),
        ),
      );

      const errors = conversationsSettled.filter(({ status }) => status === 'rejected');

      if (errors.length > 0) {
        const [firstError] = errors;
        throw firstError;
      }

      const conversationsFetched: Conversation[] = conversationsSettled
        .filter(({ status }) => status === 'fulfilled')
        // $FlowFixMe Flow 0.112 and onwards have allSettled typings but they don't seem to work when ts definition does
        .map(({ value }: $SettledPromiseResult<Conversation>) => value);

      return { data: conversationsFetched, fromTimeMs, toTimeMs };
    } catch (error) {
      dispatch(uiOperations.openErrorModal());
      return rejectWithValue({ fromTimeMs, toTimeMs });
    }
  },
);

const getConversationsFields = createAsyncThunk(
  'conversations/FETCH_CONVERSATIONS_FIELDS',
  async ({ account, bot }: { account: string, bot: string }) =>
    fetchConversationsFieldsAPI(account, bot),
);

/* ACTIONS & REDUCERS */

const { actions, reducer } = createSlice({
  name: 'conversations',
  initialState,
  reducers: {
    addNewQuickFilter(
      state: ConversationsState,
      { payload: { path, value } }: PayloadAction<{ path: string, value: string }>,
    ) {
      const existingQuickFilter: FilterItem = state.quickFilters.common.find(
        (filterItem: FilterItem) => filterItem.path === path,
      );

      if (existingQuickFilter?.values) {
        existingQuickFilter.values.push(value);
      } else {
        state.quickFilters.common.push({
          filterType: 'common',
          operator: 'in',
          path,
          values: [value],
        });
      }
    },
    clearSearchBarQuickFilters(state: ConversationsState) {
      const existingBotVersionQuickFilterIndex: number = state.quickFilters.common.findIndex(
        (filterItem: FilterItem) => filterItem.path === 'botVersion',
      );
      if (existingBotVersionQuickFilterIndex >= 0)
        state.quickFilters.common.splice(existingBotVersionQuickFilterIndex, 1);

      const existingIdQuickFilterIndex: number = state.quickFilters.common.findIndex(
        (filterItem: FilterItem) => filterItem.path === 'id',
      );
      if (existingIdQuickFilterIndex >= 0)
        state.quickFilters.common.splice(existingIdQuickFilterIndex, 1);

      const existingFromQuickFilterIndex: number = state.quickFilters.common.findIndex(
        (filterItem: FilterItem) => filterItem.path === 'from',
      );
      if (existingFromQuickFilterIndex >= 0)
        state.quickFilters.common.splice(existingFromQuickFilterIndex, 1);
    },
    resetConversations(state: ConversationsState) {
      state.error = null;
      state.selectedConversation = null;
      state.status = 'NONE';
      state.count = 0;
      state.storageSettings = DEFAULT_STORAGE_SETTINGS;
      conversationsAdapter.setAll(state, []);
    },
    setDebugMode(state: ConversationsState, { payload }: PayloadAction<boolean>) {
      state.debugMode = payload;
    },
    setEventsQuickFilter(state: ConversationsState, { payload }: PayloadAction<string[]>) {
      const existingEventsQuickFilterIndex: number = state.quickFilters.common.findIndex(
        (filterItem: FilterItem) => filterItem.path === 'events',
      );

      if (existingEventsQuickFilterIndex >= 0) {
        if (payload.length)
          state.quickFilters.common[existingEventsQuickFilterIndex].values = payload;
        else state.quickFilters.common.splice(existingEventsQuickFilterIndex, 1);
      } else {
        state.quickFilters.common.push({
          operator: 'in',
          path: 'events',
          values: payload,
        });
      }
    },
    setFilters(state: ConversationsState, { payload }: PayloadAction<FiltersByType>) {
      state.filters = payload;
    },
    setFiltersBotVersion(state: ConversationsState, { payload }: PayloadAction<string | null>) {
      state.botVersion = payload;
    },
    setFiltersModalOpen(state: ConversationsState, { payload }: PayloadAction<boolean>) {
      state.filtersModalOpen = payload;
    },
    setLogicalOperator(state: ConversationsState, { payload }: PayloadAction<string>) {
      state.logicalOperator = payload;
    },
    setOutcomesQuickFilter(state: ConversationsState, { payload }: PayloadAction<string[]>) {
      const existingOutcomesQuickFilterIndex: number = state.quickFilters.common.findIndex(
        (filterItem: FilterItem) => filterItem.path === 'outcome',
      );

      if (existingOutcomesQuickFilterIndex >= 0) {
        // NOTE: If all tags has been unselected, remove the outcome quick filter
        if (payload.length)
          state.quickFilters.common[existingOutcomesQuickFilterIndex].values = payload;
        else state.quickFilters.common.splice(existingOutcomesQuickFilterIndex, 1);
      } else {
        state.quickFilters.common.push({
          operator: 'in',
          path: 'outcome',
          values: payload,
        });
      }
    },
    setQuickFilters(state: ConversationsState, { payload }: PayloadAction<FiltersByType>) {
      state.quickFilters = payload;
    },
    setSelectedConversation(state: ConversationsState, { payload }: PayloadAction<Conversation>) {
      state.selectedConversation = payload;
    },
    setTimeRange(state: ConversationsState, { payload }: PayloadAction<[number, number]>) {
      const [fromTimeMs, toTimeMs] = payload;

      state.fromTimeMs = fromTimeMs;
      state.toTimeMs = toTimeMs;
    },
  },
  extraReducers: builder => {
    builder
      // Cases for "getConversations":
      .addCase(getConversations.pending, (state: ConversationsState) => {
        state.status = 'IN_PROGRESS';
      })
      .addCase(
        getConversations.fulfilled,
        (
          state: ConversationsState,
          {
            payload: { data, fromTimeMs, toTimeMs, count, storageSettings },
          }: PayloadAction<ConversationsPayload>,
        ) => {
          state.error = null;
          state.fromTimeMs = fromTimeMs;
          state.toTimeMs = toTimeMs;
          state.count = count;
          conversationsAdapter.addMany(state, data);
          state.status = 'SUCCESS';
          state.storageSettings = storageSettings;
        },
      )
      .addCase(
        getConversations.rejected,
        (state: ConversationsState, { error, payload: { fromTimeMs, toTimeMs } }) => {
          state.status = 'ERROR';
          state.error = error;
          state.fromTimeMs = fromTimeMs;
          state.toTimeMs = toTimeMs;
        },
      )
      // Cases for "getConversationsByIds"
      .addCase(getConversationsByIds.pending, (state: ConversationsState) => {
        state.status = 'IN_PROGRESS';
        conversationsAdapter.setAll(state, []);
      })
      .addCase(
        getConversationsByIds.fulfilled,
        (
          state: ConversationsState,
          { payload: { data, fromTimeMs, toTimeMs } }: PayloadAction<ConversationsPayload>,
        ) => {
          state.error = null;
          state.fromTimeMs = fromTimeMs;
          state.toTimeMs = toTimeMs;
          conversationsAdapter.setAll(state, data);
          state.status = 'SUCCESS';
        },
      )
      .addCase(
        getConversationsByIds.rejected,
        (state: ConversationsState, { error, payload: { fromTimeMs, toTimeMs } }) => {
          state.status = 'ERROR';
          state.error = error;
          state.fromTimeMs = fromTimeMs;
          state.toTimeMs = toTimeMs;
        },
      )
      .addCase(getConversationsFields.pending, (state: ConversationsState) => {
        state.filtersFields = DEFAULT_FILTERS_FIELDS;
      })
      .addCase(
        getConversationsFields.fulfilled,
        (state: ConversationsState, { payload }: PayloadAction<FiltersFieldsByType>) => {
          state.filtersFields = payload;
        },
      );
  },
});

/* SELECTORS */
const conversationsSelectors = conversationsAdapter.getSelectors(
  (state: RootState) => state.conversations,
);

const getDebugMode = ({ conversations }: RootState): boolean => conversations.debugMode;

const getError = ({ conversations }: RootState): string => conversations.error;

const getFilters = ({ conversations }: RootState): FiltersByType => conversations.filters;

const getFiltersBotVersion = ({ conversations }: RootState): string | null =>
  conversations.botVersion;

const getFiltersCount = ({ conversations }: RootState): number =>
  conversations.filters.common.length + conversations.filters.specific.length;

const getFiltersFields = ({ conversations }: RootState): FiltersFieldsByType =>
  conversations.filtersFields;

const getFiltersFieldsValues = ({ conversations }: RootState) => {
  const filtersFieldsValuesMap = {};

  // NOTE: Since we save the path attribute (joined with '->') as filter field
  // We use it as key of our map
  conversations.filtersFields.common.forEach((field: FiltersField) => {
    if (field.values) filtersFieldsValuesMap[field.path.join('->')] = field.values;
  });

  Object.keys(conversations.filtersFields.specific).forEach((version: string) => {
    conversations.filtersFields.specific[version].forEach((field: FiltersField) => {
      if (field.values) filtersFieldsValuesMap[field.path.join('->')] = field.values;
    });
  });

  return filtersFieldsValuesMap;
};

const getFiltersModalOpen = ({ conversations }: RootState): boolean =>
  conversations.filtersModalOpen;

const getFromTimeMs = ({ conversations }: RootState): number =>
  conversations.fromTimeMs === Infinity || !conversations.fromTimeMs
    ? moment().startOf('day').valueOf()
    : conversations.fromTimeMs;

const getLogicalOperator = ({ conversations }: RootState): string => conversations.logicalOperator;

const getQuickFilters = ({ conversations }: RootState): FiltersByType => conversations.quickFilters;

const getQuickFiltersCount = ({ conversations }: RootState): number =>
  conversations.quickFilters.common.length + conversations.quickFilters.specific.length;

const getSelectedConversation = ({ conversations }: RootState): ?Conversation =>
  conversations.selectedConversation;

const getStatus = ({ conversations }: RootState): Status => conversations.status;

const getToTimeMs = ({ conversations }: RootState): number =>
  conversations.toTimeMs === -Infinity || !conversations.toTimeMs
    ? moment().startOf('minute').valueOf()
    : conversations.toTimeMs;

const getSearchbarValue = createSelector(
  getQuickFilters,
  (quickFilters: FiltersByType): ?(string | number) => {
    const searchBarQuickFilters: FilterItem[] = quickFilters.common.filter(
      (filterItem: FilterItem) => ['botVersion', 'id', 'from'].includes(filterItem.path),
    );

    return searchBarQuickFilters.map((qf: FilterItem) => qf.values?.join(',')).join(',');
  },
);

const getSelectedEvents = createSelector(
  getQuickFilters,
  (quickFilters: FiltersByType): ?(string[] | number[]) =>
    quickFilters.common.find((filterItem: FilterItem) => filterItem.path === 'events')?.values,
);

const getSelectedOutcomes = createSelector(
  getQuickFilters,
  (quickFilters: FiltersByType): ?(string[] | number[]) =>
    quickFilters.common.find((filterItem: FilterItem) => filterItem.path === 'outcome')?.values,
);

const hasAnyFilters = createSelector(
  getFiltersCount,
  getQuickFiltersCount,
  (filtersCount: number, quickFiltersCount: number): boolean =>
    filtersCount > 0 || quickFiltersCount > 0,
);
const getAllFiltersQueryString = createSelector(
  getFiltersBotVersion,
  getFromTimeMs,
  getToTimeMs,
  getLogicalOperator,
  getFilters,
  getFiltersCount,
  getQuickFilters,
  getQuickFiltersCount,
  (
    botVersion: ?string,
    fromTimeMs: number,
    toTimeMs: number,
    logicalOperator: string,
    filters: FiltersByType,
    filtersCount: number,
    quickFilters: FiltersByType,
    quickFiltersCount: number,
  ) => {
    let filtersQueryString: string = '';

    filtersQueryString += `?from=${fromTimeMs.toString()}`;
    filtersQueryString += `&to=${toTimeMs.toString()}`;

    if (filtersCount > 0 || quickFiltersCount > 0) {
      filtersQueryString += `&o=${logicalOperator}`;

      if (filtersCount > 0) {
        filtersQueryString += `&f=${encodeURIComponent(JSON.stringify(filters))}`;
      }
      if (quickFiltersCount > 0) {
        filtersQueryString += `&qf=${encodeURIComponent(JSON.stringify(quickFilters))}`;
      }
    }

    if (botVersion) {
      filtersQueryString += `&bv=${botVersion}`;
    }

    return filtersQueryString;
  },
);

const isSelected =
  (id: string) =>
  ({ conversations }: RootState): boolean =>
    conversations.selectedConversation?.id === id;

const selectStorageSettings = ({ conversations }: RootState): StorageSettings =>
  conversations.storageSettings;

const selectors = {
  getAllFiltersQueryString,
  getById: conversationsSelectors.selectById,
  getData: conversationsSelectors.selectAll,
  getDebugMode,
  getError,
  getFilters,
  getFiltersBotVersion,
  getFiltersCount,
  getFiltersFields,
  getFiltersFieldsValues,
  getFiltersModalOpen,
  getFromTimeMs,
  getLogicalOperator,
  getQuickFilters,
  getQuickFiltersCount,
  getSearchbarValue,
  getSelectedConversation,
  getSelectedEvents,
  getSelectedOutcomes,
  getStatus,
  getTotalCount: (state: RootState) => state.conversations.count,
  getToTimeMs,
  hasAnyFilters,
  isSelected,
  selectStorageSettings,
};

/* EXPORTS */
actions.getConversations = getConversations;
actions.getConversationsByIds = getConversationsByIds;
actions.getConversationsFields = getConversationsFields;

export { actions, initialState, selectors };
export default reducer;
