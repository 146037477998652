// @flow

import type { BotConfig } from './types';
// $FlowFixMe
import { createSelector } from '@reduxjs/toolkit';

import { getBotConfig } from './bots.selectors';

const getBotConfigIntents = createSelector(
  getBotConfig,
  (config: BotConfig): string[] => config?.intents ?? [],
);

export { getBotConfigIntents };
