// @flow

import type { ExpressionValidation } from '../types';
import i18next from 'i18next';
import _trim from 'lodash/trim';

const intentExpressionIsValid = (
  expression: string,
  expressionsList: string[],
): ExpressionValidation => {
  const expressionIsValid = expression !== undefined && _trim(expression) !== '';
  const expressionNotAlreadyAdded =
    expressionsList && expressionsList.indexOf(_trim(expression).toLowerCase()) === -1;

  let errorMessage = null;
  if (!(expressionIsValid && expressionNotAlreadyAdded)) {
    errorMessage = !expressionIsValid
      ? i18next.t('Bot.Builder.views.intents.ExpressionsList.errorMessage.noValidFormat-var', {
          ns: 'views',
          value: i18next.t('coreConcept.Expression'),
        })
      : i18next.t('Bot.Builder.views.intents.ExpressionsList.errorMessage.alreadyExist-var', {
          ns: 'views',
          firstValue: i18next.t('coreConcept.Expression'),
          secondValue: i18next.t('coreConcept.expressions'),
        });
  }
  return {
    expression,
    isValid: expressionIsValid && expressionNotAlreadyAdded,
    errorMessage,
  };
};

export default intentExpressionIsValid;
