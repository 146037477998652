// @flow

import type {
  Conversation,
  ConversationsApiListPayload,
  FilterItem,
  Filters,
  FiltersFieldsByType,
  PcapsInfo,
} from '@state/ducks/conversations/types';
import request from '@amplify/api/request';
import logger from '@assets/js/calldesk-app-util/logger';

const areIncludedInFilters = (properties: string[], filters: FilterItem[]): boolean =>
  filters.some(({ path }: FilterItem) =>
    properties.some((property: string) => (path || []).includes(property)),
  );

type ConversationsApiQueryParams = {
  fromTimeMs?: number,
  toTimeMs?: number,
  after?: string,
  filters?: string,
};

/**
 * Fetch all conversations
 */
async function fetchConversations(
  account: string,
  bot: string,
  fromTimeMs: number,
  toTimeMs: number,
  filtersObject: Filters | null,
  limit: number,
  offset: number,
): Promise<ConversationsApiListPayload> {
  const withFromToParams: boolean = !areIncludedInFilters(
    ['id', 'botVersion', 'from'],
    filtersObject && filtersObject.filters ? filtersObject.filters : [],
  );

  const queryStringParameters: ConversationsApiQueryParams = {
    pageSize: limit,
    offset,
    // Removal of "from" and "to" parameters if at least one of the properties
    // like "sessionId", "botVersion", "callerNumber" are included in the filters:
    ...(withFromToParams ? { fromTimeMs, toTimeMs } : {}),
  };

  if (filtersObject && filtersObject.filters.length) {
    queryStringParameters.filters = encodeURIComponent(JSON.stringify(filtersObject));
  }

  const response: { payload: ConversationsApiListPayload } = await request.conversationsApi({
    method: 'GET',
    path: `/accounts/${account}/bots/${bot}/conversations`,
    queryStringParameters,
  });

  logger.info('state/conversations/api [fetchConversations] Retrieved conversations', response);

  return response.payload;
}

/**
 * Fetch one conversation
 */
async function fetchConversation(
  account: string,
  bot: string,
  conversationId: string,
  fromTimeMs: number,
  toTimeMs: number,
  filtersObject: Filters | null,
  limit: number,
  offset: number,
): Promise<Conversation> {
  const queryStringParameters: ConversationsApiQueryParams = {
    fromTimeMs,
    toTimeMs,
    pageSize: limit,
    offset,
  };

  if (filtersObject && filtersObject.filters.length) {
    queryStringParameters.filters = encodeURIComponent(JSON.stringify(filtersObject));
  }

  const response: { payload: Conversation } = await request.conversationsApi({
    method: 'GET',
    path: `/accounts/${account}/bots/${bot}/conversations/${conversationId}`,
    queryStringParameters,
  });

  logger.info('state/conversations/api [fetchConversation] Retrieved conversation by id', response);

  return response.payload;
}

/**
 * Fetch conversations fields (for filters)
 */
async function fetchConversationsFields(
  account: string,
  bot: string,
): Promise<FiltersFieldsByType> {
  const response: { payload: FiltersFieldsByType } = await request.conversationsApi({
    method: 'GET',
    path: `/accounts/${account}/bots/${bot}/conversations/fields`,
  });

  logger.info(
    'state/conversations/api [fetchConversationsFields] Retrieved conversations fields',
    response,
  );

  return response.payload;
}

/**
 * Get conversations CSV
 */
async function getConversationsCsv(
  account: string,
  bot: string,
  fromTimeMs: number,
  toTimeMs: number,
  filtersObject: Filters | null,
): Promise<Blob> {
  const queryStringParameters: ConversationsApiQueryParams = {
    fromTimeMs,
    toTimeMs,
  };

  if (filtersObject && filtersObject.filters.length) {
    queryStringParameters.filters = encodeURIComponent(JSON.stringify(filtersObject));
  }

  const csv: Blob = await request.conversationsApi({
    method: 'GET',
    path: `/accounts/${account}/bots/${bot}/conversations`,
    queryStringParameters,
    headers: {
      Accept: 'text/csv',
    },
    responseType: 'blob',
  });

  logger.info('state/conversations/api [getConversationsCsv] Retrieved conversations csv', csv);

  return csv;
}

/**
 * Get conversations pcaps
 */
async function getConversationsPcaps(account: string, bot: string, callId: string): Promise<any> {
  const response: { payload: PcapsInfo } = await request.conversationsApi({
    method: 'GET',
    path: `/accounts/${account}/bots/${bot}/conversations/${callId}/pcaps`,
  });

  logger.info(
    'state/conversations/api [getConversationsPcaps] Retrieved conversations pcaps',
    response,
  );

  return response.payload;
}

export {
  fetchConversation,
  fetchConversations,
  fetchConversationsFields,
  getConversationsCsv,
  getConversationsPcaps,
};
