// @flow

import type { CategoryValidators, ExerciseValidators } from '@state/ducks/onboarding/types';

import * as createIntroductionQuests from './createIntroduction';
import * as deployAndTestQuests from './deployAndTest';
import * as discoveryCallsQuests from './discoveryCalls';
import * as settingsQuests from './settings';

const builder: CategoryValidators = {
  steps: {
    createIntroduction: { quests: createIntroductionQuests },
    deployAndTest: { quests: deployAndTestQuests },
    settings: { quests: settingsQuests },
  },
};

const calls: CategoryValidators = {
  steps: {
    discoveryCalls: {
      quests: discoveryCallsQuests,
    },
  },
};

const exercise: ExerciseValidators = {
  categories: {
    builder,
    calls,
  },
};

export default exercise;
