// @flow

import type { Saga } from 'redux-saga';
import _sortBy from 'lodash/sortBy';
import { all, call, put } from 'redux-saga/effects';

import type { Action } from '@state/ducks/app/types';
import * as cognito from '@api/cognito';
import logger from '@assets/js/calldesk-app-util/logger';
import { operations as accountsOperations } from '@state/ducks/accounts';
import * as accountsApi from '@state/ducks/accounts/api';
import { operations as botsOperations } from '@state/ducks/bots';
import { operations as sessionOperations } from '@state/ducks/session';
import { operations as uiOperations } from '@state/ducks/ui';

const UNAUTHENTICATED_ROUTES: string[] = ['/login', '/forgotpassword', '/resetpassword'];

/**
 * Start saga following the app initialization
 * Should be called only once
 */
export function* startAppSaga(action: Action & { payload: Object }): Saga<any> {
  const { history, location, match } = action.payload;

  let token;

  try {
    token = yield call(cognito.checkSessionToken);
  } catch (error) {
    yield put(sessionOperations.checkSessionTokenError(error));
  }

  if (token) {
    yield put(sessionOperations.login.fulfilled({ token }));

    /**
     * In this case, user has been authenticated and tries to go back to login.
     * Redirect him to home page in order to avoid infinite loop
     */
    if (location.pathname === '/login') history.push('/');
    const user: string = yield call(cognito.getUserSub);
    const accounts: string[] = yield call(accountsApi.fetchAccountsForUser, user);
    const sortedAccounts: string[] = _sortBy(accounts);
    const defaultAccount: string | null =
      (match && match.params && match.params.account) ||
      (sortedAccounts.length ? sortedAccounts[0] : null);

    if (defaultAccount) {
      yield all([
        put(accountsOperations.updateAccounts(sortedAccounts)),
        put(uiOperations.updateSelectedAccount(defaultAccount)),
        put(botsOperations.startGetBotsSaga({ history })),
      ]);
    } else {
      logger.info(
        '@state/sagas/app::startAppSaga() => it looks like this user has no accounts associated with them',
      );
    }
  } else if (UNAUTHENTICATED_ROUTES.includes(location.pathname)) {
    history.push(`${location.pathname}${location.search || ''}`);
  } else {
    history.push(`/login`);
  }
}
