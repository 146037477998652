// @flow

import type { PayloadAction } from '@reduxjs/toolkit';
import type { Saga } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import type { StorageSettings } from '@state/ducks/app/types';
import type {
  AutoPilotStats,
  Bot,
  BotConfig,
  BotLanguage,
  LoadedBotConfig,
} from '@state/ducks/bots/types';
import { getUserSub } from '@api/cognito';
import logger from '@assets/js/calldesk-app-util/logger';
import { setProductTourCookie } from '@assets/js/calldesk-app-util/product-tour';
import { operations as accountsOperations } from '@state/ducks/accounts';
import { operations as botsOperations, selectors as botsSelectors } from '@state/ducks/bots';
import * as botsApi from '@state/ducks/bots/api';
import * as botsUtils from '@state/ducks/bots/utils';
import {
  operations as intentsOperations,
  selectors as intentsSelectors,
} from '@state/ducks/intents';
import { operations as onboardingOperations } from '@state/ducks/onboarding';
import * as optionsApi from '@state/ducks/options/api';
import { operations as uiOperations } from '@state/ducks/ui';
import { monitoringCatchException } from '@state/monitoring-enhancer';
import * as botsSagas from '@state/sagas/bots';
import * as onboardingSagas from '@state/sagas/onboarding';
/**
 * This saga in order to create new bot
 *
 * @param {string} accountId - Account where new bot will be created.
 * @param {string} botName - New bot's name.
 * @param {BotLanguage} botLanguage - Language of bot selected
 * @param {string} botTimezone - Timezone of bot selected
 * @param {File[]} autoPilotFiles - Autopilot files
 * @param {string[]} bots - Bots list from account
 * @param {null | { account: string, botName: string }} template - Template for duplication
 *
 * @returns {Saga<any>}.
 */
function* createBotSaga({
  payload: {
    accountId,
    botName,
    botLanguage,
    botTimezone,
    autoPilotFiles,
    bots = [],
    botTemplate,
    storageSettings,
  },
}: PayloadAction<{
  accountId: string,
  botName: string,
  botLanguage: BotLanguage,
  botTimezone: string,
  autoPilotFiles?: File[],
  bots: string[],
  botTemplate?: { account: string, botName: string, id: string },
  storageSettings: StorageSettings,
}>): Saga<any> {
  const userSub: string = yield call(getUserSub);
  try {
    yield put(onboardingOperations.clearOnboarding());
    // ------------------ //
    // Duplicate bot with template
    if (botTemplate) {
      yield put(
        botsOperations.setSpecificBotsStatus({
          name: 'create',
          value: 'IN_PROGRESS',
        }),
      );
      if (botTemplate.botName === 'onboarding') {
        yield call(
          onboardingSagas.createNewOnboardingSaga,
          onboardingOperations.createNewOnboardingSaga({ account: accountId, bot: botName }),
        );
      }
      yield call(
        botsSagas.duplicateBotSaga,
        botsOperations.duplicateBotSaga({
          from: {
            accountId: botTemplate.account,
            botName: botTemplate.botName,
            botTemplate: { id: botTemplate.id },
          },
          to: { accountId, botName },
          settings: {
            timezone: botTimezone,
            language: botLanguage,
            storageSettings,
          },
        }),
      );
      setProductTourCookie({ template: botTemplate.botName, play: true });
      yield put(botsOperations.setSpecificBotsStatus({ name: 'create', value: 'SUCCESS' }));

      if (botTemplate.botName !== 'onboarding') {
        // In order to play with chat after creating bot
        const botConfig: BotConfig = yield select(botsSelectors.getBotConfig, botName);
        yield call(
          botsSagas.saveBotSaga,
          botsOperations.startSaveBotSaga({
            account: accountId,
            bot: botName,
            tag: 'dev',
            description: 'init',
            parentSnapshotId: 'latest',
            botConfig,
          }),
        );
        yield call(
          botsSagas.publishBotSaga,
          botsOperations.startPublishBotSaga({
            bot: botName,
            tag: 'dev',
            environment: 'dev',
            description: '',
          }),
        );
      }

      return;
    }
    // ------------------ //

    // ------------------ //
    // Create bot without template
    // start IN_PROGRESS status here because in case above, duplicateBotSaga is in charge of it
    yield put(botsOperations.setSpecificBotsStatus({ name: 'create', value: 'IN_PROGRESS' }));
    const availableOptions = yield call(optionsApi.fetchAvailableOptions, {
      accountId,
      botName,
      filters: { optionsName: ['voices', 'intents'] },
    });

    const tacticalIntents = botsUtils.getTacticalIntentsForLanguage(
      botLanguage,
      availableOptions.intents,
    );

    yield put(intentsOperations.setIntents(tacticalIntents));

    let newBot: Bot = botsUtils.getNewBot({
      botName,
      botType: 'voicebot',
      botLanguage,
      botTimeZone: botTimezone,
      availableOptions,
      botStorageSettings: storageSettings,
    });
    if (autoPilotFiles) {
      const formattedBotConfig: LoadedBotConfig = botsUtils.formatBotConfig(newBot.config);
      const intents = yield select(
        intentsSelectors.selectIntentsMapByIds,
        formattedBotConfig.intents,
      );
      const autopilot: { stats: AutoPilotStats, config: LoadedBotConfig } = yield call(
        botsApi.generateConfigFromDiscussions,
        accountId,
        {
          ...formattedBotConfig,
          intents,
        },
        autoPilotFiles,
      );

      const formattedAutopilotConfig = botsUtils.formatBotConfigForFront(
        autopilot.config,
        availableOptions.intents,
      );

      newBot = {
        ...newBot,
        config: {
          ...formattedAutopilotConfig,
          // $FlowFixMe At this moment, intents it's map of intents
          intents: Object.keys(formattedAutopilotConfig.intents),
        },
        autoPilotStats: autopilot.stats,
      };
      yield put(intentsOperations.setIntents(formattedAutopilotConfig.intents));
    }
    yield put(botsOperations.createNewBot({ bot: botName, data: newBot }));
    // ------------------ //

    yield call(
      botsSagas.saveBotSaga,
      botsOperations.startSaveBotSaga({
        account: accountId,
        bot: botName,
        botConfig: newBot.config,
        description: 'Bot creation',
        parentSnapshotId: '',
      }),
    );

    yield put(accountsOperations.updateBots({ accountId, botIds: [...bots, botName] }));
    yield put(botsOperations.setSpecificBotsStatus({ name: 'create', value: 'SUCCESS' }));
    yield put(botsOperations.updateError(null));
  } catch (error) {
    logger.error('[ERROR] Bots > createBotSaga > ', error);
    yield put(botsOperations.updateError(error.message));
    yield put(uiOperations.openErrorModal());
    yield put(botsOperations.setSpecificBotsStatus({ name: 'create', value: 'ERROR' }));
    monitoringCatchException({
      exception: error,
      userSub,
      location: 'state/sagas/bots/createBotSaga',
      functionType: 'saga',
      params: { botLanguage, botTimezone, botTemplate, bots },
      accountId,
      botName,
    });
  }
}

export default createBotSaga;
