// @flow

import type { ProductTourCookie } from './index.type';

import { PRODUCT_TOUR_COOKIE_NAME } from './index.type';

const setProductTourCookie = (newProductTourCookie: ProductTourCookie): void =>
  localStorage.setItem(PRODUCT_TOUR_COOKIE_NAME, JSON.stringify(newProductTourCookie));

export default setProductTourCookie;
