// @flow

import type { BotConfig, Node, Transition } from '@state/ducks/bots/types';
import type { ValidateFnReturn } from '@state/ducks/onboarding/types';
import {
  GENERIC_NODE_GET_CURRENT_DAY_PART_ACTION,
  ORIGIN_NODE_NAME,
  SAY_NODE_NAME,
} from '@state/ducks/bots/types';
import { getNodesAndTransitions } from '@state/ducks/onboarding/utils/validator/utils';

type CreateSayActionValidate = ValidateFnReturn<{
  nodeSayActionInto: ?Node,
  nodes: Node[],
  transitions: Transition[],
}>;

/**
 * Validate `createSayAction` quest
 */
function validate(botConfig: BotConfig): CreateSayActionValidate {
  const { nodes, transitions }: { nodes: Node[], transitions: Transition[] } =
    getNodesAndTransitions(botConfig);

  const genericNode: ?Node = nodes.find(
    (node: Node) => node.name && node.name === GENERIC_NODE_GET_CURRENT_DAY_PART_ACTION,
  );

  const nodeSayActionInto: ?Node = nodes.find(
    (node: Node) =>
      node.action === SAY_NODE_NAME &&
      node.templates &&
      node.templates.length > 0 &&
      transitions.some(
        (transition: Transition) =>
          (transition?.startingNode === ORIGIN_NODE_NAME ||
            (genericNode && transition?.startingNode === genericNode.id)) &&
          transition.endingNode === node.id,
      ),
  );

  return {
    others: {
      nodeSayActionInto,
      nodes,
      transitions,
    },
    validated: !!nodeSayActionInto,
  };
}

export default validate;
