// @flow

import type { IconProps } from 'antd/lib/icon';
import React from 'react';
import Icon from '@ant-design/icons';

function EmptySVG() {
  return (
    <svg
      version="1.1"
      viewBox="0 0 1272 632"
      preserveAspectRatio="xMinYMin meet"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="715.452"
        width="440"
        height="256.688"
        rx="128.344"
        transform="rotate(-46.5544 0 715.452)"
        fill="#FFE085"
      />
      <g clipPath="url(#clip0)" transform="matrix(1, 0, 0, 1, -355.226776, -12.08887)">
        <ellipse cx="1256.82" cy="613.682" rx="162.807" ry="162.664" fill="#00B4F8" />
        <path
          d="M1066.85 300.457L1059.86 343.079L1060.98 343.262L1067.97 300.641L1066.85 300.457Z"
          fill="#053B4B"
        />
        <path
          d="M1065.39 280.464C1061.88 275.59 1058.02 272.982 1053.72 272.869C1046.47 272.529 1040.58 279.331 1040.58 279.444L1039.68 278.764C1039.9 278.424 1045.91 271.508 1053.72 271.849C1058.36 272.075 1062.56 274.796 1066.18 280.011L1065.39 280.464Z"
          fill="#053B4B"
        />
        <path
          d="M1114.09 277.403C1110.69 273.322 1106.73 271.168 1102.65 270.942C1095.63 270.602 1090.08 275.93 1089.97 276.043L1089.17 275.249C1089.4 275.023 1095.18 269.468 1102.65 269.808C1107.18 270.035 1111.26 272.302 1115 276.61L1114.09 277.403Z"
          fill="#053B4B"
        />
        <path
          d="M1076.03 382.264L1075.01 381.924C1077.62 374.895 1081.47 370.588 1086.68 368.887C1095.4 366.053 1104.58 371.948 1104.92 372.175L1104.24 373.082C1104.12 372.968 1095.18 367.187 1086.91 369.907C1082.15 371.495 1078.41 375.689 1076.03 382.264Z"
          fill="#053B4B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1050.89 203.717C1050.89 203.717 1034.47 189.433 1020.99 199.069C1007.51 208.705 1000.37 244.641 1045.45 255.751C1090.53 266.86 1122.81 238.746 1139.58 246.228C1156.45 253.71 1160.98 265.613 1158.6 276.27C1156.23 286.926 1153.96 329.664 1166.53 334.652C1179.67 339.753 1207.42 315.607 1207.42 315.607C1207.42 315.607 1225.66 264.48 1213.88 241.354C1202.1 218.228 1173.22 202.697 1135.05 203.604C1096.76 204.624 1078.98 186.372 1063.35 190.68C1047.72 194.988 1050.89 203.717 1050.89 203.717Z"
          fill="#053B4B"
        />
        <path
          d="M1204.19 303.058L1200.34 313.72L1201.4 314.105L1205.25 303.443L1204.19 303.058Z"
          fill="#053B4B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1119.41 431.464L1158.38 424.775C1160.3 424.095 1162.12 425.909 1161.55 427.836C1158.15 438.152 1139.8 457.537 1123.38 463.092C1120.55 440.873 1119.41 431.464 1119.41 431.464Z"
          fill="#053B4B"
        />
        <path
          d="M1122.93 463.999L1118.85 431.01L1158.38 424.322C1159.4 423.982 1160.53 424.209 1161.44 425.002C1162.23 425.796 1162.57 427.043 1162.23 428.063C1158.94 438.039 1140.94 457.878 1123.72 463.659L1122.93 463.999ZM1120.09 431.917L1123.95 462.412C1140.14 456.517 1157.93 437.359 1161.1 427.723C1161.32 427.043 1161.1 426.249 1160.64 425.796C1160.08 425.229 1159.4 425.116 1158.72 425.342L1120.09 431.917Z"
          fill="#053B4B"
        />
        <path
          d="M1012.83 519.547C1012.72 519.547 1012.6 519.547 1012.49 519.547C1002.07 519.207 1001.96 505.263 1001.96 505.15H1003.09C1003.09 505.717 1003.2 518.187 1012.49 518.413C1012.6 518.413 1012.72 518.413 1012.83 518.413C1022.69 518.413 1047.04 503.45 1056.21 497.781L1056.67 497.441C1056.78 497.328 1067.65 489.506 1065.39 480.437C1065.16 479.757 1065.05 479.076 1064.82 478.396C1063.01 470.801 1062.67 469.667 1049.87 469.1V467.967C1063.12 468.534 1063.8 470.007 1065.84 478.169C1065.95 478.85 1066.18 479.53 1066.29 480.21C1068.78 490.073 1057.57 498.121 1057.12 498.462L1056.67 498.802C1046.58 505.037 1023.03 519.547 1012.83 519.547Z"
          fill="#053B4B"
        />
        <path
          d="M963.334 389.633L962.768 388.726C963.108 388.499 992.783 369.454 1001.05 355.964C1005.92 348.028 1013.4 343.154 1020.19 343.607C1023.93 343.834 1026.88 345.761 1028.35 348.822C1032.2 357.097 1015.78 383.171 1015.1 384.305L1014.19 383.738C1014.3 383.511 1030.95 357.097 1027.44 349.389C1026.2 346.668 1023.59 344.968 1020.19 344.741C1015.32 344.514 1007.73 347.348 1002.07 356.644C993.576 370.248 964.58 388.839 963.334 389.633Z"
          fill="#053B4B"
        />
        <path
          d="M 887.446 643 C 884.275 626.109 881.103 610.238 878.385 596.181 C 868.644 547.435 861.621 512.179 864.906 484.291 C 868.191 455.724 882.462 434.978 912.704 414.913 C 941.587 395.755 947.93 393.827 953.14 392.354 C 954.726 391.9 956.198 391.447 958.237 390.54 C 983.609 378.75 1050.44 384.305 1067.43 390.54 C 1085.66 397.228 1085.43 403.803 1085.32 407.658 C 1085.32 408.905 1084.87 412.192 1081.58 414.8 C 1078.98 416.953 1074 419.107 1064.25 417.634 C 1048.51 415.14 1005.13 412.419 1004.68 412.419 L 1004.79 411.285 C 1005.24 411.285 1048.62 414.119 1064.48 416.613 C 1071.84 417.747 1077.887 417.029 1081.183 414.402 C 1084.081 412.093 1084.19 408.905 1084.19 407.771 C 1084.3 404.143 1084.53 398.249 1067.09 391.787 C 1048.62 384.985 982.136 380.904 958.804 391.787 C 956.765 392.807 955.179 393.261 953.593 393.714 C 948.496 395.188 942.153 397.115 913.497 416.16 C 851.994 456.971 860.149 497.782 879.744 596.294 C 882.576 610.351 885.747 626.222 888.805 643.113 L 887.446 643 Z"
          fill="#053B4B"
        />
        <path
          d="M1006.6 639.712C993.803 596.634 990.405 578.609 983.835 542.9C982.703 536.778 981.457 530.09 980.098 522.721L979.984 522.381L980.211 522.154C987.12 516.826 992.444 512.745 996.634 509.458C1011.36 498.121 1012.83 496.874 1022.8 491.093L1022.91 490.979H1043.75V492.113H1023.25C1013.4 497.781 1011.81 499.028 997.314 510.251C993.123 513.539 987.913 517.507 981.117 522.721C982.476 529.977 983.722 536.552 984.855 542.56C991.537 578.269 994.822 596.181 1007.62 639.259L1006.6 639.712Z"
          fill="#053B4B"
        />
        <path
          d="M 1043.98 444.614 C 1039.68 444.614 1036.05 443.934 1035.82 443.82 L 1016.8 440.646 L 1017.02 439.512 L 1036.16 442.687 C 1036.28 442.687 1050.21 445.407 1055.76 440.193 C 1057.57 438.492 1058.25 435.998 1057.91 432.937 C 1055.99 418.314 1029.14 414.006 1028.8 414.006 L 1028.847 413.116 C 1028.957 413.116 1036.05 414.006 1043.19 416.953 C 1052.7 420.921 1058.25 426.362 1059.04 432.824 C 1059.5 436.338 1058.7 439.059 1056.55 440.986 C 1053.38 443.82 1048.4 444.614 1043.98 444.614 Z"
          fill="#053B4B"
        />
        <path
          d="M1048.85 469.214C1047.6 469.214 1046.81 469.1 1046.7 469.1L1020.19 467.627L1020.31 466.493L1046.81 467.967C1046.92 467.967 1056.21 468.874 1060.86 464.339C1062.89 462.412 1063.69 459.578 1063.35 456.177C1061.99 442.46 1040.69 444.5 1040.47 444.5L1040.36 443.367C1040.58 443.367 1046.02 442.8 1051.68 444.047C1059.38 445.747 1063.8 449.942 1064.37 456.064C1064.71 459.918 1063.8 462.979 1061.54 465.133C1058.02 468.647 1052.02 469.214 1048.85 469.214Z"
          fill="#053B4B"
        />
        <path
          d="M1191.57 364.92C1187.94 364.92 1185.45 364.466 1185.34 364.353L1185.56 363.219C1185.68 363.219 1200.17 366.167 1211.05 357.551C1222.49 348.482 1222.03 334.085 1218.18 327.17C1213.54 318.894 1204.82 310.279 1189.41 316.514C1173.56 322.975 1173.44 334.878 1173.44 335.105H1172.31C1172.31 334.992 1172.42 322.295 1188.96 315.493C1205.04 308.918 1214.22 317.874 1219.09 326.603C1223.17 333.858 1223.62 348.936 1211.61 358.458C1205.04 363.786 1197 364.92 1191.57 364.92Z"
          fill="rgb(5, 59, 75)"
        />
        <path
          d="M1201.31 347.008L1185.68 343.607L1199.27 327.623L1200.17 328.417L1187.71 342.814L1201.53 345.875L1201.31 347.008Z"
          fill="#053B4B"
        />
        <path
          d="M1047.26 299.538C1046.02 299.538 1045 298.518 1045 297.271V291.83C1045 290.583 1046.02 289.562 1047.26 289.562C1048.51 289.562 1049.53 290.583 1049.53 291.83V297.271C1049.53 298.518 1048.51 299.538 1047.26 299.538Z"
          fill="#053B4B"
        />
        <path
          d="M1094.95 299.538C1093.7 299.538 1092.68 298.518 1092.68 297.271V291.83C1092.68 290.583 1093.7 289.562 1094.95 289.562C1096.2 289.562 1097.21 290.583 1097.21 291.83V297.271C1097.21 298.518 1096.2 299.538 1094.95 299.538Z"
          fill="#053B4B"
        />
        <path
          d="M990.971 574.529L989.952 574.075C1005.58 541.54 1042.28 523.515 1070.37 514.106C1100.73 503.903 1127.8 501.296 1128.14 501.296L1128.25 502.43C1128.02 502.43 1101.07 505.037 1070.82 515.24C1042.85 524.422 1006.38 542.333 990.971 574.529Z"
          fill="#053B4B"
        />
        <path
          d="M1412.09 486.445C1398.62 469.327 1368.94 450.395 1303.7 458.104C1264.62 462.639 1230.87 474.769 1230.53 474.882L1230.19 473.862C1230.53 473.748 1264.4 461.618 1303.59 456.971C1339.83 452.776 1388.31 454.25 1413 485.765L1412.09 486.445Z"
          fill="#053B4B"
        />
        <line
          fill="#D8D8D8"
          stroke="#053B4B"
          x1="1039.594"
          y1="251.878"
          x2="1026.188"
          y2="384.054"
        />
        <path
          stroke="#053B4B"
          d="M 1118.68 431.414 L 1065.448 434.174 L 1058.714 432.699 L 1056.969 428.092 L 1053.736 424.058 L 1045.433 418.248 L 1032.888 414.098 L 1005.31 411.884 L 1048.2 415.02 L 1069.974 417.881 C 1075.951 418.238 1078.256 417.43 1081.503 414.93 C 1083.767 413.187 1084.921 410.486 1085.186 407.641 C 1085.518 404.072 1083.983 400.585 1081.128 398.418 C 1074.47 393.365 1066.683 390.009 1058.438 388.641 C 1052.599 387.672 1046.747 386.787 1040.883 385.987 C 1035.198 385.211 1029.467 384.823 1023.729 384.823"
        />
        <line stroke="#053B4B" x1="1118.418" y1="431" x2="1128.188" y2="501.76" />
        <line stroke="#053B4B" x1="1202.48" y1="362.923" x2="1230.842" y2="474.611" />
        <polyline stroke="#053B4B" points="1127.843 500.898 1185.693 517.621 1231.098 473.236" />
      </g>
      <path d="M416.891 60.2197L386.147 10.6211" stroke="#073C4C" />
      <path d="M393.67 76.4998L309.398 11.9751" stroke="#073C4C" />
    </svg>
  );
}

/**
 * UnexepectedResult Icon component
 */
function EmptyIcon(props: IconProps) {
  return <Icon component={EmptySVG} {...props} />;
}

export default EmptyIcon;
