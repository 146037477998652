// @flow

import { API } from '@aws-amplify/api';
import _upperCase from 'lodash/upperCase';

import { getUserSub } from '@api/cognito';
import BotApiRequestError from '@api/errors/BotApiRequestError';
import StudioApiRequestError from '@api/errors/StudioApiRequestError';
import endpoints from '@resources/calldesk-endpoints.json';

const requestServices: {
  availableApis: string[],
} = {
  availableApis: Object.keys(endpoints),
};

type RequestParams<
  T = Object,
  H = {
    [headersName: string]: string | Object,
  },
  Q = {
    [queryParam: string]: string | Object,
  },
> = {|
  headers?: H,
  queryStringParameters?: Q,
  body?: T,
  responseType?: string,
  path: string,
  method: 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH',
|};

type ApiToEndpointMap = {
  [apiName: string]: <T = Object, R = Object, H = Object, Q = Object>(
    params: RequestParams<T, H, Q>,
  ) => R,
};

/**
 * Transform the string to match the constant declaration case, e.g
 * `const MY_CONSTANT = [...]`
 *
 * @param {string} x - the variable to encode
 * @returns {string} the constant case variant of the variable
 */
const constantCase = (x: string) => _upperCase(x).replace(/ /g, '_');

/**
 * Removing nullish values on params and constructing the query for request API
 * @param {RequestParam} params the input params
 * @return {$Shape<RequestParams>} The query object
 */
function getOptions<T>(params: RequestParams<T>): $Shape<RequestParams<T>> {
  const query: $Shape<RequestParams<T>> = {};
  if (params.headers) {
    query.headers = params.headers;
  }
  if (params.queryStringParameters) {
    query.queryStringParameters = params.queryStringParameters;
  }
  if (params.body) {
    query.body = params.body;
  }
  if (params.responseType) {
    query.responseType = params.responseType;
  }
  return query;
}

/**
 * Encode uri components for the given path so it is safe to call without losing information because of the lack of encoding
 * @param {string} path the Api path
 * @return {string} the url to call with encoded uri components
 */
function getPath(path: string): string {
  return path
    .split('/')
    .map((part: string) => encodeURIComponent(part))
    .join('/');
}

/**
 * Return the rest verb for the given operation
 * @param {string} verb - can be one of : delete | put | post | get | patch | options
 * @returns {string} the corrected HTTP verb
 */
const getRestVerb = (verb: string) => (verb === 'delete' ? 'del' : verb);

/**
 * Error handling method for our apis
 * @param {string} apiName - the api being called
 * @param {any} error - the error that occurred
 *
 * Note about error : We could use AxiosError type or class but flow-typed axios definition doesn't work with versions prior to 0.18
 * And the $AxiosError type require generic type that we can't provide here since we don't know the return type for the request.
 *
 * @returns depending on the api being called, it might return a specific error object or the payload if it's available
 * fallbacks to the root error if nothing matches
 */
const errorHandling = (apiName: string, error: any): Error => {
  switch (apiName) {
    case 'botApi': {
      return new BotApiRequestError(error.response?.status, error.response?.data);
    }
    case 'studioApi': {
      return new StudioApiRequestError(error.response?.status, error.response?.data);
    }
    default:
      return error.response?.data?.payload ?? error;
  }
};

/**
 * make a fetch wrapper around amplify client
 * @param {string} apiName
 * @returns {(params: any) => Promise<any>} The Function calling to correct calldesk API endpoint
 */
function makeCdkFetch<T = any>(apiName: string): (params: RequestParams<T>) => Promise<T> {
  return async params => {
    const options = getOptions<T>(params);
    const API_STAGE_PROP = `${constantCase(apiName)}_STAGE`;
    const envStage = process.env[API_STAGE_PROP] || endpoints[apiName][`${API_STAGE_PROP}_PROD`];
    if (envStage.includes('local')) {
      const userSub = (await getUserSub()) ?? '';
      options.headers = {
        ...options.headers,
        'cognito-authentication-provider': `local:CognitoSignIn:${userSub}`,
      };
    }

    const encodedPath: string = getPath(params.path);
    const restVerb = getRestVerb(params.method.toLowerCase());

    return API[restVerb](apiName, encodedPath, options).catch(error =>
      Promise.reject(errorHandling(apiName, error)),
    );
  };
}

/**
 * make a client for all available services
 * @param {string[]} availableServices list of services
 * @returns {ApiToEndpointMap} The object that contains a property containing the function reaching the endpoint of the said api
 */
function makeRequest(availableServices: string[]): ApiToEndpointMap {
  return availableServices.reduce(
    (services, apiName) => ({
      ...services,
      [apiName]: makeCdkFetch(apiName),
    }),
    {},
  );
}

export default makeRequest(requestServices.availableApis);
