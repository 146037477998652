/* eslint-disable max-lines */

// @flow
import type { State as RootState } from '../app/types'; // en début de fichier pour récupérer le State de App. Il y aura cet import dans tout nos slices donc on peut l'harmoniser je pense
import type {
  Chat as State,
  ChatFilters,
  ChatOptions,
  ChatResponseSuccess,
  Headers,
  Message,
  ThinkParams,
} from './types';
import type { Action, PayloadAction } from '@reduxjs/toolkit';
import {
  // $FlowFixMe
  createAsyncThunk,
  // $FlowFixMe
  createEntityAdapter,
  // $FlowFixMe
  createSelector,
  // $FlowFixMe
  createSlice,
} from '@reduxjs/toolkit';
import _entries from 'lodash/entries';
import _pickBy from 'lodash/pickBy';

import { END_OF_CONVERSATION_NODES, ORIGIN_NODE_NAME } from '@state/ducks/bots/types';

import { chat } from './api';
import { DEFAULT_CONVERSATION_STATE } from './types';
import {
  buildRollbackMap,
  errorMessageFromBotApi,
  generateMessagesFromResponses,
  sortMessagesByTimestamp,
} from './util';

const chatAdapter = createEntityAdapter({
  // concat [message.sender, message.nodeId, message.timestamp] instead of just node-id ?
  sortComparer: sortMessagesByTimestamp,
});

const initialState: State = chatAdapter.getInitialState({
  errors: {
    last: null,
    list: [],
  },
  conversationState: DEFAULT_CONVERSATION_STATE,
  rollbackMap: {},
  status: 'NONE',
  settings: {
    filters: {
      showIntentMatch: true,
      showStatsCheckpoints: false,
      showThinkAndActions: false,
    },
    isOpen: false,
    options: {
      generateRandomEntityValue: false,
    },
  },
});

/* API HANDLERS */
const sendMessage = createAsyncThunk(
  'chat/sendMessage',
  async (
    {
      accountId,
      botId,
      botVersion = 'dev',
      query,
    }: {
      accountId: string,
      botId: string,
      botVersion: string,
      query?: string,
      lastSayNodeId?: string,
      lastSayTimestamp?: number,
    },
    { getState, rejectWithValue },
  ) => {
    const { bots, chat: chatState }: RootState = getState();
    const { language } = bots.entities[botId].config;
    const { conversationState }: State = chatState;
    try {
      const responses: ChatResponseSuccess[] = await chat(
        accountId,
        botId,
        botVersion,
        conversationState,
        query,
      ).then(resp =>
        resp.filter(
          response =>
            response.newState.trace.length > 0 &&
            response.newState.context &&
            response.newState.meta,
        ),
      );

      return {
        responses,
        language,
        conversationState: responses[responses.length - 1].newState,
      };
    } catch (error) {
      if (error.newState?.trace?.length > 0 && error.newState?.context && error.newState?.meta) {
        return rejectWithValue({
          responses: [error],
          language,
          conversationState: error.newState,
        });
      }
      throw error;
    }
  },
);

/* ACTIONS & REDUCERS */
const { actions, reducer } = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    addMessage: chatAdapter.addOne,
    init(state: State, { payload }: PayloadAction<{ domains: string[] }>) {
      state.conversationState.meta.headers.sessionId = 'CDKCHAT';
      const domainToNodeId = Object.fromEntries(
        payload.domains.map(domain => [domain, ORIGIN_NODE_NAME]),
      );
      state.conversationState.domainToNodeId = domainToNodeId;
    },
    reset(state: State) {
      return {
        ...initialState,
        conversationState: {
          ...initialState.conversationState,
          meta: {
            ...initialState.conversationState.meta,
            headers: _pickBy(
              state.conversationState.meta.headers ?? initialState.conversationState.meta.headers,
              value => value !== '',
            ),
          },
        },
        // NOTE: Keep same settings when reset
        settings: state.settings,
      };
    },
    rollback(
      state: State,
      { payload: { nodeId, timestamp } }: PayloadAction<{ nodeId: string, timestamp: number }>,
    ) {
      state.conversationState = state.rollbackMap[timestamp][nodeId].state;
      state.rollbackMap = Object.fromEntries(
        _entries(state.rollbackMap)
          .filter(([rollbackTmp]) => Number(rollbackTmp) <= timestamp)
          .map(tuple => tuple),
      );

      const newMessages = Object.fromEntries(
        _entries(state.entities)
          .filter(
            ([, message]: [string, Message]) =>
              !(message.timestamp >= timestamp && message.nodeId !== nodeId),
          )
          .map(tuple => tuple),
      );
      chatAdapter.setAll(state, newMessages);
    },
    setHeaders(state: State, { payload }: PayloadAction<Headers>) {
      state.conversationState.meta.headers = payload;
    },
    setIsSettingsOpen(state: State, { payload }: PayloadAction<boolean>) {
      state.settings.isOpen = payload;
    },
    setOptions(state: State, { payload }: PayloadAction<ChatOptions>) {
      state.settings.options = payload;
    },
    setShowIntentMatchFilter(state: State, { payload }: PayloadAction<boolean>) {
      state.settings.filters.showIntentMatch = payload;
    },
    setShowStatsCheckpointsFilter(state: State, { payload }: PayloadAction<boolean>) {
      state.settings.filters.showStatsCheckpoints = payload;
    },
    setShowThinkAndActionsFilter(state: State, { payload }: PayloadAction<boolean>) {
      state.settings.filters.showThinkAndActions = payload;
    },
  },
  extraReducers: builder =>
    builder
      .addCase(
        sendMessage.fulfilled,
        (state: State, { meta, payload: { conversationState, responses, language } }) => {
          const { query, lastSayNodeId, lastSayTimestamp } = meta.arg;
          const thinkParams: ThinkParams = { query, state: state.conversationState };
          const lastSay = { nodeId: lastSayNodeId, timestamp: lastSayTimestamp };
          const fromTrace: Message[] = generateMessagesFromResponses(
            responses,
            state.conversationState,
            query,
            language,
          );

          state.rollbackMap = buildRollbackMap(responses, thinkParams, state.rollbackMap, lastSay);
          chatAdapter.addMany(state, fromTrace);
          state.conversationState = conversationState;
        },
      )
      .addCase(sendMessage.rejected, (state: State, { error, payload, meta }) => {
        state.status = 'ERROR';
        let messagesToAdd = [];
        // Meaning it's a "normal" chat error, with a invalid transition for instance
        if (payload) {
          const { query } = meta.arg;
          const { responses, conversationState, language } = payload;
          const [errorFromBotApi] = responses;
          messagesToAdd = generateMessagesFromResponses(
            responses,
            conversationState,
            query,
            language,
          ).concat(errorMessageFromBotApi(errorFromBotApi));
          state.errors.last = errorFromBotApi;
          state.errors.list = state.errors.list.concat(errorFromBotApi);
        } else {
          // Otherwise we handle more generic errors, might break if network error
          state.errors.last = error;
          state.errors.list = state.errors.list.concat(error);
          messagesToAdd = messagesToAdd.concat(errorMessageFromBotApi(error));
        }

        chatAdapter.addMany(state, messagesToAdd);
      })
      .addMatcher(
        (action: Action<string>) =>
          action.type?.startsWith('chat') && action.type?.endsWith('pending'),
        state => {
          state.status = 'IN_PROGRESS';
        },
      ),
});

/* SELECTORS */
const genericSelectors = chatAdapter.getSelectors((state: RootState) => state.chat);
const selectShowIntentMatchFilter = (state: RootState) =>
  state.chat.settings.filters.showIntentMatch;
const selectShowStatsCheckpoints = (state: RootState) =>
  state.chat.settings.filters.showStatsCheckpoints;
const selectShowThinkAndActions = (state: RootState) =>
  state.chat.settings.filters.showThinkAndActions;
const selectChatFilters = createSelector(
  selectShowIntentMatchFilter,
  selectShowStatsCheckpoints,
  selectShowThinkAndActions,
  (showIntentMatch: boolean, showStatsCheckpoints: boolean, showThinkAndActions: boolean) => ({
    showAllDetails: showIntentMatch && showStatsCheckpoints && showThinkAndActions,
    showIntentMatch,
    showStatsCheckpoints,
    showThinkAndActions,
  }),
);
const selectConversationState = (state: RootState) => state.chat.conversationState;
const selectDisplayedMessages = createSelector(
  genericSelectors.selectAll,
  selectChatFilters,
  (messages: Message[], filters: ChatFilters) => {
    let filteredMessagesTmp = messages;
    if (!filters.showThinkAndActions) {
      filteredMessagesTmp = filteredMessagesTmp.filter(message => message.sender !== 'action');
    }
    if (!filters.showStatsCheckpoints) {
      filteredMessagesTmp = filteredMessagesTmp.filter(message => message.sender !== 'stats');
    }
    if (!filters.showIntentMatch) {
      filteredMessagesTmp = filteredMessagesTmp.filter(message => message.sender !== 'think');
    }
    return filteredMessagesTmp;
  },
);
const selectHasConversationEnded = createSelector(
  genericSelectors.selectAll,
  (messages: Message[]) =>
    messages.some(
      message =>
        message.sender === 'error' ||
        (message.sender === 'action' &&
          Object.values(END_OF_CONVERSATION_NODES).includes(message.content)),
    ),
);
const selectHeaders = (state: RootState) => state.chat.conversationState.meta?.headers;
const selectNextTriggers = (state: RootState) => state.chat.conversationState.nextTriggers;
const selectIsSettingsOpen = (state: RootState) => state.chat.settings.isOpen;
const selectOptions = (state: RootState) => state.chat.settings.options;
const selectRollbackMap = (state: RootState) => state.chat.rollbackMap;

const selectors = {
  ...genericSelectors,
  selectChatFilters,
  selectConversationState,
  selectDisplayedMessages,
  selectHasConversationEnded,
  selectHeaders,
  selectIsSettingsOpen,
  selectNextTriggers,
  selectOptions,
  selectRollbackMap,
  selectShowIntentMatchFilter,
  selectShowStatsCheckpoints,
  selectShowThinkAndActions,
};

// Export des thunks
actions.sendMessage = sendMessage;

/* EXPORTS */
export { actions, selectors };
export default reducer;
