// @flow

import type { PayloadAction } from '@reduxjs/toolkit';
import type { Saga } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import type { Environment } from '@state/ducks/bots/types';
import { getUserSub } from '@api/cognito';
import { operations as botsOperations, selectors as botsSelectors } from '@state/ducks/bots';
import * as botsApi from '@state/ducks/bots/api';
import { operations as onboardingOperations } from '@state/ducks/onboarding';
import { operations as uiOperations, selectors as uiSelectors } from '@state/ducks/ui';
import { monitoringCatchException } from '@state/monitoring-enhancer';
import * as onboardingSagas from '@state/sagas/onboarding';

/**
 * Start the publish saga
 * It's important to note that this saga will only be runned by the check saga
 */
function* publishBotSaga(
  action: PayloadAction<{
    bot: string,
    tag: string,
    environment: Environment,
    description: string,
  }>,
): Saga<any> {
  const { bot, tag, description, environment } = action.payload;

  const userSub: string = yield call(getUserSub);

  const accountId: string = yield select(uiSelectors.getSelectedAccount);
  if (!accountId) return;
  const snapshotId: string = yield select(botsSelectors.getLoadedSnapshotId, bot);

  yield put(botsOperations.deployBot(bot));
  yield put(botsOperations.resetCheckBotStatus(bot));

  try {
    yield call(
      botsApi.deploySnapshotOnApi,
      accountId,
      bot,
      tag,
      environment,
      snapshotId,
      description,
    );

    yield put(botsOperations.deployBotSuccess(bot));
  } catch (error) {
    const errors = (error.response && error.response.errors) || [error.message];

    if (environment === 'dev') {
      const errorMessage: string = errors.reduce(
        (acc: string, localError: Error) => `${acc}${localError.message}`,
        '',
      );

      yield put(botsOperations.updateError(errorMessage));
      yield put(uiOperations.openErrorModal());
    }
    yield put(botsOperations.deployBotError({ bot, errors }));
  }

  try {
    yield call(
      onboardingSagas.saveAndCheckUserOnboardingSaga,
      onboardingOperations.saveAndCheckUserOnboardingSaga({
        account: accountId,
        bot,
        // NOTE: Execute validate() only for this quest, during deployment
        pathToSpecificQuest:
          'createSimpleBotWithTransfer.categories.builder.steps.deployAndTest.quests.saveAndDeployBotWithName',
      }),
    );
  } catch (error) {
    const errors = (error.response && error.response.errors) || [error.message];
    monitoringCatchException({
      exception: error,
      userSub,
      location: 'state/sagas/bots/publishBotSaga',
      functionType: 'saga',
      params: { tag, description, environment, snapshotId },
      accountId,
      botName: bot,
    });

    if (environment === 'dev') {
      const errorMessage: string = errors.reduce(
        (acc: string, localError: Error) => `${acc}${localError.message}`,
        '',
      );

      yield put(botsOperations.updateError(errorMessage));
      yield put(uiOperations.openErrorModal());
    }
    yield put(botsOperations.deployBotError({ bot, errors }));
  }
}

export default publishBotSaga;
