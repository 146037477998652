// @flow
import _omit from 'lodash/omit';

import type { TagValueType } from '@assets/js/calldesk-components/molecules/UserInputTag';
import type { Answer, AnswerResult, ChunkTrained, EntityTurn } from '@state/ducks/training/types';

/**
 * Update answer in order to remove result key if intentsTurnTrained and entitiesTurnTrained are empty array
 *
 * @param {Answer} answer - specific answer will be updated
 *
 * @returns {Answer} - specific answer updated
 */
const updateAnswerByResult = (answer: Answer): Answer => {
  const answerResult: ?AnswerResult = answer?.result;
  return (answerResult?.intentsTurnTrained?.length === 0 || !answerResult?.intentsTurnTrained) &&
    (answerResult?.entitiesTurnTrained?.length === 0 || !answerResult?.entitiesTurnTrained)
    ? {
        ...answer,
        result: null,
      }
    : answer;
};

/**
 * Update answer in order to delete intentTurn trained in result key
 *
 * @param {Answer} answer - specific answer where expression will be removed in result key
 * @param {string} intentName - intent where is the specific expression selected
 * @param {string} utterance - or named as expression
 *
 * @returns {Answer} - specific answer updated
 */
const updateAnswerStatusByIntentNameRemoved = (
  answer: Answer,
  intentName: string,
  utterance: string,
): Answer => {
  const { result } = answer;
  const answerUpdated: Answer = {
    ...answer,
    result: {
      ...result,
      intentsTurnTrained: (result?.intentsTurnTrained || []).filter(
        (intentTurnTrained: ChunkTrained) =>
          !(intentTurnTrained.mask === intentName && intentTurnTrained.utterance === utterance),
      ),
    },
  };
  return updateAnswerByResult(answerUpdated);
};

/**
 * Update answer in order to delete entityTurn as value or variant trained in result key
 *
 * @param {Answer} answer - specific answer where pronunciation or value will be removed in result key
 * @param {TagValueType} entityType
 * @param {string} entityTurnId
 * @param {string} entityValue
 * @param {string} entityVariant?
 *
 * @returns {Answer} - specific answer updated
 */
const updateAnswerStatusByEntityTurnId = (
  answer: Answer,
  entityType: TagValueType,
  entityTurnId: string,
  entityValue: string,
  entityVariant?: string,
): Answer => {
  const answerUpdated: Answer = {
    ...answer,
    result: {
      ...answer.result,
      entitiesTurnTrained: (answer?.result?.entitiesTurnTrained || []).filter(
        (entityTurnTrained: ChunkTrained) =>
          !(
            entityTurnTrained.id === entityTurnId &&
            entityTurnTrained.type === entityType &&
            entityTurnTrained.value === entityValue &&
            (!entityVariant || entityTurnTrained.pronunciation === entityVariant)
          ),
      ),
    },
  };
  return updateAnswerByResult(answerUpdated);
};

/**
 * Remove specific entity value in specific entityTurn, and update related answer
 *
 * @param {string} value - specific entity value will be removed
 * @param {string} pronunciation - specific pronunciation will be removed
 * @param {EntityTurn[]} entitiesTurn - list of entitiesTurn
 * @param {Answer} answer - specific answer where pronunciation will be removed in result key
 *
 * @returns ?{ answer: Answer, entityTurn: EntityTurn } - specific answer and entityTurn updated
 */
const removeValueInEntityTurnAndUpdateAnswer = (
  value: string,
  entityMask: string,
  entitiesTurn: EntityTurn[],
  answer: Answer,
): ?{ answer: Answer, entityTurn: EntityTurn } => {
  const entityTurn: ?EntityTurn = entitiesTurn.find(
    (entity: EntityTurn) => entity.entityMask === entityMask,
  );
  if (entityTurn) {
    const newEntityTurn: EntityTurn = _omit(entityTurn, [`content.${value}`]);
    const answerUpdated: Answer = updateAnswerStatusByEntityTurnId(
      answer,
      'ENTITY_VALUE',
      newEntityTurn.id,
      value,
    );

    return { entityTurn: newEntityTurn, answer: answerUpdated };
  }
  return null;
};

/**
 * Remove specific entity variant in specific entityTurn, and update related answer
 *
 * @param {string} value - specific entity value where pronunciation will be removed
 * @param {string} entityMask - in order to find the entity will be updated
 * @param {string} pronunciation - specific pronunciation will be removed
 * @param {EntityTurn[]} entitiesTurn -  list of entitiesTurn
 * @param {Answer} answer - specific answer where pronunciation will be removed in result key
 *
 * @returns ?{ answer: Answer, entityTurn: EntityTurn } - specific answer and entityTurn updated
 */
const removeVariantInEntityTurnAndUpdateAnswer = (
  value: string,
  entityMask: string,
  pronunciation: string,
  entitiesTurn: EntityTurn[],
  answer: Answer,
): ?{ answer: Answer, entityTurn: EntityTurn } => {
  const entityTurn: ?EntityTurn = entitiesTurn.find(
    (entity: EntityTurn) => entity.entityMask === entityMask,
  );
  if (entityTurn) {
    const newEntityTurn = {
      ...entityTurn,
      content: {
        ...entityTurn.content,
        [value]: {
          ...entityTurn.content[value],
          pronunciations: entityTurn.content[value].pronunciations?.filter(
            (variant: string) => variant !== pronunciation,
          ),
        },
      },
    };
    const answerUpdated: Answer = updateAnswerStatusByEntityTurnId(
      answer,
      'ENTITY_VARIANT',
      entityTurn.id,
      value,
      pronunciation,
    );

    return { entityTurn: newEntityTurn, answer: answerUpdated };
  }
  return null;
};

export {
  removeValueInEntityTurnAndUpdateAnswer,
  removeVariantInEntityTurnAndUpdateAnswer,
  updateAnswerStatusByEntityTurnId,
  updateAnswerStatusByIntentNameRemoved,
};
