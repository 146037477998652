// @flow

import type { BtnFnProps } from '@reactour/tour';
import React, { useState } from 'react';
import { CheckCircleFilled, CheckCircleOutlined } from '@ant-design/icons';

function CustomNextButton({
  currentStep,
  setCurrentStep,
  setIsOpen,
  stepsLength,
  ...props
}: BtnFnProps) {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const isLastStep: boolean = currentStep === stepsLength - 1;

  const iconStyle: { [style: string]: string | number } = {
    fontSize: 18,
    color: '#00B4F8',
  };

  if (isLastStep) {
    return (
      <props.Button hideArrow onClick={() => setIsOpen(false)} {...props}>
        <div
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{ cursor: 'pointer' }}
        >
          {isHovered ? (
            <CheckCircleFilled style={iconStyle} />
          ) : (
            <CheckCircleOutlined style={iconStyle} />
          )}
        </div>
      </props.Button>
    );
  }
  return <props.Button {...props} />;
}

export default CustomNextButton;
