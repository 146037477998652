// @flow

import type { ConversationState, Message, TraceItem, TraceItemFactory } from '../types';

import {
  ToCheckpointMessageFactory,
  ToClassicActionMessageFactory,
  ToGenericActionMessageFactory,
  ToGhostActionMessageFactory,
  ToInitActionMessageFactory,
  ToSayActionMessageFactory,
  ToSpecificActionMessageFactory,
} from './trace-item-factories-impl';

const SayActionFactory = ToSayActionMessageFactory();

const CONVERTERS: TraceItemFactory<any>[] = [
  ToCheckpointMessageFactory(),
  ToClassicActionMessageFactory(),
  ToGenericActionMessageFactory(),
  ToGhostActionMessageFactory(),
  ToInitActionMessageFactory(),
  ToSayActionMessageFactory(),
  ToSpecificActionMessageFactory(),
];

const Factory = () => {
  const create = (
    item: TraceItem<any>,
    state: $Shape<ConversationState>,
    language: string,
  ): ?Message => {
    const traceItemConverter: ?TraceItemFactory<any> = CONVERTERS.find(converter =>
      converter.validator(item),
    );
    if (traceItemConverter) {
      return traceItemConverter.formatter(item, state, language);
    }
    return null;
  };

  const shouldUpdateThinkMap = (traceItem: TraceItem<any>): boolean =>
    SayActionFactory.validator(traceItem);
  return { create, shouldUpdateThinkMap };
};

export default Factory;
