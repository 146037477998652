// @flow

import type { BotConfig, Node, Transition } from '@state/ducks/bots/types';
import type { Exercise, ValidateFnReturn } from '@state/ducks/onboarding/types';
import { DONE_NODE_NAME } from '@state/ducks/bots/types';
import { getNodesAndTransitions } from '@state/ducks/onboarding/utils/validator/utils';

type WrapUpTheScriptValidate = ValidateFnReturn<{
  nodeDone: ?Node,
}>;

/**
 * Validate `wrapUpTheScript` quest
 * NOTE: Blocked by `addGoodByeMessage` quest
 */
function validate(
  botConfig: BotConfig,
  {
    categories: {
      builder: {
        steps: {
          createIntroduction: {
            quests: { addGoodByeMessage },
          },
        },
      },
    },
  }: Exercise,
): WrapUpTheScriptValidate {
  const { nodes, transitions }: { nodes: Node[], transitions: Transition[] } =
    getNodesAndTransitions(botConfig);

  const nodesSayActionGoodBye: ?Node = addGoodByeMessage?.others?.nodesSayActionGoodBye;

  const nodeDone: ?Node = nodes.find(
    (node: Node) =>
      node.action === DONE_NODE_NAME &&
      transitions.some(
        (transition: Transition) =>
          nodesSayActionGoodBye &&
          transition.startingNode === nodesSayActionGoodBye.id &&
          transition.endingNode === node.id,
      ),
  );

  return {
    others: { nodeDone },
    validated: !!nodeDone,
  };
}

export default validate;
