// @flow

import type { PayloadAction } from '@reduxjs/toolkit';
import type { Saga } from 'redux-saga';
import _pickBy from 'lodash/pickBy';
import _values from 'lodash/values';
import { call, put, select } from 'redux-saga/effects';

import type { Intent, IntentsMap } from '@state/ducks/intents/types';
import { getUserSub } from '@api/cognito';
import logger from '@assets/js/calldesk-app-util/logger';
import { operations as botsOperations, selectors as botsSelectors } from '@state/ducks/bots';
import {
  operations as intentsOperations,
  selectors as intentsSelectors,
} from '@state/ducks/intents';
import { monitoringCatchException } from '@state/monitoring-enhancer';

/**
 * Imports intents inside a bot config
 *
 * Will update intent corresponding to the provided label
 * Will create new ones
 *
 *
 * NOTE : Should we delete those not present in the imported file ?
 *
 * @param {Action & { bot: string, intents: Intents }} action - the action
 */
function* importIntents(
  action: PayloadAction<{
    bot: string,
    intents: IntentsMap,
  }>,
): Saga<any> {
  const { bot, intents } = action.payload;
  const userSub: string = yield call(getUserSub);

  yield put(botsOperations.importIntents(bot));
  try {
    const configIntentIds: string[] = yield select(botsSelectors.getBotConfigIntents, bot);
    const intentUsed: { [id: string]: Intent } = yield select(
      intentsSelectors.selectAllFromIds,
      configIntentIds,
    );
    const intentsLabelById = new Map<string, string>(
      _values(intentUsed).map(intent => [intent.label, intent.id]),
    );

    const intentsToAdd: { [id: string]: Intent } = _pickBy(
      intents,
      intent => intent.scope !== 'account',
    );
    const intentsToUpsertInSlice: Intent[] = _values(intentsToAdd).map((intent: Intent) => ({
      ...intent,
      id: intentsLabelById.get(intent.label) ?? intent.id,
    }));

    yield put(
      botsOperations.upsertIntents({
        bot,
        intents: intentsToUpsertInSlice.map(intent => intent.id),
      }),
    );
    yield put(intentsOperations.upsertIntents(intentsToUpsertInSlice));

    yield put(botsOperations.importIntentsSuccess(bot));
    yield put(botsOperations.startValidateBotSaga({ bot }));
  } catch (error) {
    logger.error('Bots > Import intents', error);
    yield put(botsOperations.importIntentsFailure({ bot, error: error.message }));
    monitoringCatchException({
      exception: error,
      userSub,
      location: 'state/sagas/bots/importIntents',
      functionType: 'saga',
      params: { intentsLength: intents ? intents.length : 0 },
      botName: bot,
    });
  }
}

export default importIntents;
