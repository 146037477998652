// @flow

import type { Saga } from 'redux-saga';
import { all, takeLatest } from 'redux-saga/effects';

import { operations as appOperations } from '@state/ducks/app';
import { types as botsTypes } from '@state/ducks/bots';
import { types as customersAssetsTypes } from '@state/ducks/customers-assets';
import { types as onboardingTypes } from '@state/ducks/onboarding';
import { types as trainingTypes } from '@state/ducks/training';

import * as appSagas from './app';
import * as botsSagas from './bots';
import * as customersAssetsSagas from './customers-assets';
import * as onboardingSagas from './onboarding';
import * as trainingSagas from './training';

/**
 * All watchers should be declared here
 * Will watch on every action and trigger the appropriate saga
 */
function* actionsWatcher() {
  /* APP SAGAS WATCHERS */
  yield takeLatest(appOperations.startAppSaga.type, appSagas.startAppSaga);

  /* BOTS SAGAS WATCHERS */
  yield takeLatest(botsTypes.START_GET_BOTS_SAGA, botsSagas.getBotsSaga);
  yield takeLatest(botsTypes.START_GET_BOT_CONFIG_SAGA, botsSagas.getBotConfigSaga);
  yield takeLatest(botsTypes.START_CHECK_BOT_SAGA, botsSagas.checkBotSaga);
  yield takeLatest(botsTypes.START_PUBLISH_BOT_SAGA, botsSagas.publishBotSaga);
  yield takeLatest(botsTypes.START_SAVE_BOT_SAGA, botsSagas.saveBotSaga);
  yield takeLatest(botsTypes.START_VALIDATE_BOT_SAGA, botsSagas.validateBotSaga);
  yield takeLatest(botsTypes.DUPLICATE_BOT_SAGA, botsSagas.duplicateBotSaga);
  yield takeLatest(botsTypes.CREATE_NEW_BOT_SAGA, botsSagas.createBotSaga);
  yield takeLatest(botsTypes.DELETE_BOT_SAGA, botsSagas.deleteBotSaga);
  yield takeLatest(botsTypes.START_IMPORT_INTENTS_BOT_SAGA, botsSagas.importIntentsSagas);

  /* TRAINING SAGAS */
  yield takeLatest(trainingTypes.FETCH_TURNS_SAGA, trainingSagas.fetchTurnsSaga);
  yield takeLatest(trainingTypes.FETCH_ANSWERS_BY_TURN_SAGA, trainingSagas.fetchAnswersByTurnSaga);
  yield takeLatest(
    trainingTypes.FETCH_ENTITIES_AND_ANSWERS_BY_TURN_SAGA,
    trainingSagas.fetchEntitiesAndAnswersByTurnSaga,
  );
  yield takeLatest(trainingTypes.INIT_TRAINING_SAGA, trainingSagas.initTrainingSaga);
  yield takeLatest(trainingTypes.CHANGE_TURN_SAGA, trainingSagas.changeTurnSaga);
  yield takeLatest(
    trainingTypes.SET_CURRENT_ENTITY_TURN_SAGA,
    trainingSagas.setCurrentEntityTurnSaga,
  );
  yield takeLatest(
    trainingTypes.SET_CURRENT_ENTITY_TURN_CURRENT_ANSWER_SAGA,
    trainingSagas.setCurrentEntityTurnCurrentAnswerSaga,
  );
  yield takeLatest(trainingTypes.SAVE_TRAINING_SAGA, trainingSagas.saveTrainingSaga);

  /* ONBOARDING SAGAS */
  yield takeLatest(
    onboardingTypes.SAVE_AND_CHECK_USER_ONBOARDING_SAGA,
    onboardingSagas.saveAndCheckUserOnboardingSaga,
  );
  yield takeLatest(
    onboardingTypes.RUN_VALIDATOR_ONBOARDING_SAGA,
    onboardingSagas.runValidatorOnboardingSaga,
  );
  yield takeLatest(
    onboardingTypes.CREATE_NEW_ONBOARDING_SAGA,
    onboardingSagas.createNewOnboardingSaga,
  );

  /* CUSTOMERS-ASSETS SAGAS */
  yield takeLatest(
    customersAssetsTypes.TAG_ENTITIES_REFERENTIAL_SAGA,
    customersAssetsSagas.tagEntitiesReferentialSaga,
  );
}

/**
 * This function is imported in store.js
 * Will export all watchers declared in actionsWatcher
 */
export default function* rootSaga(): Saga<any> {
  yield all([...actionsWatcher()]);
}
