// @flow

import type { CredentialSecretItem } from '@state/ducks/accounts/types';
import type { StudioApiRequestResponse } from '@state/ducks/app/types';
import request from '@amplify/api/request';
import logger from '@assets/js/calldesk-app-util/logger';

const fetchAccountsForUser = (userSub: string): Promise<string[]> =>
  request
    .usersApi({
      method: 'GET',
      path: `/users/${userSub}/accounts`,
    })
    .then(({ payload }) => {
      logger.info('state/accounts/api [fetchAccountsForUser] Retrieved accounts', payload);
      return Promise.resolve(payload.accountIds);
    })
    .catch(error => {
      logger.info(
        'state/accounts/api [fetchAccountsForUser] Error while retrieving accounts',
        error,
      );
      return Promise.reject(error);
    });

const isAuthorized = (selectedAccount: string, right: string): Promise<boolean> =>
  request
    .usersApi({
      path: '/is-authorized',
      method: 'GET',
      queryStringParameters: { resource: selectedAccount, permission: right },
    })
    .then((usersApiResponse: { payload: { authorized: boolean } }) => {
      logger.info(`state/session/api [isAuthorized] Is Authorized on ${right}?`, usersApiResponse);
      return Promise.resolve(usersApiResponse.payload.authorized);
    })
    .catch(errorMessage => {
      logger.info('state/session/api [isAuthorized] Is Authorized ?', errorMessage);
      return Promise.reject(errorMessage);
    });

const isAuthorizedOnPlatform = (userSub: string, right: string): Promise<boolean> =>
  request
    .usersApi({
      path: `/users/${userSub}/is-authorized`,
      method: 'GET',
      queryStringParameters: { permission: right },
    })
    .then((usersApiResponse: { payload: { authorized: boolean } }) => {
      logger.info(`state/session/api [isAuthorized] Is Authorized on ${right}?`, usersApiResponse);
      return Promise.resolve(usersApiResponse.payload.authorized);
    })
    .catch(errorMessage => {
      logger.info('state/session/api [isAuthorized] Is Authorized ?', errorMessage);
      return Promise.reject(errorMessage);
    });

const v3authorizations = (accountId: string): Promise<{ [right: string]: boolean | Object }> =>
  request
    .usersApi({
      method: 'GET',
      path: '/authorizations',
      queryStringParameters: { resource: accountId },
    })
    .then((usersApiResponse: { payload: { [right: string]: boolean | Object } }) =>
      Promise.resolve(usersApiResponse.payload),
    )
    .catch(errorMessage => {
      logger.info('@state/sagas/session::v3authorizations()', errorMessage);
      return Promise.reject(errorMessage);
    });

const createAccount = (accountId: string): Promise<void> =>
  request.accountsApi({
    method: 'POST',
    path: `/accounts`,
    body: {
      account: accountId,
    },
  });

const getCredentialsByAccount = async (
  accountId: string,
): Promise<CredentialSecretItem[] | null> => {
  try {
    const response: StudioApiRequestResponse<CredentialSecretItem[]> = await request.studioApi({
      method: 'GET',
      path: `/accounts/${accountId}/credentials`,
    });
    logger.info('state/bots/api [getCredentialsByAccount] Retrieved credentials', response);
    if (response && response.payload) {
      return response.payload;
    }
    return null;
  } catch (err) {
    logger.info('state/bots/api [getCredentialsByAccount] Error while retrieving credentials', err);
    throw err;
  }
};

export {
  createAccount,
  fetchAccountsForUser,
  getCredentialsByAccount,
  isAuthorized,
  isAuthorizedOnPlatform,
  v3authorizations,
};
