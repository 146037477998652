// @flow

import type { StepType } from '@reactour/tour';
import React from 'react';
import { Trans } from 'react-i18next';
import i18next from 'i18next';

import { CDKCol, CDKRow } from '@calldesk/components';

const selectCellByIdInScript = (id: string, type: 'node' | 'edge'): string =>
  `#Canvas .x6-${type}[data-cell-id="${id}"]`;

function TransSelfcareTour({
  keyTrans,
  type = 'content',
}: {
  keyTrans: string,
  type?: 'title' | 'content',
}) {
  return <Trans i18nKey={`templates.selfcare.steps.${keyTrans}.${type}`} ns="productTour" />;
}

const selfcareSteps = (
  setSelectedNodeId?: (nodePath: ?string) => void,
  mainDomain?: string,
): StepType[] => [
  {
    position: 'center',
    content: (
      <CDKRow>
        <CDKCol>
          <CDKRow>
            <CDKCol>
              <b>
                <TransSelfcareTour keyTrans="start" type="title" />
              </b>
            </CDKCol>
          </CDKRow>
          <CDKRow>
            <CDKCol>
              <p>
                <TransSelfcareTour keyTrans="start" />
              </p>
            </CDKCol>
          </CDKRow>
        </CDKCol>
      </CDKRow>
    ),
    action: () => {
      if (setSelectedNodeId && mainDomain) {
        const nodeId: string = 'origin';
        setSelectedNodeId(`config.graphs.${mainDomain}.nodes.${nodeId}`);
      }
    },
  },
  {
    selector: selectCellByIdInScript('say_trip_question', 'node'),
    content: <TransSelfcareTour keyTrans="say_trip_question" />,
    highlightedSelectors: [selectCellByIdInScript('say_trip_question', 'node')],
  },
  {
    selector: selectCellByIdInScript('say_trip_question=>hook_1', 'edge'),
    content: <TransSelfcareTour keyTrans="say_trip_question=>hook_1" />,
    highlightedSelectors: [selectCellByIdInScript('say_trip_question=>hook_1', 'edge')],
  },
  {
    selector: selectCellByIdInScript('hook_1=>hook_2', 'edge'),
    content: <TransSelfcareTour keyTrans="hook_1=>hook_2" />,
  },
  {
    selector: selectCellByIdInScript('say_city_from_question', 'node'),
    content: <TransSelfcareTour keyTrans="city_from_question" />,
    highlightedSelectors: [
      selectCellByIdInScript('hook_1=>say_city_from_question', 'edge'),
      selectCellByIdInScript('say_city_from_question', 'node'),
    ],
  },
  {
    selector: selectCellByIdInScript('hook_4=>say_city_to_question', 'edge'),
    content: <TransSelfcareTour keyTrans="city_to_question" />,
    highlightedSelectors: [
      selectCellByIdInScript('hook_4', 'node'),
      selectCellByIdInScript('hook_4=>hook_5', 'edge'),
      selectCellByIdInScript('hook_4=>say_city_to_question', 'edge'),
    ],
  },
  {
    selector: selectCellByIdInScript('hook_7=>say_date_question', 'edge'),
    content: <TransSelfcareTour keyTrans="date_question" />,
    highlightedSelectors: [
      selectCellByIdInScript('hook_7', 'node'),
      selectCellByIdInScript('hook_7=>hook_8', 'edge'),
      selectCellByIdInScript('hook_7=>say_date_question', 'edge'),
    ],
  },
  {
    selector: selectCellByIdInScript('hook_10=>say_peoples_question', 'edge'),
    content: <TransSelfcareTour keyTrans="peoples_question" />,
    highlightedSelectors: [
      selectCellByIdInScript('hook_10', 'node'),
      selectCellByIdInScript('hook_10=>hook_11', 'edge'),
      selectCellByIdInScript('hook_10=>say_peoples_question', 'edge'),
    ],
  },
  {
    selector: selectCellByIdInScript('say_correct', 'node'),
    content: <TransSelfcareTour keyTrans="say_recap+say_correct" />,
    highlightedSelectors: [
      selectCellByIdInScript('say_recap', 'node'),
      selectCellByIdInScript('say_correct', 'node'),
    ],
  },
  {
    selector: 'button.ChatButton',
    content: (
      <div>
        <CDKRow>
          <CDKCol>
            <TransSelfcareTour keyTrans="chat" />
          </CDKCol>
        </CDKRow>
        <CDKRow>
          {(
            i18next.t(`templates.selfcare.steps.chat.examples`, {
              returnObjects: true,
              ns: 'productTour',
            }) || []
          ).map(({ content }: { content: string }) => (
            <CDKCol key={content}>- {content}</CDKCol>
          ))}
        </CDKRow>
      </div>
    ),
  },
];

export default selfcareSteps;
