// @flow

import type { BotConfig, Node, Transition } from '@state/ducks/bots/types';
import type { Exercise, ValidateFnReturn } from '@state/ducks/onboarding/types';
import { SAY_NODE_NAME } from '@state/ducks/bots/types';

export type Add2SayActionsNegativeAndPositiveValidate = ValidateFnReturn<{
  nodes: Node[],
  transitions: Transition[],
  nodesSayAction: Node[],
}>;

/**
 * Validate `add2SayActionsNegativeAndPositive` quest
 * NOTE: Blocked by `addSayActionClosedQuestion` quest
 */
function validate(
  botConfig: BotConfig,
  {
    categories: {
      builder: {
        steps: {
          createIntroduction: {
            quests: { addSayActionClosedQuestion },
          },
        },
      },
    },
  }: Exercise,
): Add2SayActionsNegativeAndPositiveValidate {
  const nodes: Node[] = addSayActionClosedQuestion?.others?.nodes ?? [];
  const nodesSayAction: Node[] = nodes.filter(
    (node: Node) => node.action === SAY_NODE_NAME && node.templates && node.templates.length > 0,
  );
  const transitions: Transition[] = addSayActionClosedQuestion?.others?.transitions ?? [];

  return {
    others: {
      nodes,
      nodesSayAction,
      transitions,
    },
    validated: nodesSayAction.length >= 4,
  };
}

export default validate;
