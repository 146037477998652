// @flow

import _get from 'lodash/get';

import type { BotConfig } from '@state/ducks/bots/types';
import type {
  Exercise,
  Quest,
  ValidateFn,
  ValidateFnReturn,
  ValidatorData,
} from '@state/ducks/onboarding/types';
import type { AvailableOptions } from '@state/ducks/options/types';

import getQuestValidator from './get-quest-validator';

/**
 * Validate quest
 */
function validateQuest(
  exerciseId: string,
  questPath: string,
  botConfig: BotConfig,
  progression: Exercise,
  validatorData: ValidatorData,
  availableOptions: AvailableOptions,
): Quest {
  const questProgression: Quest = _get(progression, questPath);
  const questValidator: ?ValidateFn<any> = getQuestValidator(exerciseId, questPath);

  if (!questValidator) return questProgression;

  const updatedQuestProgression: ValidateFnReturn<any> = questValidator(
    botConfig,
    progression,
    validatorData,
    availableOptions,
  );

  // $FlowFixMe those types need some fixes as ValidateFnReturn is not exact
  const updatedQuest: Quest = {
    ...questProgression,
    ...updatedQuestProgression,
  };

  return updatedQuest;
}

export default validateQuest;
