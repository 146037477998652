// @flow

import type { Saga } from 'redux-saga';
import { all, call, put, select } from 'redux-saga/effects';

import type { Action } from '@state/ducks/app/types';
import type { BotConfig, Entities, Entity } from '@state/ducks/bots/types';
import logger from '@assets/js/calldesk-app-util/logger';
import { operations as botsOperations, selectors as botsSelectors } from '@state/ducks/bots';
import * as botsUtils from '@state/ducks/bots/utils';
import { api as customersAssetsApi } from '@state/ducks/customers-assets';
import { operations as uiOperations } from '@state/ducks/ui';

/**
 * Tag entities referential status according difference between current botConfig and lastBotConfig
 */
function* tagEntitiesReferentialSaga({
  accountId,
  botName,
  customEntitiesToDelete,
}: Action & {
  accountId: string,
  botName: string,
  customEntitiesToDelete?: Entity[],
}): Saga<any> {
  try {
    let customEntitiesListDeleted: Entity[] = customEntitiesToDelete;

    const currBotConfig: BotConfig = yield select(botsSelectors.getBotConfig, botName);
    const lastBotConfigSaved: BotConfig = yield select(
      botsSelectors.getLastBotConfigSaved,
      botName,
    );
    const currEntities: Entities = currBotConfig.entities || {};
    const lastEntitiesSaved: Entities = lastBotConfigSaved.entities || {};

    // Find new entities
    const customEntitiesListCreated: Entity[] = Object.keys(currEntities).reduce(
      (entitiesRemoved: Entity[], entityId: string) => {
        const entity: Entity = currEntities[entityId];
        const isCreated: boolean = !Object.keys(lastEntitiesSaved).includes(entityId);
        const isCustomEntity: boolean =
          !!entity.referential &&
          !!entity.referential.id &&
          !botsUtils.checkIsSystemEntity(entity.referential.id);
        if (isCreated && isCustomEntity) {
          return [...entitiesRemoved, entity];
        }
        return entitiesRemoved;
      },
      [],
    );

    // remove S3 metadata draft tag on new entities assets
    if (customEntitiesListCreated.length > 0) {
      yield all(
        customEntitiesListCreated.map((customEntity: Entity) =>
          call(customersAssetsApi.updateAsset, {
            accountId,
            assetId: customEntity.referential.id,
            status: 'RESTORED',
            assetType: 'entities',
          }),
        ),
      );
    }

    // Find removed entities
    if (!customEntitiesToDelete) {
      customEntitiesListDeleted = Object.keys(lastEntitiesSaved).reduce(
        (entitiesRemoved: Entity[], entityId: string) => {
          const entity: Entity = lastEntitiesSaved[entityId];
          const isRemoved: boolean = !Object.keys(currEntities).includes(entityId);
          const isCustomEntity: boolean =
            !!entity.referential &&
            !!entity.referential.id &&
            !botsUtils.checkIsSystemEntity(entity.referential.id);

          if (isRemoved && isCustomEntity) {
            return [...entitiesRemoved, entity];
          }
          return entitiesRemoved;
        },
        [],
      );
    }

    // add S3 metadata deprecated tag on deleted entities assets
    if (customEntitiesListDeleted.length > 0) {
      yield all(
        customEntitiesListDeleted.map((customEntity: Entity) =>
          call(customersAssetsApi.updateAsset, {
            accountId,
            assetId: customEntity.referential.id,
            status: 'DEPRECATED',
            assetType: 'entities',
          }),
        ),
      );
    }
  } catch (error) {
    logger.error('[ERROR] Customers-assets-saga > tagEntitiesReferentialSaga >', error);
    yield put(botsOperations.updateError(error.message));
    yield put(uiOperations.openErrorModal());
  }
}

export { tagEntitiesReferentialSaga };
