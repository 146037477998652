// @flow
/* eslint-disable max-lines */

// $FlowFixMe
import type { EntityState } from '@reduxjs/toolkit';

import type { AuthType } from '@assets/js/calldesk-app-util/authFields/index.type';
import type { TagValueType } from '@assets/js/calldesk-components/molecules/UserInputTag';
import type { Status, StorageSettings } from '@state/ducks/app/types';
import type { Intent as IntentMatched } from '@state/ducks/chat/types';
import type {
  AssetBase,
  CustomReferential,
  EntityContentType,
  ReferentialContentMetadata,
} from '@state/ducks/customers-assets/types';
import type { IntentsMap } from '@state/ducks/intents/types';
import type {
  BotTemplate,
  EngineName,
  EngineVoice,
  GenericAction,
} from '@state/ducks/options/types';
import { systemAssets } from '@resources/options/available.json';

/* ACTIONS ********************************** */

/* SAGAS */
const START_CHECK_BOT_SAGA: string = 'bots/START_CHECK_BOT_SAGA';
const START_PUBLISH_BOT_SAGA: string = 'bots/START_PUBLISH_BOT_SAGA';
const START_VALIDATE_BOT_SAGA: string = 'bots/START_VALIDATE_BOT_SAGA';
const DUPLICATE_BOT_SAGA: string = 'bots/DUPLICATE_BOT_SAGA';
const START_IMPORT_INTENTS_BOT_SAGA: string = 'bots/START_IMPORT_INTENTS_BOT_SAGA';
const DELETE_BOT_SAGA: string = 'bots/DELETE_BOT_SAGA';
const DELETE_ENTITY_SAGA: string = 'bots/DELETE_ENTITY_SAGA';
const START_SAVE_BOT_SAGA: string = 'bots/START_SAVE_BOT_SAGA';
const START_GET_BOTS_SAGA: string = 'bots/START_GET_BOTS_SAGA';
const START_GET_BOT_CONFIG_SAGA: string = 'bots/START_GET_BOT_CONFIG_SAGA';
const CREATE_NEW_BOT_SAGA: string = 'bots/CREATE_NEW_BOT_SAGA';

// NODES NAMES
const GENERIC_NODE_CALCULATE_ACTION: string = 'calculate';
const GENERIC_NODE_CHECK_BUSINESS_HOURS_ACTION: string = 'checkBusinessHours';
const GENERIC_NODE_CHECK_CALL_CENTER_OPENING_HOURS_ACTION: string = 'checkCallCenterOpeningHours';
const GENERIC_NODE_CHECK_PHONE_NUMBER_TYPE_ACTION: string = 'checkPhoneNumberType';
const GENERIC_NODE_DATA_MATCH_ACTION: string = 'dataMatch';
const GENERIC_NODE_ENTITY_VALUE_EXCLUSION_ACTION: string = 'entityValueExclusion';
const GENERIC_NODE_EXECUTE_SALESFORCE_QUERY_ACTION: string = 'executeSalesforceQuery';
const GENERIC_NODE_FILTER_CONTEXT_ELEMENT_ACTION: string = 'filterContextElement';
const GENERIC_NODE_FILTER_REFERENTIAL_ACTION: string = 'filterReferential';
const GENERIC_NODE_FLASH_MESSAGE_ACTION: string = 'flashMessage';
const GENERIC_NODE_FORMAT_CONTEXT_DATA_ACTION: string = 'formatContextData';
const GENERIC_NODE_GET_CALLER_PARSED_RESPONSE_ACTION: string = 'getCallerParsedResponse';
const GENERIC_NODE_GET_CURRENT_DATE_ACTION: string = 'getCurrentDate';
const GENERIC_NODE_GET_CURRENT_DAY_PART_ACTION: string = 'getCurrentDayPart';
const GENERIC_NODE_GET_TRANSFER_PHONE_NUMBER_ACTION: string = 'getTransferPhoneNumber';
const GENERIC_NODE_INIT_CUSTOM_ENTITY_PARSER_ACTION: string = 'initCustomEntityParser';
const GENERIC_NODE_MANAGE_CALLER_ENTITY_VALUE_ACTION: string = 'manageCallerEntityValue';
const GENERIC_NODE_NO_RECORDING_ACTION: string = 'noRecording';
const GENERIC_NODE_PROCESS_COUNTER_ACTION: string = 'processCounter';
const GENERIC_NODE_PROCESS_CUSTOM_ENTITY_ACTION: string = 'processCustomEntity';
const GENERIC_NODE_PROCESS_NOT_UNDERSTOOD_ACTION: string = 'processNotUnderstood';
const GENERIC_NODE_RESET_COUNTER_ACTION: string = 'resetCounter';
const GENERIC_NODE_RETRIEVE_ELIGIBLE_INTENTS_ACTION: string = 'retrieveEligibleIntents';
const GENERIC_NODE_APPLY_DISAMBIGUATION_INTENTS_SETTINGS_ACTION: string =
  'applyDisambiguationIntentsSettings';
const GENERIC_NODE_SCHEDULE_APPOINTMENT_ACTION: string = 'scheduleAppointment';
const GENERIC_NODE_SEND_EMAIL_ACTION: string = 'sendEmail';
const GENERIC_NODE_SEND_SMS_ACTION: string = 'sendSms';
const GENERIC_NODE_SUMMARIZE_ACTION: string = 'summarize';
const GENERIC_NODE_SUBSTRING_ACTION: string = 'substring';
const GENERIC_NODE_WAIT_ACTION: string = 'wait';

const GENERIC_ACTION_NODES: {
  [key: string]: string,
} = {
  GENERIC_NODE_CALCULATE_ACTION,
  GENERIC_NODE_CHECK_BUSINESS_HOURS_ACTION,
  GENERIC_NODE_CHECK_CALL_CENTER_OPENING_HOURS_ACTION,
  GENERIC_NODE_CHECK_PHONE_NUMBER_TYPE_ACTION,
  GENERIC_NODE_DATA_MATCH_ACTION,
  GENERIC_NODE_ENTITY_VALUE_EXCLUSION_ACTION,
  GENERIC_NODE_EXECUTE_SALESFORCE_QUERY_ACTION,
  GENERIC_NODE_FILTER_CONTEXT_ELEMENT_ACTION,
  GENERIC_NODE_FILTER_REFERENTIAL_ACTION,
  GENERIC_NODE_FLASH_MESSAGE_ACTION,
  GENERIC_NODE_FORMAT_CONTEXT_DATA_ACTION,
  GENERIC_NODE_GET_CALLER_PARSED_RESPONSE_ACTION,
  GENERIC_NODE_GET_CURRENT_DATE_ACTION,
  GENERIC_NODE_GET_CURRENT_DAY_PART_ACTION,
  GENERIC_NODE_GET_TRANSFER_PHONE_NUMBER_ACTION,
  GENERIC_NODE_INIT_CUSTOM_ENTITY_PARSER_ACTION,
  GENERIC_NODE_MANAGE_CALLER_ENTITY_VALUE_ACTION,
  GENERIC_NODE_NO_RECORDING_ACTION,
  GENERIC_NODE_PROCESS_COUNTER_ACTION,
  GENERIC_NODE_PROCESS_CUSTOM_ENTITY_ACTION,
  GENERIC_NODE_PROCESS_NOT_UNDERSTOOD_ACTION,
  GENERIC_NODE_RESET_COUNTER_ACTION,
  GENERIC_NODE_RETRIEVE_ELIGIBLE_INTENTS_ACTION,
  GENERIC_NODE_APPLY_DISAMBIGUATION_INTENTS_SETTINGS_ACTION,
  GENERIC_NODE_SCHEDULE_APPOINTMENT_ACTION,
  GENERIC_NODE_SUMMARIZE_ACTION,
  GENERIC_NODE_WAIT_ACTION,
};

type GraphDomain = 'tactical' | string;

type ActionsName =
  | 'init'
  | 'abTest'
  | 'done'
  | 'folder'
  | 'generic'
  | 'getHelp'
  | 'hangUp'
  | 'hook'
  | 'module'
  | 'origin'
  | 'playDTMF'
  | 'say'
  | 'sayAndPlay'
  | 'set'
  | 'specific'
  | 'statsCheckpoint'
  | 'play'
  | 'transfer'
  | 'update'
  | 'apiRequest'
  | 'contextConfig'
  | 'retrieveEntitiesInContext'
  | 'forgetEntitiesInContext';

const INIT_NODE_NAME: string = 'init';
const AB_TEST_NODE_NAME: string = 'abTest';
const COMMENT_NODE_NAME: string = 'comment';
const DONE_NODE_NAME: string = 'done';
const FOLDER_NODE_NAME: string = 'folder';
const GENERIC_NODE_NAME: string = 'generic';
const GET_HELP_NODE_NAME: string = 'getHelp';
const HANG_UP_NODE_NAME: string = 'hangUp';
const HOOK_NODE_NAME: string = 'hook';
const MODULE_NODE_NAME: string = 'module';
const ORIGIN_NODE_NAME: string = 'origin';
const PLAY_DTMF_NODE_NAME: string = 'playDTMF';
const SAY_NODE_NAME: string = 'say';
const SET_NODE_NAME: string = 'set';
const SPECIFIC_NODE_NAME: string = 'specific';
const STATS_CHECKPOINT_NODE_NAME: string = 'statsCheckpoint';
const PLAY_NODE_NAME: string = 'play';
const TRANSFER_NODE_NAME: string = 'transfer';
const UPDATE_NODE_NAME: string = 'update';
const API_REQUEST_NODE_NAME: string = 'apiRequest';
const CONTEXT_CONFIG_NODE_NAME: string = 'contextConfig';
const RETRIEVE_ENTITIES_IN_CONTEXT: string = 'retrieveEntitiesInContext';
const FORGET_ENTITIES_IN_CONTEXT: string = 'forgetEntitiesInContext';

const SYSTEM_ACTION_NODES: string[] = [
  INIT_NODE_NAME,
  FORGET_ENTITIES_IN_CONTEXT,
  RETRIEVE_ENTITIES_IN_CONTEXT,
];

const CLASSIC_ACTION_NODES: string[] = [
  AB_TEST_NODE_NAME,
  CONTEXT_CONFIG_NODE_NAME,
  FOLDER_NODE_NAME,
  GET_HELP_NODE_NAME,
  MODULE_NODE_NAME,
  PLAY_DTMF_NODE_NAME,
  SET_NODE_NAME,
  STATS_CHECKPOINT_NODE_NAME,
  UPDATE_NODE_NAME,
];

const GHOST_NODES: string[] = [
  DONE_NODE_NAME,
  TRANSFER_NODE_NAME,
  GENERIC_NODE_SEND_SMS_ACTION,
  GENERIC_NODE_SEND_EMAIL_ACTION,
];

// WHY IN TYPE FILE
const END_OF_CONVERSATION_NODES: {
  [key: string]: string,
} = {
  DONE_NODE_NAME,
  TRANSFER_NODE_NAME,
};

const NODES_IN_PALETTE: {
  [key: string]: string,
} = {
  SAY_NODE_NAME,
  GENERIC_NODE_NAME,
  CONTEXT_CONFIG_NODE_NAME,
  SPECIFIC_NODE_NAME,
  TRANSFER_NODE_NAME,
  PLAY_DTMF_NODE_NAME,
  AB_TEST_NODE_NAME,
  MODULE_NODE_NAME,
  HOOK_NODE_NAME,
  STATS_CHECKPOINT_NODE_NAME,
  API_REQUEST_NODE_NAME,
  COMMENT_NODE_NAME,
  DONE_NODE_NAME,
};

// MODULES
const MODULE_FIRST_NAME: string = 'firstname';
const MODULE_LAST_NAME: string = 'lastname';

// VERSIONS
const FETCH_BOT_VERSIONS: string = 'bots/FETCH_BOT_VERSIONS';
const FETCH_BOT_VERSIONS_SUCCESS: string = 'bots/FETCH_BOT_VERSIONS_SUCCESS';
const FETCH_BOT_VERSIONS_ERROR: string = 'bots/FETCH_BOT_VERSIONS_ERROR';

// INTENTS EVALUATION
const FETCH_BOT_INTENTS_EVALUATIONS: string = 'bots/FETCH_BOT_INTENTS_EVALUATIONS';
const FETCH_BOT_INTENTS_EVALUATIONS_SUCCESS: string = 'bots/FETCH_BOT_INTENTS_EVALUATIONS_SUCCESS';
const FETCH_BOT_INTENTS_EVALUATIONS_ERROR: string = 'bots/FETCH_BOT_INTENTS_EVALUATIONS_ERROR';

// PHONE NUMBERS
const ADD_BOT_PHONE_NUMBERS: string = 'bots/ADD_BOT_PHONE_NUMBERS';
const FETCH_BOT_PHONE_NUMBERS: string = 'bots/FETCH_BOT_PHONE_NUMBERS';
const FETCH_BOT_PHONE_NUMBERS_SUCCESS: string = 'bots/FETCH_BOT_PHONE_NUMBERS_SUCCESS';
const FETCH_BOT_PHONE_NUMBERS_ERROR: string = 'bots/FETCH_BOT_PHONE_NUMBERS_ERROR';

// ENTITIES
const SYSTEM_ENTITIES: string[] = [...Object.keys(systemAssets.entities), 'amountOfMoney'];

/* FLOW ********************************** */

/* BOT API ERRORS */

type ErrorSeverity = 'error' | 'warning';
type ErrorCategory = 'intent' | 'setting' | 'test' | 'script' | 'misc';

type BotApiError = {
  message: string,
  title: string,
  solution: string,
  type: string,
  severity: ErrorSeverity,
  category: ErrorCategory,
  location: string,
};

/* BUILD - CallDesk banner **** */

type Banner = Object; // TODO: define this type

type Environment = 'dev' | 'internalTest' | 'clientTest' | 'prod';

/* BUILD - TTS **** */

type TtsVoice = {
  engine?: EngineVoice,
  provider: ?string | null,
  voice: string,
};

type Tts = {| voices: TtsVoice[] |};

/* BUILD - Parsers **** */
type Language =
  | 'cs-CZ'
  | 'da-DK'
  | 'de-DE'
  | 'en-GB'
  | 'en-US'
  | 'es-ES'
  | 'fi-FI'
  | 'fr-FR'
  | 'hu-HU'
  | 'it-IT'
  | 'nb-NO'
  | 'nl-NL'
  | 'pl-PL'
  | 'pt-BR'
  | 'pt-PT'
  | 'ru-RU'
  | 'sv-SE'
  | 'tr-TR';
type Setting = {
  [key: string]: any,
};
type Parser = {
  init?: string,
  parse: string,
  wait?: number,
  supportedLanguages?: Language[],
};

type Parsers = { [parser: string]: Parser };

/* MONITORING - Stats **** */

type BlockName = string;

type StatusName = string | 'notEnded';

type NodeStats = {
  starts: Array<{ blockName: BlockName }>,
  ends: Array<{ blockName: BlockName, status: StatusName }>,
};

type Block = {|
  lastStatus: StatusName,
|};

type Blocks = { [key: BlockName]: Block };

// a block tip is the definition of an "end" (extrémité)
// which could be a start as well as an end of the block
type BlockTip = { nodeId: string, domain: string };

type BlockEnds = { [key: StatusName]: BlockTip[] };
type BlockStarts = BlockTip[];

type BlockConfig = {|
  columnName?: string,
  width?: number, // in px
  start: BlockStarts,
  end: BlockEnds,
|};

type StatsConfigBlockDefinition = {
  start: BlockStarts,
  end: BlockEnds,
  mapping: { [key: string]: string },
  entity?: {
    name: string,
    parserName: string,
  },
};

type StatsConfigData = {
  [blockName: string]: StatsConfigBlockDefinition,
};

type StatsConfig = {
  data: StatsConfigData,
};

type StatsBlockConfig = {
  block: string,
  entity?: {
    name: string,
    parserName: string,
  },
};

/* BUILD - Graphs **** */

type Origin = {|
  id: 'origin',
  action: 'origin',
  coordinates: { x: number, y: number },
  group: null,
|};

type ModuleTarget = {
  accountId: string,
  botName: string,
  botVersion: string,
};

type ModuleParams = {
  statNamePrefix: string | null,
};

type ABTestBranch = {|
  label: string,
  load: number | string,
|};

type SendEmailParams = {
  sendEmail_from: ?string,
  sendEmail_to: ?(string[]),
  sendEmail_cc: ?(string[]),
  sendEmail_cci: ?(string[]),
  sendEmail_subject: ?string,
  sendEmail_content: ?string,
};

type SendSmsParams = {
  sendSms_from: ?string,
  sendSms_to: ?string,
  sendSms_message: ?string,
};

type TransferParams = {
  method?: string,
  to?: string,
  sendDigits?: string,
  headers?: { [string]: string },
};

/** OTHERS */
type ContextConfigValue = string | number | boolean | Object | null;

type ContextConfigType = 'string' | 'number' | 'boolean' | 'object';

type ContextConfigUpdate = {
  key: string,
  valueType: ContextConfigType,
  value: ContextConfigValue,
};

/** NOTE
 * Prefer spread operator than intersection A & B
 * https://github.com/flowtype/flow-bin/issues/93
 * https://github.com/facebook/flow/issues/4946
 */
type NodeBase = {|
  id: string,
  coordinates: { x: number, y: number },
  dimensions?: {
    heigth: number,
    width: number,
  },
  group?: string,
  timestampMs?: number,
|};

type NodeABTest = {|
  ...$Exact<NodeBase>,
  action: 'abTest',
  name: string,
  branches: ABTestBranch[],
|};

type NodeActionDone = {| ...$Exact<NodeBase>, action: 'done', success: boolean |};
type NodeActionGeneric = {|
  ...$Exact<NodeBase>,
  action: 'generic',
  name: string,
  params?: Object,
|};
type NodeActionGetHelp = {| ...$Exact<NodeBase>, needsTimeout: boolean |};
type NodeActionHangUp = {| ...$Exact<NodeBase>, action: 'hangUp', success: boolean |};
type NodeActionPlay = {|
  ...$Exact<NodeBase>,
  action: 'play',
  content?: string,
  filename?: string,
  url: string,
|};
type NodeActionPlayDTMF = {| ...$Exact<NodeBase>, action: 'playDTMF', digits: string |};
type NodeActionSay = {| ...$Exact<NodeBase>, action: 'say', templates: string[] |};
type NodeActionSet = {|
  ...$Exact<NodeBase>,
  action: 'set',
  key: string,
  valueType: string,
  value: string,
|};
type NodeActionSpecific = {| ...$Exact<NodeBase>, action: string |};
type NodeActionTransfer = {|
  ...$Exact<NodeBase>,
  action: 'transfer',
  to: string,
  method: string,
  sendDigits?: string,
|};
type NodeActionUpdate = {|
  ...$Exact<NodeBase>,
  action: 'update',
  produces: string[],
  deletes: string[],
|};
type NodeActionComment = {|
  ...$Exact<NodeBase>,
  action: 'comment',
  content: string,
  lastEditor?: string,
  updatedAt?: string,
|};
type NodeModule = {|
  ...$Exact<NodeBase>,
  action: 'module',
  target: ModuleTarget,
  params: ModuleParams,
|};
type StatsCheckpointStep = {
  id: string,
  name: string,
  rank: string,
  isOption?: boolean,
};
type StatsCheckpointStatusId =
  | 'inProgress'
  | 'notEligible'
  | 'success'
  | 'notCooperative'
  | 'conversationError';

type StatsCheckpointReason = {
  id: string,
  name: string,
  parentStatusId?: StatsCheckpointStatusId,
  isOption?: boolean,
};
type StatsCheckpointStatus = {
  id: StatsCheckpointStatusId,
  name: string,
  isOption?: boolean,
  reasons?: StatsCheckpointReason[],
};
type StatsCheckpointsConfig = {
  steps: {
    [id: string]: StatsCheckpointStep,
  },
  statuses: {
    [StatsCheckpointStatusId]: StatsCheckpointStatus,
  },
  reasons: {
    [id: string]: StatsCheckpointReason,
  },
};
type NodeHook = {| ...$Exact<NodeBase>, action: 'hook' |};
type NodeStatsCheckpoint = {|
  ...$Exact<NodeBase>,
  action: 'statsCheckpoint',
  params: {
    statsCheckpoint_step: ?string,
    statsCheckpoint_status: ?StatsCheckpointStatusId,
    statsCheckpoint_reason: ?string,
  },
  statsCheckpoints?: StatsCheckpointsConfig,
|};

type NodeActionSendSms = {|
  ...$Exact<NodeBase>,
  action: 'sendSms',
  params: ?SendSmsParams,
|};

type NodeActionSendEmail = {|
  ...$Exact<NodeBase>,
  action: 'sendEmail',
  params: ?SendEmailParams,
|};

type HttpHeader = {
  name: string,
  value: string,
};

type HttpParam = {
  name: string,
  value: string,
};

type OutputContextParam = {|
  contextKey: string,
  dataPath: string,
|};

type HttpBodyType = 'json' | 'x-www-form-urlencoded';

type HttpMethod = 'GET' | 'POST' | 'DELETE' | 'PATCH';

type HttpBodyPayloadUrlEncoded = { key: string, value: string }[];

type HttpBodyPayloadRawJson = { [key: string]: any };

type HttpBodyPayload = HttpBodyPayloadRawJson;

type HttpBody = {
  type: HttpBodyType,
  payload: HttpBodyPayload,
  payloadUrlEncoded?: HttpBodyPayloadUrlEncoded,
  payloadRawJson?: HttpBodyPayloadRawJson,
};

type HttpConfig = {
  [config: string]: number | string,
};

type Encryption = {
  keyArn: string,
};

type EncryptedValue = {
  value: string,
  cyphered: boolean,
  encryption?: Encryption,
  lastUpdateTimeMs?: Date,
};

type HttpOAuth2ContentPassword = {
  clientId: string,
  clientSecret: EncryptedValue,
  username: string,
  password: EncryptedValue,
  accessTokenUrl: string,
  grantType: 'password' | 'client_credentials',
  scope?: string,
};

type HttpOAuth2ContentClientCredentials = {
  clientId: string,
  clientSecret: EncryptedValue,
  accessTokenUrl: string,
  grantType: 'password' | 'client_credentials',
  scope?: string,
};

type HttpBasicAuthContent = {
  username: string,
  password: EncryptedValue,
};

type HttpAuthContent =
  | HttpBasicAuthContent
  | HttpOAuth2ContentPassword
  | HttpOAuth2ContentClientCredentials
  | string;

type HttpAuthCredentialsType = 'standard' | 'secure';

type HttpAuth = {|
  authType: AuthType,
  credentialsType: HttpAuthCredentialsType,
  content: HttpAuthContent,
|};

type APIRequestTest = {
  body?: any,
  headers?: Headers,
  params?: { [name: string]: string },
  statusCode?: number,
  elapsedTime?: number,
};

type APIRequestOptions = {
  httpMethod: HttpMethod, // Should contains others methods later
  apiUrl: string, // Could be contains context keys in path, should use NLG formatter
  httpBody: HttpBody,
  config: HttpConfig,
  httpHeaders: HttpHeader[],
  httpParams: HttpParam[],
  httpAuth?: HttpAuth,
};

type NodeAPIRequest = {|
  ...$Exact<NodeBase>,
  ...$Exact<APIRequestOptions>,
  action: 'apiRequest',
  label: string,
  outputContext: OutputContextParam[],
|};

type NodeActionContextConfig = {|
  ...$Exact<NodeBase>,
  action: 'context-config',
  updates?: ContextConfigUpdate[],
  deletes?: string[],
|};

type NodeAction =
  | NodeAPIRequest
  | NodeActionComment
  | NodeActionDone
  | NodeActionGeneric
  | NodeActionHangUp
  | NodeActionPlayDTMF
  | NodeActionSay
  | NodeActionSet
  | NodeActionSpecific
  | NodeActionTransfer
  | NodeActionUpdate
  | NodeModule
  | NodeStatsCheckpoint
  | NodeActionContextConfig;

type NodeType = {|
  label: string,
  type: string,
  icon: string,
|};

type Node = Origin | NodeAction;

type NodeMeta = {
  statsMode: boolean,
  stats: NodeStats,
  statsCheckpoints: StatsCheckpointsConfig,
  genericActions: GenericAction[],
};

type FormattedNode = {
  id: string,
  type: 'origin' | 'action',
  className: string,
  data: Node,
  meta: NodeMeta,
};

type FormattedGroup = {
  className: string,
  data: {
    coordinates: {
      x: number,
      y: number,
    },
    dimensions: {
      heigth: number,
      width: number,
    } | null,
    id: string,
    name: string | null,
  },
  id: string,
  left: number,
  meta: Object,
  top: number,
  type: string,
  h: number,
  w: number,
};

type Temporality = 'year' | 'month' | 'week' | 'day' | 'hour' | 'minute' | 'second';

type DateModifier = {
  operator: 'add' | 'minus',
  value: number,
  unit: Temporality,
};

type AdvancedConditionDateItem = {
  key: string,
  value: string | number | null,
  operator: string, // Used as key when building condition
  precision?: Temporality,
  modifier?: DateModifier,
};

type AdvancedConditionItem = {
  key: string,
  operator: string, // Used as key when building condition
  value: string | number | boolean,
};

type DateConditions = {
  isEarlierThan: AdvancedConditionDateItem[],
  isLaterThan: AdvancedConditionDateItem[],
  isSameOrEarlier: AdvancedConditionDateItem[],
  isSameOrLater: AdvancedConditionDateItem[],
  isSameAs: AdvancedConditionDateItem[],
};

type Condition = {
  ...DateConditions,
  hasKeys: string[],
  [operator: string]: AdvancedConditionItem[],
};

type TransitionType = 'default' | 'selected';

type TransitionIntentContent = {
  id: string,
  entities: string[],
};

type Transition = {
  conditions?: Condition[],
  endingNode: string,
  geometry?: Object,
  id: string,
  intents?: TransitionIntentContent[][],
  startingNode: string,
  type: string,
  label?: string,
  customLabel?: string,
};

type GraphDataElementOption = {
  label: Object,
  value: string,
};

type GraphDataElementsOptions = {
  [domain: string]: {
    nodes: Node[],
    transitions: Transition[],
  },
};

type GraphData = {|
  nodes: Node[],
  transitions: Transition[],
|};

type Graphs = {
  [domain: string]: {
    data: GraphData,
  },
};

/* BUILD - Intents **** */

type OldIntent = { [name: string]: string[] };

type IntentSuggestion = {
  id: string,
  formattedIntent: string,
  entities: string[],
};

/* BUILD - Entities **** */

// DEPRECATED TYPE
type Referential = {|
  id: string,
  name: string,
  type: string,
  settings?: Setting,
  meta?: ReferentialContentMetadata,
  values?: CustomReferential,
  deprecatedSince?: number,
  draftSince?: number,
  replacements?: {
    id: string,
    scope?: 'account' | 'bot',
  },
|};

type EntityAssetForBotConfig = {|
  id: string,
  name: string,
  type: EntityContentType,
  settings?: Setting,
  replacements?: {
    id: string,
    scope?: 'account' | 'bot',
  },
  ...?$Shape<AssetBase<EntityContentType>>,
|};

type Entity = {
  id: string,
  name: string,
  entityMask: string,
  description: string,
  init: string,
  parse: string,
  referential: EntityAssetForBotConfig,
};
type Entities = {
  [entity: string]: Entity,
};

/* QUALITY - Tests **** */

type CheckResponse = {
  errors: BotApiError[],
};

/* MONITORING - Context columns **** */

type ContextColumn = {
  label: {
    default: string,
    [language: string]: string,
  },
  type:
    | 'Number'
    | 'Date'
    | 'Time'
    | 'DateTime'
    | 'String'
    | 'Object'
    | 'Array'
    | 'Recording'
    | 'Discussion',
  path: string,
};

type ContextColumns = {
  [columnName: string]: ContextColumn,
};

/* Bot **** */
type AsrQuality = 'high' | 'medium';

type Nlu = {
  engine: EngineName,
  confidenceCutoff: number,
};

/*
 * Used as generic ressource settings
 * If you need to add a new attribute for a specific ressource, a new type should be created
 * See `CallsSettings` type below for an example
 */
type RessourceSettings = {| ttl: number |};

type CallsSettings = {|
  ...RessourceSettings,
  ...{| contextKeysToDelete?: string[] |},
|};

type LogRetentionSettings = {|
  recordings: RessourceSettings,
  discussions: RessourceSettings,
  callsData: CallsSettings,
|};

type BargeInSettings = {|
  enabled: boolean,
  delay: number,
|};

type PostTransferRecordSettings = {|
  enabled: boolean,
|};

type HangUpSettings = {|
  auto: boolean,
  delay: number,
|};

type BotType = 'voicebot' | 'ivr';
type BotLanguage = Language;

type BotSettings = {|
  actions: string | null,
  asrQuality: AsrQuality,
  banner: Banner,
  bargeInSettings: BargeInSettings,
  contextColumns: ContextColumns,
  chatApiKey?: string,
  fallbackVoice?: ?string,
  hangUpSettings: HangUpSettings,
  intents: string[],
  language: ?BotLanguage,
  logRetentionSettings: LogRetentionSettings,
  nlu: Nlu,
  parsers: Parsers,
  postTransferRecordSettings: PostTransferRecordSettings,
  primaryVoice?: ?string,
  timezone: string | null,
  type: BotType,
  storageSettings: StorageSettings,
|};

type BotConfig = {
  ...BotSettings,
  botTemplate?: BotTemplate,
  graphs: Graphs,
  phrases: string[],
  stats: StatsConfig,
  tts: Tts,
  entities: Entities,
  statsCheckpoints: StatsCheckpointsConfig,
};

type LoadedBotConfig = {
  actions: string,
  asrQuality: AsrQuality,
  banner: Banner | null,
  contextColumns: ContextColumns,
  graphs: {
    [domain: string]: GraphData,
    tactical: GraphData,
  },
  hangUpSettings: HangUpSettings,
  bargeInSettings: BargeInSettings,
  postTransferRecordSettings: PostTransferRecordSettings,
  intents: IntentsMap,
  language: string,
  nlu: Nlu,
  parsers: Parsers,
  stats: StatsConfigData,
  logRetentionSettings: LogRetentionSettings,
  tts: Tts,
  timezone: string,
};

// This strict type doesn't reflect reality (dynamo) but what is currently handled in dashboard
type CustomHeaders = {
  [key: string]: string | boolean | number | Object,
};

type DDoSConfig = {
  banTime?: number,
  disable: boolean,
  threshold?: number,
};

type PhoneNumberProviderLevel = 'primary' | 'backup' | 'sms';

type PhoneNumberCapabilities = {
  sms?: boolean,
  voice?: boolean,
};

type BlacklistItem = {
  from: string,
  name?: string,
};

type PhoneNumber = {
  accountId?: string,
  blacklist?: BlacklistItem[],
  botName?: string,
  botVersion?: string,
  carrier?: string,
  customHeaders?: CustomHeaders,
  DDoSConfig?: DDoSConfig,
  environment?: string,
  failoverPhoneNumber?: string,
  overrideAsr?: string[],
  platformStage?: string,
  provider?: string,
  providerLevel?: PhoneNumberProviderLevel, // That's the only attribute that doesn't come from DynamoDB
  taskDefinition?: string,
  readableName?: string,
  to: string,
  capabilities: PhoneNumberCapabilities,
};

type Version = {
  version: string,
  snapshotId: string,
  userSub: string,
  userEmail: string,
};

type FormattedPhoneNumber = {
  accountId?: string,
  botName?: string,
  botVersion?: string,
  carrier?: string,
  // only thing which changes compare to PhoneNumber
  // TODO: check if we can merge these two types
  customHeaders: CustomHeaders,
  blacklist?: BlacklistItem[],
  DDoSConfig?: DDoSConfig,
  environment?: string,
  failoverPhoneNumber?: string,
  overrideAsr?: string[],
  platformStage?: string,
  provider?: string,
  providerLevel?: PhoneNumberProviderLevel, // That's the only attribute that doesn't come from DynamoDB
  taskDefinition?: string,
  readableName?: string,
  to: string,
  capabilities: PhoneNumberCapabilities,
};

type AvailablePhoneNumbers = {|
  primary: FormattedPhoneNumber[],
  backup: FormattedPhoneNumber[],
  sms: FormattedPhoneNumber[],
|};

// The or type on string is required due to fact that we use Object.keys() to display all fields
type BotPhoneNumbers = {
  dev: ?AvailablePhoneNumbers,
  internalTest: ?AvailablePhoneNumbers,
  clientTest: ?AvailablePhoneNumbers,
  prod: ?AvailablePhoneNumbers,
};

type AutoPilotStats = {
  coverage: number,
  nodes: number,
  intents: number,
};

// type used to describe a default empty bot
type BotWithoutConfig = {
  id: string,
  autoPilotStats: AutoPilotStats | null,
  calls: string[],
  chatApiKey: {
    error: any,
    value: ?string,
  },
  errors: {
    check: { list: BotApiError[] },
    evaluate: ?Error,
    // we add errors into a list since bot-api return
    // an array of errors
    importIntents: string | null,
    // errors when loading a bot
    load: { list: BotApiError[] },
    phoneNumbers: ?Error,
    // errors for serenizer/deployment
    publish: { list: BotApiError[] },
    // errors when saving a bot
    save: { list: BotApiError[] },
    validate: {
      // hints for builder helper
      buildingErrors: BotApiError[], // script validation error(s) (api resolved HTTP 200)
      list: BotApiError[], // real api error(s) (api resolves HTTP !== 200)
    },
    versions: ?Error,
  },
  loadedSnapshotId: string,
  phoneNumbers: BotPhoneNumbers,
  snapshots: string[],
  status: {
    chatApiKey: Status,
    check: Status,
    deploy: Status,
    duplicate: Status,
    evaluate: Status,
    importFolder: Status,
    importIntents: Status,
    load: Status,
    phoneNumbers: Status,
    save: Status,
    validate: Status,
    versions: Status,
  },
  versions: Version[],
};

type Bot = {
  ...BotWithoutConfig,
  id: string,
  config: BotConfig,
  lastConfigSaved?: BotConfig,
};

type Bots = EntityState<Bot> & {
  error: string | null,
  status: {
    fetching: Status,
    delete: Status,
    create: Status,
  },
};

type DeployEnvironment = 'dev' | 'internalTests';

type IntentUserInput = {
  ...IntentMatched,
  parsed?: string,
  confidence?: number,
  entities: { [entityName: string]: any[] },
};

type UserInput = {
  id?: string,
  mask: string, // entityMask (ex: people) || intentName (ex: GivePeople)
  maskWithEntities?: string, // for intent only (ex: GivePeople[people])
  formattedMaskWithEntities?: string, // intent formatted with label
  utterance?: string, // for intent only
  value?: string, // for entity value & entity variant
  pronunciation?: string, // for entity variant
  type: TagValueType,
  combinedIntents?: IntentUserInput[], // for intent only
  disabled?: boolean,
};

type NextTriggers = {
  [domain: string]: {
    [intentMasksCombined: string]: string[], // utterances list in value
  },
};

type CredentialSecretItem = {
  secretName: string,
  description?: string,
  createdAt: string,
  updatedAt: string,
};

type BotAction<T> = {| ...T, bot: string, type: string |};

export type {
  ABTestBranch,
  ActionsName,
  AdvancedConditionDateItem,
  AdvancedConditionItem,
  APIRequestOptions,
  APIRequestTest,
  AsrQuality,
  AutoPilotStats,
  AvailablePhoneNumbers,
  Banner,
  BargeInSettings,
  BlacklistItem,
  Block,
  BlockConfig,
  BlockEnds,
  BlockName,
  Blocks,
  BlockStarts,
  BlockTip,
  Bot,
  BotAction,
  BotApiError,
  BotConfig,
  BotLanguage,
  BotPhoneNumbers,
  Bots,
  BotSettings,
  BotType,
  BotWithoutConfig,
  CallsSettings,
  CheckResponse,
  Condition,
  ContextColumn,
  ContextColumns,
  ContextConfigType,
  ContextConfigUpdate,
  ContextConfigValue,
  CredentialSecretItem,
  CustomHeaders,
  DateConditions,
  DateModifier,
  DeployEnvironment,
  EncryptedValue,
  Entities,
  Entity,
  EntityAssetForBotConfig,
  EntityState,
  Environment,
  FormattedGroup,
  FormattedNode,
  FormattedPhoneNumber,
  GraphData,
  GraphDataElementOption,
  GraphDataElementsOptions,
  GraphDomain,
  Graphs,
  HangUpSettings,
  HttpAuth,
  HttpAuthContent,
  HttpAuthCredentialsType,
  HttpBasicAuthContent,
  HttpBody,
  HttpBodyPayload,
  HttpBodyPayloadRawJson,
  HttpBodyPayloadUrlEncoded,
  HttpBodyType,
  HttpConfig,
  HttpHeader,
  HttpMethod,
  HttpOAuth2ContentClientCredentials,
  HttpOAuth2ContentPassword,
  HttpParam,
  IntentSuggestion,
  IntentUserInput,
  LoadedBotConfig,
  LogRetentionSettings,
  ModuleParams,
  ModuleTarget,
  NextTriggers,
  Nlu,
  Node,
  NodeABTest,
  NodeAction,
  NodeActionComment,
  NodeActionContextConfig,
  NodeActionDone,
  NodeActionGeneric,
  NodeActionGetHelp,
  NodeActionHangUp,
  NodeActionPlay,
  NodeActionPlayDTMF,
  NodeActionSay,
  NodeActionSendEmail,
  NodeActionSendSms,
  NodeActionSet,
  NodeActionSpecific,
  NodeActionTransfer,
  NodeActionUpdate,
  NodeAPIRequest,
  NodeBase,
  NodeHook,
  NodeMeta,
  NodeModule,
  NodeStats,
  NodeStatsCheckpoint,
  NodeType,
  OldIntent,
  Origin,
  OutputContextParam,
  Parser,
  Parsers,
  PhoneNumber,
  PhoneNumberCapabilities,
  PhoneNumberProviderLevel,
  PostTransferRecordSettings,
  Referential,
  RessourceSettings,
  SendEmailParams,
  SendSmsParams,
  Setting,
  StatsBlockConfig,
  StatsCheckpointReason,
  StatsCheckpointsConfig,
  StatsCheckpointStatus,
  StatsCheckpointStatusId,
  StatsCheckpointStep,
  StatsConfig,
  StatsConfigBlockDefinition,
  StatsConfigData,
  StatusName,
  Temporality,
  TransferParams,
  Transition,
  TransitionIntentContent,
  TransitionType,
  Tts,
  TtsVoice,
  UserInput,
  Version,
};

export {
  AB_TEST_NODE_NAME,
  ADD_BOT_PHONE_NUMBERS,
  API_REQUEST_NODE_NAME,
  CLASSIC_ACTION_NODES,
  COMMENT_NODE_NAME,
  CONTEXT_CONFIG_NODE_NAME,
  CREATE_NEW_BOT_SAGA,
  DELETE_BOT_SAGA,
  DELETE_ENTITY_SAGA,
  DONE_NODE_NAME,
  DUPLICATE_BOT_SAGA,
  END_OF_CONVERSATION_NODES,
  FETCH_BOT_INTENTS_EVALUATIONS,
  FETCH_BOT_INTENTS_EVALUATIONS_ERROR,
  FETCH_BOT_INTENTS_EVALUATIONS_SUCCESS,
  FETCH_BOT_PHONE_NUMBERS,
  FETCH_BOT_PHONE_NUMBERS_ERROR,
  FETCH_BOT_PHONE_NUMBERS_SUCCESS,
  FETCH_BOT_VERSIONS,
  FETCH_BOT_VERSIONS_ERROR,
  FETCH_BOT_VERSIONS_SUCCESS,
  FOLDER_NODE_NAME,
  FORGET_ENTITIES_IN_CONTEXT,
  GENERIC_ACTION_NODES,
  GENERIC_NODE_CHECK_BUSINESS_HOURS_ACTION,
  GENERIC_NODE_DATA_MATCH_ACTION,
  GENERIC_NODE_EXECUTE_SALESFORCE_QUERY_ACTION,
  GENERIC_NODE_FILTER_CONTEXT_ELEMENT_ACTION,
  GENERIC_NODE_FILTER_REFERENTIAL_ACTION,
  GENERIC_NODE_FLASH_MESSAGE_ACTION,
  GENERIC_NODE_GET_CURRENT_DATE_ACTION,
  GENERIC_NODE_GET_CURRENT_DAY_PART_ACTION,
  GENERIC_NODE_INIT_CUSTOM_ENTITY_PARSER_ACTION,
  GENERIC_NODE_MANAGE_CALLER_ENTITY_VALUE_ACTION,
  GENERIC_NODE_NAME,
  GENERIC_NODE_PROCESS_CUSTOM_ENTITY_ACTION,
  GENERIC_NODE_SCHEDULE_APPOINTMENT_ACTION,
  GENERIC_NODE_SEND_EMAIL_ACTION,
  GENERIC_NODE_SEND_SMS_ACTION,
  GENERIC_NODE_SUBSTRING_ACTION,
  GET_HELP_NODE_NAME,
  GHOST_NODES,
  HANG_UP_NODE_NAME,
  HOOK_NODE_NAME,
  INIT_NODE_NAME,
  MODULE_FIRST_NAME,
  MODULE_LAST_NAME,
  MODULE_NODE_NAME,
  NODES_IN_PALETTE,
  ORIGIN_NODE_NAME,
  PLAY_DTMF_NODE_NAME,
  PLAY_NODE_NAME,
  RETRIEVE_ENTITIES_IN_CONTEXT,
  SAY_NODE_NAME,
  SET_NODE_NAME,
  SPECIFIC_NODE_NAME,
  START_CHECK_BOT_SAGA,
  START_GET_BOT_CONFIG_SAGA,
  START_GET_BOTS_SAGA,
  START_IMPORT_INTENTS_BOT_SAGA,
  START_PUBLISH_BOT_SAGA,
  START_SAVE_BOT_SAGA,
  START_VALIDATE_BOT_SAGA,
  STATS_CHECKPOINT_NODE_NAME,
  SYSTEM_ACTION_NODES,
  SYSTEM_ENTITIES,
  TRANSFER_NODE_NAME,
  UPDATE_NODE_NAME,
};
