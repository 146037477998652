// @flow

import type { ComponentType as ReactComponent, Element as ReactElement } from 'react';
import type { SvgIconProps } from '@material-ui/core';

import type { CDKModalFuncProps } from '@calldesk/components';

import type { Status } from '@state/ducks/app/types';
import type { BotConfig, BotPhoneNumbers, Version } from '@state/ducks/bots/types';
import type { AvailableOptions } from '@state/ducks/options/types';

// in order to store some data out the botConfig
// which will be checked in validator function:
type ValidatorData = {
  calls?: { count: number, transcriptOpened: boolean },
  chat?: { opened: boolean, nodeDoneExecuted?: boolean },
  lastDeploy?: Version[],
  lastSave?: { description: string, parentSnapshotId: string },
  phoneNumbers?: BotPhoneNumbers,
};

type ValidateFnReturn<T = any> = { validated: boolean, others?: T };

type ValidateFn<T = any> = (
  botConfig: BotConfig,
  currentProgression: any,
  validatorData: ValidatorData,
  availableOptions: AvailableOptions,
) => ValidateFnReturn<T>;

type StepValidators = {
  // NOTE: Can't determine here in advance the return type for each validator
  quests: { [questId: string]: ValidateFn<any> },
};

type CategoryValidators = {
  steps: { [stepId: string]: StepValidators },
};

type ExerciseValidators = {
  categories: {
    builder: CategoryValidators,
    calls: CategoryValidators,
  },
};

type Validators = {
  [exerciseId: string]: ExerciseValidators,
};

type SubQuest = {
  id?: string,
  label?: string | ReactElement<any>,
  explain?: CDKModalFuncProps,
};
type Quest = {
  order?: number,
  label?: string | ReactElement<any>, // only for localization
  links?: string[], // only for localization
  updatedAt?: number,
  validated?: boolean,
  others?: { [other: string]: any },
  explain?: { ...CDKModalFuncProps, flashing?: boolean, onClick?: () => void },
  subQuests?: SubQuest[],
};

type Quests = { [quest: string]: Quest };

type Step = {
  order?: number,
  label?: string | ReactElement<any>, // only for localization
  quests: Quests,
};

type Steps = { [step: string]: Step };

type Category = {
  order?: number,
  label?: string | ReactElement<any>, // only for localization
  steps: Steps,
  icon?: ReactComponent<SvgIconProps>, // only for CDKCategoriesOnboarding component
};

type Categories = { [category: string]: Category };

type Exercise = {
  order?: number,
  label?: string | ReactElement<any>, // only for localization
  categories: Categories,
};

type Exercises = { [exercise: string]: Exercise };

type Onboarding = {|
  exerciseId: string,
  isEnded: boolean,
  progression: Exercise,
  startedAt: number,
  updatedAt: number,
|};

type OnboardingState = {
  // TODO: Remettre à la racine car il n'y aura qu'un seul onboarding
  // ou `data: ?Onboarding`
  currentOnboarding: ?Onboarding,
  status: {
    create: { error: ?string, value: Status },
    fetching: { error: ?string, value: Status },
    validator: { error: ?string, value: Status },
  },
  validatorData: ValidatorData,
};

// TODO: get exerciseId dynamically
const EXERCISE_KEY: string = 'createSimpleBotWithTransfer';

// // SAGAS
const CREATE_NEW_ONBOARDING_SAGA: string = 'onboarding/CREATE_NEW_ONBOARDING_SAGA';
const RUN_VALIDATOR_ONBOARDING_SAGA = 'onboarding/RUN_VALIDATOR_ONBOARDING_SAGA';
const SAVE_AND_CHECK_USER_ONBOARDING_SAGA: string =
  'onboarding/SAVE_AND_CHECK_USER_ONBOARDING_SAGA';

export type {
  Categories,
  Category,
  CategoryValidators,
  Exercise,
  Exercises,
  ExerciseValidators,
  Onboarding,
  OnboardingState,
  Quest,
  Quests,
  Step,
  Steps,
  StepValidators,
  SubQuest,
  ValidateFn,
  ValidateFnReturn,
  ValidatorData,
  Validators,
};

export {
  CREATE_NEW_ONBOARDING_SAGA,
  EXERCISE_KEY,
  RUN_VALIDATOR_ONBOARDING_SAGA,
  SAVE_AND_CHECK_USER_ONBOARDING_SAGA,
};
