// @flow
import type { AvailableOptions, Options } from './types';

import type { Entities, Graphs } from '@state/ducks/bots/types';
import type { OptionsFilters } from '@state/ducks/options/types';
import request from '@amplify/api/request';
import logger from '@assets/js/calldesk-app-util/logger';

/**
 * Retrieve Context keys that are defined for the given config (in the graph, intents, entities, ...) from studio api
 */
async function fetchAvailableContextKeys(
  accountId: string,
  botName: string,
  graphs: Graphs,
  entities: Entities,
): Promise<$PropertyType<Options, 'contextKeys'>> {
  logger.info(
    `state/options/api [fetchAvailableContextKeys] Retrieving available context keys for user on ${accountId}/${botName}`,
  );
  try {
    const { contextKeys }: Options = await request.studioApi({
      path: `/accounts/${accountId}/bots/${botName}/availableContextKeys`,
      method: 'POST',
      body: { graphs, entities },
    });
    return contextKeys;
  } catch (error) {
    logger.info(
      'state/options/api [fetchAvailableContextKeys] Error while retrieving context keys:',
      error,
    );
    throw error;
  }
}

/**
 * Retrieve available options from studio api
 *
 * @param {*} params -
 * @param {string} params.accountId - The selected account
 * @param {?string} params.botName - The selected botName
 * @param {?string[]} params.filters - filters.optionsName - if you want to exclude one or multiple options use prefix it with "!" (e.g filters = {optionsName: ['!nlu']}).
 * Conversely, only set the name of the option if you want to include it (e.g filters = {optionsName: ['nlu']})
 * @returns {Promise<Options>} - Available options
 */
async function fetchAvailableOptions({
  accountId,
  botName,
  filters,
}: {
  accountId: string,
  botName?: string,
  filters?: OptionsFilters,
}): Promise<AvailableOptions> {
  try {
    logger.info(
      `state/options/api [fetchAvailableOptions] Retrieving available options for user on ${accountId}/${
        botName || ''
      }`,
    );

    const { duration, apiVersion, ...options } = await request.studioApi({
      method: 'GET',
      path: botName
        ? `/accounts/${accountId}/bots/${botName}/availableOptions`
        : `/accounts/${accountId}/availableOptions`,
      ...(filters && {
        queryStringParameters: filters,
      }),
    });

    logger.info('state/options/api [fetchAvailableOptions] Retrieved options:', options);

    return options;
  } catch (error) {
    logger.info('state/options/api [fetchAvailableOptions] Error while retrieving options:', error);
    throw error;
  }
}

export { fetchAvailableContextKeys, fetchAvailableOptions };
