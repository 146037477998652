// @flow

import type { Action } from '@state/ducks/app/types';
import type { Entity } from '@state/ducks/bots/types';
import * as types from '@state/ducks/customers-assets/types';

// TODO : remove
const tagEntitiesReferentialSaga = (
  accountId: string,
  botName: string,
  entity?: Entity[],
): Action => ({
  type: types.TAG_ENTITIES_REFERENTIAL_SAGA,
  accountId,
  botName,
  entity,
});

export { tagEntitiesReferentialSaga };
