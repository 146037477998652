// @flow

import { createReduxEnhancer } from '@sentry/react';

import { actionTransformer, stateTransformer } from './transformers';

/**
 * Monitoring enhancer of redux by Sentry.
 * In order to sent last actions dispatched and last state of store before error caught.
 *
 * Some sensitive data are hidden,
 * and some data ducks (bots) are scoped, because Event Payload Sentry size limit is 200kb.
 * docs: https://develop.sentry.dev/sdk/event-payloads/#:~:text=Events%20are%20the%20fundamental%20data,payload%20size%20limit%20is%20200kb.
 */
const monitoringEnhancer = createReduxEnhancer({ actionTransformer, stateTransformer });

export default monitoringEnhancer;
