// @flow

// STYLES
import type { Location, RouterHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { TourProvider, useTour } from '@reactour/tour';

import { CDKLayout } from '@calldesk/components';

import type { ProductTourCookie } from '@assets/js/calldesk-app-util/product-tour/index.type';
import fromCalldeskEndpoints from '@amplify/adapters/get-api-endpoints';
import fromCalldeskCognitoConfig from '@amplify/adapters/get-auth-config';
import amplifyConfigInit from '@amplify/init';
import ability from '@assets/js/calldesk-app-util/casl/ability';
import { AbilityContext } from '@assets/js/calldesk-app-util/casl/ability-context';
import logger from '@assets/js/calldesk-app-util/logger';
import {
  CustomNextButton,
  getProductTourCookie,
  setProductTourCookie,
} from '@assets/js/calldesk-app-util/product-tour';
import ErrorBoundary from '@assets/js/calldesk-components/molecules/ErrorBoundary';
import endpointsConfig from '@resources/calldesk-endpoints.json';
import cognito from '@resources/cognito-config.json';
import { operations as appOperations } from '@state/ducks/app';
import { selectors as sessionSelectors } from '@state/ducks/session';

// COMPONENTS
import Navbar from './components/Navbar';
import PrivateRoute from './components/PrivateRoute';
import UnauthenticatedLayout from './layouts/Unauthenticated';
// VIEWS
// import Bot from './views/Bot/bot.container';
import Error from './views/Error';
import Home from './views/Home';
import Login from './views/Login';
import ForgotPassword from './views/Password/ForgotPassword';
import ResetPassword from './views/Password/ResetPassword';
import User from './views/User';

import './app.css';

amplifyConfigInit({
  cognito: fromCalldeskCognitoConfig(cognito),
  endpoints: fromCalldeskEndpoints(endpointsConfig),
});

// Seems useless to use Loadable. Try to just import Bot and it will works (test direct access by url !)
const Bot = React.lazy(() => import(/* webpackChunkName: "app" */ './views/Bot'));

function WithUnauthenticatedLayout(component) {
  return <UnauthenticatedLayout>{component}</UnauthenticatedLayout>;
}

/**
 * App Higher Order Component
 * Have to be a HOC
 */
function AppComponent() {
  const history: RouterHistory = useHistory();
  const dispatch = useDispatch();
  const location: Location = useLocation();
  const { setCurrentStep } = useTour();
  const match = useRouteMatch({
    path: '/accounts/:account/bots/:bot',
    exact: false,
    strict: false,
  });

  const isLoggedIn: boolean = useSelector(sessionSelectors.isLoggedIn);

  const initializeApp = () => {
    dispatch(appOperations.startAppSaga({ history, location, match }));
  };

  const onStopProductTour = (): void => {
    setCurrentStep(0);
    const currProductTourCookie: ?ProductTourCookie = getProductTourCookie();
    if (currProductTourCookie) {
      setProductTourCookie({ ...currProductTourCookie, play: false });
    }
  };

  /**
   * Lifecycle hook: called before component mounted.
   */
  useEffect(() => {
    logger.info('[App]: component did mount');
    initializeApp();

    // Most modern browsers (Chrome 51+, Safari 9.1+ etc) will ignore what you say
    // and just present a generic message.
    if (process.env.NODE_ENV !== 'development') {
      window.onbeforeunload = () =>
        'Are you sure you want to leave this page, all your unsaved data will be lost !';
    }
    return () => {
      if (process.env.NODE_ENV !== 'development') {
        window.removeEventListener('beforeunload', null);
      }
    };
  }, []);

  return (
    <ErrorBoundary>
      <CDKLayout style={{ height: '100vh' }}>
        <TourProvider
          steps={[]}
          beforeClose={onStopProductTour}
          padding={0}
          showBadge={false}
          styles={{
            popover: (base: any) => ({
              ...base,
              borderRadius: '8px',
            }),
          }}
          nextButton={CustomNextButton}
        >
          <AbilityContext.Provider value={ability}>
            <div className="App">
              {isLoggedIn && <Navbar />}
              <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/account" exact component={User} />

                <PrivateRoute
                  path="/accounts/:account/bots/:bot"
                  render={() => <Bot key={match.params.bot} />}
                />

                <Route
                  path="/forgotpassword"
                  exact
                  render={() => WithUnauthenticatedLayout(<ForgotPassword />)}
                />
                <Route path="/login" exact render={() => WithUnauthenticatedLayout(<Login />)} />
                <Route
                  path="/resetpassword"
                  exact
                  render={() =>
                    WithUnauthenticatedLayout(
                      <ResetPassword location={location} history={history} />,
                    )
                  }
                />
                <Route render={() => <Error code={404} />} />
              </Switch>
            </div>
          </AbilityContext.Provider>
        </TourProvider>
      </CDKLayout>
    </ErrorBoundary>
  );
}

export default AppComponent;
