// @flow

import type { State as RootState, Status } from '../app/types';
import type { Account, Accounts as AccountsState, CredentialSecretItem } from './types';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  // $FlowFixMe
  createAsyncThunk,
  // $FlowFixMe
  createEntityAdapter,
  // $FlowFixMe
  createSlice,
} from '@reduxjs/toolkit';
import _get from 'lodash/get';

import {
  createAccount as createAccountAPI,
  getCredentialsByAccount as getCredentialsByAccountAPI,
  isAuthorizedOnPlatform as isAuthorizedOnPlatformAPI,
} from './api';
import { CREATE_ACCOUNT, FETCH_CREDENTIALS, IS_AUTHORIZED_PLATFORM } from './types';
import * as utils from './utils';

const accountsAdapter = createEntityAdapter({
  selectId: (account: Account) => account.accountId,
});

/* APIs Handlers */
const initialState: AccountsState = accountsAdapter.getInitialState({
  authorizations: {},
  error: null,
  status: 'NONE',
});
const checkPlatformAuthorization = createAsyncThunk(
  IS_AUTHORIZED_PLATFORM,
  ({ userSub, right }: { userSub: string, right: string }) =>
    isAuthorizedOnPlatformAPI(userSub, right),
);

const createAccount = createAsyncThunk(CREATE_ACCOUNT, (account: string) =>
  createAccountAPI(account),
);

const fetchCredentialsByAccount = createAsyncThunk(FETCH_CREDENTIALS, (account: string) =>
  getCredentialsByAccountAPI(account),
);

/* ACTIONS & REDUCERS */

const { actions, reducer } = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    updateAccounts(state: AccountsState, { payload }: PayloadAction<string[]>) {
      const accounts: Account[] = utils.getDefaultAccounts(payload);
      accountsAdapter.setAll(state, accounts);
    },
    updateBots(
      state: AccountsState,
      { payload: { accountId, botIds } }: PayloadAction<{ accountId: string, botIds: string[] }>,
    ) {
      const currentBotIds: string[] = state.entities?.[accountId].bots;
      const updatedBotIds: string[] = utils.updateBots(currentBotIds, botIds);

      const updatedAccount = { accountId, bots: updatedBotIds };
      accountsAdapter.upsertOne(state, updatedAccount);
    },
    updateCredentialsStatus(
      state: AccountsState,
      { payload: { account, status } }: PayloadAction<{ account: string, status: Status }>,
    ) {
      // Not possible to change status with upsertOne(), because
      // only perform shallow updates in a mutable manner.
      // This means that if your update/upsert consists of an object that includes nested properties,
      // the value of the incoming change will overwrite the entire existing nested object:
      // https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
      state.entities[account].credentials.status = status;
    },
    updateError(state: AccountsState, { payload }: PayloadAction<string>) {
      state.error = payload;
    },
    updateStatus(state: AccountsState, { payload }: PayloadAction<Status>) {
      state.status = payload;
    },
  },
  extraReducers: builder => {
    builder
      // Cases for "checkPlatformAuthorization":
      .addCase(
        checkPlatformAuthorization.fulfilled,
        (
          state: AccountsState,
          {
            meta: {
              arg: { right },
            },
            payload: isAuthorized,
          },
        ) => {
          state.authorizations[right] = isAuthorized ? 'AUTHORIZED' : 'UNAUTHORIZED';
        },
      )
      .addCase(
        checkPlatformAuthorization.rejected,
        (
          state: AccountsState,
          {
            meta: {
              arg: { right },
            },
          },
        ) => {
          state.authorizations[right] = 'UNAUTHORIZED';
        },
      )
      // Cases for "createAccount":
      .addCase(createAccount.pending, (state: AccountsState) => {
        state.status = 'IN_PROGRESS';
        state.error = null;
      })
      .addCase(createAccount.fulfilled, (state: AccountsState, { meta: { arg: newAccountId } }) => {
        // Add new account in accounts list:
        const currAccountIds: string[] = state.ids;
        const accountIds: string[] = [...currAccountIds, newAccountId].sort();
        const accounts: Account[] = utils.getDefaultAccounts(accountIds);
        accountsAdapter.setAll(state, accounts);
        state.status = 'SUCCESS';
      })
      .addCase(createAccount.rejected, (state: AccountsState, { error }) => {
        state.status = 'ERROR';
        state.error = error.message;
      })
      // Cases for "fetchCredentialsByAccount":
      .addCase(
        fetchCredentialsByAccount.fulfilled,
        (state: AccountsState, { meta: { arg: account }, payload: credentialsList }) => {
          state.entities[account].credentials.status = 'SUCCESS';
          state.entities[account].credentials.error = null;
          state.entities[account].credentials.data = credentialsList;
        },
      )
      .addCase(
        fetchCredentialsByAccount.rejected,
        (state: AccountsState, { error, meta: { arg: account } }) => {
          state.entities[account].credentials.status = 'ERROR';
          state.entities[account].credentials.error = error.message;
          state.entities[account].credentials.data = [];
        },
      );
  },
});

/* SELECTORS */
const accountsSelectors = accountsAdapter.getSelectors((state: RootState) => state.accounts);

const isLoading = (state: RootState): boolean => state.accounts.status === 'IN_PROGRESS';

const isSuccess = (state: RootState): boolean => state.accounts.status === 'SUCCESS';

const getError = (state: RootState): null | string => state.accounts.error;

const getBots = (state: RootState, account?: string | null): $PropertyType<Account, 'bots'> =>
  account ? accountsSelectors.selectById(state, account)?.bots : [];

const isAuthorizedOnPlatform = (state: RootState, right: string): boolean =>
  _get(state.accounts, `authorizations['${right}']`, 'UNAUTHORIZED') === 'AUTHORIZED';

const getPlatformAuthorizationStatus = (state: RootState, right: string): string =>
  _get(state.accounts, `authorizations['${right}']`, 'NONE');

const getCredentialsData = (state: RootState, account: string): CredentialSecretItem[] =>
  accountsSelectors.selectById(state, account)?.credentials?.data || [];

const getCredentialsStatus = (state: RootState, account: string): Status =>
  accountsSelectors.selectById(state, account)?.credentials?.status || 'NONE';

const selectors = {
  getAccounts: accountsSelectors.selectIds,
  getBots,
  getCredentialsData,
  getCredentialsStatus,
  getError,
  getPlatformAuthorizationStatus,
  isAuthorizedOnPlatform,
  isLoading,
  isSuccess,
};

/* EXPORTS */
actions.checkPlatformAuthorization = checkPlatformAuthorization;
actions.createAccount = createAccount;
actions.fetchCredentialsByAccount = fetchCredentialsByAccount;

export { actions, selectors };
export default reducer;
