// @flow

import type { ActionNode, StatsCheckpointMessage, TraceItem, TraceItemFactory } from '../../types';

import { STATS_CHECKPOINT_NODE_NAME } from '@state/ducks/bots/types';

import { getId } from '../../util';

/**
 * Factory Method for Checkpoint messages
 */
const Factory = (): TraceItemFactory<ActionNode> => {
  const formatter = (traceItem: TraceItem<ActionNode>): StatsCheckpointMessage => ({
    content: {
      reason: traceItem.node.params.statsCheckpoint_reason,
      step: traceItem.node.params.statsCheckpoint_step,
      status: traceItem.node.params.statsCheckpoint_status,
    },
    sender: 'stats',
    nodeId: traceItem.node.id,
    id: getId({ timestamp: traceItem.timestamp, nodeId: traceItem.node.id }),
    timestamp: traceItem.timestamp,
  });

  const validator = (traceItem: TraceItem<any>): boolean =>
    STATS_CHECKPOINT_NODE_NAME === traceItem?.node?.action;

  return { formatter, validator };
};
export default Factory;
