// @flow

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CheckIcon from '@material-ui/icons/Check';

import { CDKForm, CDKInput, CDKModal, useForm } from '@calldesk/components';

import type { BotConfig } from '@state/ducks/bots/types';
import { operations as botsOperations, selectors as botsSelectors } from '@state/ducks/bots';

import './save-popover.css';

const BASIC_SAVE_NAME_REGEXP: RegExp = /(^[a-zA-Z0-9\s-]*$)/;
const NEGATE_DESCRIPTION_ERROR: string =
  'Only letters and digits are allowed. Special characters, accent...are prohibited';

type Props = {|
  account: string,
  bot: string,
  visible: boolean,
  close: () => void,
  lastSnapshotId: string,
|};

/**
 * Save Popover
 */
function SavePopover({ visible, account, bot, close, lastSnapshotId }: Props) {
  const dispatch = useDispatch();
  const [form] = useForm();
  const { t } = useTranslation();

  const disabledButton: boolean = form.getFieldsError().some(field => field.errors.length > 0);

  const botConfig: ?BotConfig = useSelector(state => botsSelectors.getBotConfig(state, bot));

  const handleSave = (description: string, parentSnapshotId: string) => {
    dispatch(
      botsOperations.startSaveBotSaga({ bot, account, botConfig, description, parentSnapshotId }),
    );
  };
  const handleClose = () => {
    form.resetFields();
    close();
  };

  /**
   * Handle call to parent's method `handleSave`
   */
  const handleParentSave = async () => {
    const values = await form.validateFields();
    handleSave(values.description, lastSnapshotId);
    handleClose();
  };

  const content = (
    <CDKForm form={form} layout="vertical">
      <b>{t('input.label.save')}</b>
      <CDKForm.Item
        label={t('navbar.Save.Form.description')}
        name="description"
        rules={[
          {
            message: NEGATE_DESCRIPTION_ERROR,
            whitespace: true,
            pattern: BASIC_SAVE_NAME_REGEXP,
          },
        ]}
      >
        <CDKInput />
      </CDKForm.Item>
    </CDKForm>
  );

  return (
    <CDKModal
      visible={visible}
      title={null}
      className="SavePopover"
      okText={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CheckIcon className="cdkicon anticon" />
          <span style={{ paddingLeft: '4px' }}>{t('input.label.Validate')}</span>
        </div>
      }
      width={320}
      bodyStyle={{ paddingBottom: 0 }}
      onOk={handleParentSave}
      okButtonProps={{ disabled: disabledButton }}
      onCancel={handleClose}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      {content}
    </CDKModal>
  );
}

export default SavePopover;
