// @flow

import React from 'react';
import { useTranslation } from 'react-i18next';

import './home.css';

export default function Home() {
  const { t } = useTranslation();

  return (
    <div className="Home">
      <div className="HomeHint">
        <h1>{t('Home.howToStart', { ns: 'views' })}</h1>
        <p>{t('Home.selectBot', { ns: 'views' })}</p>
      </div>
    </div>
  );
}
