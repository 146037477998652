// @flow
import ability from './ability';

export type Right = {
  subject: string,
  actions: string,
};

/**
 * This func parse json recursively in order to extract actions
 * that user is allowed on app subjects.
 * (For instance "read" on intents)
 */
function createRightsFromJson(json: Object, rights: Right[], concatSubject: string): void {
  if (!json) {
    return;
  }

  Object.keys(json).forEach(key => {
    if (typeof json[key] === 'boolean' && json[key]) {
      rights.push({ subject: concatSubject, actions: key });
    } else if (typeof json[key] === 'object') {
      createRightsFromJson(json[key], rights, concatSubject ? `${concatSubject}.${key}` : key);
    }
  });
}

/**
 * Update rights, this enables Cans components spreaded in all app
 * It is called in app start saga
 */
function updateAbility(rightsJson: { [right: string]: boolean | Object }) {
  const usersRights: Right[] = [];
  createRightsFromJson(rightsJson, usersRights, '');
  ability.update(usersRights);
}

/*

  TODO implements <Can>'s' for

  can(['read'], 'calls.discussion');
  can(['write'], 'calls.discussion');
  can(['delete'], 'calls.discussion');

  can(['read'], 'calls.recordings');
  can(['write'], 'calls.recordings');
  can(['delete'], 'calls.recordings');

  can(['export'], 'calls.csv');
  can(['use'], 'calls.search');

  + implements fullAccess

*/

export { createRightsFromJson, updateAbility };
