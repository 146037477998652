// @flow

import type { CheckpointOptions, Options, OptionsState } from './types';
import {
  // $FlowFixMe
  createAsyncThunk,
  // $FlowFixMe
  createEntityAdapter,
  // $FlowFixMe
  createSelector,
  // $FlowFixMe
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit';

import type { State as RootState, Status } from '@state/ducks/app/types';
import type {
  Entities,
  Graphs,
  StatsCheckpointReason,
  StatsCheckpointStatus,
  StatsCheckpointStatusId,
  StatsCheckpointStep,
} from '@state/ducks/bots/types';
import { selectors as botsSelectors } from '@state/ducks/bots';
import { api as studioAPI } from '@state/ducks/options';
import { selectors as uiSelectors } from '@state/ducks/ui';

const optionsAdapter = createEntityAdapter();

const initialState: OptionsState = optionsAdapter.getInitialState({
  status: 'NONE',
  error: null,
});

/* ACTIONS */
const fetchAvailableOptions = createAsyncThunk(
  'options/fetchAvailableOptions',
  async ({ selectedAccount, optionsNameFilter = [] }, { getState }) => {
    const { options } = getState();
    const newOptionsNameFilter = [];

    optionsNameFilter.forEach(optionName => {
      if (!options.ids.includes(optionName) && !optionName.startsWith('!'))
        newOptionsNameFilter.push(optionName);
    });

    return studioAPI.fetchAvailableOptions({
      accountId: selectedAccount,
      filters: { optionsName: newOptionsNameFilter },
    });
  },
);

const fetchAvailableContextKeys = createAsyncThunk(
  'options/fetchAvailableContextKeys',
  async ({ botName }, { getState }) => {
    const state = getState();
    const accountId: string = uiSelectors.getSelectedAccount(state);
    const botGraphs: Graphs = botsSelectors.getBotGraphs(state, botName);
    const botEntities: Entities = botsSelectors.getEntities(state, botName);

    const formattedGraphs = {};
    Object.keys(botGraphs).forEach((domain: string) => {
      formattedGraphs[domain] = {
        ...botGraphs[domain].data,
      };
    });

    return studioAPI.fetchAvailableContextKeys(accountId, botName, formattedGraphs, botEntities);
  },
);

/* REDUCERS */
const { reducer, actions } = createSlice({
  name: 'options',
  initialState,
  extraReducers: builder =>
    builder
      .addCase(fetchAvailableOptions.fulfilled, (state, action: PayloadAction<Options>) => {
        const formattedPayload = Object.keys(action.payload).map(optionId => ({
          id: optionId,
          content: action.payload[optionId],
        }));

        state.status = 'SUCCESS';
        optionsAdapter.upsertMany(state, formattedPayload);
      })
      .addCase(fetchAvailableContextKeys.fulfilled, (state, action: PayloadAction<Options>) => {
        state.status = 'SUCCESS';
        optionsAdapter.upsertOne(state, { id: 'context-keys', content: action.payload });
      })
      .addMatcher(
        action => action.type?.startsWith('options') && action.type?.endsWith('/pending'),
        state => {
          state.error = null;
          state.status = 'IN_PROGRESS';
        },
      )
      .addMatcher(
        action => action.type?.startsWith('options') && action.type?.endsWith('/rejected'),
        (state, action) => {
          state.status = 'ERROR';
          state.error = action.error;
        },
      ),
});

/* SELECTORS */
const genericSelectors = optionsAdapter.getSelectors((state: RootState) => state.options);

const selectOption = (
  state: RootState,
  { option, language }: { option: string, language?: string },
) => {
  const selectedOption = genericSelectors.selectById(state, option);

  if (!selectedOption || (language && !selectedOption.content?.[language])) return null;

  if (language) return selectedOption.content[language];

  return selectedOption.content;
};

const selectEntitiesOptions = (state: RootState, options?: $Keys<Options>[]) => {
  const entitiesOptions = {};
  Object.entries(genericSelectors.selectEntities(state)).forEach(
    ([key, value]: [string, Object]) => {
      if (!options || options?.includes(key)) entitiesOptions[key] = value.content;
    },
  );

  return entitiesOptions;
};

const getStatsCheckpointsOptions = createSelector(
  state => selectOption(state, { option: 'checkpoint-options' }) ?? { steps: [], statuses: [] },
  ({ steps: stepsOptions, statuses: statusesOptions }: CheckpointOptions) => {
    const steps: { [id: string]: StatsCheckpointStep } = Object.fromEntries(
      stepsOptions.map(step => [step.id, { ...step, isOption: true }]),
    );
    const statuses: { [id: StatsCheckpointStatusId]: StatsCheckpointStatus } = Object.fromEntries(
      statusesOptions.map(({ id, reasons, ...status }) => [id, { ...status, id, isOption: true }]),
    );

    // For each status, there are nested reasons. We must normalize the data
    const reasons: {
      [id: string]: StatsCheckpointReason,
    } = statusesOptions.reduce((acc, { id, reasons: reasonArray = [] }) => {
      const reasonConfig = reasonArray.reduce(
        (allReasons, currentReason) => ({
          ...allReasons,
          [currentReason.id]: { ...currentReason, parentStatusId: id, isOption: true },
        }),
        {},
      );
      return {
        ...acc,
        ...reasonConfig,
      };
    }, {});

    return {
      steps,
      statuses,
      reasons,
    };
  },
);
const getOptionsStatus = (state: RootState): Status => state.options.status;

/* EXPORTS */
const selectors = {
  ...genericSelectors,
  selectOption,
  selectEntitiesOptions,
  getOptionsStatus,
  getStatsCheckpointsOptions,
};
const allActions = {
  ...actions,
  fetchAvailableOptions,
  fetchAvailableContextKeys,
};

export { allActions as actions, selectors };
export default reducer;
