// @flow

import type { RootState } from '../../store';
import type { Snapshot } from './types';
import type { PayloadAction } from '@reduxjs/toolkit';
// $FlowFixMe
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { retrieveSnapshots as retrieveSnapshotsAPI } from './api';

const snapshotAdapter = createEntityAdapter({
  selectId: (snapshot: Snapshot) => snapshot.snapshotId,
});

const initialState = snapshotAdapter.getInitialState({
  error: null,
  status: 'NONE',
});

/* ACTIONS & REDUCERS */
const retrieveSnapshots = createAsyncThunk(
  'snapshots/FETCH_SNAPSHOTS',
  async ({ account, bot }: { account: string, bot: string }) => {
    const snapshots = await retrieveSnapshotsAPI(account, bot);

    return snapshots;
  },
);

const { actions, reducer } = createSlice({
  name: 'snapshots',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(retrieveSnapshots.pending, state => {
        state.status = 'IN_PROGRESS';
      })
      .addCase(retrieveSnapshots.fulfilled, (state, action: PayloadAction<Snapshot[]>) => {
        state.status = 'SUCCESS';
        snapshotAdapter.setAll(state, action.payload);
      })
      .addCase(retrieveSnapshots.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = 'ERROR';
        snapshotAdapter.setAll(state, []);
      });
  },
});

actions.retrieveSnapshots = retrieveSnapshots;

/* SELECTORS */
const isLoading = (state: RootState): boolean => state.snapshots.status === 'IN_PROGRESS';

const snapshotSelectors = snapshotAdapter.getSelectors(state => state.snapshots);

const selectors = {
  getList: snapshotSelectors.selectAll,
  getIds: snapshotSelectors.selectIds,
  isLoading,
};

/* EXPORTS */
export { actions, selectors };
export default reducer;
