// @flow

import type { PayloadAction } from '@reduxjs/toolkit';
import type { Saga } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import type { CheckResponse, Environment } from '@state/ducks/bots/types';
import { getUserSub } from '@api/cognito';
import logger from '@assets/js/calldesk-app-util/logger';
import { operations as botsOperations, selectors as botsSelectors } from '@state/ducks/bots';
import * as botsApi from '@state/ducks/bots/api';
import { selectors as uiSelectors } from '@state/ducks/ui';
import { monitoringCatchException } from '@state/monitoring-enhancer';

/**
 * Start the check saga where several checks will be runned before even starting the publish
 * endpoint
 * It's important to note that this saga will call the publish saga if it doesn't fails
 */
function* checkBotSaga({
  payload,
}: PayloadAction<{
  bot: string,
  tag: string,
  environment: Environment,
}>): Saga<any> {
  const { bot, environment, tag } = payload;
  const userSub: string = yield call(getUserSub);
  const accountId: string = yield select(uiSelectors.getSelectedAccount);
  if (!accountId) return;
  const snapshotId: string = yield select(botsSelectors.getLoadedSnapshotId, bot);

  yield put(botsOperations.resetDeployBotStatus(bot));
  yield put(botsOperations.checkBot(bot));

  // Run all checks before deploying
  try {
    const { errors }: CheckResponse = yield call(
      botsApi.runAllChecks,
      accountId,
      bot,
      tag,
      snapshotId,
      environment,
    );

    if (errors.length > 0) {
      yield put(botsOperations.checkBotError({ bot, errors }));
    } else {
      yield put(botsOperations.checkBotSuccess(bot));
    }
  } catch (err) {
    const errors = (err.response && err.response.errors) || [err.message];

    logger.error('Bots > Check before deploy > ', errors);
    yield put(botsOperations.checkBotError({ bot, errors }));
    monitoringCatchException({
      exception: err,
      userSub,
      location: 'state/sagas/bots/checkBotSaga',
      functionType: 'saga',
      params: { snapshotId, environment, tag },
      accountId,
      botName: bot,
    });
  }
}

export default checkBotSaga;
