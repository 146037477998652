// @flow
/* eslint react/require-default-props: 1 */

import * as React from 'react';

import { CDKCard, CDKSkeleton } from '@calldesk/components';

import { RadioCardGroup } from './components';

import './radio-card.css';

type RadioCardProps = {
  content?: Function,
  customClassName: string,
  isDisabled?: boolean,
  isSelected: boolean,
  label?: string,
  onClickTarget: Function,
  extra?: React.Node,
  isLoading?: boolean,
};

/**
 * Generic card used for radio selection (one amongs multiples)
 */
function RadioCard({
  content,
  customClassName,
  isSelected,
  isDisabled,
  isLoading,
  label,
  onClickTarget,
  extra,
}: RadioCardProps) {
  let fullClassName: string = 'radio-card';

  if (customClassName) fullClassName += ` ${customClassName}`;
  if (isSelected) fullClassName += ' selected';
  if (isDisabled) fullClassName += ' disabled';

  const handleClick = () => {
    if (!isDisabled) onClickTarget();
  };

  return (
    <CDKCard
      bodyStyle={{ display: 'flex', paddingBottom: '24px' }}
      className={fullClassName}
      title={label}
      onClick={handleClick}
      bordered={false}
      extra={extra}
    >
      {isLoading ? (
        <CDKSkeleton active loading={isLoading}>
          {content}
        </CDKSkeleton>
      ) : (
        content
      )}
    </CDKCard>
  );
}

RadioCard.Group = RadioCardGroup;

export type { RadioCardProps };
export default RadioCard;
