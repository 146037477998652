// @flow

import type { BotIdentity, Status } from '@state/ducks/app/types';
import type { Entity, UserInput } from '@state/ducks/bots/types';
import type {
  Asset,
  AssetScope,
  EntityAssetContent,
  EntityContentType,
} from '@state/ducks/customers-assets/types';

/** TYPES */
type EntityTurn = {
  ...Asset<EntityAssetContent, EntityContentType>,
  changed?: boolean,
  entityMask: string,
};

type Call = {|
  conversationStartTime: number,
  offset: number,
  sessionId: string,
|};

type ChunkTrained = UserInput;

type AnswerStatus = 'TRAINED' | 'TO_TRAIN' | 'DISCARDED';

type AnswerResult = {|
  entitiesTurnTrained?: ChunkTrained[],
  intentsTurnTrained?: ChunkTrained[],
  newStatus?: AnswerStatus,
|};

type Answer = {|
  answerId: string,
  calls: Call[],
  createdAt: number,
  customUtterance?: string,
  lastUpdatedAt: number,
  nbOccurences: number,
  notUnderstood: boolean,
  PK: string,
  result?: ?AnswerResult,
  SK: string,
  status: AnswerStatus,
  utterance: string,
|};
type AnswerChanged = {|
  answerId: string,
  lastUpdatedAt: number,
  nbOccurences: number,
  newStatus: AnswerStatus,
  oldStatus: AnswerStatus,
  result?: AnswerResult,
|};

type IntentTurnExpression = {|
  answerId?: string,
  origin: string,
  withEntityMasks?: string,
|};

type IntentTurn = {|
  changed?: boolean,
  domain: string,
  entities: $Shape<Entity>[],
  expressions?: IntentTurnExpression[],
  intentName: string,
  scope: AssetScope,
  type: 'turn' | 'other' | 'fallback',
|};

type Turn = {|
  id: string,
  intents: IntentTurn[],
  nbAnswerDiscarded: number, // Number of discarded unknowns since creation of bot
  nbAnswerToTrain: number, // Number of to_train unknowns since creation of bot
  nbAnswerTrained: number, // Number of trained unknowns since creation of bot
  question: string,
  unknowns: number, // Represent number of unknowns to train for given time range
|};

type TurnSelected = {|
  ...Turn,
  answers: Answer[],
  bulkSelectedAnswers: string[],
  currentAnswerIndex: number | null,
  currentEntityIndex: number,
  currentIntentIndex: number,
  entities: EntityTurn[],
  errorMessageAnswers: string,
  errorMessageEntities: string,
  errorMessageIntents: string,
  fetchingAnswers: Status,
  fetchingEntities: boolean,
  hasEntitiesLoadingError?: boolean,
  intents: IntentTurn[],
  nextAnswerForPagination?: string,
|};

type Notification = {|
  description?: string,
  message: string,
  type: 'error' | 'warn' | 'success' | '',
|};

type TrainingScore = {
  discarded: number,
  toTrain: number,
  trained: number,
};

type Training = {|
  currentTurn: $Shape<TurnSelected>,
  errorMessage: string,
  notification: ?Notification,
  range: {
    from: number,
    minOccurences: number,
    // to: number, // Might be used someday
  },
  status: Status,
  score: {
    ...TrainingScore,
    // NOTE: Keep impact on training score for each trained/discarded answer
    deltas: { [answerId: string]: number },
    // NOTE: Keep count of processed unknowns in order to display left toTrain unknowns count, without fetching turns again
    processed: { [turnId: string]: number },
    status: Status,
  },
  turns: Turn[],
|};

type FetchTurnRequestPayload = {|
  ...BotIdentity,
  turnParams: {
    after?: string,
    from: number,
    sort?: string,
    status?: string,
    turnId: string,
  },
|};

type FetchTurnAndEntitiesRequestPayload = {|
  ...BotIdentity,
  turn: TurnSelected | Turn,
|};

type ChangeTurnPayload = {|
  ...BotIdentity,
  answersFrom?: number,
  turnId: string,
|};

type ChangeCurrentEntityTurnPayload = {|
  accountId: string,
  fetchingReferential?: boolean,
  newEntityTurn: ?EntityTurn,
|};

type ChangeCurrentIntentTurnAnswer = {|
  newAnswer: ?Answer,
  newIntentTurn: ?IntentTurn,
|};

type ChangeCurrentEntityTurnAnswerPayload = {|
  accountId: string,
  fetchingReferential?: boolean,
  newAnswer: ?Answer,
  newEntityTurn: ?EntityTurn,
|};

type SaveTrainingPayload = {|
  ...BotIdentity,
  reload?: boolean,
|};

/** ACTION TYPES */

const INIT_TRAINING_SAGA: string = 'training/INIT_TRAINING_SAGA';

const FETCH_TURNS_SAGA: string = 'training/FETCH_TURNS_SAGA';

const FETCH_ANSWERS_BY_TURN_SAGA: string = 'training/FETCH_ANSWERS_BY_TURN_SAGA';
const SET_CURRENT_ANSWER_SAGA: string = 'training/SET_CURRENT_ANSWER_SAGA';

const CHANGE_TURN_SAGA: string = 'training/CHANGE_TURN_SAGA';

const SET_CURRENT_ENTITY_TURN_SAGA: string = 'training/SET_CURRENT_ENTITY_TURN_SAGA';

const SET_CURRENT_ENTITY_TURN_CURRENT_ANSWER_SAGA: string =
  'training/SET_CURRENT_ENTITY_TURN_CURRENT_ANSWER_SAGA';

const SET_CURRENT_INTENT_TURN_CURRENT_ANSWER_SAGA: string =
  'training/SET_CURRENT_INTENT_TURN_CURRENT_ANSWER_SAGA';

const SAVE_TRAINING_SAGA: string = 'training/SAVE_TRAINING_SAGA';

const FETCH_ENTITIES_AND_ANSWERS_BY_TURN_SAGA: string =
  'training/FETCH_ENTITIES_AND_ANSWERS_BY_TURN_SAGA';

export type {
  Answer,
  AnswerChanged,
  AnswerResult,
  AnswerStatus,
  Call,
  ChangeCurrentEntityTurnAnswerPayload,
  ChangeCurrentEntityTurnPayload,
  ChangeCurrentIntentTurnAnswer,
  ChangeTurnPayload,
  ChunkTrained,
  EntityTurn,
  FetchTurnAndEntitiesRequestPayload,
  FetchTurnRequestPayload,
  IntentTurn,
  IntentTurnExpression,
  Notification,
  SaveTrainingPayload,
  Training,
  TrainingScore,
  Turn,
  TurnSelected,
};

export {
  CHANGE_TURN_SAGA,
  FETCH_ANSWERS_BY_TURN_SAGA,
  FETCH_ENTITIES_AND_ANSWERS_BY_TURN_SAGA,
  FETCH_TURNS_SAGA,
  INIT_TRAINING_SAGA,
  SAVE_TRAINING_SAGA,
  SET_CURRENT_ANSWER_SAGA,
  SET_CURRENT_ENTITY_TURN_CURRENT_ANSWER_SAGA,
  SET_CURRENT_ENTITY_TURN_SAGA,
  SET_CURRENT_INTENT_TURN_CURRENT_ANSWER_SAGA,
};
