/* eslint-disable complexity */
// @flow

// $FlowFixMe
import type { SyntheticMouseEvent } from 'react';
import type { RouterHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useTour } from '@reactour/tour';

import { CDKLayout, CDKMenu } from '@calldesk/components';

import type { State } from '@state/ducks/app/types';
import * as cognito from '@api/cognito';
import calldeskStudioWhiteLogo from '@assets/img/calldesk-studio-white.svg';
import ability from '@assets/js/calldesk-app-util/casl/ability';
import { updateAbility } from '@assets/js/calldesk-app-util/casl/update-ability';
import { formatBotName } from '@assets/js/calldesk-app-util/format';
import {
  operations as accountsOperations,
  selectors as accountsSelectors,
} from '@state/ducks/accounts';
import { v3authorizations } from '@state/ducks/accounts/api';
import { selectors as administrationSelectors } from '@state/ducks/administration';
import { operations as botsOperations, selectors as botsSelectors } from '@state/ducks/bots';
import { canDeploy } from '@state/ducks/bots/utils';
import { selectors as onboardingSelectors } from '@state/ducks/onboarding';
import {
  operations as sessionOperations,
  selectors as sessionSelectors,
} from '@state/ducks/session';
import { operations as uiOperations, selectors as uiSelectors } from '@state/ducks/ui';

import AccountsSubMenu from './components/AccountsSubMenu';
import AdministrationComponent from './components/AdministrationSubMenu';
import BotActions from './components/BotActionsSubMenu';
import BotsSubMenu from './components/BotsSubMenu';
import OnboardingButton from './components/OnboardingButton';
import PersistActions from './components/PersistActions';
import AdministrationModal from '../AdministrationModal';
import DeleteBotModal from '../DeleteBotModal';
import DuplicateBotModal from '../DuplicateBotModal';
import NewAccountModal from '../NewAccountModal';
import CreateNewBotModal from '../NewBotModal';

import './navbar.css';

const { Item } = CDKMenu;
const { Header } = CDKLayout;

/**
 * Main Navbar
 */
function MainNavbar() {
  const match = useRouteMatch('/accounts/:account/bots/:bot');
  const { accountFromRoute, botFromRoute } = {
    accountFromRoute: match?.params.account ?? '',
    botFromRoute: match?.params.bot ?? '',
  };
  const location = useLocation();
  const dispatch = useDispatch();
  const history: RouterHistory = useHistory();
  const { setIsOpen } = useTour();

  const [showCreateBotModal, setShowCreateBotModal] = useState(false);
  const [showNewAccountModal, setShowNewAccountModal] = useState(false);
  const [showDeleteBotModal, setShowDeleteBotModal] = useState(false);
  const [showDuplicateBotModal, setShowDuplicateBotModal] = useState(false);
  const [showAdministrationModal, setShowAdministrationModal] = useState(false);

  const showPersistActions = location.pathname.replace(match?.url, '').includes('builder');

  const botConfigHasChanged: boolean = useSelector(state =>
    botsSelectors.botConfigHasChanged(state, botFromRoute),
  );
  const selectedAccount: string = useSelector<State, string>(uiSelectors.getSelectedAccount);
  const onboardingModalIsShown: boolean = useSelector<State, boolean>(
    uiSelectors.getShowOnboardingModal,
  );
  const botHasOnboarding: boolean = useSelector<State, boolean>(
    onboardingSelectors.selectBotHasOnboarding,
  );
  const accountIds: string[] = useSelector<State, string[]>(accountsSelectors.getAccounts);
  const bots: string[] = useSelector<State, string[]>(state =>
    accountsSelectors.getBots(state, selectedAccount),
  );
  const isAuthorizedToCreateAccount: boolean = useSelector<State, boolean>(state =>
    accountsSelectors.isAuthorizedOnPlatform(state, 'accounts.create'),
  );
  const isAuthorizedToCreateAccountStatus: string = useSelector<State, string>(state =>
    accountsSelectors.getPlatformAuthorizationStatus(state, 'accounts.create'),
  );
  const userID = useSelector(sessionSelectors.selectUserId);
  const userRoles = useSelector(state => administrationSelectors.selectUserRoles(state, userID));

  const checkPlatformAuthorization = async () => {
    const userSub: ?string = await cognito.getUserSub();
    dispatch(
      accountsOperations.checkPlatformAuthorization({
        userSub: userSub ?? '',
        right: 'accounts.create',
      }),
    );
  };

  const handleSelectAccount = (accountId: string) => {
    if (botConfigHasChanged) return;

    dispatch(uiOperations.updateSelectedAccount(accountId));
    dispatch(botsOperations.startGetBotsSaga({ history }));
  };
  const handleLogout = () => {
    dispatch(sessionOperations.logout({ history }));
  };

  const showSaveButton = ability.can('save', 'builder');
  const showDeployButton = canDeploy();

  /**
   * Handle toggle of onboarding modal
   */
  const handleShowOnboardingModal = (): void => {
    setIsOpen(false);
    dispatch(uiOperations.showOnboardingModal(!onboardingModalIsShown));
  };

  const correctAccount: string = accountFromRoute || selectedAccount || 'workspace';

  const onMenuActionClicked = ({
    key,
    domEvent,
  }: {
    key: string,
    domEvent: SyntheticMouseEvent,
  }): void => {
    domEvent.preventDefault();
    switch (key) {
      case 'botActions:duplicate':
        setShowDuplicateBotModal(true);
        break;
      case 'botsAction:delete':
        setShowDeleteBotModal(true);
        break;
      case 'accounts:create':
        setShowNewAccountModal(true);
        break;
      case 'bots:create':
        setShowCreateBotModal(true);
        break;
      case 'administration:userAccess':
        setShowAdministrationModal(true);
        break;
      case 'administration:logout':
        handleLogout();
        break;
      default:
    }
  };

  useEffect(() => {
    if (selectedAccount) {
      v3authorizations(selectedAccount).then(userRights => updateAbility(userRights));
    }
  }, [selectedAccount, userRoles]);

  const handleAccountsDropDownVisibleChange = async () => {
    if (isAuthorizedToCreateAccountStatus === 'NONE') {
      await checkPlatformAuthorization();
    }
  };

  return (
    <Header className="Navbar">
      <CDKMenu
        mode="horizontal"
        onOpenChange={handleAccountsDropDownVisibleChange}
        selectedKeys={[correctAccount, botFromRoute]}
        theme="dark"
        triggerSubMenuAction="click"
        className="navbar-menu"
        onClick={onMenuActionClicked}
      >
        <Item key="brand" className="navbar-menu-brand">
          <Link to="/">
            <img className="navbar-menu-brand-icon" src={calldeskStudioWhiteLogo} alt="calldesk" />
          </Link>
        </Item>

        <AccountsSubMenu
          key="accountsSubMenu"
          selectedAccount={selectedAccount || accountFromRoute}
          accountIds={accountIds}
          correctAccount={correctAccount}
          bots={bots}
          botFromRoute={botFromRoute}
          handleSelectAccount={handleSelectAccount}
          create={isAuthorizedToCreateAccount}
        />
        <BotsSubMenu
          key="botsSubMenu"
          formatBotName={formatBotName}
          botFromRoute={botFromRoute}
          bots={bots}
          selectedAccount={selectedAccount}
        />
        {showPersistActions && (
          <PersistActions
            key="persistActions"
            accountInUrl={accountFromRoute}
            botInUrl={botFromRoute}
            showSaveButton={showSaveButton}
            showDeployButton={showDeployButton}
          />
        )}

        {botHasOnboarding && (
          <OnboardingButton
            key="onboardingButton"
            handleShowOnboardingModal={handleShowOnboardingModal}
          />
        )}

        {botFromRoute && <BotActions key="botActions" />}

        <AdministrationComponent key="administration" />
      </CDKMenu>

      {showCreateBotModal && <CreateNewBotModal close={() => setShowCreateBotModal(false)} />}

      {showNewAccountModal && <NewAccountModal close={() => setShowNewAccountModal(false)} />}

      {showAdministrationModal && (
        <AdministrationModal close={() => setShowAdministrationModal(false)} />
      )}

      {showDeleteBotModal && (
        <DeleteBotModal
          accountId={selectedAccount}
          botName={botFromRoute}
          close={() => setShowDeleteBotModal(false)}
        />
      )}
      {showDuplicateBotModal && (
        <DuplicateBotModal
          accountId={selectedAccount}
          botName={botFromRoute}
          close={() => setShowDuplicateBotModal(false)}
        />
      )}
    </Header>
  );
}

export default MainNavbar;
