// @flow

import type { SubMenuProps } from 'antd/lib/menu';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Menu } from 'antd';

import ability from '@assets/js/calldesk-app-util/casl/ability';

const { SubMenu, Item } = Menu;

type Props = SubMenuProps;

function AdministrationSubMenuComponent(props: Props) {
  const { t } = useTranslation();

  return (
    <SubMenu
      {...props}
      className="AdministrationSubMenu"
      title={<AccountCircleIcon className="anticon cdkicon" style={{ marginBottom: '-4px' }} />}
      key="administration"
      theme="light"
    >
      {ability.can('create', 'administration.accounts') && (
        <Item key="administration:userAccess">
          <span>{t('navbar.AdministrationSubMenu.userAccess')}</span>
        </Item>
      )}
      <Item key="administration:logout">
        <span>{t('navbar.AdministrationSubMenu.logout')}</span>
      </Item>
    </SubMenu>
  );
}

export default AdministrationSubMenuComponent;
