// @flow

import type { StepType } from '@reactour/tour';

type ProductTourCookie = {
  template: string,
  play?: boolean,
};

type StepsMap = { [stepsName: string]: StepType[] };

const PRODUCT_TOUR_COOKIE_NAME: string = 'startProductTour';

export type { ProductTourCookie, StepsMap, StepType };
export { PRODUCT_TOUR_COOKIE_NAME };
