// @flow

import type { GraphData, Transition } from '../../types';
import _flatMap from 'lodash/flatMap';

/**
 * Indicates wether or not the bot config uses the previous intent data model
 */
function isIntentModelV1(botConfig: Object): boolean {
  const intents = Object.keys(botConfig.intents);
  if (botConfig.graphs) {
    const intentsInTransitions: boolean = _flatMap(
      Object.keys(botConfig.graphs).map((domain: string) => botConfig.graphs[domain]),
      (graph: GraphData) => graph.transitions,
    ).some(
      (transition: Transition) =>
        Array.isArray(transition.intents) &&
        transition.intents.length > 0 &&
        typeof transition.intents[0] === 'string',
    );
    return intentsInTransitions;
  }
  if (intents.length < 1) return false;
  return intents.every(intent => Array.isArray(botConfig.intents[intent]));
}

export default isIntentModelV1;
