// @flow

import type { ProductTourCookie } from './index.type';

import { PRODUCT_TOUR_COOKIE_NAME } from './index.type';

// simplify with a useLocalStorage cookie ?
const getProductTourCookie = (): ?ProductTourCookie => {
  const data: ?string = localStorage.getItem(PRODUCT_TOUR_COOKIE_NAME);
  return data ? JSON.parse(data) : null;
};

export default getProductTourCookie;
