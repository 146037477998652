// @flow

import type { Node } from 'react';
import React from 'react';
import { NavLink } from 'react-router-dom';

// eslint-disable-next-line import/extensions
import calldeskStudioLogo from '@assets/img/calldesk-studio.svg';

import './styles.css';

type Props = $Exact<{
  children: Node,
}>;

/**
 * @description Unauthenticated layout
 * @param {Object} props - Properties
 */
function Unauthenticated(props: Props) {
  return (
    <div className="LayoutContainer">
      <div className="LayoutLeftSection">
        <div className="Logo">
          <NavLink to="/login">
            <img src={calldeskStudioLogo} alt="CallDesk Logo" />
          </NavLink>
        </div>
        <div className="LayoutContent">{props.children}</div>
      </div>
      <div className="LayoutRightSection" />
    </div>
  );
}

export default Unauthenticated;
