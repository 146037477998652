// @flow

const COUNTRY_CODE_BY_CLOUD_REGION = {
  'eu-west-1': 'IE',
  'me-jeddah-1': 'SA',
};

/**
 * @summary The function takes a country code and returns the corresponding flag emoji.
 *
 * @param {string} countryCode (optional) - the 2 char country code
 * @returns {string} the Flag Emoji corresponding
 */
const getFlagEmoji = (countryCode?: string = ''): string =>
  countryCode && // $FlowFixMe : it exists https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DisplayNames/of
  new Intl.DisplayNames('en', { type: 'region', fallback: 'none' }).of(countryCode.toUpperCase())
    ? String.fromCodePoint(...[...countryCode.toUpperCase()].map(x => 0x1f1a5 + x.charCodeAt(0)))
    : '';

/**
 * This function returns the flag emoji for a given region.
 */
const getFlagForRegion = (region: string): string =>
  getFlagEmoji(COUNTRY_CODE_BY_CLOUD_REGION[region]);

/**
 * @summary The function returns the name of a country in a specified language based on its region.
 *
 * @param {string} region represents the Cloud region (ex: eu-west-1)
 * @param {string} lang is the language in which the country should be translated to
 * @returns The function `getRegionCountryNameInLang` takes in a `region` and a `lang` parameter and
 * returns the name of the country in the specified language
 */
const getRegionCountryNameInLang = (region: string, lang: string) => {
  // $FlowFixMe : it exists https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DisplayNames/of
  const intl = new Intl.DisplayNames([lang], { type: 'region' });
  return intl.of(COUNTRY_CODE_BY_CLOUD_REGION[region]);
};

export { COUNTRY_CODE_BY_CLOUD_REGION, getFlagEmoji, getFlagForRegion, getRegionCountryNameInLang };
