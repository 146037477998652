// @flow

import type { Node } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import _keys from 'lodash/keys';

import { CDKButton, CDKDivider, CDKModal, CDKTypography, useForm } from '@calldesk/components';

import type { State, Status } from '@state/ducks/app/types';
import type { BotApiError, Environment, Version } from '@state/ducks/bots/types';
import type { EnvironmentsVersions, SelectedEnvironments } from '@state/ducks/ui/types';
import ability from '@assets/js/calldesk-app-util/casl/ability';
import { operations as botsOperations, selectors as botsSelectors } from '@state/ducks/bots';
import { ENVIRONMENTS } from '@state/ducks/bots/utils';

import CheckResults from './components/CheckResults';
import DeployConfirmation from './components/DeployConfirmation';
import DeployForm from './components/DeployForm';
import ModulesList from './components/ModulesList';

import './deploy-popover.css';

type StepMeta = {
  disabled?: boolean,
  render: () => Node,
  subtitle?: string,
  title: string,
  withCancel?: boolean,
  withDividers?: boolean,
};

type Props = {|
  account: string,
  bot: string,
  checkStatus: Status,
  close: () => void,
|};

// the higher the number the more "important" an environment is
const RANKED_ENVIRONMENTS: { [env: Environment]: number } = {
  dev: 1,
  internalTest: 2,
  clientTest: 3,
  prod: 4,
};

/**
 * Deploy Popover Component
 */
function DeployPopover({ account, bot, checkStatus, close }: Props) {
  const dispatch = useDispatch();
  const [form] = useForm();
  const { t } = useTranslation();

  const [currentStep, setCurrentStep] = useState(0);
  const [environmentsVersions, setEnvironmentsVersions] = useState<EnvironmentsVersions>({});
  const [selectedEnvironments, setSelectedEnvironments] = useState<SelectedEnvironments>({});

  const { environment: lastSelectedEnv, version } = form.getFieldsValue(true);
  const impactedEnvironments: Environment[] = lastSelectedEnv
    ? _keys(selectedEnvironments).filter(
        (env: Environment) =>
          environmentsVersions[env] === version &&
          RANKED_ENVIRONMENTS[env] > RANKED_ENVIRONMENTS[lastSelectedEnv],
      )
    : [];

  const botVersions: Version[] = useSelector<State, Version[]>(state =>
    botsSelectors.getBotVersions(state, bot),
  );
  const botVersionsStatus: ?Status = useSelector(state =>
    botsSelectors.getBotSpecificStatus(state, bot, 'versions'),
  );
  const checkErrors: BotApiError[] = useSelector(state =>
    botsSelectors.getLastCheckErrors(state, bot),
  );
  const environmentVersionsStatus: Status = useSelector<State, Status>(state =>
    botsSelectors.getBotPhoneNumbersStatus(state, bot),
  );

  const resetCheckBotStatus = () => dispatch(botsOperations.resetCheckBotStatus(bot));
  const retrieveBotVersions = () => {
    if (botVersionsStatus === 'NONE') {
      dispatch(botsOperations.fetchBotVersions({ account, bot }));
    }
  };
  const runCheckBotSaga = (botVersion: string, environment: Environment) => {
    dispatch(botsOperations.startCheckBotSaga({ bot, tag: botVersion, environment }));
  };
  const runPublishBotSaga = (botVersion: string, environment: Environment) => {
    dispatch(
      botsOperations.startPublishBotSaga({ bot, tag: botVersion, environment, description: '' }),
    );
  };

  const isDeployingOnDev: boolean = lastSelectedEnv === 'dev';
  const hasImpactOnOtherEnvironments: boolean = ENVIRONMENTS.some(
    (env: Environment) => selectedEnvironments[env] && env !== lastSelectedEnv,
  );
  const isDeployingOnDevWithImpacts: boolean = isDeployingOnDev && hasImpactOnOtherEnvironments;
  const isImpactingEnvironments: boolean = impactedEnvironments.length > 0;

  /**
   * Handle call to parent's method `close`
   * In case we close the deployPopover and the check has been success, we reset view to deployForm
   */
  const handleClose = () => {
    if (checkStatus === 'SUCCESS') resetCheckBotStatus();
    if (checkStatus !== 'IN_PROGRESS') close();
  };

  const handleNextStep = (
    runCheck?: boolean,
    runConfirmation?: boolean,
    runDeploy?: boolean,
  ): void => {
    if (runCheck) {
      runCheckBotSaga(version, lastSelectedEnv);
      setCurrentStep(currentStep + 1);
    } else if (runConfirmation) {
      setCurrentStep(currentStep + 2);
    } else if (runDeploy) {
      handleClose();
      runPublishBotSaga(version, lastSelectedEnv);
    } else {
      // Case where we display confirmation after check step
      setCurrentStep(currentStep + 1);
    }
  };

  /**
   * Handle submit of form values
   */
  const handleSubmit = () => {
    switch (currentStep) {
      case 0:
        if (isDeployingOnDev && !isDeployingOnDevWithImpacts) {
          // NOTE: Case where deploy on dev is impactless, we run deploy directly
          handleNextStep(false, false, true);
        } else if (isDeployingOnDev) {
          // NOTE: Case where deploy on dev is impactful, we skip check and go to confirmation
          handleNextStep(false, true);
        } else {
          // NOTE: Case where deploy is not on dev, we go to check step
          handleNextStep(true);
        }
        break;
      case 1:
        if (isImpactingEnvironments) {
          // NOTE: After check step, case where deploy is impactful, we go to confirmation
          handleNextStep();
        } else {
          // NOTE: After check step, case where deploy is impactless, we run deploy directly
          handleNextStep(false, false, true);
        }
        break;
      default:
        handleNextStep(false, false, true);
    }
  };

  const deployStepsMeta: StepMeta[] = [
    {
      title: t('navbar.Deploy.Form.title'),
      subtitle: t('navbar.Deploy.Form.subtitle'),
      render: () => (
        <DeployForm
          account={account}
          bot={bot}
          botVersions={botVersions}
          environments={ENVIRONMENTS.filter(env => ability.can(env, 'builder.deploy'))}
          environmentsVersions={environmentsVersions}
          fetchingStatus={environmentVersionsStatus}
          form={form}
          selectedEnvironments={selectedEnvironments}
          setEnvironmentsVersions={setEnvironmentsVersions}
          setSelectedEnvironments={setSelectedEnvironments}
        />
      ),
      disabled: !lastSelectedEnv || !version,
    },
    {
      title: t('navbar.Deploy.CheckResults.title'),
      render: () => <CheckResults checkErrors={checkErrors} checkStatus={checkStatus} />,
      withDividers: true,
      disabled: checkStatus === 'IN_PROGRESS' || checkStatus === 'ERROR',
    },
    {
      title: t('navbar.Deploy.Confirmation.title'),
      render: () => (
        <DeployConfirmation impactedEnvironments={impactedEnvironments} version={version} />
      ),
      withCancel: true,
      withDividers: true,
    },
  ];

  const getSubmitButtonLabel = () => {
    if (currentStep === 0) return isDeployingOnDev ? 'Deploy' : 'Check';
    return 'Deploy';
  };

  useEffect(() => {
    // In case check status is SUCCESS, ERROR or WARNING, we display directly the results of check
    if (checkStatus !== 'NONE') setCurrentStep(1);
    retrieveBotVersions();
  }, []);

  return (
    <CDKModal
      width={320}
      className="DeployPopover"
      onCancel={handleClose}
      closable
      footer={null}
      visible
    >
      {currentStep === 0 && (
        <>
          <ModulesList />
          <CDKDivider />
        </>
      )}
      <CDKTypography.Title level={5} strong>
        {deployStepsMeta[currentStep].title}
      </CDKTypography.Title>
      {environmentVersionsStatus === 'ERROR' && <p>{t('navbar.Deploy.Form.Errors.fetching')}</p>}
      {deployStepsMeta[currentStep].subtitle && <p>{deployStepsMeta[currentStep].subtitle}</p>}
      {deployStepsMeta[currentStep].withDividers && <CDKDivider />}
      {deployStepsMeta[currentStep].render()}
      {deployStepsMeta[currentStep].withDividers && <CDKDivider />}
      <div className="ActionsContainer">
        {deployStepsMeta[currentStep].withCancel && (
          <CDKButton type="danger" onClick={() => handleClose()}>
            {t('input.label.cancel')}
          </CDKButton>
        )}
        <CDKButton
          type="primary"
          onClick={handleSubmit}
          id="DeployFormSubmitButton"
          icon={currentStep === 0 && !isDeployingOnDev ? <AutorenewIcon /> : <CloudUploadIcon />}
          disabled={deployStepsMeta[currentStep].disabled}
        >
          {getSubmitButtonLabel()}
        </CDKButton>
      </div>
    </CDKModal>
  );
}

export default React.memo<Props>(DeployPopover);
