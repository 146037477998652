// @flow

import type { BotConfig } from '@state/ducks/bots/types';
import type { ValidateFnReturn } from '@state/ducks/onboarding/types';

/**
 * Validate `addConfirmAndInfirmIntent` quest
 */
function validate(botConfig: BotConfig): ValidateFnReturn<void> {
  return {
    validated: ['Confirm', 'Infirm'].every((systemIntent: string) =>
      botConfig?.intents?.includes(systemIntent),
    ),
  };
}

export default validate;
