// @flow
import type { Status } from '../app/types';
// $FlowFixMe
import type { EntityState } from '@reduxjs/toolkit';

const FETCH_RECORDING = 'recordings/FETCH_RECORDING';
const FETCH_RECORDING_SUCCESS = 'recordings/FETCH_RECORDING_SUCCESS';
const FETCH_RECORDING_ERROR = 'recordings/FETCH_RECORDING_ERROR';
const RESET_RECORDINGS = 'recordings/RESET_RECORDINGS';

// TODO: Delete when only using new calls experience
type RecordingApiResource = {
  url: string,
  expirationDate: string,
};

type RecordingPayload = {
  url: string,
  expirationDate: string,
};

type RecordingsApiPayload = {
  mainRecording: RecordingPayload,
  postTransferRecording?: RecordingPayload,
};

type RecordingsApiResponse = {
  status: number,
  code: string,
  payload: RecordingsApiPayload,
};

type Recording = {
  isFetching: boolean,
  error: string | null,
  mainRecording: RecordingPayload,
  postTransferRecording?: RecordingPayload,
  sessionId: string,
};

type Recordings = EntityState<Recording> & { status: Status };

export type {
  Recording,
  RecordingApiResource,
  RecordingPayload,
  Recordings,
  RecordingsApiPayload,
  RecordingsApiResponse,
};

export { FETCH_RECORDING, FETCH_RECORDING_ERROR, FETCH_RECORDING_SUCCESS, RESET_RECORDINGS };
