// @flow

import type { PayloadAction } from '@reduxjs/toolkit';
import type { Saga } from 'redux-saga';
import moment from 'moment';
import { all, call, put, select } from 'redux-saga/effects';

import type { BotConfig, BotPhoneNumbers, Version } from '@state/ducks/bots/types';
import type { ConversationsApiListPayload } from '@state/ducks/conversations/types';
import type { Exercise, ValidatorData } from '@state/ducks/onboarding/types';
import type { AvailableOptions } from '@state/ducks/options/types';
import logger from '@assets/js/calldesk-app-util/logger';
import {
  api as botsApi,
  operations as botsOperations,
  selectors as botsSelectors,
} from '@state/ducks/bots';
import { api as conversationsApi } from '@state/ducks/conversations';
import {
  operations as onboardingOperations,
  selectors as onboardingSelectors,
} from '@state/ducks/onboarding';
import { api as optionsApi } from '@state/ducks/options';

import { getBotConfigSaga } from '../bots';

/**
 * Check quests in user's progression
 */
function* runValidatorOnboardingSaga({
  payload,
}: PayloadAction<{
  account: string,
  bot: string,
  pathToSpecificQuest?: string,
}>): Saga<any> {
  const { account, bot, pathToSpecificQuest } = payload;
  let availableOptions: AvailableOptions;

  yield put(
    onboardingOperations.setSpecificStatus({ statusName: 'validator', statusValue: 'IN_PROGRESS' }),
  );

  try {
    const from: number = moment().startOf('day').valueOf();
    const to: number = moment().startOf('minute').valueOf();
    // Fetch some data useful in order to valid some quests
    const {
      availableOptions: availableOptionsFetched,
      botVersions,
      botPhoneNumbers,
      calls,
    }: {
      availableOptions: AvailableOptions,
      botVersions: Version[],
      botPhoneNumbers: BotPhoneNumbers,
      calls: ConversationsApiListPayload,
    } = yield all({
      availableOptions: call(optionsApi.fetchAvailableOptions, {
        accountId: account,
        botName: bot,
        // "voices" is currently the only options required by the current quests
        filters: { optionsName: ['voices'] },
      }),
      botVersions: call(botsApi.fetchVersionsForBot, account, bot),
      botPhoneNumbers: call(botsApi.loadBotPhoneNumbers, account, bot),
      calls: call(conversationsApi.fetchConversations, account, bot, from, to, null, 10, 0),
    });

    availableOptions = availableOptionsFetched;

    const currentValidatorData: ValidatorData = yield select(
      onboardingSelectors.selectValidatorData,
    );
    yield put(
      onboardingOperations.setValidatorData({
        ...currentValidatorData,
        calls: {
          ...currentValidatorData.calls,
          count: calls.count,
        },
        lastDeploy: botVersions,
        phoneNumbers: botPhoneNumbers,
      }),
    );
  } catch (error) {
    logger.error(
      'saga/onboarding [runValidatorOnboardingSaga] - fetching conversations error:',
      error.message,
    );
  }

  try {
    let botConfig: BotConfig = yield select(botsSelectors.getBotConfig, bot);

    // Fetch "botConfig" if is undefined, (i.e: run validator from calls page)
    if (!botConfig || !botConfig.graphs || Object.keys(botConfig.graphs).length === 0) {
      const botsFetchingStatus: boolean = yield select(
        botsSelectors.getSpecificBotsStatus,
        'fetching',
      );

      yield call(
        getBotConfigSaga,
        botsOperations.startGetBotConfigSaga({
          account,
          bot,
          botsFetched: botsFetchingStatus !== 'NONE',
          snapshotId: 'latest',
        }),
      );
      botConfig = yield select(botsSelectors.getBotConfig, bot);
    }

    const progression: Exercise = yield select(onboardingSelectors.selectProgression);
    const validatorData: ValidatorData = yield select(onboardingSelectors.selectValidatorData);
    yield put(
      onboardingOperations.validatingProgression({
        botConfig,
        pathToSpecificQuest,
        progression,
        validatorData,
        availableOptions,
      }),
    );
    yield put(
      onboardingOperations.setSpecificStatus({ statusName: 'validator', statusValue: 'SUCCESS' }),
    );
  } catch (error) {
    yield put(
      onboardingOperations.setSpecificStatus({
        statusName: 'validator',
        statusValue: 'ERROR',
        error: error.message,
      }),
    );
  }
}

export default runValidatorOnboardingSaga;
