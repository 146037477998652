// @flow

import type { ApiResponse } from '@api/index.type';
import type { Exercise, Onboarding } from '@state/ducks/onboarding/types';
import request from '@amplify/api/request';
import logger from '@assets/js/calldesk-app-util/logger';

const createUserOnboarding = async (
  userId: string,
  accountId: string,
  botName: string,
  exerciseId: string,
): Promise<?Onboarding> => {
  try {
    const res: ApiResponse<Onboarding> = await request.usersApi({
      method: 'POST',
      path: `/users/${userId}/accounts/${accountId}/bots/${botName}/exercise/${exerciseId}/onboarding`,
    });
    logger.info('state/onboarding/api [createUserOnboarding] data: ', res);
    return res.payload;
  } catch (error) {
    logger.error('state/onboarding/api [createUserOnboarding] error: ', error);
    throw error;
  }
};

const getUserOnboarding = async (
  userId: string,
  accountId: string,
  botName: string,
  exerciseId: string,
): Promise<?Onboarding> => {
  try {
    const res: ApiResponse<Onboarding> = await request.usersApi({
      method: 'GET',
      path: `/users/${userId}/accounts/${accountId}/bots/${botName}/exercise/${exerciseId}/onboarding`,
    });
    logger.info('state/onboarding/api [getUserOnboarding] data: ', res);
    return res.payload;
  } catch (error) {
    logger.error('state/onboarding/api [getUserOnboarding] error: ', error);
    throw error;
  }
};

const updateUserOnboarding = async (
  userId: string,
  accountId: string,
  botName: string,
  exerciseId: string,
  progression: Exercise,
): Promise<?Onboarding> => {
  try {
    const res: ApiResponse<Onboarding> = await request.usersApi({
      method: 'PUT',
      path: `/users/${userId}/accounts/${accountId}/bots/${botName}/exercise/${exerciseId}/onboarding`,
      body: { progression },
    });
    logger.info('state/onboarding/api [updateUserOnboarding] data: ', res);
    return res.payload;
  } catch (error) {
    logger.error('state/onboarding/api [updateUserOnboarding] error: ', error);
    throw error;
  }
};

const deleteUserOnboarding = async (
  userId: string,
  accountId: string,
  botName: string,
  exerciseId: string,
): Promise<void> => {
  try {
    await request.usersApi({
      method: 'DELETE',
      path: `/users/${userId}/accounts/${accountId}/bots/${botName}/exercise/${exerciseId}/onboarding`,
    });
    logger.info('state/onboarding/api [deleteUserOnboarding] onboarding is correctly deleted');
  } catch (error) {
    logger.error('state/onboarding/api [deleteUserOnboarding] error: ', error);
    throw error;
  }
};

export { createUserOnboarding, deleteUserOnboarding, getUserOnboarding, updateUserOnboarding };
