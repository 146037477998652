// @flow

import type { MonitoringCatchExceptionArg } from '../index.type';
import { captureException } from '@sentry/react';

const monitoringCatchException = ({
  message,
  exception,
  location,
  functionType,
  params,
  userSub,
  accountId,
  botName,
  others,
}: MonitoringCatchExceptionArg): void => {
  // NOTE: Type CaptureContext does not seem to be exported from sentry, when available feel free to replace the any
  const context: any = {
    tags: {
      location,
      functionType,
    },
  };

  if (accountId) context.tags.accountId = accountId;
  if (botName) context.tags.botName = botName;
  if (userSub) context.userSub = { id: userSub };
  if (params) context.extra = { ...context.extra, params };
  if (others) context.extra = { ...context.extra, others };

  captureException(exception || message, context);
};

export default monitoringCatchException;
