// @flow

import type { Session } from './types';

import type { State } from '@state/ducks/app/types';
import { SENSITIVE_DATA } from '@state/monitoring-enhancer/index.type';

const sessionStateMonitoring = (state: State): Session =>
  // $FlowFixMe we purposely modify the state shape for monitoring
  Object.keys(state.session).reduce(
    (session: Session, key: string) => ({
      ...session,
      [key]: ['checkSessionTokenError', 'loginError'].includes(key)
        ? state.session[key]
        : SENSITIVE_DATA,
    }),
    {},
  );

export default sessionStateMonitoring;
