// @flow
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import InfoIcon from '@material-ui/icons/Info';

import {
  CDKButton,
  CDKCheckbox,
  CDKCol,
  CDKDivider,
  CDKList,
  CDKRow,
  CDKSwitch,
  CDKTooltip,
  CDKTypography,
} from '@calldesk/components';

import type { Role } from '@state/ducks/administration/types';
import { selectors as adminSelectors } from '@state/ducks/administration';

type Props = {
  buildProdRole: ?Role,
  currentRoles: ?(number[]),
  currentUsername: string,
  grantAllRoles: () => void,
  grantRole: (role: Role) => void,
  personalDataRole: ?Role,
  roles: Role[],
  selectedHimself: boolean,
};

/**
 * Roles Manager component
 */
function RolesManager({
  buildProdRole,
  currentRoles,
  currentUsername,
  grantAllRoles,
  grantRole,
  personalDataRole,
  roles,
  selectedHimself,
}: Props) {
  const { t } = useTranslation();

  const [loadingItemValue, setLoadingItemValue] = useState<?string>(null);

  const canDeployToProd = currentRoles?.includes(buildProdRole?.id);
  const canDownloadPersonalData = currentRoles?.includes(personalDataRole?.id);
  const hasAllRoles = currentRoles?.length === roles.length;
  const hasRole = item => currentRoles?.includes(item.id);
  const isLoading: boolean = useSelector(adminSelectors.isLoading);

  const ignoreRoleNamesInList: string[] = [
    ...(buildProdRole ? [buildProdRole?.name] : []),
    ...(personalDataRole ? [personalDataRole?.name] : []),
  ];
  return (
    <>
      <div className="CurrentUserInformation">
        <div className="CurrentUsername">
          <b>{currentUsername}</b>
          <br />
          <span>
            {t('commons.roles', { ns: 'administration' })} (
            {(currentRoles && currentRoles.length) || 0})
          </span>
        </div>
        <CDKButton
          ghost={hasAllRoles}
          type="primary"
          onClick={() => grantAllRoles()}
          loading={isLoading && !loadingItemValue}
        >
          {t(hasAllRoles ? 'RolesManager.Rights.DisableAll' : 'RolesManager.Rights.ActivateAll', {
            ns: 'administration',
          })}
        </CDKButton>
      </div>
      <CDKDivider />
      <CDKList
        dataSource={roles}
        renderItem={(role: Role) =>
          !ignoreRoleNamesInList.includes(role.name) && (
            <CDKList.Item key={role.id} onClick={event => setLoadingItemValue(event.target.id)}>
              <CDKList.Item.Meta
                avatar={
                  <CDKTooltip
                    overlayStyle={{ zIndex: 999999 }}
                    title={role.description?.split('.').map((sentence, key) => (
                      <p key={key}>{sentence}</p>
                    ))}
                    key={role.id}
                  >
                    <InfoIcon />
                  </CDKTooltip>
                }
                title={
                  <CDKRow align="middle" justify="space-between" gutter={[16, 16]}>
                    <CDKCol span={6}>
                      <CDKTypography.Text strong={hasRole(role)}>{role.name}</CDKTypography.Text>
                    </CDKCol>
                    <CDKCol span={15}>
                      {role.name === 'Build' && (
                        <CDKCheckbox
                          disabled={!hasRole(role)}
                          checked={canDeployToProd}
                          onChange={() => buildProdRole && grantRole(buildProdRole)}
                        >
                          <CDKTypography.Text type="secondary" strong>
                            {t('RolesManager.Rights.CanDeployToProd', {
                              ns: 'administration',
                            })}
                          </CDKTypography.Text>
                        </CDKCheckbox>
                      )}
                      {role.name === 'Monitoring' && (
                        <CDKCheckbox
                          disabled={!hasRole(role)}
                          checked={canDownloadPersonalData}
                          onChange={() => personalDataRole && grantRole(personalDataRole)}
                        >
                          <CDKTypography.Text type="secondary" strong>
                            {t('RolesManager.Rights.CanDownloadRecordings', {
                              ns: 'administration',
                            })}
                          </CDKTypography.Text>
                        </CDKCheckbox>
                      )}
                    </CDKCol>
                    <CDKCol span={3}>
                      <CDKSwitch
                        id={role.name}
                        className="default-switch-checked"
                        checked={hasRole(role)}
                        onChange={() => {
                          grantRole(role);
                          setLoadingItemValue(null);
                        }}
                        disabled={role.name === 'Admin' && selectedHimself}
                        loading={isLoading && loadingItemValue === role.name}
                      />
                    </CDKCol>
                  </CDKRow>
                }
              />
            </CDKList.Item>
          )
        }
      />
    </>
  );
}

export default RolesManager;
