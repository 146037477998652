// @flow

import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

import { CDKSpin } from '@calldesk/components';

import './loading-spinner.css';

type IconProps = {
  size?: number,
  color?: string,
  thickness?: number,
};

type LoadingSpinnerProps = IconProps & {
  pageCentered?: boolean,
};

const antIcon = (props: IconProps) => (
  <LoadingOutlined
    style={{
      fontSize: props.size ?? 24,
      fontWeight: (props.thickness ?? 400) * 100,
      color: props.color ?? '#073C4C',
    }}
    spin
  />
);

/**
 * Loading spinner to display when pages are loading.
 */
function LoadingSpinner(props: LoadingSpinnerProps) {
  const { pageCentered, ...otherProps } = props;

  return (
    <div className={`LoadingSpinner${pageCentered ? ' page-centered' : ''}`}>
      <CDKSpin indicator={antIcon(otherProps)} />
    </div>
  );
}

/**
 * Loading Spinner centered on page
 */
function Centered() {
  return (
    <LoadingSpinner
      className="Calls_LazyLoading"
      size={80}
      thickness={5}
      color="#2b8abd"
      pageCentered
    />
  );
}

LoadingSpinner.Centered = Centered;

export default LoadingSpinner;
