// @flow

import type { BotConfig, Version } from '@state/ducks/bots/types';
import type { Exercise, ValidateFnReturn, ValidatorData } from '@state/ducks/onboarding/types';

/**
 * Validate `saveAndDeployBotWithName` quest
 */
function validate(
  botConfig?: BotConfig,
  progression: Exercise,
  validatorData: ValidatorData,
): ValidateFnReturn<void> {
  const lastDeploy: ?(Version[]) = validatorData?.lastDeploy || [];

  return {
    validated:
      !!lastDeploy &&
      lastDeploy.length > 0 &&
      lastDeploy.some(
        (itemVersion: Version) => itemVersion.version && itemVersion.version === 'dev',
      ),
  };
}

export default validate;
