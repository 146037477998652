// @flow

import type { Action } from '@state/ducks/app/types';
import { TOO_LARGE_ACTION_TYPES as onboardingTooLargeActionTypes } from '@state/ducks/onboarding/actions-monitoring';
import { TOO_LARGE_ACTION_TYPES as optionsTooLargeActionTypes } from '@state/ducks/options/actions-monitoring';
import { SENSITIVE_ACTION_TYPES as sessionSensitiveActionTypes } from '@state/ducks/session/actions-monitoring';
import { SENSITIVE_ACTION_TYPES as transcriptsSensitiveActionTypes } from '@state/ducks/transcripts/actions-monitoring';

import { SENSITIVE_DATA, TOO_LARGE_DATA } from '../index.type';

const sensitiveActionTypes: string[] = [
  ...sessionSensitiveActionTypes,
  ...transcriptsSensitiveActionTypes,
];

const tooLargeActionTypes: string[] = [
  ...onboardingTooLargeActionTypes,
  ...optionsTooLargeActionTypes,
];

const actionTransformer = (action: Action): { [actionType: string]: Action | string } => {
  if (sensitiveActionTypes.includes(action.type)) {
    return { [action.type]: SENSITIVE_DATA };
  }
  if (tooLargeActionTypes.includes(action.type)) {
    return { [action.type]: TOO_LARGE_DATA };
  }
  return { [action.type]: { ...action } };
};

export default actionTransformer;
