// @flow

import type { ProductTourCookie, StepsMap, StepType } from './index.type';

import stepsMap from '../steps';
import getProductTourCookie from './get-product-tour-cookie';

/**
 * It returns steps of product tour according to cookie
 * @param {(element: ElementOnScriptSelected | null) => void} zoomOnNodeId - In order to zoom on element selected
 * @param {string} mainDomain - Main domain of current bot
 */
const getStepsTourByCookie = (
  setSelectedNodeId: (nodePath: ?string) => void,
  mainDomain: string,
): StepType[] => {
  const productTourCookie: ?ProductTourCookie = getProductTourCookie();
  if (productTourCookie) {
    const steps: StepsMap = stepsMap(setSelectedNodeId, mainDomain);
    const hasTour: boolean =
      productTourCookie && Object.keys(steps).includes(productTourCookie.template);
    return hasTour ? steps[productTourCookie.template] : [];
  }
  return [];
};

export default getStepsTourByCookie;
