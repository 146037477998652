/* eslint-disable max-lines */
// @flow

import type { Exercise } from '@state/ducks/onboarding/types';

const createSimpleBotWithTransfer: Exercise = {
  label: 'Deploy and call your first callbot!',
  categories: {
    builder: {
      label: 'Builder',
      steps: {
        createIntroduction: {
          description: 'Design callbot script',
          quests: {
            createSayAction: {
              label: 'Add a welcome message',
              subQuests: [
                {
                  label:
                    'Drag a <spanLink>Say action</spanLink> from the top of the <spanLink>actions palette</spanLink> and drop it next to the <spanLink>Origin node</spanLink>',
                },
                {
                  label:
                    'Drag a <spanLink>transition</spanLink> from the <spanLink>Origin node</spanLink> to the <spanLink>Say action</spanLink>',
                },
                {
                  label:
                    'Click on the <spanLink>Say action</spanLink> and write “Hello world!” in the <spanLink>configuration panel</spanLink>',
                },
                {
                  label:
                    'Close the <spanLink>configuration panel</spanLink> and click on <spanLink>Verify</spanLink>',
                },
              ],
            },
            addSayActionClosedQuestion: {
              label: 'Add a question after the welcome message',
              subQuests: [
                {
                  label:
                    'Insert another <spanLink>Say action</spanLink> below the “Hello world!” <spanLink>Say action</spanLink>',
                },
                {
                  label:
                    'Click on the new <spanLink>Say action</spanLink> and write “Are you feeling good?” (or a similar Yes/No question) in the <spanLink>configuration panel</spanLink>',
                },
                {
                  label:
                    'Close the <spanLink>configuration panel</spanLink> and click on <spanLink>Verify</spanLink>',
                },
              ],
            },
            add2SayActionsNegativeAndPositive: {
              label: 'Add reactions to the caller’s answer',
              subQuests: [
                {
                  label:
                    'Insert two new <spanLink>Say actions</spanLink> on each side of the “Are you feeling good?” <spanLink>Say action</spanLink>',
                },
                {
                  label:
                    'Write “Happy to hear that!” (or something similar) in the first empty <spanLink>Say action</spanLink>. This one will be used in case of a positive answer',
                },
                {
                  label:
                    'Write “Oh, I’m sorry to hear that” (or something similar) in the remaining empty <spanLink>Say action</spanLink>. This one will be used in case of a negative answer',
                },
                {
                  label:
                    'Close the <spanLink>configuration panel</spanLink> and click on <spanLink>Verify</spanLink>',
                },
              ],
            },
            linkSayActionIntroToClosedQuestion: {
              label: 'Start linking the actions together',
              subQuests: [
                {
                  label:
                    'Drag a <spanLink>transition</spanLink> from the “Hello world!” Say action to the “Are you feeling good?” Say action',
                },
                {
                  label: 'Click on <spanLink>Verify</spanLink>',
                },
              ],
            },
            linkSayActionClosedQuestionToNegativeAndPositive: {
              label: 'Keep linking!',
              subQuests: [
                {
                  label:
                    ' Drag a <spanLink>transition</spanLink> from the “Are you feeling good?” <spanLink>Say action</spanLink> to the “Happy to hear that!” <spanLink>Say action</spanLink> ',
                },
                {
                  label:
                    ' Do the same for the “Oh, I’m sorry to hear that” <spanLink>Say action</spanLink>.',
                },
                {
                  label: 'Click on <spanLink>Verify</spanLink>',
                },
              ],
            },
            addConfirmAndInfirmIntent: {
              label: 'Add intents to the bot’s knowledge',
              subQuests: [
                {
                  label:
                    'Go to the <spanLink>Intents section</spanLink> using the <spanLink>navigation panel</spanLink> on the left',
                },
                {
                  label:
                    'Click on the <spanLink>“Add intent”</spanLink> button and select the <spanLink>“Confirm”</spanLink> and <spanLink>“Infirm”</spanLink> system intents',
                },
                {
                  label: 'Click on <spanLink>Verify</spanLink>',
                },
              ],
            },
            addTransitionsToIntents: {
              label: 'Put intents on transitions to create the logic',
              subQuests: [
                {
                  label:
                    'Go back to <spanLink>Script section</spanLink> using the <spanLink>navigation panel</spanLink>',
                },
                {
                  label:
                    'Click on the <spanLink>transition</spanLink> going from “Are you feeling good?” to “Happy to hear that!”',
                },
                {
                  label:
                    'In the <spanLink>configuration panel</spanLink>, click on the <spanLink>Intents dropdown</spanLink>, select the Intent <spanLink>“Confirm”</spanLink> and click on the <spanLink>“+”</spanLink> button',
                },
                {
                  label:
                    'Do the same for the <spanLink>transition</spanLink> going from “Are you feeling good?” to “Oh, I’m sorry to hear that.”, (or whatever you put for both), this time selecting the <spanLink>“Infirm”</spanLink> intent',
                },
                {
                  label:
                    'Close the <spanLink>configuration panel</spanLink> and click on <spanLink>Verify</spanLink>',
                },
              ],
            },
            addGoodByeMessage: {
              label: 'Add goodbye message',
              subQuests: [
                {
                  label:
                    'Insert a new <spanLink>Say action</spanLink> at the bottom of your script',
                },
                {
                  label:
                    'Click on the new <spanLink>Say action</spanLink> and write “Thanks for calling, I hope you have a great day.” (or something similar) in the <spanLink>configuration panel</spanLink>',
                },
                {
                  label:
                    'Drag a <spanLink>transition</spanLink> from “Happy to hear that!” (or whatever you put) to the new <spanLink>Say action</spanLink>',
                },
                {
                  label:
                    'Do the same from “Oh, I’m sorry to hear that.” (or whatever you put) to the new <spanLink>Say action</spanLink>',
                },
                {
                  label: 'Click on <spanLink>Verify</spanLink>',
                },
              ],
            },
            wrapUpTheScript: {
              label: 'Wrap up the script',
              subQuests: [
                {
                  label:
                    'Drag a <spanLink>Done action</spanLink> from the bottom of the <spanLink>actions palette</spanLink> and drop it at the bottom of your script',
                },
                {
                  label:
                    'Drag a <spanLink>transition</spanLink> from the “Thanks for calling, I hope you have a great day” (or whatever you put) <spanLink>Say action</spanLink> to the <spanLink>Done action</spanLink>',
                },
                {
                  label: 'Click on <spanLink>Verify</spanLink>',
                },
              ],
            },
            saveBotIntroStep: {
              label: 'Save the bot for safety',
            },
          },
        },
        createTransferBranch: {
          description: 'Create a transfer branch (call exploitation)',
          quests: {
            addFirstNameAndLastNameModules: {
              label:
                'Following the Say action of the negation, add "lastname" & "firstname" with "calldesk-modules" node in v1, link them following the Say action then between them',
            },
            preventTransferWithSayAction: {
              label:
                'Add a Say action to warn of the transfer and say goodbye using the retrieved last name and title (Mr./Ms.) present in the context, linking it from the previous module',
            },
            addTransferCallAction: {
              label:
                'Add a Transfer action with a number to which to transfer the call, link it to the previous Say',
            },
            addDoneActionTransferStep: {
              label: 'Add a Done action, link it to the previous Transfer Action',
            },
            saveBotTransferStep: {
              label: 'Save the bot for safety',
            },
          },
        },
        addDissuasionBranch: {
          description: 'Create a Dissuasion branch (future automation branch)',
          quests: {
            addSayToAskReasonPositiveCase: {
              label:
                'Add a Say to ask the reason for the call in case of a positive answer to the Yes/No question',
            },
            add3IntentsAndUtterances: {
              label: 'Create 3 intentions in the Intents section and add utterances',
            },
            add3SayActionsFor3Intents: {
              label:
                'Create 3 Say actions that include the possible reasons for appeal corresponding to each intention created previously, and link them to the Say action from the previous question',
            },
            addIntentsOnCorrespondingTransitions: {
              label: 'Add the intentions on the corresponding transitions',
            },
            addFinalSayActionGoodBye: {
              label: 'Create a final Say actionto say goodbye and link it to the previous Say',
            },
            addDoneActionDissuasionStep: {
              label: 'Add a Done action and link it to the previous Say action',
            },
            addGetCurrentDayPartAction: {
              label:
                'Add a Studio Action "Get current part of the day" at the beginning of the script',
            },
            addEndingSayAction: {
              label:
                'In the Say actionending conversation in the 2 branches, using "Good {dayPart}" to adapt to the time of day',
            },
            saveBotCurrentDatePartStep: {
              label: 'Save the bot for safety',
            },
          },
          others: {
            dayPart: 'Good {dayPart}',
          },
        },
        settings: {
          description: 'Prepare the bot for deployment over the phone',
          quests: {
            addChooseVoice: {
              label: 'Choose the voice of your callbot',
              subQuests: [
                {
                  label:
                    'Go to the <spanLink>Settings section</spanLink> using the <spanLink>navigation panel</spanLink>',
                },
                {
                  label:
                    'Select the <spanLink>“bot voice”</spanLink> dropdown to change the callbot’s voice. Google Wavenet voices provide the most realistic experience',
                },
                {
                  label: 'Click on <spanLink>Verify</spanLink>',
                },
              ],
            },
            addPhoneNumber: {
              label: 'Give your callbot a phone number',
              subQuests: [
                {
                  label:
                    'Scroll down to see the <spanLink>Telephony settings</spanLink>. On the <spanLink>“dev” environment</spanLink>, click on <spanLink>Add phone number</spanLink>',
                },
                {
                  label:
                    'Choose an available number from <spanLink>“Primary”</spanLink> or <spanLink>“Backup”</spanLink> and click on <spanLink>Add</spanLink>',
                },
                {
                  label: 'Click on <spanLink>Verify</spanLink>',
                },
              ],
            },
            saveBotForSettingsStep: {
              label: 'Save the bot again',
            },
          },
        },
        deployAndTest: {
          description: 'Deploy your bot over the phone',
          quests: {
            saveAndDeployBotWithName: {
              label: 'Save and deploy your bot',
              subQuests: [
                {
                  label:
                    'Click on <spanLink>Save</spanLink> on the top right corner of the app. Write “1st version” (or something similar) in the <spanLink>description</spanLink> and click <spanLink>Validate</spanLink>',
                },
                {
                  label:
                    'On the bot has been saved, click on <spanLink>Deploy</spanLink> right next to <spanLink>Save</spanLink>. Select <spanLink>“Dev”</spanLink> and click <spanLink>“Deploy”</spanLink>',
                },
                {
                  label:
                    'Click <spanLink>Verify</spanLink> to check that the bot has been deployed successfully',
                },
              ],
            },
            openChat: {
              label: 'Open the chat (chat section or from the script directly)',
            },
            testJourney: {
              label: 'Test the journey',
            },
          },
        },
      },
    },
    calls: {
      label: 'Calls',
      steps: {
        discoveryCalls: {
          description: 'Let’s call your bot!',
          quests: {
            lookingCall: {
              label: 'Make the call',
              subQuests: [
                {
                  label:
                    'Pick up your phone and call the <spanLink>number</spanLink> you attached to the <spanLink>dev environment</spanLink> in the <spanLink>Settings section</spanLink>.',
                },
                {
                  label: 'Have a little conversation with your <spanLink>callbot</spanLink>!',
                },
                {
                  label:
                    'After hanging up, go to the <spanLink>Calls section</spanLink> in the top left of the app. You should see the call you just made!',
                },
                {
                  label:
                    'Click on <spanLink>Verify</spanLink> one last time. Congratulations for <spanLink>finishing the onboarding!</spanLink>',
                },
              ],
            },
          },
        },
      },
    },
  },
};

export default createSimpleBotWithTransfer;
