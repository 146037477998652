// @flow

import type { BotConfig, Node, Transition } from '@state/ducks/bots/types';
import type { Exercise, ValidateFnReturn } from '@state/ducks/onboarding/types';
import { SAY_NODE_NAME } from '@state/ducks/bots/types';
import {
  getNodesAndTransitions,
  nodesAreLinkedByTransitions,
} from '@state/ducks/onboarding/utils/validator/utils';

type AddGoodByeMessageValidate = ValidateFnReturn<{
  nodesSayActionGoodBye: ?Node,
}>;

/**
 * Validate `addGoodByeMessage` quest
 * NOTE: Blocked by `addTransitionsToIntents` quest
 */
function validate(
  botConfig: BotConfig,
  {
    categories: {
      builder: {
        steps: {
          createIntroduction: {
            quests: { addTransitionsToIntents },
          },
        },
      },
    },
  }: Exercise,
): AddGoodByeMessageValidate {
  const { nodes, transitions }: { nodes: Node[], transitions: Transition[] } =
    getNodesAndTransitions(botConfig);
  const nodesSayActionPositiveNegative: Node[] =
    addTransitionsToIntents?.others?.nodesSayActionPositiveNegative ?? [];
  const nodesSayActionGoodBye: ?Node = nodes.find(
    (node: Node) =>
      node &&
      node.action === SAY_NODE_NAME &&
      node.templates &&
      node.templates.length > 0 &&
      nodesSayActionPositiveNegative &&
      nodesSayActionPositiveNegative.length > 0 &&
      nodesSayActionPositiveNegative.every((nodePositiveOrNegative: Node) =>
        nodesAreLinkedByTransitions(nodePositiveOrNegative.id, node.id, { nodes, transitions }),
      ),
  );

  return {
    others: {
      nodesSayActionGoodBye,
    },
    validated: !!nodesSayActionGoodBye,
  };
}

export default validate;
