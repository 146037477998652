// @flow

import type { DatasetResultContent, DatasetUtterance } from '@state/ducks/assessments/types';
import type { LoadedBotConfig } from '@state/ducks/bots/types';
import request from '@amplify/api/request';
import logger from '@assets/js/calldesk-app-util/logger';

const evaluateUtterances = async ({
  account,
  bot,
  botConfig,
  utterances,
}: {
  account: string,
  bot: string,
  botConfig: LoadedBotConfig,
  utterances: DatasetUtterance[],
}): Promise<DatasetResultContent> => {
  try {
    return await request.botApi({
      method: 'POST',
      path: `/accounts/${account}/bots/${bot}/evaluate-utterances`,
      body: {
        botConfig,
        utterances,
      },
    });
  } catch (error) {
    logger.info('state/bots/api [evaluateUtterances] Error while evaluating utterances', error);
    throw new Error(`Error while evaluating utterances, error: ${error.message}`);
  }
};

export { evaluateUtterances };
