// @flow

import _includes from 'lodash/includes';

/**
 * Extract an array with entities(string[]) used in each intent expression
 */
function extractEntitiesFromIntent(expressions: string[]): string[][] {
  const entities: string[][] = [];
  let hasALeastOneExpressionWithoutEntity: boolean = false;
  for (let expIndex = 0; expIndex < expressions.length; expIndex += 1) {
    const entitiesInSameExpression: string[] = [];
    const matches = expressions[expIndex].match(/{(.*?)\}/g);
    if (matches) {
      for (let matchIndex = 0; matchIndex < matches.length; matchIndex += 1) {
        const intentEntity: string = matches[matchIndex].replace('{', '').replace('}', '');

        if (!_includes(entitiesInSameExpression, intentEntity)) {
          entitiesInSameExpression.push(intentEntity);
        }
      }
      // JSON.stringify is mandatory because array with same elements are always different
      if (!JSON.stringify(entities).includes(JSON.stringify(entitiesInSameExpression))) {
        entities.push(entitiesInSameExpression);
      }
    } else {
      hasALeastOneExpressionWithoutEntity = true;
    }
  }
  if (hasALeastOneExpressionWithoutEntity) entities.splice(0, 0, []);
  return entities;
}

export default extractEntitiesFromIntent;
