// @flow

/* eslint no-console: 0 */

const info = (...args: any[]) => {
  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    console.info(...args);
  }
};

const log = (...args: any[]) => {
  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    console.log(...args);
  }
};

const warn = (...args: any[]) => {
  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    console.warn(...args);
  }
};

const error = (...args: any[]) => {
  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    console.error(...args);
  }
};

export default {
  info,
  log,
  warn,
  error,
};
