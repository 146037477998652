// @flow

import type { BotConfig, GraphData, Node, Transition } from '@state/ducks/bots/types';

const getNodesAndTransitions = (
  botConfig: BotConfig,
): { nodes: Node[], transitions: Transition[] } => {
  const mainDomain: ?string = Object.keys(botConfig?.graphs ?? {}).find(
    (domain: string) => domain !== 'tactical',
  );
  const graphData: $Shape<GraphData> =
    mainDomain && botConfig?.graphs?.[mainDomain]
      ? botConfig.graphs[mainDomain].data
      : { nodes: [], transitions: [] };
  const nodes: Node[] = graphData?.nodes ?? [];
  const transitions: Transition[] = graphData?.transitions ?? [];

  return { nodes, transitions };
};

export default getNodesAndTransitions;
