// @flow

import type { PayloadAction } from '@reduxjs/toolkit';
import type { Saga } from 'redux-saga';
import { all, call, put, select, take } from 'redux-saga/effects';

import type { BotConfig } from '@state/ducks/bots/types';
import type { IntentsMap } from '@state/ducks/intents/types';
import { getUserSub } from '@api/cognito';
import logger from '@assets/js/calldesk-app-util/logger';
import { operations as botsOperations } from '@state/ducks/bots';
import * as botsApi from '@state/ducks/bots/api';
import {
  operations as intentsOperations,
  selectors as intentsSelectors,
} from '@state/ducks/intents';
import { operations as optionsOperations } from '@state/ducks/options';
import { operations as uiOperations } from '@state/ducks/ui';
import { monitoringCatchException } from '@state/monitoring-enhancer';

/**
  1. This saga is used to get the BotConfig by calling bot-api.
   - if triggered by an action with a snapshotId property, it will load
   the bot config associated to this snapshotId.
   - if not, it will simply fetch the latest BotConfig.
  2. It will also fetch all available options in order for Settings page to work properly
  3. It will also check for user's authorization to access Settings page on read/write mode
*/
function* getBotConfigSaga({
  payload,
}: PayloadAction<{
  bot: string,
  account: string,
  botsFetched: boolean,
  snapshotId: string,
}>): Saga<any> {
  logger.info('saga/bots [getBotConfigSaga] - Start getting bot config');
  // we start by fetching the current acccountId
  const { snapshotId, account: accountId, bot: botName } = payload;

  logger.info('saga/bots [getBotConfigSaga] - Bots downloaded, continue the saga');
  yield put(botsOperations.botLoad(botName));

  try {
    // we then fetch the BotConfig associated to snapshotId and load it in the state
    const botConfig: { config: BotConfig, loadedSnapshotId: string } = yield call(
      botsApi.loadBotFromApi,
      accountId,
      botName,
      snapshotId,
    );

    const { config, loadedSnapshotId } = botConfig;
    const domains: string[] = Object.keys(config.graphs);
    yield put(uiOperations.updateSelectedDomain(domains && domains[0]));

    // NOTE: `addIntents` must occur before `botLoadSuccess` because intents will be converted to string[] in the latter
    yield put(intentsOperations.setIntents(config.intents));

    // Required to test thunk called in sagas
    const fetchAllIntentsBase = yield call(intentsOperations.fetchAllIntentsBase, { accountId });
    yield put(fetchAllIntentsBase);

    yield put(
      botsOperations.botLoadSuccess({
        bot: botName,
        botConfig: config,
        snapshotId: loadedSnapshotId,
      }),
    );

    // tous ceux d'account
    yield take(intentsOperations.fetchAllIntentsBase.fulfilled);

    const allAccountIntentsBase = yield select(intentsSelectors.selectAllByScope, 'account');
    // We cast botConfig.config.intents here because at this point, it is an Intents map (to retrieve the intent scope) and not an array of string
    const botConfigIntents: IntentsMap = ((botConfig.config.intents: any): IntentsMap);

    const remoteAccountIntentIds = allAccountIntentsBase.map(intent => intent.id);
    // If we have account intents in the bot config that are not present anymore remotely, we wish to safely remove them from bot config
    const deleteMissingAccountIntentPromises = Object.keys(botConfigIntents)
      .filter(
        intentId =>
          botConfigIntents[intentId].scope === 'account' &&
          !remoteAccountIntentIds.includes(intentId),
      )
      .map(intentId => put(botsOperations.deleteIntent({ bot: botName, intentId })));
    // Delete all missing account intents here because before botLoadSuccess operation,
    // the bot config is not stored yet
    yield all(deleteMissingAccountIntentPromises);
    yield all([
      put(botsOperations.startValidateBotSaga({ bot: botName })),
      put(optionsOperations.fetchAvailableContextKeys({ botName })),
    ]);
  } catch (error) {
    const userSub: string = yield call(getUserSub);
    // BotApiRequestError
    const errors = (error.response && error.response.errors) || [error.message];
    if (![403, 404].includes(error.status)) {
      monitoringCatchException({
        exception: error,
        userSub,
        location: 'state/sagas/bots/getBotConfigSaga',
        functionType: 'saga',
        params: { snapshotId, botsFetched: payload.botsFetched },
        accountId,
        botName,
      });
    }
    yield put(botsOperations.botLoadError({ bot: botName, errors }));
  }
}

export default getBotConfigSaga;
