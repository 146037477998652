// @flow

import type { OptionsState } from './types';

import type { State } from '@state/ducks/app/types';
import { TOO_LARGE_DATA } from '@state/monitoring-enhancer/index.type';

const optionsStateMonitoring = (state: State): OptionsState => ({
  ...state.options,
  options: {
    ...state.options.options,
    // $FlowFixMe: property voluntary transformed
    ...(state.options.options && state.options.options.voices ? { voices: TOO_LARGE_DATA } : {}),
  },
});

export default optionsStateMonitoring;
