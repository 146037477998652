// @flow

import type { Exercise } from '@state/ducks/onboarding/types';

const createSimpleBotWithTransfer: Exercise = {
  label: 'Créer un bot simple avec transfert ou dissuasion',
  categories: {
    builder: {
      label: 'Builder',
      steps: {
        createIntroduction: {
          description: "Créer l'introduction du bot",
          quests: {
            createSayAction: {
              label: "Créer une Say action avec message d'introduction",
              subQuests: [
                {
                  label:
                    'Drag a Say action from the top of the actions palette and drop it next to the Origin node',
                },
                {
                  label: 'Drag a transition from the Origin node to the Say action',
                },
                {
                  label:
                    'Click on the Say action and write “Hello world!” in the configuration panel',
                },
                {
                  label: 'Close the configuration panel and click on Verify',
                },
              ],
            },
            addSayActionClosedQuestion: {
              label: 'Ajouter une Say action avec première question fermée',
            },
            add2SayActionsNegativeAndPositive: {
              label:
                'Ajouter 2 Say actions avec les messages en cas de réponses positive et négative',
            },
            linkSayActionIntroToClosedQuestion: {
              label:
                "Lier la Say action du message d'accueil à celle de la question Oui/Non à l'aide d'une transition",
            },
            linkSayActionClosedQuestionToNegativeAndPositive: {
              label:
                "Lier la Say action de la question Oui/Non d'un côté vers la Say action en cas de réponse négative, de l'autre vers celle de la réponse positive",
            },
            addConfirmAndInfirmIntent: {
              label:
                'Se rendre dans la Section Intent et ajouter les intentions "Confirm" et "Infirm"',
            },
            addTransitionsToIntents: {
              label: 'Sélectionner les transitions concernées et ajouter les intents, puis valider',
            },
            saveBotIntroStep: {
              label: 'Sauvegarder le bot par sécurité',
            },
          },
        },
        createTransferBranch: {
          description: "Créer une branche de transfert (exploitation de l'appel)",
          quests: {
            addFirstNameAndLastNameModules: {
              label:
                'A la suite de la Say de la négation, ajouter les modules "lastname" & "firstname" dans l\'action "calldesk-modules" en v1, les relier à la suite de la Say action puis entre eux',
            },
            preventTransferWithSayAction: {
              label:
                'Ajouter une Say action pour prévenir du transfert et dire au revoir en utilisant le nom de famille récupéré et le titre (M. / Mme.) présents dans le contexte, la relier depuis le module précédent',
            },
            addTransferCallAction: {
              label:
                "Ajouter un Transfer action avec un numéro vers lequel transférer l'appel, la relier à la Say précédente",
            },
            addDoneActionTransferStep: {
              label: 'Ajouter une Done action, la relier à la Transfer Action précédente',
            },
            saveBotTransferStep: {
              label: 'Sauvegarder le bot par sécurité',
            },
          },
        },
        addDissuasionBranch: {
          description: 'Créer une branche Dissuasion (future automatisation)',
          quests: {
            addSayToAskReasonPositiveCase: {
              label:
                "Ajouter une Say pour demander la raison de l'appel en cas de réponse positive à la question fermée",
            },
            add3IntentsAndUtterances: {
              label: 'Créer 3 intentions dans la section Intents et y ajouter des utterrances',
            },
            add3SayActionsFor3Intents: {
              label:
                "Créer 3 Say actions qui reprennent les raisons d'appel possibles correspondant à chaque intention créée précédemment, et les relier à la Say action de la question précédente",
            },
            addIntentsOnCorrespondingTransitions: {
              label: 'Ajouter les intentions sur les transitions correspondantes',
            },
            addFinalSayActionGoodBye: {
              label:
                'Créer une dernière Say action pour dire au revoir et la relier aux Say précédentes',
            },
            addDoneActionDissuasionStep: {
              label: "Ajouter une Done action et la relier à la Say action d'au revoir précédente",
            },
            addGetCurrentDayPartAction: {
              label: 'Ajouter au début du script une Studio Action "Get current part of the day"',
            },
            addEndingSayAction: {
              label:
                'Dans les Say action terminant la conversation dans les 2 branches, en utilisant "Bonne {dayPart}" pour adapter selon le moment de la journée',
            },
            saveBotCurrentDatePartStep: {
              label: 'Sauvegarder le bot par sécurité',
            },
          },
          others: {
            dayPart: 'Bonne {dayPart}',
          },
        },
        settings: {
          description: 'Paramètrage',
          quests: {
            addChooseVoice: {
              label: 'Se rendre dans la Section Settings, ajouter choisir une voix la voix',
            },
            addPhoneNumber: {
              label: 'Ajouter une numéro de téléphone pour l\'environnement de "dev"',
            },
            saveBotForSettingsStep: {
              label: 'Sauvegarder le bot à nouveau',
            },
          },
        },
        deployAndTest: {
          description: 'Déploiement et test',
          quests: {
            saveAndDeployBotWithName: {
              label:
                'Sauvegarder et déployer le bot : donner le nom "dev" à la version et déployer le bot en environnement de "dev"',
            },
            openChat: {
              label: 'Ouvrir le Chat (setion Chat ou depuis le script directement)',
            },
            testJourney: {
              label: 'Tester le parcours',
            },
          },
        },
      },
    },
    calls: {
      label: 'Appels',
      steps: {
        discoveryCalls: {
          description: 'Découvrir les appels',
          quests: {
            lookingCall: {
              label:
                "Si tout semble fonctionner, appeler le numéro de téléphone sur lequel le bot est déployer et dialoguer avec lui, puis se rendre dans l'onglet Calls pour voir cet appel",
            },
          },
        },
      },
    },
  },
};

export default createSimpleBotWithTransfer;
