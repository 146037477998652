// @flow
import { Auth } from '@aws-amplify/auth';

/**
 * Submit a forgot password request
 *
 * @param username
 * @return {Promise<void>} additionnal message if needed for reset password flow, otherwise resolves empty
 */
function forgotPassword(username: string, code: string, password: string): Promise<void> {
  return Auth.forgotPasswordSubmit(username, code, password);
}

export default forgotPassword;
