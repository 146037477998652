// @flow

import type { ComponentType, Element as ReactElement } from 'react';
import React from 'react';

type ErrorInfo = { componentStack: string };
type ErrorHandler = (error: Error, info: ErrorInfo) => void;
type ErrorHandlingComponent<Props> = (props: Props, error?: Error) => ReactElement<any>;
type ErrorState = { error?: Error };

/**
 * Wraps the functional component to make it an error boundary.
 * The caught error is passed to the component function as a second parameter.
 *
 * @param {ErrorHandlingComponent<any>} component - New component
 * @param {ErrorHandler} errorHandler - Error caught
 *
 * @return {ComponentType<any>} - Error component created
 */
const ErrorBoundaryMaker = (
  component: ErrorHandlingComponent<any>,
  errorHandler?: ErrorHandler,
): ComponentType<any> =>
  class ErrorBoundary extends React.Component<any, ErrorState> {
    constructor() {
      super();
      this.state = {
        error: undefined,
      };
    }

    /**
     * Get error lifecycle
     */
    static getDerivedStateFromError(error: Error) {
      return { error };
    }

    /**
     * Catch error component
     */
    componentDidCatch(error: Error, info: ErrorInfo) {
      if (errorHandler) {
        errorHandler(error, info);
      }
    }

    /**
     * Render component
     */
    render() {
      return component(this.props, this.state.error);
    }
  };

export default ErrorBoundaryMaker;
