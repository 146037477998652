// @flow

import moment from 'moment';

const DATE_RANGES_MAP: { [key: string]: number } = {
  lastDay: moment().subtract(1, 'days').valueOf(),
  last7Days: moment().subtract(7, 'days').valueOf(),
  lastMonth: moment().subtract(30, 'days').valueOf(),
  last3Months: moment().subtract(3, 'months').valueOf(),
  last6Months: moment().subtract(6, 'months').valueOf(),
};

export { DATE_RANGES_MAP };
