// @flow
import { Auth } from '@aws-amplify/auth';

/**
 * Get Current session for authenticated user
 *
 * @return {Promise<CognitoUserSession>}
 */
function currentSession() {
  return Auth.currentSession();
}

export default currentSession;
