// @flow

import type {
  BotAction,
  BotPlayAction,
  BotSayAction,
  ChatResponseSuccess,
  ConfidenceIntent,
  Intent,
  IntentMatch,
  Message,
  RollBackMap,
  SayMessage,
  ThinkDebug,
  ThinkDebugResult,
  ThinkMessage,
  ThinkParams,
} from './types';
import _compact from 'lodash/compact';
import _isArray from 'lodash/isArray';

import type { BotApiError } from '@state/ducks/bots/types';
import type { ConversationState, ErrorMessage } from '@state/ducks/chat/types';
import BotApiRequestError from '@api/errors/BotApiRequestError';
import { SAY_NODE_NAME } from '@state/ducks/bots/types';

import { ThinkItemFactory, TraceItemFactory } from './utils';

const MAX_OTHER_INTENTS_DISPLAYED: number = 4;

const getId = (message: $Shape<Message>): string =>
  `${message.nodeId}-${message.timestamp.toString(10)}`;

/**
 * Build bot response message
 * Takes an array of actions and returns a string containing bot message
 */
function buildBotResponseMessage(actions: BotSayAction[]): string {
  if (!actions || !_isArray(actions)) {
    return '';
  }

  const messages = actions
    .filter(({ action }) => action === SAY_NODE_NAME)
    .map(({ message }) => message);

  return _compact(messages).join(' ');
}

const fallbackFormatterMap = {
  say: (sayAction: BotSayAction) => ({
    action: sayAction.action,
    id: sayAction.id,
    params: {
      bestTemplate: sayAction.bestTemplate,
      message: sayAction.message,
      templates: sayAction.templates,
    },
  }),
  play: (playAction: BotPlayAction) => ({
    action: playAction.action,
    id: playAction.id,
    params: {
      url: playAction.url || playAction.uri,
      content: playAction.content,
      filename: playAction.filename,
    },
  }),
};

/**
 * It returns a function that takes an action and returns the action if it's in the map, otherwise it
 * returns the action
 * @param BotAction - The action object that is being formatted.
 * @returns The action itself.
 */
function fallbackTraceFormatter(action: BotAction) {
  if (fallbackFormatterMap[action.action]) return fallbackFormatterMap[action.action](action);
  return action;
}

const thinkResponseSenders: string[] = ['think', 'user'];

/**
 * Sort function that sort Messages list by timestamp
 * If the timestamps are the same, sort the user's message before the bot's message
 */
const sortMessagesByTimestamp = (messageA: Message, messageB: Message): number => {
  if (messageA.timestamp < messageB.timestamp) {
    return -1;
  }
  if (messageA.timestamp > messageB.timestamp) {
    return 1;
  }
  // means timestamp is same
  // BUG FIX : Because we treat the latest execute trace before the think response,
  // in the case where the bot response has the same timestamp as the user's message, we need to sort the message sent by the user before
  if (thinkResponseSenders.includes(messageB.sender) && messageA.sender === 'bot') {
    return 1;
  }
  // Since Node.11, Array sort algorithm changed and our sort function was unstable because it lacked this check
  // New algorithm iterates in reverse order so we need to check this condition aswell
  // @see https://github.com/nodejs/node/issues/24294
  if (thinkResponseSenders.includes(messageA.sender) && messageB.sender === 'bot') {
    return -1;
  }
  return 0;
};

/**
 * Get intent match as Think Message
 *
 * NOTE: In case of NU matched on this turn, debugMode will return no results in `mainResults`.
 * Thus making mandatory to be based on `intentMatch.intents` first result instead.
 * This then lead to a duplicate issue in case an intent has been matched. `intentMatch.intents[0]`
 * will be equal to `debug.outcomesWide.mainResults[0]`.
 * It also handle the debugMode `false` mode even if today, `true` mode is forced directly on API call.
 */
const intentMatchAsThinkMessage = (
  intentMatch: IntentMatch,
  timestamp: number,
  thinkDebug: ThinkDebug,
): ThinkMessage => {
  const content: string = intentMatch.intents
    .map((intent: Intent) => `${intent.intent}[${Object.keys(intent.entities).join()}]`)
    .join('+');
  const matchedIntent: string = intentMatch.intents.map(({ intent }) => intent).join('+');

  const skipFirst: boolean = matchedIntent !== 'NotUnderstood';

  const formatDebugResult = (thinkDebugResult: ThinkDebugResult) => ({
    intent: thinkDebugResult.intents.map(({ intent }) => intent).join('+'),
    confidence: thinkDebugResult.confidence,
  });

  const formattedMainResults: ConfidenceIntent[] = (thinkDebug.outcomesWide?.mainResults || [])
    .slice(skipFirst ? 1 : 0)
    .map(formatDebugResult);

  /**
   * Only add 4 first unmatched intents
   */
  const neededOtherResults: number = MAX_OTHER_INTENTS_DISPLAYED - formattedMainResults.length;
  const formattedOtherResults: ConfidenceIntent[] = (thinkDebug.outcomesWide?.otherResults || [])
    .slice(0, neededOtherResults)
    .map(formatDebugResult);

  const matchedIntentScores: number[] = intentMatch.intents.map(({ confidence }) => +confidence);
  const matchedIntentScoreMin: number = Math.min(...matchedIntentScores);

  return {
    confidencesList: [
      {
        intent: matchedIntent,
        confidence: matchedIntentScoreMin,
      },
      ...formattedMainResults,
      ...formattedOtherResults,
    ],
    content,
    sender: 'think',
    state: thinkDebug,
    timestamp,
    nodeId: `${content}-${timestamp.toString(10)}`,
    id: getId({ timestamp, nodeId: content }),
  };
};

const stateFormattedForActionMessage = (state: ConversationState): ConversationState => {
  const newState: ConversationState = { ...state };
  delete newState.nextTriggers;

  return {
    ...newState,
    meta: state.meta || {},
  };
};

const errorMessageFromBotApi = (error: BotApiRequestError): ErrorMessage => {
  const location: ?string = error.response?.errors?.find(
    (errorItem: BotApiError) => !!errorItem.location,
  )?.location;
  const isNodeError: boolean = !!location && location.includes('.nodes');
  const errorMessage: $Shape<ErrorMessage> = {
    sender: 'error',
    solution: error.solution || 'Contact an administrator.',
    content: error.message || 'An unknown error occurred while chatting',
    timestamp: Date.now(),
    id: 'error',
    nodeId: 'error',
  };

  if (location && isNodeError) {
    errorMessage.location = location;
    const [, nodeId] = location.split(/nodes./);
    errorMessage.nodeId = nodeId;
  }

  return errorMessage;
};

/**
 * It takes a list of responses, and returns a list of messages
 * @param string - The string to be converted to a number.
 */
const generateMessagesFromResponses = (
  responses: ChatResponseSuccess[],
  conversationState: ConversationState,
  query?: string,
  language: string,
): Message[] => {
  // We keep already formatted trace item because we don't want to rebuild the message
  // and override the context (that would show the future context after the action)
  const knownIds = new Set<string>();

  let traceMessages = responses.flatMap(({ newState: { trace, context, meta, stats } }) => {
    const currentActionState: $Shape<ConversationState> = {
      context,
      meta,
      nextTriggers: conversationState.nextTriggers,
      ...(stats && { stats }),
    };
    const messagesToAdd = trace
      .map(item => TraceItemFactory().create(item, currentActionState, language))
      .filter(Boolean)
      .filter(message => !knownIds.has(message.id));

    messagesToAdd.forEach(({ id }) => knownIds.add(id));

    return messagesToAdd;
  });
  // If we have a query, meaning it's not the chat init, then we add the user's query in the chat with the associated intent match
  if (query) {
    // We take the last response with an intentMatch property and log it as a think in the chat
    // Reverse mutate the array so we make a copy of it
    const chatResponse: ?ChatResponseSuccess = [...responses]
      .reverse()
      .find(response => response.thinkResponse?.intentMatch);

    if (chatResponse && chatResponse.thinkResponse) {
      const { thinkResponse } = chatResponse;

      const thinkMessage: ThinkMessage = intentMatchAsThinkMessage(
        thinkResponse.intentMatch,
        thinkResponse.timestamp,
        thinkResponse.debug,
      );
      const userMessage: SayMessage = ThinkItemFactory().formatter(
        thinkResponse.intentMatch,
        language,
        thinkResponse.timestamp,
      );
      traceMessages = traceMessages.concat([userMessage, thinkMessage]);
    }
  }
  return traceMessages;
};

/**
 * It takes a list of responses, and returns a map of timestamps to nodeIds to thinkParams
 * @param RollBackMap - This is the map of all the nodes that can to be rolled back.
 */
const buildRollbackMap = (
  responses: ChatResponseSuccess[],
  thinkParams: ThinkParams,
  rollbackMap: RollBackMap,
  { nodeId, timestamp }: { nodeId?: string, timestamp?: number } = {},
) =>
  responses
    .flatMap(({ newState }) => newState.trace)
    .filter(TraceItemFactory().shouldUpdateThinkMap)
    .filter(
      item =>
        !!nodeId &&
        !!timestamp &&
        getId({ nodeId, timestamp }) === `${item.node.id}-${item.timestamp.toString(10)}`,
    )
    .reduce(
      (acc, item) => ({
        ...acc,
        [item.timestamp]: {
          ...acc[item.timestamp],
          [item.node.id]: thinkParams,
        },
      }),
      { ...rollbackMap },
    );

export {
  buildBotResponseMessage,
  buildRollbackMap,
  errorMessageFromBotApi,
  fallbackTraceFormatter,
  generateMessagesFromResponses,
  getId,
  intentMatchAsThinkMessage,
  sortMessagesByTimestamp,
  stateFormattedForActionMessage,
};
