// @flow

import type { State as RootState, StorageSettings } from '../app/types';
import type {
  Recording,
  RecordingPayload,
  Recordings as RecordingsState,
  RecordingsApiPayload,
} from './types';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  // $FlowFixMe
  createAsyncThunk,
  // $FlowFixMe
  createEntityAdapter,
  // $FlowFixMe
  createSlice,
} from '@reduxjs/toolkit';
import _isEmpty from 'lodash/isEmpty';

import { fetchRecording as fetchRecordingAPI } from './api';

const recordingsAdapter = createEntityAdapter({
  selectId: (transcript: Recording) => transcript.sessionId,
});

/* APIs Handlers */
const initialState: RecordingsState = recordingsAdapter.getInitialState({
  status: 'NONE',
  currentAudioTime: 0, // seconds
  currentPostTransferAudioTime: 0,
});

const fetchRecording = createAsyncThunk(
  'recordings/FETCH_RECORDING',
  async (
    {
      account,
      bot,
      sessionId,
      startTime,
      storageSettings,
    }: {
      account: string,
      bot: string,
      sessionId: string,
      startTime: number,
      storageSettings: StorageSettings,
    },
    { rejectWithValue },
  ) => {
    try {
      const { mainRecording, postTransferRecording }: RecordingsApiPayload =
        await fetchRecordingAPI(account, bot, sessionId, startTime, storageSettings);
      return { sessionId, mainRecording, postTransferRecording };
    } catch (error) {
      return rejectWithValue(sessionId);
    }
  },
);

/* ACTIONS & REDUCERS */

const { actions, reducer } = createSlice({
  name: 'recordings',
  initialState,
  reducers: {
    setCurrentAudioTime(state: RecordingsState, { payload }: PayloadAction<number>) {
      state.currentAudioTime = payload;
    },
    setCurrentPostTransferAudioTime(state: RecordingsState, { payload }: PayloadAction<number>) {
      state.currentPostTransferAudioTime = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchRecording.pending, (state: RecordingsState) => {
        state.status = 'IN_PROGRESS';
      })
      .addCase(
        fetchRecording.fulfilled,
        (
          state: RecordingsState,
          {
            payload: { mainRecording, postTransferRecording, sessionId },
          }: PayloadAction<{
            sessionId: string,
            mainRecording: RecordingPayload,
            postTransferRecording: RecordingPayload,
          }>,
        ) => {
          const newRecording = {
            error: null,
            isFetching: false,
            mainRecording,
            postTransferRecording,
            sessionId,
          };
          state.status = 'SUCCESS';
          recordingsAdapter.upsertOne(state, newRecording);
        },
      )
      .addCase(fetchRecording.rejected, (state: RecordingsState, { error, payload: sessionId }) => {
        const newRecording = {
          isFetching: false,
          error,
          sessionId,
        };
        state.status = 'ERROR';
        recordingsAdapter.upsertOne(state, newRecording);
      });
  },
});

/* SELECTORS */
const recordingSelectors = recordingsAdapter.getSelectors((state: RootState) => state.recordings);

const getCurrentAudioTime = ({ recordings }: RootState) => recordings.currentAudioTime;
const getCurrentPostTransferAudioTime = ({ recordings }: RootState) =>
  recordings.currentPostTransferAudioTime;

const shouldFetchRecording = (sessionId?: string) => (state: RootState) => {
  const transcript: ?RecordingsState = recordingSelectors.selectById(state, sessionId);
  return _isEmpty(transcript);
};
const selectors = {
  getAll: recordingSelectors.selectAll,
  getCurrentAudioTime,
  getCurrentPostTransferAudioTime,
  getRecordingById: recordingSelectors.selectById,
  shouldFetchRecording,
};

/* EXPORTS */
actions.fetchRecording = fetchRecording;

export { actions, initialState, selectors };
export default reducer;
