// @flow
import { Auth } from '@aws-amplify/auth';

/**
 * sign out method using cognito
 * @param username
 * @param password
 * @return {Promise<void>}
 */
async function signOut(): Promise<void> {
  await Auth.Credentials.clear();
  return Auth.signOut();
}

export default signOut;
