// @flow

import type { PayloadAction } from '@reduxjs/toolkit';
import type { Saga } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import type { Exercise, Onboarding } from '@state/ducks/onboarding/types';
import * as cognito from '@api/cognito';
import logger from '@assets/js/calldesk-app-util/logger';
import {
  api as onboardingApi,
  operations as onboardingOperations,
  selectors as onboardingSelectors,
} from '@state/ducks/onboarding';

import runValidatorOnboardingSaga from './runValidatorOnboardingSaga';

/**
 * Check progression and save onboarding
 */
function* saveAndCheckUserOnboardingSaga({
  payload,
  type,
}: PayloadAction<{
  account: string,
  bot: string,
  pathToSpecificQuest?: string,
}>): Saga<any> {
  const { account, bot, pathToSpecificQuest } = payload;
  const exerciseId: ?string = yield select(onboardingSelectors.selectExerciseId);
  const botHasOnboarding: boolean = yield select(onboardingSelectors.selectBotHasOnboarding);

  if (botHasOnboarding) {
    yield call(runValidatorOnboardingSaga, {
      type,
      payload: { account, bot, pathToSpecificQuest },
    });
    const progression: Exercise = yield select(onboardingSelectors.selectProgression);

    if (exerciseId && progression) {
      try {
        yield put(
          onboardingOperations.setSpecificStatus({
            statusName: 'fetching',
            statusValue: 'IN_PROGRESS',
          }),
        );
        const user: string = yield call(cognito.getUserSub);
        const onboardingUpdated: Onboarding = yield call(
          onboardingApi.updateUserOnboarding,
          user,
          account,
          bot,
          exerciseId,
          progression,
        );
        yield put(
          onboardingOperations.setSpecificStatus({
            statusName: 'fetching',
            statusValue: 'SUCCESS',
          }),
        );
        yield put(onboardingOperations.setOnboarding(onboardingUpdated));
        logger.info('saga/onboarding [saveAndCheckUserOnboardingSaga] - onboarding data updated:', {
          account,
          bot,
          exerciseId,
        });
      } catch (error) {
        yield put(
          onboardingOperations.setSpecificStatus({
            statusName: 'fetching',
            statusValue: 'ERROR',
            error: error.message,
          }),
        );
      }
    } else {
      logger.warn(
        'saga/onboarding [saveAndCheckUserOnboardingSaga] - some onboarding data missing for updating:',
        {
          account,
          bot,
          exerciseId,
        },
      );
    }
    logger.info('saga/onboarding [saveAndCheckUserOnboardingSaga] - progression validated:', {
      account,
      bot,
      exerciseId,
    });
  } else {
    logger.warn('saga/onboarding [saveAndCheckUserOnboardingSaga] - bot has not onboarding:', {
      account,
      bot,
      exerciseId,
    });
  }
}

export default saveAndCheckUserOnboardingSaga;
