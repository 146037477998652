// @flow

import type { RouterHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CDKCheckbox, CDKModal, cdkNotification } from '@calldesk/components';

import type { State, Status } from '@state/ducks/app/types';
import { selectors as accountsSelectors } from '@state/ducks/accounts';
import { operations as botsOperations, selectors as botsSelectors } from '@state/ducks/bots';

import './delete-bot-modal.css';

type ParentProps = {
  accountId: string,
  botName: string,
  close: (boolean: ?boolean) => void,
};

/**
 * DeleteBotModal
 */
function DeleteBotModal({ accountId, botName, close }: ParentProps) {
  const history: RouterHistory = useHistory();
  const dispatch = useDispatch();

  const [checked, setChecked] = useState<boolean>(false);
  const [currBotName, setCurrBotName] = useState<string>('');

  const botError: ?string = useSelector<State, ?string>(botsSelectors.getError);
  const deleteStatus: Status = useSelector<State, Status>(state =>
    botsSelectors.getSpecificBotsStatus(state, 'delete'),
  );
  const bots: string[] = useSelector<State, string[]>(state =>
    accountsSelectors.getBots(state, accountId),
  );

  const setDeleteStatus = (status: Status) =>
    dispatch(botsOperations.setSpecificBotsStatus({ name: 'delete', value: status }));
  const deleteBot = () => dispatch(botsOperations.deleteBotSaga({ accountId, botName, bots }));

  const isLoading: boolean = deleteStatus === 'IN_PROGRESS';

  useEffect(() => {
    setCurrBotName(botName);
  }, []);

  useEffect(() => {
    // Wait for the success of the saga and redirect to home page
    if (['SUCCESS'].includes(deleteStatus) && checked) {
      setDeleteStatus('NONE');
      cdkNotification.success({
        message: 'Deletion done',
        description: `Bot "${currBotName}" has been successfully deleted`,
        top: 75,
      });
      history.push('/');
      close(true);
    }
    if (deleteStatus === 'ERROR' && checked) {
      cdkNotification.error({
        message: 'Deletion error',
        description: botError,
        top: 75,
      });
      setDeleteStatus('NONE');
    }
  }, [deleteStatus, checked]);

  return (
    <CDKModal
      title={
        <>
          Delete bot <span className="bot-name-header">{botName}</span>
        </>
      }
      okButtonProps={{ disabled: !checked }}
      visible
      wrapClassName="DeleteBotModal"
      onOk={deleteBot}
      okText="Delete bot"
      onCancel={() => !isLoading && close()}
      onClose={() => !isLoading && close()}
      confirmLoading={isLoading}
      maskClosable={!isLoading}
      cancelButtonProps={{ disabled: isLoading }}
    >
      <p>This bot will be permanently deleted. Are you sure?</p>
      <CDKCheckbox
        data-test-id="delete-bot-confirm"
        checked={checked}
        onChange={() => setChecked(!checked)}
      >
        Yes I&apos;m sure I want to <span className="bot-name-checkbox">delete {botName}</span>
      </CDKCheckbox>
    </CDKModal>
  );
}

export default DeleteBotModal;
