// @flow

import type { Conversations } from './types';

import type { State as RootState } from '@state/ducks/app/types';
import { SENSITIVE_DATA } from '@state/monitoring-enhancer/index.type';

const conversationsStateMonitoring = (state: RootState): Conversations => ({
  ...state.conversations,
  entities: SENSITIVE_DATA,
});

export default conversationsStateMonitoring;
