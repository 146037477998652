// @flow

import type { RecordingApiResource, RecordingsApiPayload, RecordingsApiResponse } from './types';

import type { StorageSettings } from '@state/ducks/app/types';
import request from '@amplify/api/request';
import { formatStorageSettingsHeaders } from '@assets/js/calldesk-app-util/format';
import logger from '@assets/js/calldesk-app-util/logger';

const fetchRecording = async (
  selectedAccount: string,
  bot: string,
  conversationId: string,
  discussionStartTime: number,
  storageSettings: StorageSettings,
): Promise<RecordingsApiPayload> => {
  let response: RecordingApiResource | RecordingsApiPayload;

  try {
    const { payload }: RecordingsApiResponse =
      await request.conversationsApi<RecordingsApiResponse>({
        path: `/accounts/${selectedAccount}/bots/${bot}/conversations/${conversationId}/recordings`,
        queryStringParameters: {
          discussionStartTime,
        },
        method: 'GET',
        headers: formatStorageSettingsHeaders(storageSettings),
      });
    response = payload;
  } catch (error) {
    logger.info(
      'state/recordings/api [fetchRecording] Error while retrieving recordings',
      error.message,
    );
    throw error;
  }

  return response;
};

export { fetchRecording };
