// @flow

import type {
  BotAction,
  Bots as BotsState,
  StatsBlockConfig,
  StatsConfigBlockDefinition,
} from './types';
import type { PayloadAction } from '@reduxjs/toolkit';

type StatBotAction<T> = {| ...BotAction<T>, block: string |};

const getStatsConfigBlock = (
  state: BotsState,
  { bot, block }: { bot: string, block: string },
): ?StatsConfigBlockDefinition => state.entities[bot].config.stats.data?.[block];

const reducers = {
  addBlockConfigStartNode: (
    state: BotsState,
    {
      payload: { bot, block, domain, nodeId },
    }: PayloadAction<StatBotAction<$Exact<{ domain: string, nodeId: string }>>>,
  ) => {
    let statBlock: ?StatsConfigBlockDefinition = getStatsConfigBlock(state, { bot, block });

    if (!statBlock) {
      statBlock = {
        start: [],
        end: {},
        mapping: {},
      };
    }
    if (!statBlock?.start) {
      statBlock.start = [];
    }
    statBlock.start.push({ nodeId, domain });

    state.entities[bot].config.stats.data[block] = statBlock;
  },
  addBlockConfigEndNode: (
    state: BotsState,
    {
      payload: { bot, block, domain, nodeId, status, statusReporting },
    }: PayloadAction<
      StatBotAction<
        $Exact<{ domain: string, nodeId: string, status: string, statusReporting: string }>,
      >,
    >,
  ) => {
    let statBlock: ?StatsConfigBlockDefinition = getStatsConfigBlock(state, { bot, block });

    if (!statBlock) {
      statBlock = {
        start: [],
        end: {},
        mapping: {},
      };
    }

    if (!statBlock.start) {
      statBlock.end = {};
    }

    if (!statBlock.end[status]) {
      statBlock.end[status] = [];
    }
    statBlock.end[status].push({ nodeId, domain });

    if (!statBlock.mapping) {
      statBlock.mapping = {};
    }
    statBlock.mapping[status] = statusReporting;

    state.entities[bot].config.stats.data[block] = statBlock;
  },
  deleteBlockConfigNode: (
    state: BotsState,
    {
      payload: { bot, block, domain, nodeId, status, statType },
    }: PayloadAction<
      StatBotAction<
        $Exact<{
          domain: string,
          nodeId: string,
          statType: string,
          status: string,
        }>,
      >,
    >,
  ) => {
    const statBlock: ?StatsConfigBlockDefinition = getStatsConfigBlock(state, { bot, block });

    if (statBlock) {
      if (statType === 'entry') {
        statBlock.start = statBlock.start.filter(
          blockTip => !(blockTip.nodeId === nodeId && blockTip.domain === domain),
        );
      }
      if (statType === 'exit') {
        if (statBlock.end[status]) {
          statBlock.end[status] = statBlock.end[status].filter(
            blockTip => !(blockTip.nodeId === nodeId && blockTip.domain === domain),
          );

          if (statBlock.end[status].length === 0) {
            delete statBlock.end[status];

            if (statBlock?.mapping?.[status]) {
              delete statBlock.mapping[status];
            }
          }
        }
      }
    }

    state.entities[bot].config.stats.data[block] = statBlock;
  },
  addStatBlock: (
    state: BotsState,
    {
      payload: { bot, blockConfig },
    }: PayloadAction<
      StatBotAction<
        $Exact<{
          blockConfig: StatsBlockConfig,
        }>,
      >,
    >,
  ) => {
    state.entities[bot].config.stats.data[blockConfig.block] = {
      start: [],
      end: {},
      mapping: {},
    };

    if (blockConfig.entity) {
      state.entities[bot].config.stats.data[blockConfig.block].entity = blockConfig.entity;
    }
  },
  updateStatBlock: (
    state: BotsState,
    {
      payload: { bot, blockConfig, target },
    }: PayloadAction<
      StatBotAction<
        $Exact<{
          blockConfig: StatsBlockConfig,
          target: string,
        }>,
      >,
    >,
  ) => {
    state.entities[bot].config.stats.data[blockConfig.block] = {
      ...state.entities[bot].config.stats.data[target],
    };

    if (blockConfig.entity) {
      state.entities[bot].config.stats.data[blockConfig.block].entity = blockConfig.entity;
    } else {
      delete state.entities[bot].config.stats.data[blockConfig.block].entity;
    }

    if (blockConfig.block !== target) {
      delete state.entities[bot].config.stats.data[target];
    }
  },
  deleteStatBlock: (
    state: BotsState,
    {
      payload: { bot, block },
    }: PayloadAction<
      StatBotAction<
        $Exact<{
          block: string,
        }>,
      >,
    >,
  ) => {
    delete state.entities[bot].config.stats.data[block];
  },
};

export default reducers;
