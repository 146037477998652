// @flow
/* eslint complexity: ['error', 13] */
/* eslint-disable max-lines */

import type { LoadedBotConfig } from '@state/ducks/bots/types';
import type { IntentsEvaluations } from '@state/ducks/intents/types';
import request from '@amplify/api/request';
import logger from '@assets/js/calldesk-app-util/logger';

export const fetchIntentsEvaluationForBot = async (
  account: string,
  bot: string,
  botConfig: LoadedBotConfig,
): Promise<IntentsEvaluations> => {
  let intentsEvaluation: IntentsEvaluations;
  try {
    const { averageIntentScore, intents }: IntentsEvaluations = await request.botApi({
      method: 'POST',
      path: `/accounts/${account}/bots/${bot}/evaluate-intents`,
      body: botConfig,
    });

    intentsEvaluation = {
      averageIntentScore,
      intents,
    };
  } catch (error) {
    logger.info(
      'state/bots/api [fetchIntentsEvaluationForBot] Error while evaluating intents',
      error,
    );
    throw error;
  }
  return intentsEvaluation;
};
