// @flow

import type { BotAction, Bots as BotsState, Entities, Entity } from './types';
import type { PayloadAction } from '@reduxjs/toolkit';

const reducers = {
  updateBotConfigEntity: (
    state: BotsState,
    { payload: { bot, entity } }: PayloadAction<BotAction<$Exact<{ entity: Entity }>>>,
  ) => {
    state.entities[bot].config.entities[entity.id] = entity;
  },
  deleteBotConfigEntity: (
    state: BotsState,
    { payload: { bot, entityId } }: PayloadAction<BotAction<$Exact<{ entityId: string }>>>,
  ) => {
    delete state.entities[bot].config.entities[entityId];
  },
  addEntities: (
    state: BotsState,
    { payload: { bot, entities } }: PayloadAction<BotAction<$Exact<{ entities: Entities }>>>,
  ) => {
    state.entities[bot].config.entities = {
      ...state.entities[bot].config.entities,
      ...entities,
    };
  },
};

export default reducers;
