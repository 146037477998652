// @flow

import type { PayloadAction } from '@reduxjs/toolkit';
import type { Saga } from 'redux-saga';
import { all, call, put, select } from 'redux-saga/effects';

import type { Assessment } from '@state/ducks/assessments/types';
import type { BotConfig } from '@state/ducks/bots/types';
import type { AccountIntent, IntentsMap } from '@state/ducks/intents/types';
import { getUserSub } from '@api/cognito';
import { selectors as assessmentsSelectors } from '@state/ducks/assessments';
import { operations as botsOperations } from '@state/ducks/bots';
import * as botsApi from '@state/ducks/bots/api';
import { formatBotConfig, formatIntentsForBotConfig } from '@state/ducks/bots/utils';
import {
  api as customersAssetsAPI,
  operations as assetsOperations,
} from '@state/ducks/customers-assets';
import { selectors as intentsSelectors } from '@state/ducks/intents';
import { operations as onboardingOperations } from '@state/ducks/onboarding';
import { operations as uiOperations } from '@state/ducks/ui';
import { monitoringCatchException } from '@state/monitoring-enhancer';
import * as customersAssetsSagas from '@state/sagas/customers-assets';
import * as onboardingSagas from '@state/sagas/onboarding';

/**
 * This saga is used in order to save a bot. It will happen at 2 places :
 * - When you create a new bot
 * - When you hit the save button
 */
function* saveBotSaga({
  payload: { account, bot, botConfig, description, parentSnapshotId, ...action },
}: PayloadAction<{
  bot: string,
  account: string,
  botConfig: BotConfig,
  description: string,
  parentSnapshotId: string,
  callback?: Function,
}>): Saga<any> {
  const userSub: string = yield call(getUserSub);
  yield put(botsOperations.saveBot(bot));

  try {
    yield call(
      customersAssetsSagas.tagEntitiesReferentialSaga,
      assetsOperations.tagEntitiesReferentialSaga(account, bot),
    );
    yield put(botsOperations.resetDeployBotStatus(bot));
    yield put(botsOperations.resetCheckBotStatus(bot));
    yield put(
      onboardingOperations.setValidatorData({ lastSave: { description, parentSnapshotId } }),
    );
    yield call(
      onboardingSagas.saveAndCheckUserOnboardingSaga,
      onboardingOperations.saveAndCheckUserOnboardingSaga({
        account,
        bot,
      }),
    );
    if (action.callback) yield action.callback();
  } catch (error) {
    const errors = (error.response && error.response.errors) || [error.message];
    monitoringCatchException({
      exception: error,
      userSub,
      location: 'state/sagas/bots/saveBotSaga',
      functionType: 'saga',
      params: { description, parentSnapshotId },
      accountId: account,
      botName: bot,
    });

    yield put(botsOperations.saveBotError({ bot, errors }));
    yield put(uiOperations.openErrorModal());
  }

  // NOTE: Could be done in `formatBotConfig` but it's used also in bot creation so needs deeper refactor
  const intentsMap: IntentsMap = yield select(intentsSelectors.selectEntities);
  const accountIntents: AccountIntent[] = yield select(state =>
    intentsSelectors.selectAllByScope(state, 'account'),
  );
  const assessments: Assessment[] = yield select(assessmentsSelectors.selectAll);

  const formattedIntentsMap: IntentsMap = formatIntentsForBotConfig({
    intentsListFromBotsDuck: botConfig.intents,
    intentsMapFromIntentsDuck: intentsMap,
  });

  try {
    const snapshotId: string = yield call(
      botsApi.saveBotOnApi,
      account,
      bot,
      {
        ...formatBotConfig(botConfig),
        intents: formattedIntentsMap,
      },
      description,
      parentSnapshotId,
    );

    yield all(
      accountIntents
        .filter((intent: AccountIntent) => botConfig.intents.includes(intent.id))
        .map((intent: AccountIntent) =>
          call(customersAssetsAPI.updateAsset, {
            accountId: account,
            assetType: 'intents',
            assetId: intent.id,
            content: {
              description: intent.description,
              utterances: intent.utterances,
              entities: intent.entities,
            },
            contentType: 'custom',
            name: intent.label,
            scope: 'account',
          }),
        ),
    );

    yield all(
      assessments.map(({ dataset }) => {
        if (dataset.content)
          return call(customersAssetsAPI.updateAsset, {
            accountId: account,
            botId: bot,
            assetType: 'assessments',
            assetId: dataset.id,
            content: dataset.content,
            contentType: dataset.contentType,
            name: dataset.name,
            scope: 'bot',
          });
        return [];
      }),
    );

    yield put(botsOperations.saveBotSuccess({ bot, snapshotId }));
  } catch (error) {
    const errors = error.response?.errors ?? [error];

    yield put(botsOperations.saveBotError({ bot, errors }));
    yield put(uiOperations.openErrorModal());
  }
}
export default saveBotSaga;
