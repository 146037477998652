// @flow

import type {
  ConversationState,
  PlayContent,
  SayActionNode,
  SayContent,
  SayMessage,
  SayMessageContentItem,
  TraceItem,
  TraceItemFactory,
} from '../../types';

import { format as nlgFormat } from '@calldesk/nlg';

import { REGEXP_WORDS_IN_SENTENCE } from '@assets/js/calldesk-app-util/format';
import { PLAY_NODE_NAME, SAY_NODE_NAME } from '@state/ducks/bots/types';

import { getId } from '../../util';

const HIGHLIGHT_REGEXP_PATTERN = /(\[object Object\]|{missing key in context})/;

// entity regexp - slightly different from nlg.PATTERN because `{` and `}` are inside the capture group
// caution : It will match HIGHLIGHT_REGEXP_PATTERN aswell
const BOLD_REGEXP_PATTERN =
  /({[^%}]+(?:%fulldatetime|fulldate|datetime|date|time|feminine|phoneNumber|day|dayofmonth|month|year|hours|minutes|datewithoutpreposition|timewithoutpreposition|(\d{1,2})by\3)?})/;

const Factory = (): TraceItemFactory<SayActionNode> => {
  const formatSayContent = (
    params: SayContent,
    context: any,
    language: string,
  ): SayMessageContentItem[] => {
    const templateSplitted: string[] = params.bestTemplate.split(REGEXP_WORDS_IN_SENTENCE);

    const formattedMessageItems: SayMessageContentItem[] = templateSplitted.reduce(
      (acc: SayMessageContentItem[], word: string, index: number) => {
        const nlgValue: string = nlgFormat(word, context, language, false);

        const isLastWord: boolean = templateSplitted.length === index + 1;
        const isNLGValue: boolean = BOLD_REGEXP_PATTERN.test(word);
        const isMalformed: boolean = HIGHLIGHT_REGEXP_PATTERN.test(nlgValue);
        const isDifferent: boolean = nlgValue !== word;

        return [
          ...acc,
          {
            bold: isNLGValue || (!isMalformed && isDifferent),
            highlight: isMalformed,
            italic: false,
            item: isLastWord ? nlgValue : nlgValue.concat(' '),
            ...(isDifferent
              ? {
                  tooltip: word,
                }
              : {}),
          },
        ];
      },
      [],
    );

    return formattedMessageItems;
  };

  const formatPlayContent = (params: PlayContent): SayMessageContentItem[] => {
    const messageItems = [];

    if (params.content) {
      messageItems.push({
        bold: false,
        highlight: false,
        italic: false,
        item: `[Play audio transcript] ${String(params.content)}`,
      });
    } else if (params.filename) {
      messageItems.push({
        bold: false,
        highlight: false,
        italic: false,
        item: '[Play audio index] ',
      });
      messageItems.push({
        bold: true,
        highlight: false,
        italic: false,
        item: String(params.filename),
      });
    } else if (params.url) {
      const urlWithoutQuery: string = String(params.url).split('?')[0];
      const shortenedUrl: string =
        urlWithoutQuery.length > 60
          ? `${urlWithoutQuery.slice(0, 30)}[...]${urlWithoutQuery.slice(-30)}`
          : urlWithoutQuery;

      messageItems.push({
        bold: false,
        highlight: false,
        italic: false,
        item: `[Play audio URL] ${shortenedUrl}`,
      });
    }

    return messageItems;
  };

  const formatContent = (
    traceItem: TraceItem<SayActionNode>,
    context: any,
    language: string,
  ): SayMessageContentItem[] => {
    if (traceItem.node.action === SAY_NODE_NAME && traceItem.node.params.bestTemplate) {
      return formatSayContent(traceItem.node.params, context, language);
    }

    if (
      traceItem.node.action === PLAY_NODE_NAME &&
      (traceItem.node.params.url || traceItem.node.params.content || traceItem.node.params.filename)
    ) {
      return formatPlayContent(traceItem.node.params);
    }

    return [];
  };

  const formatter = (
    traceItem: TraceItem<SayActionNode>,
    { context, meta }: $Shape<ConversationState>,
    language: string,
  ): SayMessage => ({
    sender: 'bot',
    content: formatContent(traceItem, { ...context, meta }, language),
    timestamp: traceItem.timestamp,
    nodeId: traceItem.node.id,
    id: getId({ timestamp: traceItem.timestamp, nodeId: traceItem.node.id }),
    domain: traceItem.domain,
    ...(traceItem.path ? { module: traceItem.path } : {}),
  });

  const validator = (traceItem: TraceItem<any>): boolean =>
    (traceItem.node?.action === SAY_NODE_NAME && !!traceItem.node?.params?.bestTemplate) ||
    traceItem.node?.action === PLAY_NODE_NAME;

  return { formatter, validator };
};

export default Factory;
