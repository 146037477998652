// @flow

import React, { useEffect, useState } from 'react';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/WarningRounded';
import _mapValues from 'lodash/mapValues';

import { CDKList } from '@calldesk/components';

import type { Status } from '@state/ducks/app/types';
import type { BotApiError } from '@state/ducks/bots/types';
import LoadingSpinner from '@assets/js/calldesk-components/molecules/LoadingSpinner';

import './check-results.css';

type ParentProps = {|
  checkErrors: BotApiError[],
  checkStatus: Status,
|};

type CheckCategory = 'script' | 'intent' | 'setting' | 'test' | 'misc';

type Check = {|
  label: string,
  type: CheckCategory,
|};

type ErrorsByCategories = {
  [category: string]: BotApiError[],
};

const CHECKS_LIST: Check[] = [
  { label: 'Script', type: 'script' },
  { label: 'Setting', type: 'setting' },
  { label: 'Intent', type: 'intent' },
  { label: 'Others', type: 'misc' },
];

/**
 * Categorize errors so we browse them only once
 */
function categorizeErrorsOnLoad(errors: BotApiError[]): ErrorsByCategories {
  const errorsByCategories: ErrorsByCategories = {};
  CHECKS_LIST.forEach(check => {
    errorsByCategories[check.type] = [];
  });

  errors.forEach((error: BotApiError) => {
    const { category } = error;

    if (!errorsByCategories[category]) errorsByCategories[category] = [];
    errorsByCategories[category].push(error);
  });

  return errorsByCategories;
}

function StatusIcon({
  checkType,
  statusByCheckType,
}: {
  checkType: string,
  statusByCheckType: { [string]: string },
}) {
  if (statusByCheckType[checkType] === 'error') {
    return <CloseIcon className="close-icon-error" />;
  }
  if (statusByCheckType[checkType] === 'warning') {
    return <CloseIcon className="close-icon-warning" />;
  }
  return <CheckIcon className="hasNoErrors" />;
}

function ErrorLine({ error }: { error: BotApiError }) {
  return (
    <CDKList.Item button="true">
      <CDKList.Item.Meta
        title={error.message}
        description={error.solution}
        className={`${error.severity}-message`}
        avatar={<ErrorIcon className={`${error.severity}-report-icon`} />}
      />
    </CDKList.Item>
  );
}

/**
 * Check Results Component
 */
function CheckResults({ checkErrors, checkStatus }: ParentProps) {
  const [errorsByCategories, setErrorsByCategories] = useState<ErrorsByCategories>(
    categorizeErrorsOnLoad(checkErrors),
  );

  useEffect(() => {
    setErrorsByCategories(categorizeErrorsOnLoad(checkErrors));
  }, [checkErrors]);

  const isChecking: boolean = checkStatus === 'IN_PROGRESS';
  const statusByCheckType = _mapValues(errorsByCategories, category => {
    if (!category.length) {
      return 'success';
    }
    if (category.some(error => error.severity === 'error')) {
      return 'error';
    }
    return 'warning';
  });

  return (
    <div className="CheckResultsCard">
      {CHECKS_LIST.map((check: Check) => (
        <div key={check.label}>
          <h4 className="error-category">
            <span>{check.label}</span>
            {isChecking ? (
              <LoadingSpinner size={24} thickness={5} color="#2b8abd" />
            ) : (
              <StatusIcon statusByCheckType={statusByCheckType} checkType={check.type} />
            )}
          </h4>
          {!isChecking &&
            statusByCheckType[check.type] !== 'success' &&
            errorsByCategories[check.type] &&
            errorsByCategories[check.type].map(error => (
              <ErrorLine key={`error-${error.message}`} error={error} />
            ))}
        </div>
      ))}
    </div>
  );
}

export default CheckResults;
