// @flow

import type { Accounts } from '@state/ducks/accounts/types';
import type { Administration } from '@state/ducks/administration/types';
import type { Assessments } from '@state/ducks/assessments/types';
import type { Bots } from '@state/ducks/bots/types';
import type { Chat } from '@state/ducks/chat/types';
import type { Conversations } from '@state/ducks/conversations/types';
import type { Intents } from '@state/ducks/intents/types';
import type { OnboardingState } from '@state/ducks/onboarding/types';
import type { OptionsState } from '@state/ducks/options/types';
import type { Recordings } from '@state/ducks/recordings/types';
import type { Session } from '@state/ducks/session/types';
import type { Snapshots } from '@state/ducks/snapshots/types';
import type { Training } from '@state/ducks/training/types';
import type { Transcripts } from '@state/ducks/transcripts/types';
import type { UI } from '@state/ducks/ui/types';

/* ACTIONS ********************************** */

const SUCCESS: string = 'SUCCESS';
const ERROR: string = 'ERROR'; // TODO update to FAILURE ??

const FLUSH_STORE = 'app/FLUSH_STORE';

const DEFAULT_STORAGE_SETTINGS = { region: 'eu-west-1', provider: 'aws' };
const DEFAULT_CONVERSATIONS_FILTERS = { common: [], specific: [] };
const DEFAULT_FILTERS_FIELDS = { common: [], specific: [] };

/* FLOW ********************************** */

type Status = 'NONE' | 'IN_PROGRESS' | 'ERROR' | 'SUCCESS' | 'WARNING';

type botStatus = { [key: string]: Status };
type Action = {
  type: string,
} & Object;

type BotApiRequestError = {
  name: string,
  status: number,
  message: string,
  solution: string,
  response: Object, // TODO: replace with BotApiError type
};

type BotApiRequestResponse<T> = {
  duration: number,
  apiVersion: string,
  apiResponse: T,
  error?: string,
};

type StudioApiRequestResponse<T> = {
  duration: number,
  apiVersion: string,
  payload: T,
  error?: string,
};

type State = {|
  accounts: Accounts,
  administration: Administration,
  assessments: Assessments,
  bots: Bots,
  chat: Chat,
  conversations: Conversations,
  intents: Intents,
  onboarding: OnboardingState,
  options: OptionsState,
  recordings: Recordings,
  session: Session,
  snapshots: Snapshots,
  training: Training,
  transcripts: Transcripts,
  ui: UI,
|};

type BreadcrumbSentry = {
  category: string,
  timestamp: number,
  data: Object,
  level: string,
  message: string,
};

/**
 * Because of Flow don't that type from @reduxjs/toolkit, we create this type for better dev experience.
 */
type SerializedError = {
  name?: string,
  message?: string,
  code?: string,
  stack?: string,
};

type SelectOption = { label: string, value: string, disabled?: boolean };

type BotIdentity = {
  accountId: string,
  botName: string,
};

type ActionMeta = {
  arg: {
    [key: string]: any,
  },
  requestId: string,
  requestStatus: string,
};

type ActionError = {
  error: Error,
};

type StorageSettings = {|
  region: string,
  provider: string,
|};

export type {
  Action,
  ActionError,
  ActionMeta,
  BotApiRequestError,
  BotApiRequestResponse,
  BotIdentity,
  botStatus,
  BreadcrumbSentry,
  SelectOption,
  SerializedError,
  State,
  Status,
  StorageSettings,
  StudioApiRequestResponse,
};
export {
  DEFAULT_CONVERSATIONS_FILTERS,
  DEFAULT_FILTERS_FIELDS,
  DEFAULT_STORAGE_SETTINGS,
  ERROR,
  FLUSH_STORE,
  SUCCESS,
};
