// @flow

import type { StepType } from '@reactour/tour';
import React from 'react';
import { Trans } from 'react-i18next';

import { CDKCol, CDKRow } from '@calldesk/components';

const onboardingSteps = (): StepType[] => [
  {
    position: 'center',
    content: (
      <CDKRow>
        <CDKCol>
          <CDKRow>
            <CDKCol>
              <b>
                <Trans i18nKey="templates.onboarding.steps.start.title" ns="productTour" />
              </b>
            </CDKCol>
          </CDKRow>
          <CDKRow>
            <CDKCol>
              <p>
                <Trans i18nKey="templates.onboarding.steps.start.content" ns="productTour" />
              </p>
            </CDKCol>
          </CDKRow>
        </CDKCol>
      </CDKRow>
    ),
  },
  {
    selector: '[data-tour="sidebar"]',
    content: <Trans i18nKey="templates.onboarding.steps.sidebar.content" ns="productTour" />,
  },
  {
    selector: '[data-tour="builder-sidebar"]',
    content: (
      <Trans i18nKey="templates.onboarding.steps.builder-sidebar.content" ns="productTour" />
    ),
  },
  {
    selector: '[data-tour="nodes-palette"]',
    position: 'right',
    content: <Trans i18nKey="templates.onboarding.steps.nodes-palette.content" ns="productTour" />,
  },
  {
    selector: '[data-tour="journal-onboarding"]',
    content: (
      <Trans i18nKey="templates.onboarding.steps.journal-onboarding.content" ns="productTour" />
    ),
  },
];

export default onboardingSteps;
