// @flow

import type { SubMenuProps } from 'antd/lib/menu';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MoreIcon from '@material-ui/icons/MoreVert';
import { Menu } from 'antd';

import ability from '@assets/js/calldesk-app-util/casl/ability';

const { SubMenu, Item } = Menu;

type Props = SubMenuProps;

function BotsSubMenuComponent(props: Props) {
  const { t } = useTranslation();
  return (
    <SubMenu
      {...props}
      key="botActions"
      title={<MoreIcon style={{ fontSize: '18px' }} data-test-id="bot-actions" />}
      theme="light"
    >
      <Item key="botActions:rename" disabled>
        <span>{t('navbar.BotsActionSubMenu.rename')}</span>
      </Item>
      <Item key="botActions:duplicate" disabled={!ability.can('create', 'builder')}>
        <span>{t('navbar.BotsActionSubMenu.duplicate')}</span>
      </Item>
      <Item key="botsAction:delete" disabled={!ability.can('delete', 'builder')}>
        <span>{t('navbar.BotsActionSubMenu.remove')}</span>
      </Item>
    </SubMenu>
  );
}

export default BotsSubMenuComponent;
