// @flow

import type { IntentsMap } from '../types';
import _flatten from 'lodash/flatten';
import _uniq from 'lodash/uniq';

import getIntentEntitiesFromExpressions from './extract-entities-from-intent';

/**
 * Extract all entities used in all intents expressions
 */
function extractEntitiesFromIntents(intents: IntentsMap): string[] {
  let entities: string[] = [];

  Object.keys(intents).forEach((intent: string) => {
    const expressions: string[] = intents[intent]?.utterances || [];
    entities = _uniq(_flatten(entities.concat(getIntentEntitiesFromExpressions(expressions))));
  });

  return entities;
}

export default extractEntitiesFromIntents;
