// @flow

import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _capitalize from 'lodash/capitalize';

import { CDKDivider, CDKModal, cdkNotification, CDKSpin } from '@calldesk/components';

import type { Role, User, UserUI } from '@state/ducks/administration/types';
import type { State } from '@state/ducks/app/types';
import LoadingSpinner from '@assets/js/calldesk-components/molecules/LoadingSpinner';
import {
  operations as adminOperations,
  selectors as adminSelectors,
} from '@state/ducks/administration';
import { selectors as sessionSelectors } from '@state/ducks/session';
import { selectors as uiSelectors } from '@state/ducks/ui';

import RolesManager from './components/RolesManager.component';
import UsersManager from './components/UsersManager.component';

import './AdministrationModal.css';

type Props = {
  close: () => void,
};

/**
 * Administration modal component
 * TODO: Rename file and css file
 */
function AdministrationModal({ close }: Props) {
  const dispatch = useDispatch();

  const currentAccount: string = useSelector(uiSelectors.getSelectedAccount);
  const currentUserEmail: string = useSelector(sessionSelectors.getCurrentUserEmail);
  const roles: Role[] = useSelector(adminSelectors.getRoles);
  const manageableUsers: UserUI[] = useSelector(adminSelectors.getManageableUsers);
  const isLoading: boolean = useSelector(adminSelectors.isLoading);
  const administrationError: ?string = useSelector(adminSelectors.getError);
  const selectedUser: ?User = useSelector((state: State) => state.administration.selectedUser);
  const deployProdRole: ?Role = useSelector((state: State) => state.administration.deployProdRole);
  const personalDataRole: ?Role = useSelector((state: State) => state.administration.personalData);

  const adminOps = {
    selectUser: (user: User) => dispatch(adminOperations.selectUser(user)),
    getRoles: () => dispatch(adminOperations.getRoles()),
    getManageableUsers: account => dispatch(adminOperations.getManageableUsers(account)),
    assignRole: (role: Role) =>
      dispatch(
        adminOperations.assignRole({
          roleId: role.id.toString(10),
          accountId: currentAccount,
          userId: selectedUser?.id,
        }),
      ),
    unassignRole: (role: Role) =>
      dispatch(
        adminOperations.unassignRole({ role, accountId: currentAccount, user: selectedUser }),
      ),
    assignAllRoles: () =>
      dispatch(adminOperations.assignAllRoles({ accountId: currentAccount, user: selectedUser })),
    unassignAllRoles: () =>
      dispatch(
        adminOperations.unassignAllRoles({
          currentUserEmail,
          selectedUser,
          accountId: currentAccount,
        }),
      ),
    createUser: (user: User) =>
      dispatch(adminOperations.createUser({ user, accountId: currentAccount })),
    deleteUser: (user: User) =>
      dispatch(adminOperations.deleteUser({ user, accountId: currentAccount })),
    resendInvitation: (user: User) =>
      dispatch(adminOperations.resendInvitation({ user, accountId: currentAccount })),
    reset: () => dispatch(adminOperations.reset()),
  };

  const handleSingleRoleToggle = (role: Role) => {
    if (selectedUser && !selectedUser.roles.includes(role.id)) {
      adminOps.assignRole(role);
      return;
    }
    adminOps.unassignRole(role);
  };

  const handleAllRolesToggle = () => {
    if (selectedUser?.roles.length === roles.length) {
      adminOps.unassignAllRoles();
      return;
    }
    adminOps.assignAllRoles();
  };

  const createUser = ({ firstName, lastName, email }: $Shape<User>) => {
    const newUser: $Shape<User> = {
      firstName,
      lastName,
      email,
      roles: [],
      cognitoStatus: '',
    };

    adminOps.createUser(newUser);
  };

  useEffect(() => {
    adminOps.getManageableUsers(currentAccount);
    adminOps.getRoles();

    return () => adminOps.reset();
  }, []);

  useEffect(() => {
    if (administrationError) {
      cdkNotification.error({
        message: administrationError,
        top: 75,
      });
    }
  }, [administrationError]);

  return (
    <CDKModal
      title={
        <Trans
          i18nKey="commons.title"
          ns="administration"
          values={{ account: _capitalize(currentAccount).replace(/-/g, ' ') }}
          components={{ bold: <b />, highlight: <span className="administration-account-name" /> }}
        />
      }
      footer={null}
      centered
      width={1000}
      onCancel={close}
      visible
    >
      <CDKSpin
        spinning={isLoading && !roles.length && !manageableUsers.length}
        indicator={<LoadingSpinner size={60} thickness={2} color="#2b8abd" pageCentered />}
      >
        <div className="AdministrationModalContent">
          <div className="ModalLeftSection">
            <UsersManager
              createUser={createUser}
              deleteUser={adminOps.deleteUser}
              manageableUsers={manageableUsers}
              selectedUser={selectedUser}
              currentUserEmail={currentUserEmail}
              selectUser={user => adminOps.selectUser(user)}
              resendInvitation={adminOps.resendInvitation}
            />
          </div>

          <CDKDivider type="vertical" />

          <div className="ModalRightSection">
            {selectedUser && selectedUser.id && (
              <RolesManager
                roles={roles}
                currentUsername={
                  selectedUser &&
                  `${_capitalize(selectedUser.firstName)} ${_capitalize(selectedUser.lastName)}`
                }
                selectedHimself={selectedUser?.email === currentUserEmail}
                currentRoles={selectedUser?.roles}
                buildProdRole={deployProdRole}
                grantRole={(role: Role) => handleSingleRoleToggle(role)}
                grantAllRoles={handleAllRolesToggle}
                personalDataRole={personalDataRole}
              />
            )}
          </div>
        </div>
      </CDKSpin>
    </CDKModal>
  );
}

export default React.memo<Props>(AdministrationModal);
