// @flow

import type { OnboardingState } from '@state/ducks/onboarding/types';

const ONBOARDING_STATE_BY_DEFAULT: OnboardingState = {
  currentOnboarding: null,
  status: {
    fetching: { error: null, value: 'NONE' },
    create: { error: null, value: 'NONE' },
    validator: { error: null, value: 'NONE' },
  },
  validatorData: {},
};

export default ONBOARDING_STATE_BY_DEFAULT;
