/* eslint-disable max-lines */
// @flow

import type {
  ExpressionError,
  FormattedIntentsEvaluations,
  Intent,
  IntentBase,
  Intents as State,
  IntentsDescription,
  IntentsEvaluations,
  IntentsMap,
} from './types';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  createAction,
  // $FlowFixMe
  createAsyncThunk,
  // $FlowFixMe
  createEntityAdapter,
  // $FlowFixMe
  createSelector,
  // $FlowFixMe
  createSlice,
} from '@reduxjs/toolkit';
import _difference from 'lodash/difference';
import _entries from 'lodash/entries';
import _omit from 'lodash/omit';
import _partition from 'lodash/partition';
import _uniq from 'lodash/uniq';
import _values from 'lodash/values';

import type { SerializedError, State as RootState, Status } from '@state/ducks/app/types';
import type {
  Asset,
  AssetScope,
  IntentAssetContent,
  IntentContentType,
} from '@state/ducks/customers-assets/types';
import uuid from '@assets/js/calldesk-app-util/uuid';
import { systemIntents } from '@resources/options/available.json';
import { operations as botOperations } from '@state/ducks/bots';
import { api as customersAssetsAPI } from '@state/ducks/customers-assets';

import {
  extractEntitiesFromIntent,
  formatIntentEvaluation,
  intentExpressionIsValid,
} from './utils';

const intentsAdapter = createEntityAdapter();

/* API HANDLERS */
const initialState: State = intentsAdapter.getInitialState({
  status: {
    intents: 'NONE',
    evaluation: 'NONE',
  },
  errors: { last: null, list: [], fetching: null, evaluation: null },
  evaluation: { list: {} },
});

const fetchBotIntentsEvaluations = createAction('intents/fetchBotIntentsEvaluations');

const createIntent = createAsyncThunk(
  'intents/createIntent',
  async (
    { id, account, bot, scope, label, type, description = '', language },
    { dispatch, getState },
  ) => {
    const { options } = getState();
    let createAssetResponse: ?Asset<IntentAssetContent, IntentContentType>;

    if (scope === 'account') {
      createAssetResponse = await customersAssetsAPI.createAsset({
        accountId: account,
        assetType: 'intents',
        content: {
          description,
          utterances: [],
          entities: [],
        },
        contentType: type,
        name: label,
        scope: 'account',
      });
    }

    let newIntent = options.entities.intents?.content?.[language]?.[id] ?? {
      description,
      entities: [],
      id: scope === 'account' ? createAssetResponse?.id : id,
      label,
      scope,
      type: 'custom',
      utterances: [],
    };

    // In case of system intent, there is no id, type and scope in available options
    if (!newIntent.id) newIntent = { ...newIntent, id, type: 'system', scope: 'bot' };

    // TODO : reactively add the intent in bot slice with extra reducer ?
    dispatch(botOperations.addIntent({ bot, intentId: newIntent.id }));

    return newIntent;
  },
);

const changeIntentScope = createAsyncThunk(
  'intents/changeIntentScope',
  async ({ account, bot, id, scope }, { dispatch, getState }) => {
    const {
      intents: { entities },
    } = getState();
    const contentType: IntentContentType = 'custom';
    const currIntent: Intent = entities[id];
    let newIntent: $Shape<Intent>;

    if (scope === 'account') {
      const assetResponse: Asset<IntentAssetContent, IntentContentType> =
        await customersAssetsAPI.createAsset({
          accountId: account,
          assetType: 'intents',
          content: {
            description: currIntent.description || '',
            utterances: currIntent.utterances || [],
            entities: currIntent.entities,
          },
          contentType,
          name: currIntent.label,
          scope,
        });

      newIntent = {
        description: assetResponse.content.description,
        entities: assetResponse.content.entities,
        id: assetResponse.id,
        label: assetResponse.name,
        scope: assetResponse.scope,
        type: assetResponse.contentType,
        utterances: assetResponse.content.utterances,
      };
    } else {
      newIntent = {
        ...currIntent,
        id: uuid(),
        scope,
      };
    }

    dispatch(botOperations.deleteIntent({ bot, intentId: id }));
    dispatch(botOperations.addIntent({ bot, intentId: newIntent.id }));

    return newIntent;
  },
);

const deleteAccountIntent = createAsyncThunk(
  'intents/deleteAccountIntent',
  async ({ account, bot, id }, { dispatch }) => {
    await customersAssetsAPI.deleteAsset({
      accountId: account,
      assetId: id,
      assetType: 'intents',
    });

    dispatch(botOperations.deleteIntent({ bot, intentId: id }));
  },
);

const deleteIntent = createAsyncThunk(
  'intents/deleteIntent',
  async ({ bot, id: intentId }, { dispatch }) => {
    dispatch(botOperations.deleteIntent({ bot, intentId }));
  },
);

const fetchAllIntentsBase = createAsyncThunk(
  'intents/fetchAllIntentsBase',
  async ({ accountId, scope = 'account' }) => {
    const allIntents = await customersAssetsAPI.getAllAssets({
      accountId,
      assetType: 'intents',
      scope,
    });

    return allIntents.map(({ id, name, contentType }) => ({
      id,
      label: name,
      scope,
      type: contentType,
    }));
  },
);

const fetchIntent = createAsyncThunk(
  'intents/fetchIntent',
  async ({ id, account, scope = 'account' }) => {
    const { name, contentType, content } = await customersAssetsAPI.getAsset({
      assetId: id,
      accountId: account,
      assetType: 'intents',
    });
    return {
      id,
      label: name,
      scope,
      type: contentType,
      ...content,
    };
  },
  {
    condition: ({ scope = 'account', id }, { getState }) => {
      const { intents } = getState();
      return scope === 'account' && !intents.entities[id]?.utterances;
    },
  },
);

const fetchIntents = createAsyncThunk(
  'intents/fetchIntents',
  async ({ intentsIds, account, scope = 'account' }) => {
    const res = await Promise.all(
      intentsIds.map(intentId =>
        customersAssetsAPI.getAsset({
          assetId: intentId,
          accountId: account,
          assetType: 'intents',
        }),
      ),
    );
    return res.map(intent => ({
      id: intent.id,
      label: intent.name,
      scope,
      type: intent.contentType,
      ...intent.content,
    }));
  },
);

/* ACTIONS & REDUCERS */

const { actions, reducer } = createSlice({
  name: 'intents',
  initialState,
  reducers: {
    updateIntent: (
      state: State,
      { payload: { intentId, intent } }: PayloadAction<{ intent: Intent, intentId: string }>,
    ) => {
      intentsAdapter.updateOne(state, { id: intentId, changes: intent });
    },
    upsertIntents: (state: State, { payload }: PayloadAction<IntentsMap>) => {
      intentsAdapter.upsertMany(state, payload);
    },
    addExpressionsToIntent: (
      state: State,
      {
        payload: { intentId, expressions },
      }: PayloadAction<{ expressions: string[], intentId: string }>,
    ) => {
      const utterances = state.entities[intentId]?.utterances || [];
      const lowerCasedUtterances = utterances.map(expr => expr.toLowerCase());
      const [valid, errors] = _partition(
        expressions.map(expr => intentExpressionIsValid(expr, lowerCasedUtterances)),
        newExp => newExp.isValid,
      );

      if (valid.length > 0) {
        const newExp = valid.map(expr => expr.expression);
        const newEntities: string[][] = extractEntitiesFromIntent(newExp.concat(utterances));

        state.entities[intentId].entities = newEntities;
        state.errors.last = null;
        utterances.unshift(...newExp);
        state.entities[intentId].utterances = _uniq(utterances);
      }

      if (errors.length > 0) {
        const expressionErrors = errors.map(({ isValid, ...rest }) => ({ ...rest }));
        state.errors.last = expressionErrors;
        state.errors.list.push(...expressionErrors);
      }
    },
    deleteExpressionsInIntent: (
      state: State,
      {
        payload: { expressions, intentId },
      }: PayloadAction<$Exact<{ intentId: string, expressions: string[] }>>,
    ) => {
      const newUtterances: string[] = _difference(state.entities[intentId].utterances, expressions);
      intentsAdapter.updateOne(state, {
        id: intentId,
        changes: { entities: extractEntitiesFromIntent(newUtterances), utterances: newUtterances },
      });
    },
    setIntents: (state: State, { payload }: PayloadAction<IntentsMap>) => {
      const REGEXP_UUID: RegExp =
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
      const formattedIntentsMap: IntentsMap = Object.keys(payload).reduce(
        (intentsMapAcc: IntentsMap, intentId: string) => {
          const intent: Intent = payload[intentId];
          // Old config doesn't have scope key, this why we define here the scope on presence of utterances or not
          const intentAssetScope: AssetScope = intent.utterances ? 'bot' : 'account';
          const intentEnrichedWithScope: Intent = {
            ...intent,
            id: intentId,
            scope: intent.scope || intentAssetScope,
            type: REGEXP_UUID.test(intentId) ? 'custom' : 'system',
          };

          return {
            ...intentsMapAcc,
            [intentId]: intentEnrichedWithScope,
          };
        },
        {},
      );

      // NOTE: Flush before `setAll` because some leftovers from previous bot might still exist
      intentsAdapter.removeAll(state);
      intentsAdapter.setAll(state, formattedIntentsMap);
    },
    updateExpressionInIntent: (
      state: State,
      {
        payload: { intentId, expressionIndex, newExpressionValue },
      }: PayloadAction<{ expressionIndex: number, newExpressionValue: string, intentId: string }>,
    ) => {
      const newUtterances = state.entities[intentId].utterances;
      newUtterances[expressionIndex] = newExpressionValue;
      const entities = extractEntitiesFromIntent(newUtterances);
      intentsAdapter.updateOne(state, {
        id: intentId,
        changes: {
          entities,
        },
      });
    },
    /* INTENT EVALUATION */
    intentEvaluation: (state: State) => {
      state.status.evaluation = 'IN_PROGRESS';
    },
    intentEvaluationSuccess: (
      state: State,
      {
        payload: { intents: evaluatedIntentsMap, averageIntentScore },
      }: PayloadAction<
        $Exact<{
          intents: IntentsEvaluations,
          averageIntentScore: number,
        }>,
      >,
    ) => {
      const intents: Intent[] = _values(state.entities);

      Object.keys(evaluatedIntentsMap).forEach((evaluatedIntentLabel: string) => {
        const intent: ?Intent = intents.find(({ label }: Intent) => label === evaluatedIntentLabel);

        // - NOTE: Cannot use API response for "utterances" because in case count is < 5, some
        // utterances are duplicated in order to meet minimal requirements for evaluation.
        // - NOTE: Optional chaining applied because new intents could be received from the API,
        // which are not yet in the store
        const hasAtLeastFiveUtterances: boolean = (intent?.utterances?.length || 0) >= 5;

        if (!!intent && hasAtLeastFiveUtterances) {
          state.evaluation.list[intent.id] = formatIntentEvaluation(
            evaluatedIntentsMap[intent.label],
          );
        }
      });
      state.evaluation.averageScore = averageIntentScore;
      state.status.evaluation = 'SUCCESS';
    },
    intentEvaluationError: (state: State, { payload }: PayloadAction<SerializedError>) => {
      state.status.evaluation = 'ERROR';
      state.errors.list.push(payload);
      state.errors.evaluation = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(changeIntentScope.fulfilled, (state, { payload: intent }: PayloadAction<Intent>) => {
        intentsAdapter.upsertOne(state, intent);
      })
      .addCase(createIntent.fulfilled, (state, { payload }: PayloadAction<Intent>) => {
        intentsAdapter.addOne(state, payload);
      })
      .addCase(
        deleteIntent.fulfilled,
        (state, { meta }: { meta: { arg: { id: string, scope: AssetScope } } }) => {
          if (meta.arg.scope === 'bot') {
            intentsAdapter.removeOne(state, meta.arg.id);
          }
        },
      )
      .addCase(
        fetchAllIntentsBase.fulfilled,
        (
          state: State,
          { payload, meta }: PayloadAction<IntentBase[]> & { meta: { arg: { scope: AssetScope } } },
        ) => {
          const scope = meta.arg?.scope || 'account';
          const intentsToClean = state.ids.filter(
            intentId => state.entities[intentId].scope === scope,
          );

          // Flush previous account intents in case there are missing account intents set by setIntents action
          intentsAdapter.removeMany(state, intentsToClean);
          intentsAdapter.setMany(state, payload);
        },
      )
      .addCase(fetchIntents.fulfilled, (state, { payload }: PayloadAction<Intent[]>) => {
        intentsAdapter.upsertMany(state, payload);
      })
      .addCase(fetchIntent.fulfilled, (state, { payload }: PayloadAction<Intent>) => {
        intentsAdapter.setOne(state, payload);
      })
      .addCase(
        deleteAccountIntent.fulfilled,
        (state, { meta }: { meta: { arg: { id: string } } }) => {
          intentsAdapter.removeOne(state, meta.arg.id);
        },
      )
      .addMatcher(
        action => action.type?.startsWith('intents') && action.type?.endsWith('/pending'),
        state => {
          state.errors.fetching = null;
          state.status.intents = 'IN_PROGRESS';
        },
      )
      .addMatcher(
        action => action.type?.startsWith('intents') && action.type?.endsWith('/rejected'),
        (state, action) => {
          state.status.intents = 'ERROR';
          state.errors.fetching = action.error;
        },
      )
      .addMatcher(
        action => action.type?.startsWith('intents') && action.type?.endsWith('/fulfilled'),
        state => {
          state.status.intents = 'SUCCESS';
          state.errors.fetching = null;
        },
      );
  },
});

Object.assign(actions, {
  changeIntentScope,
  createIntent,
  deleteIntent,
  fetchAllIntentsBase,
  fetchIntent,
  fetchIntents,
  fetchBotIntentsEvaluations,
  deleteAccountIntent,
});

/* SELECTORS */
const genericSelectors = intentsAdapter.getSelectors((state: RootState) => state.intents);
const selectAllFromIds = createSelector(
  genericSelectors.selectAll,
  (state: RootState, intentsIds: string[]) => intentsIds,
  (intents: Intent[], intentsIds: string[]): Intent[] =>
    intents.filter(intent => intentsIds.includes(intent.id)),
);
const selectAllByScope = createSelector(
  genericSelectors.selectAll,
  (state, scope: AssetScope) => scope,
  (intents: Intent[], scope: AssetScope): Intent[] =>
    intents.filter(intent => intent.scope === scope),
);
const selectIntentsMapByIds = createSelector(
  genericSelectors.selectEntities,
  (state, intentsIds: string[]) => intentsIds,
  (intents: IntentsMap, intentsIds: string[]): IntentsMap =>
    intentsIds.reduce(
      (intentAcc, intentId) => ({ ...intentAcc, [intentId]: intents[intentId] }),
      {},
    ),
);

const SYSTEM_INTENTS: { [id: string]: Intent } = Object.fromEntries(
  systemIntents.map((intent: string) => [
    intent,
    {
      label: intent,
      description: '',
      utterances: [],
      entities: [],
    },
  ]),
);
const selectIntentsDescription = createSelector(
  selectIntentsMapByIds,
  (intents: IntentsMap): IntentsDescription =>
    Object.fromEntries(
      _entries({ ...SYSTEM_INTENTS, ...intents }).map(([key, intent]) => [
        key,
        _omit(intent, ['utterances']),
      ]),
    ),
);

const getIntentsError = (state: RootState): ExpressionError[] => state.intents.errors.list;

const getIntentsEvaluation = (state: RootState): FormattedIntentsEvaluations =>
  state.intents.evaluation;

const getIntentsEvaluationStatus = (state: RootState): Status => state.intents.status.evaluation;

const selectors = {
  ...genericSelectors,
  getIntentsError,
  getIntentsEvaluation,
  getIntentsEvaluationStatus,
  selectAllByScope,
  selectAllFromIds,
  selectIntentsDescription,
  selectIntentsMapByIds,
};

/* EXPORTS */
export { actions, selectors };
export default reducer;
