// @flow

import _get from 'lodash/get';

import type { ValidateFn } from '@state/ducks/onboarding/types';

import validators from './validators';

/**
 * Get quest validator for a given path in exercise
 */
function getQuestValidator(exerciseId: string, questPath: string): ?ValidateFn<any> {
  const matchingExerciseId: ?string = Object.keys(validators).find(
    (exerciseKey: string) => exerciseKey === exerciseId,
  );

  return matchingExerciseId ? _get(validators, `${matchingExerciseId}.${questPath}`, null) : null;
}

export default getQuestValidator;
