// @flow

import type { IntentMatch, SayMessage } from '../types';

import ThinkItemToSayFactory from './think-item-to-message-impl/think-item-to-say-action-message';

const ThinkToSay = ThinkItemToSayFactory();

const Factory = () => {
  const formatter = (intentMatch: IntentMatch, language: string, timestamp: number): SayMessage =>
    ThinkToSay.formatter(intentMatch, language, timestamp);
  return { formatter };
};

export default Factory;
