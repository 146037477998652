// @flow
import React, { type Node } from 'react';
import { Redirect, Route } from 'react-router-dom';

import * as cognito from '@api/cognito';
import logger from '@assets/js/calldesk-app-util/logger';

type Props = {
  component?: any,
  path: string,
  render?: () => Node,
};
type State = {
  isAuthenticated: boolean | null,
};

/**
 *
 */
export default class PrivateRoute extends React.Component<Props, State> {
  /**
   * Constructor
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      isAuthenticated: null,
    };
  }

  /**
   * Lifecycle hook
   */
  componentDidMount() {
    const { path } = this.props;

    logger.info('[PrivateRoute]: component did mount');
    logger.info(`[PrivateRoute]: checking user authentication to access ${path}`);

    cognito
      .checkSessionToken()
      .then(userToken => {
        if (userToken) {
          this.setState({ isAuthenticated: true });
        } else {
          this.setState({ isAuthenticated: false });
        }
      })
      .catch(() => this.setState({ isAuthenticated: false }));
  }

  /**
   * Rendering method
   */
  render() {
    const { isAuthenticated } = this.state;
    const { path, component, render } = this.props;

    if (isAuthenticated !== null) {
      if (isAuthenticated) {
        return <Route path={path} component={component} render={render} />;
      }
      return <Redirect to={{ pathname: '/login' }} />;
    }
    return null;
  }
}
