// @flow

type MonitoringCatchExceptionArg = {
  exception?: any,
  message?: string,
  location: string,
  userSub?: string,
  functionType: 'saga' | 'action' | 'request',
  params?: Object,
  accountId?: string,
  botName?: string,
  others?: any,
};

const DUCK_KEYS_NOT_AVAILABLE: string[] = [
  'accounts',
  'administration',
  'chat',
  'discussions',
  'snapshots',
  'sources',
  'training',
  'ui',
];

const DATA_NOT_AVAILABLE: string = 'N/A';

const TOO_LARGE_DATA: string = 'TOO_LARGE_DATA';

const SENSITIVE_DATA: string = 'SENSITIVE_DATA';

export type { MonitoringCatchExceptionArg };
export { DATA_NOT_AVAILABLE, DUCK_KEYS_NOT_AVAILABLE, SENSITIVE_DATA, TOO_LARGE_DATA };
