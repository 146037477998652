// @flow

import request from '@amplify/api/request';

/**
 *
 * @return {Promise<{name: string, description: string, id: number}[]>}
 */
async function getRoles() {
  const { payload } = await request.usersApi({
    method: 'GET',
    path: '/roles',
  });

  return payload;
}

/**
 *
 * @return {Promise<{firstName: string, lastName: string, roles: number[], id: number, email: string}[]>}
 */
async function getManageableUsers(accountId: string) {
  const { payload } = await request.usersApi({
    method: 'GET',
    path: `/users`,
    queryStringParameters: { accountId },
  });

  return payload;
}

/**
 *
 * @param userId
 * @param accountId
 * @param roleId
 * @return {Promise<boolean>}
 */
async function assignRole({
  userId,
  accountId,
  roleId,
}: {
  userId: string,
  accountId: string,
  roleId: string,
}) {
  const { payload } = await request.usersApi({
    method: 'PATCH',
    path: `/users/${userId}/accounts/${accountId}/roles/${roleId}`,
  });

  return payload;
}

/**
 *
 * @param userId
 * @param accountId
 * @param rolesIds
 * @return {Promise<boolean>}
 */
async function unassignRole({
  userId,
  accountId,
  roleId,
}: {
  userId: string,
  accountId: string,
  roleId: number,
}) {
  const { payload } = await request.usersApi({
    method: 'DELETE',
    path: `/users/${userId}/accounts/${accountId}/roles/${roleId}`,
  });

  return payload;
}

/**
 *
 * @param firstName
 * @param lastName
 * @param email
 * @param roles
 * @param accountId
 * @return {Promise<{id: (*|string), cognitoStatus: ?string}>}
 */
async function createUser({
  firstName,
  lastName,
  email,
  accountId,
}: {
  firstName: string,
  lastName: string,
  email: string,
  accountId: string,
}) {
  const { payload } = await request.usersApi({
    method: 'POST',
    path: `/users`,
    body: {
      firstName,
      lastName,
      email,
      accountId,
    },
  });

  return payload;
}

/**
 *
 * @param userId
 * @param accountId
 * @return {Promise<boolean>}
 */
async function removeUserAccess({ userId, accountId }: { userId: string, accountId: string }) {
  const { payload } = await request.usersApi({
    method: 'DELETE',
    path: `/users/${userId}/accounts/${accountId}/access`,
  });

  return payload;
}

/**
 *
 * @return {Promise<{firstName: string, lastName: string, roles: number[], id: number, email: string}[]>}
 */
async function resendTemporaryPassword(
  notConfirmedUserEmail: string,
  accountId: string,
): Promise<{ temporaryPasswordSent: boolean }> {
  const { payload } = await request.usersApi({
    method: 'POST',
    path: `/users/resend-password`,
    body: { notConfirmedUserEmail, accountId },
  });

  return payload;
}

export {
  assignRole,
  createUser,
  getManageableUsers,
  getRoles,
  removeUserAccess,
  resendTemporaryPassword,
  unassignRole,
};
