// @flow

import { Ability, AbilityBuilder } from '@casl/ability';

/**
 * This is where gate keeper rules are applied to user
 */
function defineRules() {
  const { rules } = AbilityBuilder.extract();
  return new Ability(rules);
}

const ability: Ability = defineRules();

export default ability;
