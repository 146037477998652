// @flow

import type { ActionMeta, State as RootState } from '../app/types';
import type { Administration as State, Role, User } from './types';
import {
  // $FlowFixMe
  createAsyncThunk,
  // $FlowFixMe
  createEntityAdapter,
  // $FlowFixMe
  createSelector,
  // $FlowFixMe
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit';
import i18next from 'i18next';

import {
  assignRole as assignRoleAPI,
  createUser as createUserAPI,
  getManageableUsers as getManageableUsersAPI,
  getRoles as getRolesAPI,
  removeUserAccess as removeUserAccessAPI,
  resendTemporaryPassword as resendTemporaryPasswordAPI,
  unassignRole as unassignRoleAPI,
} from './api';

const sort = (entityA, entityB, prop) =>
  entityA[prop].toString().localeCompare(entityB[prop].toString());

const rolesAdapter = createEntityAdapter({
  selectId: (role: Role) => role.name.toLocaleLowerCase(),
  sortComparer: (roleA, roleB) => sort(roleA, roleB, 'level'),
});
const usersAdapter = createEntityAdapter({
  sortComparer: (userA, userB) => sort(userA, userB, 'email'),
});

const initialState = {
  deployProdRole: null,
  error: null,
  manageableUsers: usersAdapter.getInitialState(),
  personalData: null,
  roles: rolesAdapter.getInitialState(),
  selectedUser: null,
  status: 'NONE',
  temporaryPasswordSent: null,
};

/* API HANDLERS */

/* ROLES */
const getRoles = createAsyncThunk('administration/FETCH_ROLES', () => getRolesAPI());
const assignRole = createAsyncThunk(
  'administration/ASSIGN_ROLE',
  (assignArgs: { userId: string, accountId: string, roleId: string }) => assignRoleAPI(assignArgs),
);
const unassignRole = createAsyncThunk(
  'administration/UNASSIGN_ROLE',
  async (
    { accountId, role, user }: { accountId: string, role: Role, user: User },
    { getState },
  ) => {
    const currentState = getState();
    const buildRole = rolesAdapter
      .getSelectors()
      .selectById(currentState.administration.roles, 'build');
    const deployProdId: number = currentState.administration.deployProdRole?.id;

    if (role.id === buildRole.id && user.roles.includes(deployProdId))
      await unassignRoleAPI({ userId: user.id, accountId, roleId: deployProdId });

    await unassignRoleAPI({ userId: user.id, accountId, roleId: role.id });

    return buildRole;
  },
);
const assignAllRoles = createAsyncThunk(
  'administration/ASSIGN_ALL_ROLES',
  async ({ accountId, user }: { accountId: string, user: User }, { getState }) => {
    const currentState = getState();

    const allRoles = rolesAdapter.getSelectors().selectAll(currentState.administration.roles);
    const rolesToAdd = allRoles.filter(role => !user.roles.includes(role.id));

    await Promise.all(
      rolesToAdd.map(role => assignRoleAPI({ userId: user.id, accountId, roleId: role.id })),
    );
  },
);
const unassignAllRoles = createAsyncThunk(
  'administration/UNASSIGN_ALL_ROLES',
  async ({
    selectedUser,
    currentUserEmail,
    accountId,
  }: {
    // TODO : use getState instead ?
    selectedUser: User,
    currentUserEmail: string,
    accountId: string,
  }) => {
    let rolesToDelete = selectedUser.roles;
    // Current logged user should not be able to remove the admin role
    // which is the number 1 in enum
    if (selectedUser.email === currentUserEmail) {
      rolesToDelete = rolesToDelete.filter(role => role !== 1);
    }

    await Promise.all(
      rolesToDelete.map(roleId => unassignRoleAPI({ userId: selectedUser.id, accountId, roleId })),
    );
  },
);
/* USERS */
const getManageableUsers = createAsyncThunk(
  'administration/FETCH_MANAGEABLE_USERS',
  async (accountId: string) => getManageableUsersAPI(accountId),
);
const createUser = createAsyncThunk(
  'administration/ADD_USER',
  async ({ user, accountId }: { user: User, accountId: string }) => {
    const { id, cognitoStatus } = await createUserAPI({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      accountId,
    });

    return { ...user, cognitoStatus, id };
  },
);
const deleteUser = createAsyncThunk(
  'administration/DELETE_USER',
  ({ user, accountId }: { user: User, accountId: string }) =>
    removeUserAccessAPI({ userId: user.id, accountId }),
);
const resendInvitation = createAsyncThunk(
  'administration/RESEND_INVITATION',
  async ({ user, accountId }: { user: User, accountId: string }) => {
    const { temporaryPasswordSent } = await resendTemporaryPasswordAPI(user.email, accountId);

    if (temporaryPasswordSent) return;

    throw new Error(i18next.t('operations.errors.sendInvitation', { ns: 'administration' }));
  },
);
/* ACTIONS & REDUCERS */

// eslint-disable-next-line import/no-mutable-exports
const { actions, reducer } = createSlice({
  name: 'administration',
  initialState,
  reducers: {
    selectUser: (state: State, action: PayloadAction<User>) => {
      state.selectedUser = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: builder =>
    builder
      /* FETCH ROLES */
      .addCase(getRoles.fulfilled, (state, action: PayloadAction<{ roles: Role[] }>) => {
        rolesAdapter.setAll(state.roles, action.payload.roles);
        state.deployProdRole = action.payload.roles.find(({ name }) => name === 'Deploy-Prod');
        state.personalData = action.payload.roles.find(
          ({ name }) => name === 'Monitoring-Personal-Data',
        );
      })
      .addCase(getRoles.rejected, state => {
        rolesAdapter.setAll(state.roles, []);
      })
      /* ASSIGN ROLE */
      .addCase(assignRole.fulfilled, (state, { meta }) => {
        const { arg } = meta;
        state.selectedUser.roles.push(parseInt(arg.roleId, 10));
        state.manageableUsers.entities[arg.userId].roles.push(parseInt(arg.roleId, 10));
      })
      /* UNASSIGN ROLE */
      .addCase(unassignRole.fulfilled, (state, action: PayloadAction<Role> & { meta: Object }) => {
        let { roles } = state.selectedUser;
        const { arg } = action.meta;

        if (arg.role.id === action.payload.id)
          roles = roles.filter(roleId => roleId !== state.deployProdRole.id);

        state.selectedUser.roles = roles.filter(roleId => roleId !== arg.role.id);
        state.manageableUsers.entities[state.selectedUser.id] = state.selectedUser;
      })
      /* ASSIGN ALL ROLES */
      .addCase(assignAllRoles.fulfilled, state => {
        const allRoles = rolesAdapter.getSelectors().selectAll(state.roles);
        const allRolesIds = allRoles.map(role => role.id);

        state.selectedUser.roles = allRolesIds;
        state.manageableUsers.entities[state.selectedUser.id].roles = allRolesIds;
      })
      /* UNASSIGN ALL ROLES */
      .addCase(unassignAllRoles.fulfilled, (state, { meta }) => {
        const { arg } = meta;
        const userManagedHisOwnRoles = state.selectedUser.email === arg.currentUserEmail;
        state.selectedUser.roles = userManagedHisOwnRoles ? [1] : [];
        state.manageableUsers.entities[state.selectedUser.id].roles = userManagedHisOwnRoles
          ? [1]
          : [];
      })
      /* FETCH MANAGEABLE USERS */
      .addCase(getManageableUsers.fulfilled, (state, action: PayloadAction<{ users: User[] }>) => {
        usersAdapter.setAll(state.manageableUsers, action.payload.users);
      })
      .addCase(getManageableUsers.rejected, state => {
        usersAdapter.setAll(state.manageableUsers, []);
      })
      /* CREATE USER */
      .addCase(createUser.pending, (state, { meta }: { meta: ActionMeta }) => {
        usersAdapter.addOne(state.manageableUsers, { ...meta.arg.user });
      })
      .addCase(createUser.rejected, state => {
        // We remove the "loading" user without id
        usersAdapter.removeOne(state.manageableUsers, undefined);
      })
      .addCase(
        createUser.fulfilled,
        (state, action: PayloadAction<User> & { meta: ActionMeta }) => {
          // We remove the "loading" user without id
          usersAdapter.removeOne(state.manageableUsers, undefined);
          usersAdapter.upsertOne(state.manageableUsers, action.payload);
          // Update the selected user if one was set
          if (state.selectedUser) state.selectedUser = action.payload;
        },
      )
      /* DELETE USER */
      .addCase(deleteUser.fulfilled, (state, { meta }: { meta: ActionMeta }) => {
        state.selectedUser = {};
        usersAdapter.removeOne(state.manageableUsers, meta.arg.user.id);
      })
      /* RESEND INVITATION */
      .addCase(resendInvitation.fulfilled, state => {
        state.temporaryPasswordSent = true;
      })
      .addCase(resendInvitation.rejected, state => {
        state.temporaryPasswordSent = false;
      })
      .addMatcher(
        action => action.type?.startsWith('administration') && action.type?.endsWith('/pending'),
        state => {
          state.error = null;
          state.status = 'IN_PROGRESS';
        },
      )
      .addMatcher(
        action => action.type?.startsWith('administration') && action.type?.endsWith('/rejected'),
        (state, action) => {
          state.status = 'ERROR';
          state.error = action.error;
        },
      )
      .addMatcher(
        action => action.type?.startsWith('administration') && action.type?.endsWith('/fulfilled'),
        state => {
          state.status = 'SUCCESS';
        },
      ),
});

Object.assign(actions, {
  getRoles,
  assignRole,
  unassignRole,
  assignAllRoles,
  unassignAllRoles,
  getManageableUsers,
  createUser,
  deleteUser,
  resendInvitation,
});

/* SELECTORS */
const rolesSelector = rolesAdapter.getSelectors((state: RootState) => state.administration.roles);
const usersSelector = usersAdapter.getSelectors(
  (state: RootState) => state.administration.manageableUsers,
);

const selectUserRoles = createSelector(usersSelector.selectById, user => user?.roles);

const isLoading = (state: RootState): boolean => state.administration.status === 'IN_PROGRESS';
const getError = (state: RootState): ?string =>
  state.administration.status === 'ERROR' ? state.administration.error?.message : null;

const selectors = {
  isLoading,
  getError,
  getRoles: rolesSelector.selectAll,
  getManageableUsers: usersSelector.selectAll,
  selectUserRoles,
};

/* EXPORTS */
export { actions, selectors };
export default reducer;
