// @flow

import type { AvailablePhoneNumbers, BotConfig } from '@state/ducks/bots/types';
import type { Exercise, ValidateFnReturn } from '@state/ducks/onboarding/types';

/**
 * Validate `addPhoneNumber` quest
 */
function validate(
  botConfig?: BotConfig,
  currentProgression?: Exercise,
  validatorData: any,
): ValidateFnReturn<void> {
  const phoneNumberDev: ?AvailablePhoneNumbers =
    validatorData && validatorData.phoneNumbers && validatorData.phoneNumbers.dev;

  return {
    validated: !!(
      (phoneNumberDev && phoneNumberDev.primary && phoneNumberDev.primary.length > 0) ||
      (phoneNumberDev && phoneNumberDev.backup && phoneNumberDev.backup.length > 0)
    ),
  };
}

export default validate;
