// @flow

import type { PayloadAction } from '@reduxjs/toolkit';
import type { Saga } from 'redux-saga';
import { call, put, select, take } from 'redux-saga/effects';

import type { BotApiError } from '@state/ducks/bots/types';
import type { IntentsMap } from '@state/ducks/intents/types';
import { getUserSub } from '@api/cognito';
import { operations as botsOperations, selectors as botsSelectors } from '@state/ducks/bots';
import * as botsApi from '@state/ducks/bots/api';
import { formatBotConfig } from '@state/ducks/bots/utils';
import {
  operations as intentsOperations,
  selectors as intentsSelectors,
} from '@state/ducks/intents';
import { selectors as uiSelectors } from '@state/ducks/ui';
import { monitoringCatchException } from '@state/monitoring-enhancer';

/**
 * Start saga to validate a bot (its config and its graphs)
 */
function* validateBotSaga({
  payload,
}: PayloadAction<{
  bot: string,
}>): Saga<any> {
  const userSub: string = yield call(getUserSub);
  const accountId: string = yield select(uiSelectors.getSelectedAccount);
  const botConfig = yield select(botsSelectors.getBotConfig, payload.bot);

  const botConfigIntents = yield select(intentsSelectors.selectAllFromIds, botConfig.intents);

  // Required to test thunk called in sagas
  const fetchIntentsThunk = yield call(intentsOperations.fetchIntents, {
    intentsIds: botConfigIntents.filter(intent => !intent.utterances).map(intent => intent.id),
    account: accountId,
  });

  yield put(fetchIntentsThunk);
  yield take(intentsOperations.fetchIntents.fulfilled);

  const formattedIntentsMap: IntentsMap = yield select(
    intentsSelectors.selectIntentsMapByIds,
    botConfig.intents,
  );

  yield put(botsOperations.validateBot(payload.bot));

  try {
    const { hints }: { hints: BotApiError[] } = yield call(
      botsApi.validateBotConfig,
      accountId,
      payload.bot,
      {
        ...formatBotConfig(botConfig),
        intents: formattedIntentsMap,
      },
    );

    yield put(botsOperations.validateBotSuccess({ bot: payload.bot, hints }));
  } catch (error) {
    const errors = (error.response && error.response.errors) || [error.message];
    yield put(botsOperations.validateBotError({ bot: payload.bot, errors }));
    // We don't want to send an exception to sentry in the validate method when status code is 422 (Unprocessable Entity)
    // Because it means that the config the user is editing does not comply to the bot config schema.
    // It can happen in various situation while the bot is under construction, so it's better to disable the tracking of this issue
    if (![403, 422].includes(error.status)) {
      monitoringCatchException({
        exception: error,
        userSub,
        location: 'state/sagas/bots/validateBotSaga',
        functionType: 'saga',
        accountId,
        botName: payload.bot,
      });
    }
  }
}

export default validateBotSaga;
