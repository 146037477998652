// @flow

import type { BotConfig } from '@state/ducks/bots/types';
import type { Categories, Exercise, ValidatorData } from '@state/ducks/onboarding/types';
import type { AvailableOptions } from '@state/ducks/options/types';

import sorting from '../sorting';
import validateSteps from './validate-steps';

/**
 * Run validators functions on each categories
 */
function validateCategories(
  exerciseId: string,
  botConfig: BotConfig,
  progression: Exercise,
  validatorData: ValidatorData,
  availableOptions: AvailableOptions,
): Categories {
  const categories: Categories = progression.categories || {};

  return Object.keys(categories)
    .sort(sorting(categories))
    .reduce(
      (newCategories: Categories, cat: string) => ({
        ...newCategories,
        [cat]: {
          ...categories[cat],
          steps: validateSteps(
            exerciseId,
            cat,
            botConfig,
            progression,
            validatorData,
            availableOptions,
          ),
        },
      }),
      {},
    );
}

export default validateCategories;
