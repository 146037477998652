// @flow

import type { ActionMessage, ActionNode, TraceItem, TraceItemFactory } from '../../types';

import type { ConversationState } from '@state/ducks/chat/types';
import { GENERIC_ACTION_NODES } from '@state/ducks/bots/types';

import { getId, stateFormattedForActionMessage } from '../../util';

const Factory = (): TraceItemFactory<ActionNode> => {
  const formatter = (
    traceItem: TraceItem<ActionNode>,
    state: $Shape<ConversationState>,
  ): ActionMessage => {
    const newState: $Shape<ConversationState> = stateFormattedForActionMessage(state);
    const actionMessage: ActionMessage = {
      content: traceItem.node.name ?? traceItem.node.action,
      sender: 'action',
      state: newState,
      nodeId: traceItem.node.id,
      id: getId({ timestamp: traceItem.timestamp, nodeId: traceItem.node.id }),
      timestamp: traceItem.timestamp,
      type: 'generic',
    };

    if (traceItem.node.params) actionMessage.params = traceItem.node.params;
    return actionMessage;
  };

  const validator = (traceItem: TraceItem<any>): boolean =>
    Object.values(GENERIC_ACTION_NODES).includes(traceItem.node?.name);

  return { formatter, validator };
};
export default Factory;
