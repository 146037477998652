// @flow

import React from 'react';
import { useTranslation } from 'react-i18next';
import OnboardingIcon from '@material-ui/icons/Explore';
import { Menu } from 'antd';

import { CDKTooltip } from '@calldesk/components';

type Props = {| handleShowOnboardingModal: () => void |};

function OnboardingButton({ handleShowOnboardingModal, ...props }: Props) {
  const { t } = useTranslation();

  return (
    <Menu.Item
      key="onboarding"
      className="onboarding-item-menu"
      onClick={handleShowOnboardingModal}
      data-tour="journal-onboarding"
      {...props}
    >
      <CDKTooltip title={t('other.Journal', { ns: 'commons' })}>
        <OnboardingIcon />
      </CDKTooltip>
    </Menu.Item>
  );
}

export default OnboardingButton;
