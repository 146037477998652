// @flow

import type { BotConfig, Tts } from '@state/ducks/bots/types';
import type { ValidateFnReturn, ValidatorData } from '@state/ducks/onboarding/types';
import type { AvailableOptions } from '@state/ducks/options/types';

/**
 * Validate `addChooseVoice` quest
 */
function validate(
  botConfig: BotConfig,
  currentProgression: any,
  validatorData: ValidatorData,
  { voices: availableVoices }: AvailableOptions,
): ValidateFnReturn<void> {
  const tts: Tts = botConfig.tts || { voices: [] };
  const language: string = botConfig.language || 'en-US';
  const defaultVoice: ?string =
    availableVoices?.[language] && Object.keys(availableVoices[language])[0];
  const primaryVoice: ?string = tts.voices?.[0]?.voice;

  return {
    validated: !!defaultVoice && !!primaryVoice && defaultVoice !== primaryVoice,
  };
}

export default validate;
