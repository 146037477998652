// @flow
import { Auth } from '@aws-amplify/auth';

/**
 * Initiate a forgot password request
 *
 * @param username
 * @return {Promise<?string>} additionnal message if needed for reset password flow, otherwise resolves empty
 */
function forgotPassword(username: string): Promise<?string> {
  return Auth.forgotPassword(username);
}

export default forgotPassword;
