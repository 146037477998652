// @flow

import type { RouterHistory } from 'react-router';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { Saga } from 'redux-saga';
import _sortBy from 'lodash/sortBy';
import { call, put, select } from 'redux-saga/effects';

import { getUserSub } from '@api/cognito';
import {
  operations as accountsOperations,
  selectors as accountsSelectors,
} from '@state/ducks/accounts';
import { operations as appOperations } from '@state/ducks/app';
import { operations as botsOperations } from '@state/ducks/bots';
import * as botsApi from '@state/ducks/bots/api';
import { selectors as uiSelectors } from '@state/ducks/ui';
import { monitoringCatchException } from '@state/monitoring-enhancer';

/**
 * Start saga following the start of getBots
 */
function* getBotsSaga({
  payload,
}: PayloadAction<{ history?: RouterHistory, account?: string }>): Saga<any> {
  let accountId: string;
  const userSub: string = yield call(getUserSub);
  if (!payload.account) {
    accountId = yield select(uiSelectors.getSelectedAccount);
  } else {
    accountId = payload.account;
  }
  const isLoading: boolean = yield select(accountsSelectors.isLoading);

  if (isLoading) return;

  yield put(botsOperations.setSpecificBotsStatus({ name: 'fetching', value: 'IN_PROGRESS' }));

  const accountBots: string[] = yield select(accountsSelectors.getBots, accountId);

  try {
    const fetchedBots: string[] =
      accountBots && accountBots.length > 0
        ? accountBots
        : yield call(botsApi.fetchBotsForAccount, accountId);

    if (fetchedBots && fetchedBots.length > 0) {
      const sortedBots: string[] = _sortBy(fetchedBots);
      // update bots in state
      yield put(accountsOperations.updateBots({ accountId, botIds: sortedBots }));
      // let store knows that accounts fetching is a SUCCESS
      yield put(botsOperations.setSpecificBotsStatus({ name: 'fetching', value: 'SUCCESS' }));
    }
  } catch (err) {
    const error = err;

    // update error in state
    yield put(botsOperations.updateError(error.message));
    // let store knows that bots fetching is an ERROR
    yield put(botsOperations.setSpecificBotsStatus({ name: 'fetching', value: 'ERROR' }));
    if (error.status === 403 || error.status === 404) {
      if (payload.history) {
        const { history } = payload;
        history.push('/');
        yield put(appOperations.startAppSaga({ history, location: history.location }));
      }
    } else {
      monitoringCatchException({
        exception: error,
        userSub,
        location: 'state/sagas/bots/getBotsSaga',
        functionType: 'saga',
        accountId: payload.account,
      });
    }
  }
}
export default getBotsSaga;
