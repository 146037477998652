/**
 *
 * @param cognito
 * @return {{userPoolWebClientId: *, region: *, identityPoolId: *, userPoolId: *}}
 */
function fromCalldeskCognitoConfig(cognito) {
  return {
    identityPoolId: cognito.COGNITO_IDENTITY_POOL_ID,
    region: cognito.COGNITO_REGION,
    userPoolId: cognito.COGNITO_USER_POOL_ID,
    userPoolWebClientId: cognito.COGNITO_APP_CLIENT_ID,
  };
}

export default fromCalldeskCognitoConfig;
