// @flow

import type { RouterHistory } from 'react-router-dom';
import type { Meta } from 'antd-form-builder';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  CDKForm,
  CDKFormBuilder,
  CDKInput,
  CDKModal,
  cdkNotification,
  useForm,
} from '@calldesk/components';

import type { State, Status } from '@state/ducks/app/types';
import { normalizeNewBotName } from '@assets/js/calldesk-app-util/format';
import { selectors as accountsSelectors } from '@state/ducks/accounts';
import { operations as botsOperations, selectors as botsSelectors } from '@state/ducks/bots';

import './duplicate-bot-modal.css';

const BASIC_BOT_NAME_REGEXP: RegExp = /(^[a-zA-Z0-9-]*$)/;
const NEGATE_BOT_ERROR: string =
  'Only letters and digits are allowed. Special characters, accent...are prohibited';

type ParentProps = {
  accountId: string,
  botName: string,
  close: (boolean: ?boolean) => void,
};

/**
 * Duplicate Bot Modal Component
 */
function DuplicateBotModal({ accountId, botName, close }: ParentProps) {
  const dispatch = useDispatch();
  const [form] = useForm();
  const forceUpdate = CDKFormBuilder.useForceUpdate();
  const history: RouterHistory = useHistory();
  const { t } = useTranslation();

  const { account: newAccount, botName: newBotName } = form.getFieldsValue();

  const accounts: string[] = useSelector<State, string[]>(accountsSelectors.getAccounts);
  const botDuplicatedStatus: ?Status = useSelector<State, ?Status>(state =>
    botsSelectors.getBotSpecificStatus(state, newBotName, 'duplicate'),
  );
  const duplicateError: ?string = useSelector<State, ?string>(botsSelectors.getError);

  const submitDuplicateForm: (toAccount: string, toBot: string) => void = (
    toAccount: string,
    toBot: string,
  ) =>
    dispatch(
      botsOperations.duplicateBotSaga({
        from: { accountId, botName },
        to: { accountId: toAccount, botName: toBot },
      }),
    );

  const updateDuplicateStatus: (status: Status) => void = (status: Status) => {
    dispatch(botsOperations.updateDuplicateBotStatus({ bot: botName, status }));
  };

  const onFormSubmit = ({ botName: newBot, account: newAccountId }) => {
    submitDuplicateForm(newAccountId, newBot);
  };

  const metaForm: Meta = {
    fields: [
      {
        initialValue: botName,
        key: 'botName',
        label: 'New bot name',
        labelCol: { span: 9 },
        normalize: normalizeNewBotName,
        required: true,
        rules: [
          {
            validator: async (rule: string, value: string) => {
              if (!BASIC_BOT_NAME_REGEXP.test(value)) {
                throw new Error(NEGATE_BOT_ERROR);
              }
            },
          },
        ],
        widget: CDKInput,
        wrapperCol: { offset: 1 },
      },
      {
        initialValue: accountId,
        key: 'account',
        label: 'Duplicate to account',
        labelCol: { span: 9 },
        options: accounts,
        required: true,
        widget: 'select',
        wrapperCol: { offset: 1 },
      },
    ],
  };

  useEffect(() => {
    if (['SUCCESS'].includes(botDuplicatedStatus)) {
      updateDuplicateStatus('NONE');
      cdkNotification.success({
        message: t('notif.done-var', {
          context: 'female',
          value: t('other.Duplicate'),
        }),
        description: t('navbar.BotsActionSubMenu.duplicate-notif.done.description', {
          newBotName,
          newAccount,
        }),
        top: 75,
      });
      history.push(`/accounts/${newAccount}/bots/${newBotName}/builder/script`);
      close();
    }
    if (botDuplicatedStatus === 'ERROR') {
      updateDuplicateStatus('NONE');
      cdkNotification.error({
        message: 'Duplicate bot error',
        description: duplicateError,
        top: 75,
      });
    }
  }, [botDuplicatedStatus]);

  const isSubmitDisabled: boolean = !!form.getFieldsError().filter(({ errors }) => errors.length)
    .length;

  return (
    <CDKModal
      className="DuplicateBotModal"
      confirmLoading={botDuplicatedStatus === 'IN_PROGRESS'}
      okButtonProps={{ disabled: isSubmitDisabled }}
      okText={t('navbar.BotsActionSubMenu.duplicate')}
      onCancel={close}
      onOk={form.submit}
      title={`Duplicate bot ${botName}`}
      visible
    >
      <CDKForm
        className="duplicateBotForm"
        form={form}
        labelAlign="left"
        onFieldsChange={forceUpdate}
        onFinish={onFormSubmit}
      >
        <CDKFormBuilder form={form} meta={metaForm} />
      </CDKForm>
    </CDKModal>
  );
}

export default DuplicateBotModal;
