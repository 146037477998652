// @flow

import type { State } from './app/types';

import accountsReducers from './accounts';
import administrationReducers from './administration';
import assessmentsReducers from './assessments';
import botsReducers from './bots';
import chatReducers from './chat';
import conversationsReducers from './conversations';
import intentsReducers from './intents';
import onboardingReducers from './onboarding';
import optionsReducers from './options';
import recordingsReducers from './recordings';
import sessionReducers from './session';
import snapshotsReducers from './snapshots';
import trainingReducers from './training';
import transcriptsReducers from './transcripts';
import uiReducers from './ui';

/* NOTE: This should be the only action and action creator allowed
in this file. If you need to create another action, think about
creating a new duck */
const FLUSH_STORE = 'FLUSH_STORE';
const RESET_APP_STATES = 'RESET_APP_STATES';
const flushStore = () => ({ type: FLUSH_STORE });
const resetAppStates = () => ({ type: RESET_APP_STATES });

// todo : merge with combineReducers , see commit 55b1371a3128772ce113f09d3795f89de3e7d218
// It got reverted because (valuable ?) UT where broken otherwise
const rootReducer = (state: Object = {}, action: Object): State => {
  switch (action && action.type) {
    case FLUSH_STORE:
      // load default values in sub reducers
      return {
        ...state,
        accounts: accountsReducers(undefined, action),
        administration: administrationReducers(undefined, action),
        assessments: assessmentsReducers(undefined, action),
        bots: botsReducers(undefined, action),
        chat: chatReducers(undefined, action),
        conversations: conversationsReducers(undefined, action),
        intents: intentsReducers(undefined, action),
        onboarding: onboardingReducers(undefined, action),
        options: optionsReducers(undefined, action),
        recordings: recordingsReducers(undefined, action),
        session: sessionReducers(undefined, action),
        snapshots: snapshotsReducers(undefined, action),
        training: trainingReducers(undefined, action),
        transcripts: transcriptsReducers(undefined, action),
        ui: uiReducers(undefined, action),
      };
    case RESET_APP_STATES:
      // resets all state EXCEPT for bots, accounts, session
      return {
        ...state,
        administration: administrationReducers(undefined, action),
        assessments: assessmentsReducers(undefined, action),
        chat: chatReducers(undefined, action),
        conversations: conversationsReducers(undefined, action),
        intents: intentsReducers(undefined, action),
        onboarding: onboardingReducers(undefined, action),
        options: optionsReducers(undefined, action),
        recordings: recordingsReducers(undefined, action),
        snapshots: snapshotsReducers(undefined, action),
        training: trainingReducers(undefined, action),
        transcripts: transcriptsReducers(undefined, action),
      };
    default:
      return {
        ...state,
        accounts: accountsReducers(state.accounts, action),
        administration: administrationReducers(state.administration, action),
        assessments: assessmentsReducers(state.assessments, action),
        bots: botsReducers(state.bots, action),
        chat: chatReducers(state.chat, action),
        conversations: conversationsReducers(state.conversations, action),
        intents: intentsReducers(state.intents, action),
        onboarding: onboardingReducers(state.onboarding, action),
        options: optionsReducers(state.options, action),
        recordings: recordingsReducers(state.recordings, action),
        session: sessionReducers(state.session, action),
        snapshots: snapshotsReducers(state.snapshots, action),
        training: trainingReducers(state.training, action),
        transcripts: transcriptsReducers(state.transcripts, action),
        ui: uiReducers(state.ui, action),
      };
  }
};

export { FLUSH_STORE, flushStore, RESET_APP_STATES, resetAppStates };
export default rootReducer;
