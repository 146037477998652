/* eslint-disable max-lines */
/* eslint-disable no-unused-vars */
// @flow

import type { ApiResponse } from '@api/index.type';
import type { Entity } from '@state/ducks/bots/types';
import type {
  Asset,
  AssetBase,
  AssetContentType,
  AssetScope,
  AssetType,
  CustomReferential,
  EntityAsset,
  EntityContentStatus,
  ReferentialContentMetadata,
  ReferentialContentStatus,
  Replacements,
  SourceFileAsset,
  SourceFileData,
} from '@state/ducks/customers-assets/types';
import request from '@amplify/api/request';
import addPropertiesIfExist from '@assets/js/calldesk-app-util/add-properties-if-exist';
import logger from '@assets/js/calldesk-app-util/logger';

type SourceObjectParams = {|
  scope: AssetScope,
|};
type ValuesObjectParams = {|
  bot?: string,
  subPath?: string,
|};

// REFERENTIALS ASSETS

const getRandomValueEntityReferential = async (
  accoundId: string,
  referentialId: string,
): Promise<?string> => {
  try {
    const { payload } = await request.customersAssetsApi({
      method: 'GET',
      path: `/accounts/${accoundId}/referentials/${referentialId}/random-value`,
    });
    return payload;
  } catch (error) {
    logger.info(
      'state/customersAssets/api [getRandomValueEntityReferential] Error while retrieving rando value',
      error.message,
    );
    return null;
  }
};

// GENERIC ENDPOINT

/**
 * Get Asset files for an account
 *
 * @typeparam {T = typeof undefined} typing contentType (optional)
 *
 * @param {string} accoundId
 * @param {AssetType} assetType - the asset type
 * @param {AssetScope} scope - the asset scope (default to account)
 *
 * @returns {Promise<AssetBase<T>[]>} [async] the assets list
 */
async function getAllAssets<T = typeof undefined>(
  {
    accountId,
    assetType,
    botId,
    subPath,
    scope = 'account',
  }: {
    accountId: string,
    assetType: AssetType,
    botId?: string,
    scope?: AssetScope,
    subPath?: string,
  },
  omitAssetName?: string[],
): Promise<AssetBase<T>[]> {
  const queryStringParameters = addPropertiesIfExist<SourceObjectParams, ValuesObjectParams>(
    { scope },
    { bot: botId, subPath },
  );

  try {
    const { payload }: ApiResponse<AssetBase<T>[]> = await request.customersAssetsApi({
      method: 'GET',
      path: `/accounts/${accountId}/assets/${assetType}`,
      queryStringParameters,
    });

    const omitAssetNameRegex = omitAssetName ? new RegExp(`(${omitAssetName.join('|')})`) : '';

    logger.info('state/customersAssets/api [getAllAssets] assets fetched');
    return payload.filter(asset => (omitAssetName ? !asset.name.match(omitAssetNameRegex) : asset));
  } catch (err) {
    logger.info('state/customersAssets/api [getAllAssets] Error while fetching assets');
    throw new Error(`Error while fetching ${assetType}, error: ${err.message}`);
  }
}

/**
 * Get Asset file
 *
 * @typeparam {K} typing content
 * @typeparam {T = typeof undefined} typing contentType (optional)
 *
 * @param {string} arg.accoundId
 * @param {AssetType} arg.assetType - the asset type
 * @param {string} arg.assetId - the asset to delete
 * @param {AssetScope} arg.scope - the asset scope (default to account)
 *
 * @returns  {Promise<Asset<K, V>>} [async] the asset fetched with its content
 */
async function getAsset<K, V = typeof undefined>({
  accountId,
  assetId,
  assetType,
  botId,
  scope = 'account',
  subPath,
}: {
  accountId: string,
  assetId: string,
  assetType: AssetType,
  botId?: string,
  scope?: AssetScope,
  subPath?: string,
}): Promise<Asset<K, V>> {
  try {
    const queryStringParameters = addPropertiesIfExist<SourceObjectParams, ValuesObjectParams>(
      { scope },
      { bot: botId, subPath },
    );

    const { payload }: ApiResponse<Asset<K, V>> = await request.customersAssetsApi({
      method: 'GET',
      path: `/accounts/${accountId}/assets/${assetType}/${assetId}`,
      queryStringParameters,
    });

    logger.info('state/customersAssets/api [getAsset] Retrieved asset: ', payload);

    return payload;
  } catch (error) {
    logger.info('state/customersAssets/api [getAsset] error while fetching replacements', error);
    throw new Error(
      `Error while retrieving ${assetType} with id ${assetId}, error: ${error.message}`,
    );
  }
}

/**
 * Create asset.
 *
 * @typeparam {K} typing content
 * @typeparam {T = typeof undefined} typing contentType (optional)
 *
 * @param {string} arg.accoundId
 * @param {AssetType} arg.assetType - the asset type
 * @param {string} arg.botId
 * @param {T} arg.content - the asset to create
 * @param {AssetContentType} arg.contentType - the asset type
 * @param {Object} arg.metadata - optional metadata of asset
 * @param {string} arg.name - the asset name
 * @param {AssetScope} arg.scope - the asset scope (default to account)
 *
 * @returns {Promise<Asset<K, V>>} [async] Response if creating is done.
 * @throws {Error} If error while posting
 */
async function createAsset<K, V = typeof undefined>({
  accountId,
  assetType,
  botId,
  content,
  contentType,
  metadata,
  name,
  scope = 'account',
  subPath,
}: {
  accountId: string,
  assetType: AssetType,
  botId?: string,
  content: K,
  contentType?: V,
  metadata?: Object,
  name: string,
  scope?: AssetScope,
  subPath?: string,
}): Promise<Asset<K, V>> {
  try {
    const queryStringParameters = addPropertiesIfExist<SourceObjectParams, ValuesObjectParams>(
      { scope },
      { bot: botId, subPath },
    );

    const res: ApiResponse<Asset<K, V>> = await request.customersAssetsApi({
      method: 'POST',
      path: `/accounts/${accountId}/assets/${assetType}`,
      body: { name, content, contentType, metadata },
      queryStringParameters,
    });

    logger.info('state/customersAssets/api [createAsset] asset created');
    return res.payload;
  } catch (err) {
    logger.info('state/customersAssets/api [createAsset] Error while posting asset');
    throw new Error(`Error while posting ${assetType}, error: ${err.message}`);
  }
}

/**
 * Update asset.
 *
 * @typeparam {K} typing content
 * @typeparam {T = typeof undefined} typing contentType (optional)
 *
 * @param {string} arg.accoundId
 * @param {string} arg.assetId
 * @param {AssetType} arg.assetType - the asset type
 * @param {string} arg.botId
 * @param {T} arg.content - the asset to create
 * @param {AssetContentType} arg.contentType - the asset type
 * @param {Object} arg.metadata - optional metadata of asset
 * @param {string} arg.name - the asset name
 * @param {AssetScope} arg.scope - the asset scope (default to account)
 *
 * @returns {Promise<Asset<K, V>>} [async] Response if updating is done.
 * @throws {Error} If error while posting
 */
async function updateAsset<K, V = typeof undefined>({
  accountId,
  assetId,
  assetType,
  botId,
  content,
  contentType,
  metadata,
  name,
  scope = 'account',
  status,
  subPath,
}: {
  accountId: string,
  assetId: string,
  assetType: AssetType,
  botId?: string,
  content?: K,
  contentType?: V,
  metadata?: Object,
  name?: string,
  scope?: AssetScope,
  status?: EntityContentStatus,
  subPath?: string,
}): Promise<Asset<K, V>> {
  try {
    const queryStringParameters = addPropertiesIfExist<SourceObjectParams, ValuesObjectParams>(
      { scope },
      { bot: botId, subPath },
    );

    const { payload }: ApiResponse<Asset<K, V>> = await request.customersAssetsApi({
      method: 'PUT',
      path: `/accounts/${accountId}/assets/${assetType}/${assetId}`,
      // eslint-disable-next-line no-restricted-globals
      body: { content, contentType, name, metadata, ...(status ? { status } : {}) },
      queryStringParameters,
    });

    logger.info('state/customersAssets/api [updateAsset] Updated asset', payload);

    return payload;
  } catch (err) {
    logger.info('state/customersAssets/api [updateAsset] Error while updating asset');
    throw new Error(`Error while updating ${assetType} with id ${assetId}, error: ${err.message}`);
  }
}

/**
 * Delete asset.
 *
 * @param {string} arg.accoundId
 * @param {AssetType} arg.assetType - the asset type
 * @param {string} arg.assetId - the asset to delete
 * @param {AssetScope} arg.scope - the asset scope (default to account)
 *
 * @returns {Promise<void>} [async].
 * @throws {Error} If error while posting
 */
async function deleteAsset({
  accountId,
  assetId,
  assetType,
  botId,
  scope = 'account',
  subPath,
}: {
  accountId: string,
  assetId: string,
  assetType: AssetType,
  botId?: string,
  scope?: AssetScope,
  subPath?: string,
}): Promise<void> {
  const queryStringParameters = addPropertiesIfExist<SourceObjectParams, ValuesObjectParams>(
    { scope },
    { bot: botId, subPath },
  );

  try {
    await request.customersAssetsApi({
      method: 'DELETE',
      path: `/accounts/${accountId}/assets/${assetType}/${assetId}`,
      queryStringParameters,
    });

    logger.info('state/customersAssets/api [deleteAsset] asset deleted');
  } catch (err) {
    logger.info('state/customersAssets/api [deleteAsset] Error while deleting asset');
    throw new Error(`Error while posting ${assetType},  with id ${assetId}, error: ${err.message}`);
  }
}

export {
  createAsset,
  deleteAsset,
  getAllAssets,
  getAsset,
  getRandomValueEntityReferential,
  updateAsset,
};
