// @flow

import type { FormInstance } from 'antd';
import type { Meta } from 'antd-form-builder';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { WarningOutlined } from '@ant-design/icons';

import {
  CDKAutoComplete,
  CDKCheckbox,
  CDKFormBuilder,
  CDKSpin,
  CDKTag,
  CDKTypography,
} from '@calldesk/components';

import type { BotPhoneNumbers, Environment, Version } from '@state/ducks/bots/types';
import type { EnvironmentsVersions, SelectedEnvironments } from '@state/ducks/ui/types';
import camelToSentenceCase from '@assets/js/calldesk-app-util/camelToSentenceCase';

type Props = {
  account: string,
  bot: string,
  botPhoneNumbers: BotPhoneNumbers,
  botVersions: Version[],
  environments: Environment[],
  environmentsVersions: EnvironmentsVersions,
  form: FormInstance,
  isFetching: boolean,
  selectedEnvironments: SelectedEnvironments,
  setSelectedEnvironments: (selectedEnvironments: SelectedEnvironments) => void,
};

const BASIC_BOT_VERSION_REGEXP: RegExp = /^([a-zA-Z0-9.-]*)$/;
const NA: string = 'N/A';

const RANK_BY_ENV_MAP: { [env: Environment]: number } = {
  dev: 0,
  internalTest: 1,
  clientTest: 2,
  prod: 3,
};

/**
 * Generate props for Deploy Form
 */
const useDeployFormProps = ({
  account,
  bot,
  botPhoneNumbers,
  botVersions,
  environments,
  environmentsVersions,
  form,
  isFetching,
  selectedEnvironments,
  setSelectedEnvironments,
}: Props) => {
  const forceUpdate = CDKFormBuilder.useForceUpdate();
  const { t } = useTranslation();

  const lastSelectedEnv: Environment = form.getFieldValue('environment');

  const getEnvCheckboxStyle = (env: Environment) => {
    const style: Object = {};

    if (lastSelectedEnv && selectedEnvironments?.[env]) {
      if (lastSelectedEnv === env) style.color = '#118CBC';
      else if (RANK_BY_ENV_MAP[env] < RANK_BY_ENV_MAP[lastSelectedEnv]) style.color = '#BEBEBE';
      else if (RANK_BY_ENV_MAP[env] > RANK_BY_ENV_MAP[lastSelectedEnv]) style.color = '#EB5757';
    }

    return style;
  };

  const handleOnEnvChange = (newEnv: Environment) => {
    const version: string = form.getFieldValue('version');
    const isEnvSelected: boolean = !!selectedEnvironments[newEnv];
    const newSelectedEnvironments: { [env: Environment]: boolean } = {};
    const selectedEnvVersion: string =
      environmentsVersions[newEnv] !== NA ? environmentsVersions[newEnv] : version;

    // Set form values
    form.setFieldsValue({ environment: isEnvSelected ? null : newEnv });
    if (!isEnvSelected) form.setFieldsValue({ version: selectedEnvVersion });

    // Toggle impacted environments
    environments.forEach((env: Environment) => {
      const isSameEnvVersionAsSelectedEnvVersion: boolean =
        environmentsVersions[env] === selectedEnvVersion;

      newSelectedEnvironments[env] = isEnvSelected
        ? false
        : env === newEnv || isSameEnvVersionAsSelectedEnvVersion;
    });

    setSelectedEnvironments(newSelectedEnvironments);
    forceUpdate();
  };

  const hasPhoneNumber = (env: string): boolean =>
    botPhoneNumbers[env] &&
    (botPhoneNumbers[env].backup.length > 0 ||
      botPhoneNumbers[env].primary.length > 0 ||
      botPhoneNumbers[env].sms.length > 0);

  /**
   * The function checks if the environment is not 'dev' and if there are no backup, primary, or sms
   * phone numbers for the bot in that environment.
   */
  const hasPhoneNumberOnProd = (env: string): boolean => env === 'dev' || hasPhoneNumber(env);

  const formMeta: Meta = {
    fields: [
      ...environments.map(env => ({
        key: env,
        name: 'environment',
        formItemLayout: [24, 24],
        label: <CDKTypography.Text strong>{camelToSentenceCase(env)}</CDKTypography.Text>,
        widget: () => (
          <CDKSpin spinning={isFetching}>
            <CDKCheckbox
              checked={selectedEnvironments?.[env]}
              onChange={() => handleOnEnvChange(env)}
              style={getEnvCheckboxStyle(env)}
              data-test-id={`checkbox-environment-${env}`}
            >
              {!isFetching && hasPhoneNumberOnProd(env) && (
                <CDKTypography.Text>
                  {t('navbar.Deploy.Form.deployedVersion-var', {
                    version: environmentsVersions[env],
                  })}
                </CDKTypography.Text>
              )}
              {!hasPhoneNumberOnProd(env) && (
                <Trans
                  className="PhoneNumber"
                  i18nKey="navbar.Deploy.disable.PhoneNumbers"
                  ns="commons"
                  components={{
                    navlink: <NavLink to={`/accounts/${account}/bots/${bot}/builder/settings`} />,
                    container: <p />,
                  }}
                />
              )}
            </CDKCheckbox>
            {!hasPhoneNumber(env) && (
              <CDKTag icon={<WarningOutlined />} color="error">
                {t('navbar.Deploy.Validation.PhoneNumbers')}
              </CDKTag>
            )}
          </CDKSpin>
        ),
      })),
      {
        initialValue: 'dev',
        formItemLayout: 24,
        key: 'version',
        label: t('navbar.Deploy.Form.BotVersionField.label'),
        required: true,
        widgetProps: {
          options: botVersions.map((option: Version) => ({ value: option.version })),
        },
        widget: CDKAutoComplete,
        rules: [
          {
            validator: async (rule: string, value: string) => {
              if (!BASIC_BOT_VERSION_REGEXP.test(value)) {
                throw new Error(t('navbar.Deploy.Form.BotVersionField.Errors.pattern'));
              }
            },
          },
        ],
      },
    ],
  };

  const onFormChange = (changedFields: Object) => {
    const hasVersionFieldChanged: boolean = !!changedFields.version;

    if (hasVersionFieldChanged) {
      const newSelectedEnvironments: { [env: Environment]: boolean } = {};

      // Toggle environments with same version
      environments.forEach((env: Environment) => {
        newSelectedEnvironments[env] =
          env === lastSelectedEnv || environmentsVersions[env] === changedFields.version;
      });
      setSelectedEnvironments(newSelectedEnvironments);
    }

    forceUpdate();
  };

  return {
    formMeta,
    onFormChange,
  };
};

export default useDeployFormProps;
