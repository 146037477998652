// @flow

import type {
  EntityAssetContent,
  EntityAssetContentValue,
} from '@state/ducks/customers-assets/types';
import type { EntityTurn } from '@state/ducks/training/types';

/**
 * Return list of variants trained from entity referential, according utterance
 *
 * @param {string} utterance - Text parsed (Ex: Hello, my name is Jean)
 * @param {EntityAssetContent} content - Entity referential
 *
 * @returns {{ value: string, pronunciation: string }[]} - list of variants trained
 * Ex: [{
 *  value: 'Jean',
 *  pronunciation: 'Jen'
 * }]
 */
const findVariantsTrained = (
  utterance: string,
  content: EntityAssetContent,
): { value: string, pronunciation: string }[] =>
  Object.keys(content).reduce((acc: { value: string, pronunciation: string }[], value: string) => {
    const entityAssetContentValue: EntityAssetContentValue = content[value];
    const pronunciations: string[] = entityAssetContentValue.pronunciations || [];

    if (pronunciations.length > 0) {
      const valuesPronunciationsTrained: {
        value: string,
        pronunciation: string,
      }[] = pronunciations
        .map((pronunciation: string) => ({
          pronunciation,
          value,
        }))
        .filter(
          ({ pronunciation }: { value: string, pronunciation: string }) =>
            utterance.search(pronunciation) >= 0,
        );

      if (valuesPronunciationsTrained.length > 0) {
        return acc.concat(valuesPronunciationsTrained);
      }
    }
    return acc;
  }, []);

/**
 * Append entityMasks in utterance, according entity values, from entities turn list
 *
 * @param {string} utterance - Text parsed (Ex: Hello, my name is Jean)
 * @param {EntityTurn[]} entitiesTurn - Entity referential
 *
 * @returns {string} - new utterance with entityMasks appended
 * Ex: 'Hello, my name is {name}'
 */
const entitiesTurnToEntityMasksInUtterance = (
  utterance: string,
  entitiesTurn: EntityTurn[],
): string =>
  entitiesTurn.reduce((newUtterance: string, entityTurn: EntityTurn) => {
    const entityAssetContentValues: string[] = Object.keys(entityTurn.content).filter(
      (value: string) => utterance.search(value) >= 0,
    );
    if (entityAssetContentValues.length > 0) {
      return entityAssetContentValues.reduce(
        (acc: string, value: string) => acc.replace(value, `{${entityTurn.entityMask}}`),
        newUtterance,
      );
    }
    return newUtterance;
  }, utterance);

/**
 * Append entityMasks in utterance, according entity variants, from entities turn list
 *
 * @param {string} utterance - Text parsed (Ex: Hello, my name is Jen)
 * @param {EntityTurn[]} entitiesTurn - Entity referential
 *
 * @returns {string} - new utterance with entityMasks appended
 * Ex: 'Hello, my name is {name}'
 */
const entityTurnVariantsToEntityMasksInUtterance = (
  utterance: string,
  entitiesTurn: EntityTurn[],
): string =>
  entitiesTurn.reduce((newUtterance: string, entityTurn: EntityTurn) => {
    const trained: { value: string, pronunciation: string }[] = findVariantsTrained(
      utterance,
      entityTurn.content,
    );

    if (trained.length > 0) {
      return trained.reduce(
        (acc: string, { pronunciation }: { value: string, pronunciation: string }) =>
          acc.replace(pronunciation, `{${entityTurn.entityMask}}`),
        newUtterance,
      );
    }
    return newUtterance;
  }, utterance);

/**
 * Return entityMasks in utterance
 */
const appendEntityMasksInUtterance = (
  utteranceOrigin: string,
  entitiesTurn: EntityTurn[],
): string => {
  const utteranceWithEntityMasksByValues: string = entitiesTurnToEntityMasksInUtterance(
    utteranceOrigin,
    entitiesTurn,
  );
  return entityTurnVariantsToEntityMasksInUtterance(utteranceWithEntityMasksByValues, entitiesTurn);
};

export {
  appendEntityMasksInUtterance,
  entitiesTurnToEntityMasksInUtterance,
  entityTurnVariantsToEntityMasksInUtterance,
};
