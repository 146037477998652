/* eslint-disable id-length */
// @flow

import moment from 'moment';

import enGB from './en_GB';
import frFR from './fr_FR';

import 'moment/locale/fr';

const resources = {
  en: enGB,
  fr: frFR,
};

moment.updateLocale('en', {
  relativeTime: {
    d: 'day',
    M: 'month',
  },
});

moment.updateLocale('en', {
  relativeTime: {
    d: 'day',
    M: 'month',
  },
});

const formatter = (value: any, format: string, lng: string) => {
  if (format === undefined) {
    return value;
  }

  if (format === 'uppercase') {
    return value.toUpperCase();
  }

  if (format === 'relativeTime') {
    const dateValue = moment(+value);
    dateValue.locale(lng);
    return dateValue.fromNow(true);
  }

  if (!Number.isNaN(value) && !Number.isNaN(parseFloat(value))) {
    return Intl.NumberFormat(lng).format(value);
  }

  if (value instanceof Date) {
    const dateValue = moment(value);
    dateValue.locale(lng);
    return dateValue.format(format);
  }

  return value;
};

const initConfig = {
  resources,
  lng: 'en',
  fallbackLng: 'en',
  defaultNS: 'commons',

  interpolation: {
    escapeValue: false,
    format: formatter,
  },

  react: { transSupportBasicHtmlNodes: true, transKeepBasicHtmlNodesFor: ['u', 'a'] },
};

export { formatter, initConfig, resources };
