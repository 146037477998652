// @flow

// NOTE : uncomment this import to activate why did you render
// IMPORTANT : it has to be the first import of the application (@see https://github.com/welldone-software/why-did-you-render#setup)
// import './wdyr';
import type { Breadcrumb } from '@sentry/types';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { init as SentryInit, Replay } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import LoadingSpinner from '@assets/js/calldesk-components/molecules/LoadingSpinner';

import store from './state/store';
import App from './App';
import localization from './localization';
import serviceWorker from './serviceWorkerRegistration';

// $FlowFixMe
import 'antd/dist/antd.less';
// $FlowFixMe
import './antd.less.hack.less';
// Those global styles must be imported AFTER the ant's one
import '@calldesk/components/dist/index.css';
import './index.css';

// Sentry initialization to enable javascript errors tracking
// and performance monitoring
SentryInit({
  dsn: 'https://8c558a3fa0374c0fadb459bca6b033cd@o1143438.ingest.sentry.io/6204002',
  integrations: [
    new BrowserTracing(),
    new Replay({
      // Mask values of <input> elements.
      maskAllInputs: false,
      // Mask all elements that match the class name.
      maskTextClass: 'sentry-mask',
      // Mask all text content.
      maskAllText: false,
    }),
  ],
  // Set of basic common ignore rules for everyday things
  // @see https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // See https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402/4
    // See https://stackoverflow.com/questions/63020810/what-is-best-way-in-javascript-to-stop-throwing-resizeobserver-loop-limit-excee
    'ResizeObserver loop limit exceeded',
    /401|403|404|502|503/,
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production:
  tracesSampleRate: 0.7,
  environment: process.env.REACT_APP_ENV,
  // By default Sentry SDKs normalize any context to a depth of 3,
  // which in the case of sending Redux state you probably will want to increase that:
  normalizeDepth: 100,
  // Display a Crash-Report Modal in app
  // beforeSend: event => {
  //   if (event.exception) showReportDialog({ eventId: event.event_id, lang: 'en' });
  //   return event;
  // },
  // In order to avoid send console.log Sentry in breadcrumb:
  beforeBreadcrumb(breadcrumb: Breadcrumb) {
    if (breadcrumb.category === 'console') {
      return null;
    }
    return breadcrumb;
  },
  // Last 15 breadcrumb sent to Sentry
  maxBreadcrumbs: 15,
  // Maximum number of characters a single value can have before it will be truncated (defaults to 250):
  maxValueLength: 1000,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});

localization();

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');

const root = createRoot(container);

root.render(
  <Provider store={store}>
    <Suspense fallback={<LoadingSpinner.Centered />}>
      <Router>
        <App />
      </Router>
    </Suspense>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
