// @flow

import type { BotConfig } from '@state/ducks/bots/types';
import type { Exercise, ValidateFnReturn, ValidatorData } from '@state/ducks/onboarding/types';

/**
 * Validate `lookingCall` quest
 */
function validate(
  botConfig?: BotConfig,
  progression: Exercise,
  validatorData: ValidatorData,
): ValidateFnReturn<void> {
  return {
    validated:
      (!!validatorData && !!validatorData.calls && !!validatorData.calls.transcriptOpened) ||
      (!!validatorData &&
        !!validatorData.calls &&
        !!validatorData.calls.count &&
        validatorData.calls.count > 0),
  };
}

export default validate;
