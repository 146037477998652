// @flow

import type { State } from '@state/ducks/app/types';
import type { Bots } from '@state/ducks/bots/types';
import type { Conversations } from '@state/ducks/conversations/types';
import type { OptionsState } from '@state/ducks/options/types';
import type { Session } from '@state/ducks/session/types';
import type { Transcripts } from '@state/ducks/transcripts/types';
import reducers from '@state/ducks';
// Ducks state monitoring
import botsStateMonitoring from '@state/ducks/bots/state-monitoring';
import conversationsStateMonitoring from '@state/ducks/conversations/state-monitoring';
import optionsStateMonitoring from '@state/ducks/options/state-monitoring';
import sessionStateMonitoring from '@state/ducks/session/state-monitoring';
import transcriptsStateMonitoring from '@state/ducks/transcripts/state-monitoring';

import { DATA_NOT_AVAILABLE, DUCK_KEYS_NOT_AVAILABLE } from '../index.type';

const DUCKS_STATE_MAP_TRANSFORMED: { [duckKey: string]: Object } = {
  bots: (state: State): Bots => botsStateMonitoring(state),
  conversations: (state: State): Conversations => conversationsStateMonitoring(state),
  options: (state: State): OptionsState => optionsStateMonitoring(state),
  session: (state: State): Session => sessionStateMonitoring(state),
  transcripts: (state: State): Transcripts => transcriptsStateMonitoring(state),
};

const stateTransformer = (state: State) => {
  const currState: State = reducers(state, { type: 'STATE_TRANSFORMER' });

  return Object.keys(currState).reduce((newState: State, duckKey: Object) => {
    if (DUCK_KEYS_NOT_AVAILABLE.includes(duckKey)) {
      return {
        ...newState,
        [duckKey]: DATA_NOT_AVAILABLE,
      };
    }
    return {
      ...newState,
      [duckKey]: Object.keys(DUCKS_STATE_MAP_TRANSFORMED).includes(duckKey)
        ? DUCKS_STATE_MAP_TRANSFORMED[duckKey](newState)
        : newState[duckKey],
    };
  }, currState);
};

export default stateTransformer;
