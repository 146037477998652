// @flow
import { Auth, CognitoUser } from '@aws-amplify/auth';

/**
 * Get current authenticated user
 *
 * @return {Promise<CognitoUser>}
 */
function getCurrentAuthenticatedUser(): Promise<CognitoUser> {
  return Auth.currentUserPoolUser();
}

export default getCurrentAuthenticatedUser;
