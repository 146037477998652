// @flow

import type { BotConfig, GraphData, Graphs } from './types';
// $FlowFixMe
import { createSelector } from '@reduxjs/toolkit';
import _set from 'lodash/set';

import type { State as RootState } from '@state/ducks/app/types';
import type {
  GraphDataElementsOptions,
  Node,
  NodeModule,
  Transition,
} from '@state/ducks/bots/types';

import { getBotConfig } from './bots.selectors';

const getBotGraphs = createSelector(getBotConfig, (config: BotConfig): ?Graphs => config?.graphs);

const getBotDomains = createSelector(getBotGraphs, (graphs: ?Graphs): string[] =>
  Object.keys(graphs || {}),
);

const getGraphDataForDomain = createSelector(
  getBotGraphs,
  (state: RootState, bot: string, domain: string) => domain,
  (graphs: ?Graphs, domain: string): GraphData | null => graphs?.[domain]?.data || null,
);

const getMatchingGraphElements = createSelector(
  getBotGraphs,
  (state: RootState, bot: string, search: string) => search,
  (graphs: ?Graphs, search: string): GraphDataElementsOptions => {
    if (!graphs || !search) return {};

    const matchingElementsByDomain: GraphDataElementsOptions = {};
    // NOTE: For each node or transition, compare all its attributes to searched term
    Object.keys(graphs).forEach((domain: string) => {
      const matchingNodes: Node[] = graphs[domain].data.nodes.filter((node: Node) =>
        JSON.stringify(node).toLocaleLowerCase().includes(search.toLocaleLowerCase()),
      );

      const matchingTransitions: Transition[] = graphs[domain].data.transitions.filter(
        (transition: Transition) =>
          JSON.stringify(transition).toLocaleLowerCase().includes(search.toLocaleLowerCase()),
      );

      _set(matchingElementsByDomain, `${domain}.nodes`, matchingNodes);
      _set(matchingElementsByDomain, `${domain}.transitions`, matchingTransitions);
    });

    return matchingElementsByDomain;
  },
);

const getBotGraphsModuleNodes = createSelector(getBotGraphs, (graphs: ?Graphs): NodeModule[] =>
  graphs
    ? Object.keys(graphs).reduce((acc, domain) => {
        // $FlowFixMe: Only Module nodes are kept
        const domainModuleNodes: NodeModule[] = graphs[domain].data.nodes.filter(
          node => node.action === 'module',
        );

        return [...acc, ...domainModuleNodes];
      }, [])
    : [],
);

const getBotGraphsDevModuleNodesCount = createSelector(
  getBotGraphsModuleNodes,
  (moduleNodes: NodeModule[]): number =>
    moduleNodes.filter((node: NodeModule) => node.target.botVersion === 'dev').length,
);

export {
  getBotDomains,
  getBotGraphs,
  getBotGraphsDevModuleNodesCount,
  getBotGraphsModuleNodes,
  getGraphDataForDomain,
  getMatchingGraphElements,
};
