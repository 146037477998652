// @flow

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import type { Environment } from '@state/ducks/bots/types';

import './deploy-confirmation.css';

type ParentProps = {|
  impactedEnvironments: Environment[],
  version: string,
|};

/**
 * Deploy Confirmation Component
 */
function DeployConfirmation({ impactedEnvironments, version }: ParentProps) {
  const { t } = useTranslation();

  return (
    <div className="DeployConfirmation">
      <WarningRoundedIcon className="WarningIcon" />
      <p>
        <Trans
          i18nKey="navbar.Deploy.Confirmation.subtitle-var"
          ns="commons"
          values={{ version }}
          components={{
            bold: <b />,
          }}
        />
      </p>
      <p className="ImpactedEnv">
        {impactedEnvironments.map((env: Environment) => (
          <b key={env}>{env}</b>
        ))}
      </p>
      <p>{t('navbar.Deploy.Confirmation.warning')}</p>
      <p>{t('navbar.Deploy.Confirmation.confirmation')}</p>
    </div>
  );
}

export default DeployConfirmation;
