// @flow

import type {
  AvailablePhoneNumbers,
  Bot,
  BotApiError,
  BotConfig,
  BotPhoneNumbers,
  Version,
} from './types';
// $FlowFixMe
import { createSelector } from '@reduxjs/toolkit';
import _concat from 'lodash/concat';
import _flatten from 'lodash/flatten';
import _isEqual from 'lodash/isEqual';
import _uniq from 'lodash/uniq';

import type { botStatus, State as RootState, Status } from '@state/ducks/app/types';

import { botsSelectors } from './slice';

const getBotConfig = (state: RootState, bot: string): ?BotConfig =>
  state.bots.entities[bot]?.config;

const getBotStatus = (state: RootState, bot: string): ?botStatus =>
  state.bots.entities[bot]?.status;

const getLastBotConfigSaved = (state: RootState, bot: string): ?BotConfig =>
  state.bots.entities[bot]?.lastConfigSaved;

// TODO : use createSelector
const botConfigHasChanged = (state: RootState, bot: string): boolean => {
  const currentBotConfig: ?BotConfig = getBotConfig(state, bot);
  const lastBotConfigSaved: ?BotConfig = getLastBotConfigSaved(state, bot);

  return (
    !!currentBotConfig && !!lastBotConfigSaved && !_isEqual(currentBotConfig, lastBotConfigSaved)
  );
};

// Error or warning hints from validate endpoint for builder helper
const getLastBuildingErrors = (state: RootState, bot: string): ?(BotApiError[]) =>
  botsSelectors.selectById(state, bot)?.errors?.validate?.buildingErrors;

const botHasBuildingErrors = createSelector(
  getLastBuildingErrors,
  errors => errors?.filter(({ severity }) => severity === 'error').length > 0,
);

const getBotSpecificStatus = (state: RootState, bot: string, key: string): ?Status =>
  botsSelectors.selectById(state, bot)?.status?.[key];

const getBotPhoneNumbersForEnv = (
  state: RootState,
  bot: string,
  env: string,
): AvailablePhoneNumbers => botsSelectors.selectById(state, bot)?.phoneNumbers[env];

const getBotPhoneNumbersStatus = (state: RootState, bot: string): Status =>
  botsSelectors.selectById(state, bot)?.status.phoneNumbers;

const getSpecificBotsStatus = (
  state: RootState,
  status: 'fetching' | 'delete' | 'create',
): Status => state.bots.status?.[status] || 'NONE';

const getCreatedBotStatus = createSelector(
  [
    (state: RootState, bot: string) => bot,
    (state: RootState) => getSpecificBotsStatus(state, 'create'),
    (state: RootState, bot: string) => getBotSpecificStatus(state, bot, 'load'),
    (state: RootState, bot: string) => getBotSpecificStatus(state, bot, 'validate'),
  ],
  (bot: string, createStatus: Status, loadBotStatus: Status, validateBotStatus: Status) => {
    if (bot) {
      return [createStatus, loadBotStatus, validateBotStatus].every((status: Status) =>
        ['SUCCESS'].includes(status),
      )
        ? 'SUCCESS'
        : 'IN_PROGRESS';
    }
    return 'NONE';
  },
);

const getLoadedSnapshotId = (state: RootState, bot: string) =>
  botsSelectors.selectById(state, bot)?.loadedSnapshotId;

const getChatApiKey = (state: RootState, bot: string): string | null =>
  botsSelectors.selectById(state, bot)?.chatApiKey.value || null;

const getBotErrorMessages = createSelector(
  (state: RootState, bot: string) => botsSelectors.selectById(state, bot)?.errors,
  (errors: { [key: string]: { list: BotApiError[] } }) => {
    const allErrorTypesArePresent: boolean =
      !!errors &&
      ['save', 'load', 'publish'].every(errorType => Object.keys(errors).includes(errorType));
    if (allErrorTypesArePresent) {
      const {
        save: { list: saveErrosList },
        load: { list: loadErrosList },
        publish: { list: publishErrorsList },
      } = errors;

      return _uniq(
        _flatten(
          _concat(
            // This selector is mainly used to display errors in ErrorModal component
            // Since publish and validate errors are already shown in DeployPopover and
            // BuilderHelper, we only need to display save, chat and load errors in modal
            saveErrosList.map(err => err.message ?? err),
            loadErrosList.map(err => err.message ?? err),
            publishErrorsList.map(err => err.message ?? err),
          ),
        ),
      );
    }

    return [];
  },
);

const getBot = (state: RootState, bot: string): Bot | null =>
  botsSelectors.selectById(state, bot) || null;

const getLastCheckErrors = (state: RootState, bot: string): BotApiError[] =>
  botsSelectors.selectById(state, bot)?.errors?.check?.list ?? [];

const getError = (state: RootState): string | null => state.bots?.error;

const getBotVersions = (state: RootState, bot: string): Version[] =>
  botsSelectors.selectById(state, bot)?.versions || [];

const getBotPhoneNumbers = (state: RootState, bot: string): BotPhoneNumbers =>
  botsSelectors.selectById(state, bot)?.phoneNumbers;

const getBotimportIntentsError = (state: RootState, bot: string): ?Error =>
  botsSelectors.selectById(state, bot)?.errors?.importIntents;

export {
  botConfigHasChanged,
  botHasBuildingErrors,
  getBot,
  getBotConfig,
  getBotErrorMessages,
  getBotimportIntentsError,
  getBotPhoneNumbers,
  getBotPhoneNumbersForEnv,
  getBotPhoneNumbersStatus,
  getBotSpecificStatus,
  getBotStatus,
  getBotVersions,
  getChatApiKey,
  getCreatedBotStatus,
  getError,
  getLastBotConfigSaved,
  getLastBuildingErrors,
  getLastCheckErrors,
  getLoadedSnapshotId,
  getSpecificBotsStatus,
};
