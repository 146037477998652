// @flow

import type { Location } from 'react-router-dom';
import _deburr from 'lodash/deburr';
import _startCase from 'lodash/startCase';
import moment from 'moment';

import type { StorageSettings } from '@state/ducks/app/types';
import VALIDATION_BY_TYPEOF from '@assets/js/calldesk-app-util/validation-by-typeof';

type HttpStatusType =
  | 'client-error'
  | 'error'
  | 'information'
  | 'redirection'
  | 'server-error'
  | 'success';

const REGEXP_PHONE_NUMBER: RegExp = /^(\+|00)[1-9][0-9]{3,20}$/;
const REGEXP_WORDS_IN_SENTENCE: RegExp = /[ ,]+/;
const REGEXP_ENTITY_MASK: RegExp = /{[a-zA-Z0-9_-].+}/;
const REGEXP_SQUARE_BRACKETS: RegExp = /\[[^\]]*?\]/g;
const REGEXP_EXTRACT_VALUE_BETWEEN_SQUARE_BRACKETS: RegExp = /[^[\]]+(?=])/g;
const REGEXP_REJECT_NUMBERS: RegExp = /(^[a-zA-Z-$&+,:;=?@#|'<>.^*(){}%!]*$)/;
const REGEXP_METACHARS: RegExp = /(^[\w-_]*$)/;
const REGEXP_FILE_EXTENTION: RegExp = /\.[^/.]+$/;

const formatBotName = (bot: string): string => (bot === 'cnp' ? 'CNP Assurances' : _startCase(bot));

const formatDate = (date: string): string => moment(date).format('lll');

const removeHTMLInString = (stringContent: string, customRegExp?: RegExp): string => {
  const regex: RegExp = customRegExp || /(<([^>]+)>)/gi;
  if (stringContent) {
    return stringContent.replace(regex, '');
  }
  return '';
};

const getCharBeforeAfterFromTextSelection = (
  selection: Selection,
): ?{ before: ?string, after: ?string } => {
  if (selection && selection.rangeCount > 0 && selection.type === 'Range') {
    // Text content of the element.
    const text: ?string = selection.anchorNode?.textContent;
    if (text) {
      // selection.anchorOffset is the start position of the selection
      const before: string = text.substring(selection.anchorOffset - 1, selection.anchorOffset);

      // selection.extentOffset is the end position of the selection
      const after: string = text.substring(
        // $FlowFixMe: extentOffset exists
        selection.extentOffset,
        // $FlowFixMe: extentOffset exists
        selection.extentOffset + 1,
      );

      // Check if there are any letters before or after selected string.
      // If not, change before and after to null.
      return {
        before: before.length === 1 ? before : null,
        after: after.length === 1 ? after : null,
      };
    }
  }
  return null;
};

const getLastPropertyInLocationUrl = (location: Location): ?string => {
  const properties: string[] = location.pathname
    .split('/')
    .filter((property: string) => !!property);

  if (properties.length > 0) {
    return properties[properties.length - 1];
  }

  return null;
};

const phoneNumberIsValid = (phoneNumber?: string): boolean =>
  !!phoneNumber && REGEXP_PHONE_NUMBER.test(phoneNumber);

const diffBetween2Strings = (stringA: string, stringB: string) =>
  stringB
    .split(REGEXP_WORDS_IN_SENTENCE)
    .reduce((acc: string[], wordB: string) => {
      const nextSameWord: ?string = stringA
        .split(REGEXP_WORDS_IN_SENTENCE)
        .find((nextA: string) => nextA === wordB);
      const indexNextSameWord: number = stringB
        .split(REGEXP_WORDS_IN_SENTENCE)
        .indexOf(nextSameWord);
      if (indexNextSameWord < 0) {
        return [...acc, wordB];
      }
      return acc;
    }, [])
    .join(' ');

const normalizeNewBotName = (value: string): string =>
  _deburr(value).replace(/ /g, '-').toLowerCase();

const valueToObject = (value: string | Object): Object => {
  const isAlreadyAnObject: boolean = typeof value === 'object';
  if (isAlreadyAnObject) {
    return value;
  }

  if (VALIDATION_BY_TYPEOF.object(value)) {
    return JSON.parse(value);
  }

  return {};
};

const HTTP_STATUS_TYPES_MAP: { [type: string]: HttpStatusType } = {
  clientError: 'client-error',
  defaultError: 'error',
  information: 'information',
  redirection: 'redirection',
  serverError: 'server-error',
  success: 'success',
};

/**
 * @description Return a string value in order to specify if HTTP status code is an information, an error or and success response.
 * List of HTTP status codes: https://en.wikipedia.org/wiki/List_of_HTTP_status_codes
 *
 * @param {string | number} statusCode - HTTP status code, like 200, 404, etc
 *
 * @return {HttpStatusType} type of HTTP status code.
 */

const getHttpStatusTypeByStatusCode = (statusCode: string | number): HttpStatusType => {
  const statusCodeFormatted: string =
    typeof statusCode === 'number' ? statusCode.toString() : statusCode;

  if (/1[0-9]{2}/g.test(statusCodeFormatted)) {
    return HTTP_STATUS_TYPES_MAP.information;
  }
  if (/2[0-9]{2}/g.test(statusCodeFormatted)) {
    return HTTP_STATUS_TYPES_MAP.success;
  }
  if (/3[0-9]{2}/g.test(statusCodeFormatted)) {
    return HTTP_STATUS_TYPES_MAP.redirection;
  }
  if (/4[0-9]{2}/g.test(statusCodeFormatted)) {
    return HTTP_STATUS_TYPES_MAP.clientError;
  }
  if (/5[0-9]{2}/g.test(statusCodeFormatted)) {
    return HTTP_STATUS_TYPES_MAP.serverError;
  }
  return HTTP_STATUS_TYPES_MAP.defaultError;
};

/**
 * Format storage settings into calldesk specific HTTP headers
 */
function formatStorageSettingsHeaders(storageSettings: StorageSettings): {
  'CDK-Storage-Provider': string,
  'CDK-Storage-Region': string,
} {
  return {
    'CDK-Storage-Provider': storageSettings.provider,
    'CDK-Storage-Region': storageSettings.region,
  };
}

export type { HttpStatusType };
export {
  diffBetween2Strings,
  formatBotName,
  formatDate,
  formatStorageSettingsHeaders,
  getCharBeforeAfterFromTextSelection,
  getHttpStatusTypeByStatusCode,
  getLastPropertyInLocationUrl,
  HTTP_STATUS_TYPES_MAP,
  normalizeNewBotName,
  phoneNumberIsValid,
  REGEXP_ENTITY_MASK,
  REGEXP_EXTRACT_VALUE_BETWEEN_SQUARE_BRACKETS,
  REGEXP_FILE_EXTENTION,
  REGEXP_METACHARS,
  REGEXP_PHONE_NUMBER,
  REGEXP_REJECT_NUMBERS,
  REGEXP_SQUARE_BRACKETS,
  REGEXP_WORDS_IN_SENTENCE,
  removeHTMLInString,
  valueToObject,
};
