// @flow

import type { Node, Transition } from '@state/ducks/bots/types';

const filterNodesAccordingTransition = (
  nodeCompared: ?Node,
  nodeType: string,
  nodes: Node[],
  transitions: Transition[],
  findOne?: boolean,
): Node[] | ?Node => {
  if (!nodeCompared) {
    return findOne ? null : [];
  }

  const filtering = (node: Node) =>
    node &&
    node.action === nodeType &&
    node.templates &&
    node.templates.length > 0 &&
    transitions.some(
      (transition: Transition) =>
        transition.startingNode === nodeCompared.id && transition.endingNode === node.id,
    );

  return findOne ? nodes.find(filtering) : nodes.filter(filtering);
};

export default filterNodesAccordingTransition;
