// @flow

import type { Answer, AnswerChanged, Turn } from './types';

import type { ApiResponse } from '@api/index.type';
import request from '@amplify/api/request';
import logger from '@assets/js/calldesk-app-util/logger';

/**
 * Get turns list according to accountId and botName.
 *
 * @param {string} accountId - the account id
 * @param {string} botName - the bot name
 * @param {string} status - the status
 *
 * @returns {Promise<Turn[]>} [async] Response if retrieving is done.
 * @throws {Error} If error while trying to retrieve turns
 */
const getTurns = async (accountId: string, botName: string, from: number): Promise<Turn[]> => {
  try {
    const res: ApiResponse<Turn[]> = await request.trainingApi({
      method: 'GET',
      path: `/accounts/${accountId}/bots/${botName}/turns`,
      queryStringParameters: { from, status: 'TO_TRAIN' },
    });
    logger.info(
      `state/training/api [getTurns] Retrieved turns / accountId: "${accountId}", botName: "${botName}"`,
    );
    return res.payload;
  } catch (err) {
    logger.info(
      `state/training/api [getTurns] Error while retrieving turns / accountId: "${accountId}", botName: "${botName}", error message: `,
      err.message,
    );
    throw new Error(`Error while retrieving turns, error: ${err.message}`);
  }
};

const fetchTrainingScore = async (
  accountId: string,
  botName: string,
  from: number,
): Promise<Object> => {
  let response: Object;

  try {
    const trainingApiResponse = await request.trainingApi({
      method: 'GET',
      path: `/accounts/${accountId}/bots/${botName}/score`,
      queryStringParameters: { from },
    });
    const { trained, discarded } = trainingApiResponse.payload;

    logger.info(
      `state/training/api [getTrainingScore] Retrieved training score / accountId: "${accountId}", botName: "${botName}", from "${from}"`,
    );
    response = { trained, discarded };
  } catch (error) {
    logger.info(
      `state/training/api [getTrainingScore] Error while retrieving training score / accountId: "${accountId}", botName: "${botName}", from "${from}" error message: `,
      error.message,
    );
    throw error;
  }

  return response;
};

/**
 * Get answers list according to accountId, botName, and turnId.
 *
 * @param {string} accoundId
 * @param {string} botName
 * @param {{
 *   turnId: string,
 *   from: number,
 *   status?: string,
 *   sort?: string,
 *   to?: number,
 * }} turnParams
 *
 * @returns {Promise<Answer[]>} [async] Response if retrieving is done.
 * @throws {Error} If error while trying to retrieve turns
 */
const getAnswersByTurnId = async (
  accoundId: string,
  botName: string,
  turnParams: {
    turnId: string,
    from: number,
    status?: string,
    sort?: string,
  },
): Promise<{ after: string, items: Answer[] }> => {
  const { turnId, from, status = 'TO_TRAIN', sort = 'descending' } = turnParams;

  try {
    const res: ApiResponse<{ after: string, items: Answer[] }> = await request.trainingApi({
      method: 'GET',
      path: `/accounts/${accoundId}/bots/${botName}/turns/${turnId}/answers`,
      queryStringParameters: { status, from, sort },
    });
    logger.info(
      `state/training/api [getAnswersByTurnId] Retrieved turnId "${turnId}" / accountId: "${accoundId}", botName: "${botName}", from "${from}", status "${status}", sort "${sort}"`,
    );
    return res.payload;
  } catch (err) {
    logger.info(
      `state/training/api [getAnswersByTurnId] Error while retrieving turnId "${turnId}" / accountId: "${accoundId}", botName: "${botName}", from "${from}", status "${status}", sort "${sort}"`,
      err.message,
    );
    throw new Error(`Error while retrieving answers, error: ${err.message}`);
  }
};

/**
 * Save answers list according to accountId, botName, and turnId.
 *
 * @param {string} accoundId
 * @param {string} botName
 * @param {string} turnId
 * @param {AnswerChanged[]} answersChanged
 *
 * @returns {Promise<{ [answerId: string]: boolean }>} [async] Response if saving is done.
 * @throws {Error} If error while trying to retrieve turns
 */
const putAnswersChanged = async (
  accoundId: string,
  botName: string,
  turnId: string,
  answersChanged: AnswerChanged[],
): Promise<{ [answerId: string]: boolean }> => {
  try {
    const res: ApiResponse<{ [answerId: string]: boolean }> = await request.trainingApi({
      method: 'PUT',
      path: `/accounts/${accoundId}/bots/${botName}/turns/${turnId}/answers`,
      body: answersChanged,
    });
    logger.info(
      `state/training/api [putAnswersChanged] Answers saved, accountId: "${accoundId}", botName: "${botName}", turnId: "${turnId}"}`,
    );
    return res.payload;
  } catch (err) {
    logger.info(
      `state/training/api [putAnswersChanged] Error while saving answers, accountId: "${accoundId}", botName: "${botName}", turnId: "${turnId}"}`,
    );
    throw new Error(`Error while saving answers, error: ${err.message}`);
  }
};

export { fetchTrainingScore, getAnswersByTurnId, getTurns, putAnswersChanged };
