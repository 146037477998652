import _upperCase from 'lodash/upperCase';

const constantCase = x => _upperCase(x).replace(/ /g, '_');

const fromCalldeskEndpoints = apis =>
  Object.keys(apis).reduce((endpoints, name) => {
    const api = apis[name];
    const API_STAGE_PROP = `${constantCase(name)}_STAGE`;
    const stage = process.env[API_STAGE_PROP] || api[`${API_STAGE_PROP}_PROD`];
    const protocol = stage.includes('local') ? 'http' : 'https';
    const endpoint = `${protocol}://${api[`URL_${stage.toUpperCase()}`]}`;

    return endpoint ? [...endpoints, { name, endpoint, region: api.REGION }] : endpoints;
  }, []);

export default fromCalldeskEndpoints;
