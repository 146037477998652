// @flow

import type { BotConfig, Node, Transition } from '@state/ducks/bots/types';
import type { Exercise, ValidateFnReturn } from '@state/ducks/onboarding/types';
import { SAY_NODE_NAME } from '@state/ducks/bots/types';
import { filterNodesAccordingTransition } from '@state/ducks/onboarding/utils/validator/utils';

type LinkSayActionClosedQuestionToNegativeAndPositiveValidate = ValidateFnReturn<{
  nodes: Node[],
  nodesSayActionPositiveNegative: Node[],
  transitions: Transition[],
}>;

/**
 * Validate `linkSayActionClosedQuestionToNegativeAndPositive` quest
 * NOTE: Blocked by `linkSayActionIntroToClosedQuestion` quest
 */
function validate(
  botConfig: BotConfig,
  {
    categories: {
      builder: {
        steps: {
          createIntroduction: {
            quests: { linkSayActionIntroToClosedQuestion },
          },
        },
      },
    },
  }: Exercise,
): LinkSayActionClosedQuestionToNegativeAndPositiveValidate {
  const nodes: Node[] = linkSayActionIntroToClosedQuestion?.others?.nodes ?? [];
  const transitions: Transition[] = linkSayActionIntroToClosedQuestion?.others?.transitions ?? [];
  const nodeSayActionClosedQuestion: ?Node =
    linkSayActionIntroToClosedQuestion?.others?.nodeSayActionClosedQuestion;

  const nodesSayActionPositiveNegative: Node[] = filterNodesAccordingTransition(
    nodeSayActionClosedQuestion,
    SAY_NODE_NAME,
    nodes,
    transitions,
  );

  return {
    others: {
      nodes,
      nodesSayActionPositiveNegative,
      transitions,
    },
    validated: nodesSayActionPositiveNegative.length === 2,
  };
}

export default validate;
