// @flow

import type { Bot, Bots } from './types';

import type { State } from '@state/ducks/app/types';
import { TOO_LARGE_DATA } from '@state/monitoring-enhancer/index.type';

const botsStateMonitoring = (state: State): Bots => ({
  ...state.bots,
  // Catch only bots loaded
  entities: state.bots.ids.reduce((bots: { [botId: string]: Bot }, botKey: string) => {
    const bot: Bot = state.bots.entities[botKey];
    const botIsLoaded: boolean = !!bot && !!bot.loadedSnapshotId;

    // Since this obj is for monitoring purpose only, we don't mind mistyping it
    const botConfigMonitoringObj = botIsLoaded
      ? {
          ...bots,
          [botKey]: {
            ...bot,
            // $FlowFixMe
            ...(bot && bot.config
              ? {
                  config: {
                    ...bot.config,
                    entities: TOO_LARGE_DATA,
                    intents: TOO_LARGE_DATA,
                    graphs: {
                      ...bot.config.graphs,
                      tactical: TOO_LARGE_DATA,
                    },
                  },
                }
              : {}),
            ...(bot && bot.lastConfigSaved ? { lastConfigSaved: TOO_LARGE_DATA } : {}),
          },
        }
      : bots;

    return botConfigMonitoringObj;
  }, {}),
});

export default botsStateMonitoring;
