// @flow
import type { Status } from '../app/types';
// $FlowFixMe
import type { EntityState } from '@reduxjs/toolkit';

/* ACTIONS ********************************** */

const CREATE_ACCOUNT: string = 'accounts/CREATE_ACCOUNT';

const IS_AUTHORIZED_PLATFORM: string = 'accounts/platform/IS_AUTHORIZED';

const UPDATE_STATUS: string = 'accounts/UPDATE_STATUS';
const UPDATE_ERROR: string = 'accounts/UPDATE_ERROR';

const UPDATE_ACCOUNTS: string = 'accounts/UPDATE_ACCOUNTS';
const UPDATE_BOTS: string = 'accounts/UPDATE_BOTS';

const FETCH_CREDENTIALS: string = 'accounts/FETCH_CREDENTIALS';

const START_CHECK_AUTHORIZATION_SAGA: string = 'accounts/START_CHECK_AUTHORIZATION_SAGA';

/* FLOW ********************************** */

type Authorization = 'CHECKING' | 'AUTHORIZED' | 'UNAUTHORIZED';

type Authorizations = {
  [right: string]: Authorization,
};

type CredentialSecretItem = {
  secretName: string,
  description?: string,
  createdAt: string,
  updatedAt: string,
};
type Account = {|
  accountId: string,
  bots: string[], // Array of bot's name
  authorizations: Authorizations,
  credentials: {
    status: Status,
    error: ?string,
    data: CredentialSecretItem[],
  },
|};

type AccountsMap = { [account: string]: Account };

type Accounts = EntityState<Account> & {
  authorizations: Authorizations,
  error: string | null,
  status: Status,
};

export type { Account, Accounts, AccountsMap, CredentialSecretItem };
export {
  CREATE_ACCOUNT,
  FETCH_CREDENTIALS,
  IS_AUTHORIZED_PLATFORM,
  START_CHECK_AUTHORIZATION_SAGA,
  UPDATE_ACCOUNTS,
  UPDATE_BOTS,
  UPDATE_ERROR,
  UPDATE_STATUS,
};
