// @flow

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, List } from 'antd';

import { CDKButton } from '@calldesk/components';

import type { User, UserUI } from '@state/ducks/administration/types';

import AddUserForm from './AddUserForm.component';
import UserListItem from './UserListItem.component';

type Props = $Exact<{
  manageableUsers: UserUI[],
  selectUser: (selectedUser: User) => void,
  selectedUser: ?User,
  createUser: (user: $Shape<UserUI>) => void,
  deleteUser: (user: UserUI) => void,
  currentUserEmail: string | null,
  resendInvitation: (user: User) => void,
}>;

/**
 * Users manager component
 */
function UsersManager(props: Props) {
  const {
    manageableUsers,
    selectUser,
    selectedUser,
    createUser,
    deleteUser,
    currentUserEmail,
    resendInvitation,
  } = props;
  const { t } = useTranslation();

  const [showCreateUser, setShowCreateUser] = useState(false);

  return (
    <>
      <div className="NewUserFormContainer">
        <div className="NewUserFormHeader">
          <b>
            {t('UsersManager.Users.All', { ns: 'administration' })} ({manageableUsers.length})
          </b>
          <CDKButton
            type="primary"
            onClick={() => setShowCreateUser(!showCreateUser)}
            disabled={showCreateUser}
          >
            {t('UsersManager.Users.Add', { ns: 'administration' })}
          </CDKButton>
        </div>
        {showCreateUser && (
          <>
            <Divider />
            <AddUserForm
              addUser={user => createUser(user)}
              cancel={() => setShowCreateUser(false)}
              users={manageableUsers}
            />
          </>
        )}
      </div>
      <Divider />
      <List
        dataSource={manageableUsers}
        renderItem={item => (
          <UserListItem
            user={item}
            deleteUser={deleteUser}
            selectedUser={selectedUser}
            currentUserEmail={currentUserEmail}
            selectUser={selectUser}
            resendInvitation={resendInvitation}
          />
        )}
      />
    </>
  );
}

export default UsersManager;
