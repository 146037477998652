// @flow
import { Auth, CognitoUser } from '@aws-amplify/auth';

type User = {
  username: string,
  password: string,
};

/**
 * sign in method using cognito
 * @param username
 * @param password
 * @return {Promise<CognitoUser>}
 */
function signIn({ username, password }: User): Promise<CognitoUser> {
  return Auth.signIn(username, password);
}

export default signIn;
