/* eslint-disable calldesk-react/sort-func-comp */
// @flow

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

import { CDKButton, CDKInput } from '@calldesk/components';

import type { AuthChallenge } from '@api/cognito';
import LoadingSpinner from '@assets/js/calldesk-components/molecules/LoadingSpinner';
import { operations as appOperations } from '@state/ducks/app';
import {
  operations as sessionOperations,
  selectors as sessionSelectors,
} from '@state/ducks/session';

import NewPasswordRequired from './components/NewPasswordRequired';

import './login.css';

/**
 * Login component
 */
function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const loginError: ?string = useSelector(sessionSelectors.getError);
  const token: ?(AuthChallenge | string) = useSelector(sessionSelectors.getToken);

  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState(loginError);
  const [isLoading, setIsLoading] = useState(false);
  const [newPasswordRequired, setNewPasswordRequired] = useState(false);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState(null);
  const canSubmit: boolean = Boolean(email && email.trim() && password && password.trim());

  const onSuccessCompleteNewPassword = () =>
    dispatch(appOperations.startAppSaga({ history, location }));

  const handleSubmit = async () => {
    if (email && password) {
      setIsLoading(true);
      dispatch(sessionOperations.login({ email, password }));
    }
  };

  useEffect(() => {
    if (loginError !== errorMessage) {
      setErrorMessage(loginError);
      setIsLoading(false);
    }
  }, [loginError]);

  useEffect(() => {
    if (isLoading) {
      setErrorMessage(null);
    }
  }, [isLoading]);

  useEffect(() => {
    if (token) {
      if (
        typeof token === 'object' &&
        token.challenge &&
        token?.challenge === 'NEW_PASSWORD_REQUIRED'
      ) {
        setNewPasswordRequired(true);
        setUser(token.user);
      }
      dispatch(appOperations.startAppSaga({ history, location }));
    } else if (location.pathname !== '/login') {
      history.push('/login');
    }
  }, [token]);

  if (newPasswordRequired) {
    return (
      <NewPasswordRequired
        user={user}
        onSuccess={onSuccessCompleteNewPassword}
        onCancel={() => {
          setIsLoading(false);
          setNewPasswordRequired(false);
          setUser(null);
        }}
      />
    );
  }

  return (
    <div className="LoginForm">
      <h2>
        Welcome to
        <br />
        calldesk studio
      </h2>
      <div className="LoginFormField">
        <CDKInput
          placeholder="Email adress"
          value={email}
          onChange={event => setEmail(event.target.value)}
        />
      </div>
      <div className="LoginFormField">
        <CDKInput
          type={showPassword ? 'text' : 'password'}
          placeholder="Password"
          value={password}
          onChange={event => setPassword(event.target.value)}
          suffix={
            showPassword ? (
              <EyeInvisibleOutlined onClick={() => setShowPassword(false)} />
            ) : (
              <EyeOutlined onClick={() => setShowPassword(true)} />
            )
          }
        />
      </div>
      <div className="LoginFormActions">
        <NavLink to="/forgotpassword">Forgot password</NavLink>
      </div>
      <div className="SubmitButtonContainer">
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <CDKButton type="primary" onClick={handleSubmit} disabled={!canSubmit}>
          {isLoading ? <LoadingSpinner size={20} /> : 'Log in'}
        </CDKButton>
      </div>
    </div>
  );
}

export default Login;
