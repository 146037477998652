// @flow

import type { S3ProviderPutOutput, StoragePutConfig } from '@aws-amplify/storage';
import React from 'react';
import { Storage } from '@aws-amplify/storage';
import i18n from 'i18next';

import type {
  CDKRcCustomRequestOptions,
  CDKRcFile,
  CDKUploadChangeParam,
  CDKUploadFile,
  CDKUploadProps,
} from '@calldesk/components';
import { cdkNotification } from '@calldesk/components';

import logger from '@assets/js/calldesk-app-util/logger';

const BASIC_BOT_NAME_REGEXP: RegExp = /(^[a-zA-Z0-9-]*$)/;
const NEGATE_BOT_ERROR: string =
  'Only letters and digits are allowed. Special characters, accent...are prohibited';
const RESERVED_BOT_NAME_ERROR: string =
  'This name is reserved for system resources. Please choose a different name.';
const RESERVED_BOT_NAME_REGEXP: RegExp = /(tactical)/;
const CDK_CUSTOMER_DATA_BUCKET: string = 'calldesk-customer-data-eu-west-1';
const REGION: string = 'eu-west-1';

const botTemplateContent = (name: string) => (
  <>
    <h3>
      {i18n.t(`NewBotModal.templates.${name}.level`, {
        ns: 'views',
      })}
    </h3>
    <p>
      {i18n.t(`NewBotModal.templates.${name}.description`, {
        ns: 'views',
      })}
    </p>
    <ul>
      {i18n
        .t(`NewBotModal.templates.${name}.features`, { returnObjects: true, ns: 'views' })
        .map((item: string) => (
          <li key={item}>{item}</li>
        ))}
    </ul>
  </>
);

const getDraggerUploadProps = (
  files: File[],
  selectedAccount: string | null,
  setFiles: (newFiles: File[]) => void,
  setUploadError: (error: string | null) => void,
): CDKUploadProps => ({
  beforeUpload: async (file: CDKRcFile) => {
    if (files.length > 0) {
      setUploadError(i18n.t('notif.upload.errors.maxOneFile-var'));
      return Promise.reject();
    }
    if (file.type !== 'application/json') {
      setUploadError(i18n.t('notif.upload.errors.formatFileNotAllowed-var', { format: 'JSON' }));
      return Promise.reject();
    }
    try {
      const result = await file.text();
      const transcripts = JSON.parse(result);
      const isValid = transcripts[0][0].utterance;
      setUploadError(null);
      return Promise.resolve(isValid);
    } catch (error) {
      setUploadError(i18n.t('notif.upload.errors.fileDataNotValid-var', { format: 'JSON' }));
      return Promise.reject();
    }
  },
  accept: '.json',
  multiple: false,
  customRequest: async (request: CDKRcCustomRequestOptions) => {
    const { onSuccess, onError, onProgress, file } = request;
    const config: StoragePutConfig = {
      contentType: file.type,
      progressCallback: ({ loaded, total }) => {
        onProgress(
          {
            percent: Math.round((loaded / total) * 100),
          },
          file,
        );
      },
      level: 'public',
      bucket: CDK_CUSTOMER_DATA_BUCKET,
      region: REGION,
    };
    if (!selectedAccount) return;
    await Storage.put(`${selectedAccount}/autopilot/${file.name}`, file, config)
      .then((data: S3ProviderPutOutput) => {
        logger.info('NewBotModal > uploaded successfully, ', data, onSuccess);
        onSuccess(data.key, file);
      })
      .catch((err: Error) => {
        logger.info('NewBotModal > error while uploading file.');
        onError(err);
      });
  },
  onChange(info: CDKUploadChangeParam<CDKUploadFile<any>>) {
    setUploadError(null);
    const { status } = info.file;
    setFiles(info.fileList);
    if (status === 'error') {
      logger.info(info.file.error);
      cdkNotification.error({
        top: 75,
        message: i18n.t('notif.upload.errors.couldNotUpload'),
      });
    }
  },
});

export {
  BASIC_BOT_NAME_REGEXP,
  botTemplateContent,
  CDK_CUSTOMER_DATA_BUCKET,
  getDraggerUploadProps,
  NEGATE_BOT_ERROR,
  REGION,
  RESERVED_BOT_NAME_ERROR,
  RESERVED_BOT_NAME_REGEXP,
};
