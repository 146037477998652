// @flow

import type { Session as State } from './types';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  // $FlowFixMe
  createAsyncThunk,
  // $FlowFixMe
  createSlice,
} from '@reduxjs/toolkit';

import type { AuthChallenge } from '@api/cognito';
import type { ActionError, State as RootState } from '@state/ducks/app/types';
import * as globalOperations from '@state/ducks';

import { login as loginAPI, logout as logoutAPI } from './api';

type LoginCredentials = {|
  email: string,
  password: string,
|};

/* APIs Handlers */
const login = createAsyncThunk('session/LOGIN', async ({ email, password }: LoginCredentials) => {
  const token: AuthChallenge | string = await loginAPI(email, password);

  return { token };
});
const logout = createAsyncThunk('session/LOGOUT', async ({ history }, { dispatch }) => {
  await logoutAPI();

  dispatch(globalOperations.flushStore());
  history.push(`/login`);
});

/* ACTIONS & REDUCERS */
const { actions, reducer } = createSlice({
  name: 'session',
  initialState: {
    checkSessionTokenError: null,
    email: null,
    loginError: null,
    status: 'NONE',
    token: null,
    id: null,
  },
  reducers: {
    checkSessionTokenError(state: State, { payload }: PayloadAction<?string>) {
      state.checkSessionTokenError = payload;
      state.token = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(login.pending, (state: State) => {
        state.status = 'IN_PROGRESS';
      })
      .addCase(login.fulfilled, (state: State, action: PayloadAction<{ token: string }>) => {
        const { token } = action.payload;

        // TODO : handle AuthChallenge to enable MFA
        if (token && typeof token === 'string') {
          const user = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
          state.email = user.email;
          state.id = user.sub;
        }

        state.loginError = null;
        state.status = 'SUCCESS';
        state.token = token;
      })
      .addCase(login.rejected, (state: State, { error }: ActionError) => {
        state.loginError = error.message;
        state.status = 'ERROR';
        state.token = null;
      });
  },
});

/* SELECTORS */
const getCurrentUserEmail = (state: RootState): string => state.session.email || 'N/A';
const getError = (state: RootState): ?string => state.session.loginError;
const getToken = (state: RootState): ?(AuthChallenge | string) => state.session.token;
const isLoggedIn = (state: RootState): boolean => !!state.session.token;
const selectUserId = (state: RootState) => state.session.id;

/* EXPORTS */
const selectors = {
  getCurrentUserEmail,
  getError,
  getToken,
  isLoggedIn,
  selectUserId,
};

actions.login = login;
actions.logout = logout;

export { actions, selectors };
export default reducer;
