// @flow

import type { SubMenuProps } from 'antd/lib/menu';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import ArrowIcon from '@material-ui/icons/ArrowDropDown';
import { Menu } from 'antd';

import ability from '@assets/js/calldesk-app-util/casl/ability';

const { SubMenu, Item } = Menu;

type Props = {
  formatBotName: (botName: string) => string,
  botFromRoute: string,
  bots: string[],
  selectedAccount: string,
} & SubMenuProps;

function BotsSubMenuComponent(props: Props) {
  const { formatBotName, botFromRoute, bots, selectedAccount, ...restProps } = props;

  const { t } = useTranslation();

  return (
    <SubMenu
      key="bots"
      title={
        <>
          {botFromRoute || t('navbar.BotsSubMenu.bots')}&nbsp;
          <ArrowIcon />
        </>
      }
      theme="light"
      popupClassName="navbar-submenu"
      data-test-id="select-bot"
      {...restProps}
    >
      {ability.can('create', 'builder') && (
        <Item className="create" key="bots:create" icon={<AddIcon className="anticon cdkicon" />}>
          <span>{t('navbar.BotsSubMenu.newBot')}</span>
        </Item>
      )}
      {bots &&
        bots.map((botName: string, index: number) => (
          <Item
            key={`${botName}-${index.toString()}`}
            className={botName === botFromRoute && 'selected'}
          >
            <Link to={`/accounts/${selectedAccount || ''}/bots/${botName}/conversations`}>
              {formatBotName(botName)}
            </Link>
          </Item>
        ))}
    </SubMenu>
  );
}

export default BotsSubMenuComponent;
