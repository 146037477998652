// @flow

export { default as add2SayActionsNegativeAndPositive } from './add2SayActionsNegativeAndPositive/validate';
export { default as addConfirmAndInfirmIntent } from './addConfirmAndInfirmIntent/validate';
export { default as addGoodByeMessage } from './addGoodByeMessage/validate';
export { default as addSayActionClosedQuestion } from './addSayActionClosedQuestion/validate';
export { default as addTransitionsToIntents } from './addTransitionsToIntents/validate';
export { default as createSayAction } from './createSayAction/validate';
export { default as linkSayActionClosedQuestionToNegativeAndPositive } from './linkSayActionClosedQuestionToNegativeAndPositive/validate';
export { default as linkSayActionIntroToClosedQuestion } from './linkSayActionIntroToClosedQuestion/validate';
export { default as wrapUpTheScript } from './wrapUpTheScript/validate';
