// @flow

import type { Meta } from 'antd-form-builder';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  CDKButton,
  CDKCol,
  CDKForm,
  CDKFormBuilder,
  CDKInput,
  CDKRow,
  useForm,
} from '@calldesk/components';

import type { User, UserUI } from '@state/ducks/administration/types';

type Props = {
  addUser: (user: $Shape<UserUI>) => void,
  cancel: () => void,
  users: UserUI[],
};

/**
 * Add User Form Component
 */
function AddUserForm({ addUser, cancel, users }: Props) {
  const forceUpdate = CDKFormBuilder.useForceUpdate();
  const [form] = useForm();
  const { t } = useTranslation();

  const metaForm: Meta = {
    columns: 24,
    fields: [
      {
        colSpan: 12,
        key: 'firstName',
        placeholder: t('other.firstName'),
        required: true,
        widget: CDKInput,
      },
      {
        colSpan: 12,
        key: 'lastName',
        placeholder: t('other.lastName'),
        required: true,
        widget: CDKInput,
      },
      {
        colSpan: 24,
        key: 'email',
        placeholder: t('other.UserEmail'),
        required: true,
        rules: [
          {
            validator: async (rule, value) => {
              if (users.map(user => user.email).includes(value)) {
                throw new Error(`Email "${value}" already exists.`);
              }
            },
          },
        ],
        type: 'email',
        widget: CDKInput,
      },
    ],
  };

  const onFormSubmit = async (newUser: $Shape<User>) => {
    addUser(newUser);
    cancel();
  };

  const { firstName, lastName, email } = form.getFieldsValue();
  const isSubmitDisabled: boolean =
    !firstName ||
    !lastName ||
    !email ||
    !form.isFieldsTouched() ||
    !!form.getFieldsError().filter(({ errors }) => errors.length).length;

  return (
    <CDKForm
      className="NewUserForm"
      form={form}
      onFieldsChange={forceUpdate}
      onFinish={onFormSubmit}
    >
      <CDKFormBuilder form={form} meta={metaForm} />
      <CDKRow justify="end" gutter={8}>
        <CDKCol>
          <CDKForm.Item>
            <CDKButton onClick={cancel}>{t('input.label.cancel')}</CDKButton>
          </CDKForm.Item>
        </CDKCol>
        <CDKCol>
          <CDKForm.Item>
            <CDKButton disabled={isSubmitDisabled} type="primary" htmlType="submit">
              {t('input.label.add')}
            </CDKButton>
          </CDKForm.Item>
        </CDKCol>
      </CDKRow>
    </CDKForm>
  );
}

export default AddUserForm;
