// @flow

// STYLES
import React from 'react';

// COMPONENTS
import E403 from './components/E403';
import E404 from './components/E404';

import './error.css';

type Props = $Exact<{ code: number }>;

/**
 * Error page component.
 */
export default function Error(props: Props) {
  const { code } = props;

  return <div className="Error">{code === 403 ? <E403 /> : <E404 />}</div>;
}
