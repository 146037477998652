// @flow

import type { FormInstance } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _get from 'lodash/get';

import { CDKForm, CDKFormBuilder } from '@calldesk/components';

import type { State, Status } from '@state/ducks/app/types';
import type { BotPhoneNumbers, Environment, Version } from '@state/ducks/bots/types';
import type { EnvironmentsVersions, SelectedEnvironments } from '@state/ducks/ui/types';
import { operations as botsOperations, selectors as botsSelectors } from '@state/ducks/bots';

import useDeployFormProps from './utils/useDeployFormProps';

import './deploy-form.css';

type Props = {|
  account: string,
  bot: string,
  botVersions: Version[],
  environments: Environment[],
  environmentsVersions: EnvironmentsVersions,
  fetchingStatus: Status,
  form: FormInstance,
  selectedEnvironments: SelectedEnvironments,
  setEnvironmentsVersions: (environmentsVersions: EnvironmentsVersions) => void,
  setSelectedEnvironments: (selectedEnvironments: SelectedEnvironments) => void,
|};

const NA: string = 'N/A';

/**
 * Retrieve PhoneNumbers from Env
 * @param {BotPhoneNumbers} phoneNumbers
 * @param {string} env - the env to search
 * @returns {string} The most important phone number, or NA
 */
function getPhoneNumberForEnv(phoneNumbers: BotPhoneNumbers, env: string) {
  const primary: ?string = _get(phoneNumbers, `${env}.primary[0].botVersion`);
  const backup: ?string = _get(phoneNumbers, `${env}.backup[0].botVersion`);
  const sms: ?string = _get(phoneNumbers, `${env}.sms[0].botVersion`);

  return primary ?? backup ?? sms ?? NA;
}

/**
 * Deploy Form Component
 */
function DeployForm({
  account,
  bot,
  botVersions,
  environments,
  environmentsVersions,
  fetchingStatus,
  form,
  selectedEnvironments,
  setEnvironmentsVersions,
  setSelectedEnvironments,
}: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const botPhoneNumbers: BotPhoneNumbers = useSelector(state =>
    botsSelectors.getBotPhoneNumbers(state, bot),
  );
  const hasBuildingErrors: boolean = useSelector<State, boolean>(state =>
    botsSelectors.botHasBuildingErrors(state, bot),
  );

  const fetchBotPhoneNumbers = () =>
    dispatch(botsOperations.fetchBotPhoneNumbers({ account, bot }));

  const lastSelectedEnv: Environment = form.getFieldValue('environment');
  const hasFetchingFailed: boolean = fetchingStatus === 'ERROR';
  const isDeployingOnDev: boolean = lastSelectedEnv === 'dev';
  const isFetching: boolean = fetchingStatus === 'IN_PROGRESS';

  // eslint-disable-next-line calldesk-react/sort-func-comp
  const { formMeta, onFormChange } = useDeployFormProps({
    account,
    bot,
    botPhoneNumbers,
    botVersions,
    environments,
    environmentsVersions,
    form,
    isFetching,
    selectedEnvironments,
    setSelectedEnvironments,
  });

  useEffect(() => {
    setEnvironmentsVersions({
      dev: getPhoneNumberForEnv(botPhoneNumbers, 'dev'),
      internalTest: getPhoneNumberForEnv(botPhoneNumbers, 'internalTest'),
      clientTest: getPhoneNumberForEnv(botPhoneNumbers, 'clientTest'),
      prod: getPhoneNumberForEnv(botPhoneNumbers, 'prod'),
    });
  }, [botPhoneNumbers]);

  useEffect(() => {
    fetchBotPhoneNumbers();
  }, []);

  return (
    <CDKForm className="DeployForm" form={form} layout="vertical" onValuesChange={onFormChange}>
      {hasFetchingFailed && <p>{t('navbar.Deploy.Form.Errors.fetching')}</p>}
      <CDKFormBuilder form={form} meta={formMeta} />
      {hasBuildingErrors && isDeployingOnDev && (
        <p className="warning-message">{t('navbar.Deploy.Form.Errors.validation')}</p>
      )}
    </CDKForm>
  );
}

export default DeployForm;
