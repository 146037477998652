// @flow

type CalendarContentType = 'OPENING_HOURS' | 'SCHEDULE_APPOINTMENTS';

type EntityContentType = 'RegExp' | 'Simple';

type IntentContentType = 'custom' | 'system';

type AssessmentContentType = 'intents';

type AssetType =
  | 'assessments'
  | 'audios'
  | 'calendars'
  | 'entities'
  | 'intents'
  | 'replacements'
  | 'source-files';

type AssetScope = 'account' | 'bot';

type AssetContentType =
  | CalendarContentType
  | EntityContentType
  | IntentContentType
  | AssessmentContentType;

type AssetBase<T = typeof undefined> = {|
  assetType: AssetType,
  contentType?: T,
  deprecatedSince?: number,
  draftSince?: number,
  fileType: 'application/json',
  id: string,
  lastModified?: number,
  name: string,
  scope: AssetScope,
  uri?: string,
  versionId: string,
|};

type Asset<K, V = typeof undefined> = AssetBase<V> & {
  content: K,
};

// REFRENTIALS
type AdditionnalPropertyType = 'string' | 'number' | 'boolean' | 'array' | 'any';
type AdditionnalPropertyValueType = string | number | boolean | Array<any> | Object;
type AdditionnalProperty = {
  value: AdditionnalPropertyValueType,
  type: AdditionnalPropertyType,
};
type AdditionnalProperties = {
  [property: string]: AdditionnalProperty,
};

type ReferentialValue = {|
  pronunciations?: string[],
  description?: string,
  additionnalProperties?: AdditionnalProperties,
|};

// TODO to remove
type EntityAssetContentValue = {|
  pronunciations?: string[],
  description?: string,
  additionnalProperties?: AdditionnalProperties,
|};

type ReferentialFileValue = {
  pronunciations?: string[],
  [additionnalProperty: string]: boolean | string | number | Object,
};

type EntitiesFileValue = {
  [entityName: string]: ReferentialFileValue,
};

// TODO to remove
type CustomReferential = {
  [value: string]: ReferentialValue,
};

type EntityAssetContent = {
  [value: string]: EntityAssetContentValue,
};

type EntityContentStatus = 'DEPRECATED' | 'DRAFT' | 'RESTORED';

type ReferentialContentStatus = 'DEPRECATED' | 'DRAFT' | 'RESTORED';

type ReferentialContentMetadata = { [metadata: string]: string };

type ReferentialContent = {|
  id: string,
  name: string,
  type: string,
  values?: CustomReferential,
  meta?: ReferentialContentMetadata,
  deprecatedSince?: number,
  draftSince?: number,
|};

type EntityAsset = {
  referential: ReferentialContent,
};

type Entity = {
  entityType: string,
  referentialId: string,
  values: CustomReferential,
};

type Replacements = { [char: string]: string[] };

// SOURCE FILES

type SourceFileData = {
  id: string,
  name: string,
  type: string,
  path: string,
  lastModified: Date,
  scope: 'bot' | 'account',
};

type SourceFileAsset = SourceFileData & {
  content: string,
  schema?: string,
};

type SourceFilesAsset = {
  sourceFile: SourceFilesAsset,
};

// CALENDARS

type Availability = {
  startTime: string, // ISO 8601 : 11:20:18Z
  endTime: string, // ISO 8601 : 11:50:18Z
  slots?: number, // Only for SCHEDULE_APPOINTMENTS
  transferPhoneNumber?: string, // Only for OPENING_HOURS
};

type WeeklyPlanning = {|
  '0'?: Availability[], // sunday
  '1'?: Availability[], // monday
  '2'?: Availability[], // tuesday
  '3'?: Availability[], // wednesday
  '4'?: Availability[], // thrusday
  '5'?: Availability[], // friday
  '6'?: Availability[], // saturday
|};

type ExceptionnalPlanning = {
  [dayISO: string]: Availability[], // Use date format ISO 8601 yyyy-mm-dd
};

type AppointmentStatus = 'PLANNED' | 'WAITING' | 'DONE';

type Appointment = {
  name: string, // Dynamically generated on creation based on callerNumber for the moment
  description?: string, // Optionnal, will be used for later
  start: string, // Booking time for that day full ISO 8601 ex: 2021-03-30T11:06:18Z
  end: string, // full ISO 8601 ex: 2021-03-30T11:06:18Z
  status: AppointmentStatus, // Used for later. DONE by default for the moment
};

type Appointments = {
  [dayISO: string]: Appointment[], // Use date format ISO with only day/month/year
};

type PlanningType = 'weekly' | 'exceptionnal';

type CalendarContent = {
  description: string,
  planning: WeeklyPlanning,
  exceptionnal?: ExceptionnalPlanning,
  appointments?: Appointments,
  timezone?: string,
};

// AUDIOS

// NOTE: null because audio has no contentType attribute set
type AudioAsset = AssetBase<null>;

// INTENTS

type IntentAssetContent = {
  entities?: string[][],
  description?: string,
  utterances?: string[],
};

// SAGAS
const TAG_ENTITIES_REFERENTIAL_SAGA: string = 'customers-assets/TAG_ENTITIES_REFERENTIAL_SAGA';

export type {
  AdditionnalProperties,
  AdditionnalProperty,
  AdditionnalPropertyType,
  AdditionnalPropertyValueType,
  Appointment,
  Appointments,
  AppointmentStatus,
  AssessmentContentType,
  Asset,
  AssetBase,
  AssetContentType,
  AssetScope,
  AssetType,
  AudioAsset,
  Availability,
  CalendarContent,
  CalendarContentType,
  CustomReferential,
  EntitiesFileValue,
  Entity,
  EntityAsset,
  EntityAssetContent,
  EntityAssetContentValue,
  EntityContentStatus,
  EntityContentType,
  ExceptionnalPlanning,
  IntentAssetContent,
  IntentContentType,
  PlanningType,
  ReferentialContent,
  ReferentialContentMetadata,
  ReferentialContentStatus,
  ReferentialFileValue,
  ReferentialValue,
  Replacements,
  SourceFileAsset,
  SourceFileData,
  WeeklyPlanning,
};

export { TAG_ENTITIES_REFERENTIAL_SAGA };
