// @flow

import _get from 'lodash/get';
import _set from 'lodash/set';

import type { BotConfig } from '@state/ducks/bots/types';
import type { Exercise, Quest, ValidatorData } from '@state/ducks/onboarding/types';
import type { AvailableOptions } from '@state/ducks/options/types';
import { DEFAULT_TTS_VOICES } from '@state/ducks/bots/utils';

import validateCategories from './validate-categories';
import validateQuest from './validate-quest';

/**
 * Run validators for each quests in exercise
 */
function validateExercise(
  exerciseId: string,
  questPath?: string,
  botConfig: BotConfig,
  progression: Exercise,
  validatorData: ValidatorData,
  // "voices" is currently the only options required by the current quests
  availableOptions?: AvailableOptions = { voices: DEFAULT_TTS_VOICES },
): Exercise {
  const newProgression: Exercise = { ...progression };

  // Validate a specific quest in exercise
  if (questPath) {
    const questProgression: ?Quest = _get(newProgression, questPath);

    if (questProgression) {
      const updatedQuestProgression: Quest = validateQuest(
        exerciseId,
        questPath,
        botConfig,
        newProgression,
        validatorData,
        availableOptions,
      );

      _set(newProgression, questPath, updatedQuestProgression);
    }
  } else {
    // NOTE: Quests needs to be validated sequentially because a quest N+1 cannot be validated if quest N is not valid
    // TODO: Check if last "scope" has failed. If yes, just stop the loop or skip computing for next ones
    newProgression.categories = validateCategories(
      exerciseId,
      botConfig,
      newProgression,
      validatorData,
      availableOptions,
    );
  }

  return newProgression;
}

export default validateExercise;
