// @flow

import type { PayloadAction } from '@reduxjs/toolkit';
import type { Saga } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import type { Status, StorageSettings } from '@state/ducks/app/types';
import { getUserSub } from '@api/cognito';
import logger from '@assets/js/calldesk-app-util/logger';
import {
  operations as accountsOperations,
  selectors as accountsSelectors,
} from '@state/ducks/accounts';
import { operations as botsOperations } from '@state/ducks/bots';
import * as botsApi from '@state/ducks/bots/api';
import { operations as uiOperations } from '@state/ducks/ui';
import { monitoringCatchException } from '@state/monitoring-enhancer';
import * as botsSagas from '@state/sagas/bots';

/**
 * Due to fact we use duplicate saga to duplicate or create a bot from a template
 * this function is based on botTemplate existence to return right status to update
 *
 * @param {*} params -
 * @param {boolean} params.isBotTemplate - is or not a creation bot from a template
 * @param {Status} params.statusValue - the value of the status
 * @param {Status} params.botName - the bot name used for duplicate status
 * @returns - the right redux effects
 */
function setDuplicateOrCreateStatus({
  isBotTemplate,
  statusValue,
  botName,
}: {
  isBotTemplate: boolean,
  statusValue: Status,
  botName: string,
}): Function {
  if (isBotTemplate) {
    return put(botsOperations.setSpecificBotsStatus({ name: 'create', value: statusValue }));
  }
  return put(botsOperations.updateDuplicateBotStatus({ bot: botName, status: statusValue }));
}

/**
 * This saga is used in order to duplicate current bot
 *
 * @param {{ accountId: string, botName: string }} from - bot origin will be duplicated.
 * @param {{ accountId: string, botName: string }} to - bot destination will be created from "from".
 * @param {string} timezone - the timezone to set to the new bot
 * @param {string[]} bots - bots list from account selected by new bot.
 */
function* duplicateBotSaga({
  payload: { from, to, settings },
}: PayloadAction<{
  from: { accountId: string, botName: string, botTemplate?: { id: string } },
  to: { accountId: string, botName: string },
  settings?: {
    timezone?: string,
    language?: string,
    storageSettings: StorageSettings,
  },
}>): Saga<any> {
  const userSub: string = yield call(getUserSub);
  let botsList: string[] = [];
  try {
    if (from.accountId !== to.accountId) {
      yield call(
        botsSagas.getBotsSaga,
        botsOperations.startGetBotsSaga({
          account: to.accountId,
        }),
      );
    }
    botsList = yield select(accountsSelectors.getBots, to.accountId);
    const botsListUpdated: string[] = [...botsList, to.botName];

    yield put(accountsOperations.updateBots({ accountId: to.accountId, botIds: botsListUpdated }));

    yield setDuplicateOrCreateStatus({
      isBotTemplate: !!from.botTemplate,
      statusValue: 'IN_PROGRESS',
      botName: to.botName,
    });

    yield call(botsApi.duplicateBotOnApi, from, to, settings);
    yield call(
      botsSagas.getBotConfigSaga,
      botsOperations.startGetBotConfigSaga({
        bot: to.botName,
        account: to.accountId,
        botsFetched: true,
        snapshotId: 'latest',
      }),
    );
    yield setDuplicateOrCreateStatus({
      isBotTemplate: !!from.botTemplate,
      statusValue: 'SUCCESS',
      botName: to.botName,
    });
    yield put(uiOperations.updateSelectedAccount(to.accountId));
  } catch (error) {
    logger.error('Bots > duplicateBot > ', error);
    yield put(accountsOperations.updateBots({ accountId: to.accountId, botIds: botsList }));
    yield put(botsOperations.updateError(error.message));
    yield put(uiOperations.openErrorModal());
    yield setDuplicateOrCreateStatus({
      isBotTemplate: !!from.botTemplate,
      statusValue: 'ERROR',
      botName: to.botName,
    });
    monitoringCatchException({
      exception: error,
      userSub,
      location: 'state/sagas/bots/duplicateBotSaga',
      functionType: 'saga',
      params: { from, to },
      accountId: from.accountId,
      botName: from.botName,
    });
  }
}
export default duplicateBotSaga;
