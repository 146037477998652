// @flow

import type { BotConfig, Node, Transition } from '@state/ducks/bots/types';
import type { Exercise, ValidateFnReturn } from '@state/ducks/onboarding/types';
import { SAY_NODE_NAME } from '@state/ducks/bots/types';
import { filterNodesAccordingTransition } from '@state/ducks/onboarding/utils/validator/utils';

type LinkSayActionIntroToClosedQuestionValidate = ValidateFnReturn<{
  nodeSayActionClosedQuestion: ?Node,
  nodes: Node[],
  transitions: Transition[],
}>;

/**
 * Validate `linkSayActionIntroToClosedQuestion` quest
 * NOTE: Blocked by `addSayActionClosedQuestion` quest
 */
function validate(
  botConfig: BotConfig,
  {
    categories: {
      builder: {
        steps: {
          createIntroduction: {
            quests: { addSayActionClosedQuestion },
          },
        },
      },
    },
  }: Exercise,
): LinkSayActionIntroToClosedQuestionValidate {
  const nodes: Node[] = addSayActionClosedQuestion?.others?.nodes ?? [];
  const transitions: Transition[] = addSayActionClosedQuestion?.others?.transitions ?? [];
  const nodeSayActionInto: ?Node = addSayActionClosedQuestion?.others?.nodeSayActionInto;

  const nodeSayActionClosedQuestion: ?Node = filterNodesAccordingTransition(
    nodeSayActionInto,
    SAY_NODE_NAME,
    nodes,
    transitions,
    true,
  );

  return {
    validated: !!nodeSayActionClosedQuestion,
    others: {
      nodes,
      transitions,
      nodeSayActionClosedQuestion,
    },
  };
}

export default validate;
