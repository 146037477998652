// @flow

import Assessments from './assessments.json';
import Calendars from './calendars.json';
import Chat from './chat.json';
import entities from './entities.json';
import FlashMessages from './flash-messages.json';
import History from './history.json';
import intents from './intents.json';
import Script from './script.json';
import Settings from './settings.json';
import Sources from './sources.json';
import Training from './training.json';

export default {
  Assessments,
  Calendars,
  Chat,
  entities,
  FlashMessages,
  History,
  intents,
  Script,
  Settings,
  Sources,
  Training,
};
