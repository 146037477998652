// @flow

import type { GraphData } from '../../types';

import type { BotIntent } from '@state/ducks/intents/types';
import type { IntentsOptionsMap } from '@state/ducks/options/types';
import uuid from '@assets/js/calldesk-app-util/uuid';
import { extractEntitiesFromIntent } from '@state/ducks/intents/utils';

import isV1Model from './is-intent-model-v1';

/**
 * Turns previous intents format to the new one
 */
function transformOldIntentModel({
  intents,
  graphs,
  language = 'en-US',
  availableIntents,
}: {
  intents: { [intentName: string]: string[] },
  graphs: GraphData,
  language: string,
  availableIntents: IntentsOptionsMap,
}): { [id: string]: BotIntent } {
  if (!isV1Model({ intents, graphs }))
    throw new Error('intent model is not from previous format. This transformation cannot occur');
  return Object.fromEntries(
    Object.keys(intents).map((intentName: string) => {
      const isAvailableSystemIntent: boolean = Boolean(availableIntents[language]?.[intentName]);
      const id: string = isAvailableSystemIntent ? intentName : uuid();

      return [
        id,
        {
          id,
          label: intentName,
          description: '',
          utterances: intents[intentName],
          entities: extractEntitiesFromIntent(intents[intentName]),
          scope: 'bot',
          type: 'custom',
        },
      ];
    }),
  );
}

export default transformOldIntentModel;
