/* eslint-disable max-lines */
// @flow

import type { RouterHistory } from 'react-router-dom';
import type {
  AvailablePhoneNumbers,
  BotAction,
  BotConfig,
  BotLanguage,
  BotPhoneNumbers,
  Bots as BotsState,
  Entity,
  Environment,
  FormattedPhoneNumber,
  Version,
} from './types';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  // $FlowFixMe
  createAction,
  // $FlowFixMe
  createAsyncThunk,
  // $FlowFixMe
  createEntityAdapter,
  // $FlowFixMe
  createSlice,
} from '@reduxjs/toolkit';
import _values from 'lodash/values';

import type { ProductTourCookie } from '@assets/js/calldesk-app-util/product-tour/index.type';
import type { ActionError, ActionMeta, State as RootState } from '@state/ducks/app/types';
import type { IntentsMap } from '@state/ducks/intents/types';
import {
  getProductTourCookie,
  removeProductTourCookie,
  setProductTourCookie,
} from '@assets/js/calldesk-app-util/product-tour';
import { systemAssets } from '@resources/options/available.json';
import { api as customersAssetsApi } from '@state/ducks/customers-assets';
import { selectors as optionsSelectors } from '@state/ducks/options';

import * as api from './api';
// Reducers
import botsReducer from './bots.reducer';
// Selectors
import * as botsRootSelectors from './bots.selectors';
import settingsReducer from './config.reducer';
import * as settingsSelectors from './config.selectors';
import entitiesReducer from './entities.reducer';
import * as entitiesSelectors from './entities.selectors';
import graphsReducer from './graphs.reducer';
import * as graphsSelectors from './graphs.selectors';
import intentsReducer from './intents.reducer';
import * as intentsSelectors from './intents.selectors';
import statsReducer from './stats.reducer';
import * as statsSelectors from './stats.selectors';
import {
  CREATE_NEW_BOT_SAGA,
  DELETE_BOT_SAGA,
  DUPLICATE_BOT_SAGA,
  START_CHECK_BOT_SAGA,
  START_GET_BOT_CONFIG_SAGA,
  START_GET_BOTS_SAGA,
  START_IMPORT_INTENTS_BOT_SAGA,
  START_PUBLISH_BOT_SAGA,
  START_SAVE_BOT_SAGA,
  START_VALIDATE_BOT_SAGA,
} from './types';

const rootAdapter = createEntityAdapter({});

const initialState: BotsState = rootAdapter.getInitialState({
  error: null,
  status: {
    fetching: 'NONE',
    delete: 'NONE',
    create: 'NONE',
  },
});

const EMPTY_ENV_PHONE_NUMBERS: AvailablePhoneNumbers = {
  primary: [],
  backup: [],
  sms: [],
};

const EMPTY_BOT_PHONE_NUMBERS: BotPhoneNumbers = {
  dev: EMPTY_ENV_PHONE_NUMBERS,
  internalTest: EMPTY_ENV_PHONE_NUMBERS,
  clientTest: EMPTY_ENV_PHONE_NUMBERS,
  prod: EMPTY_ENV_PHONE_NUMBERS,
};

const sortFunction = (phoneNumber1: FormattedPhoneNumber, phoneNumber2: FormattedPhoneNumber) => {
  const textA: string = phoneNumber1.to.toUpperCase();
  const textB: string = phoneNumber2.to.toUpperCase();

  if (textA < textB) return -1;
  if (textA > textB) return 1;
  return 0;
};

/* API HANDLERS */
const startGetBotsSaga = createAction<{ history: RouterHistory }, typeof START_GET_BOTS_SAGA>(
  START_GET_BOTS_SAGA,
);
const startGetBotConfigSaga = createAction<
  { bot: string, account: string, botsFetched: boolean, snapshotId: string },
  typeof START_GET_BOT_CONFIG_SAGA,
>(START_GET_BOT_CONFIG_SAGA);
const startValidateBotSaga = createAction<{ bot: string }, typeof START_VALIDATE_BOT_SAGA>(
  START_VALIDATE_BOT_SAGA,
);
const deleteBotSaga = createAction<
  { accountId: string, botName: string, bots: string[] },
  typeof DELETE_BOT_SAGA,
>(DELETE_BOT_SAGA);
const startCheckBotSaga = createAction<
  { bot: string, tag: string, environment: Environment },
  typeof START_CHECK_BOT_SAGA,
>(START_CHECK_BOT_SAGA);
const duplicateBotSaga = createAction<
  {
    from: { accountId: string, botName: string, botTemplate?: { id: string } },
    to: { accountId: string, botName: string },
    settings?: {
      timezone?: string,
      language?: string,
    },
  },
  typeof DUPLICATE_BOT_SAGA,
>(DUPLICATE_BOT_SAGA);
const createBotSaga = createAction<
  {
    accountId: string,
    botName: string,
    botLanguage: BotLanguage,
    botTimezone: string,
    autoPilotFiles: File[],
    bots: string[],
    botTemplate: null | { account: string, botName: string, id: string },
  },
  typeof CREATE_NEW_BOT_SAGA,
>(CREATE_NEW_BOT_SAGA);
const startPublishBotSaga = createAction<
  { bot: string, tag: string, environment: Environment, description: string },
  typeof START_PUBLISH_BOT_SAGA,
>(START_PUBLISH_BOT_SAGA);
const startSaveBotSaga = createAction<
  {
    bot: string,
    account: string,
    botConfig: BotConfig,
    description: string,
    parentSnapshotId: string,
    callback?: Function,
  },
  typeof START_SAVE_BOT_SAGA,
>(START_SAVE_BOT_SAGA);
const startImportIntentsBotSaga = createAction<
  {
    bot: string,
    intents: IntentsMap,
  },
  typeof START_IMPORT_INTENTS_BOT_SAGA,
>(START_IMPORT_INTENTS_BOT_SAGA);

/**
 * ASYNC THUNK
 */
const createChatApiKey = createAsyncThunk(
  'bots/createChatApiKey',
  ({ bot, account }: BotAction<$Exact<{ account: string }>>) => api.createChatApiKey(account, bot),
);
const fetchBotPhoneNumbers = createAsyncThunk(
  'bots/fetchBotPhoneNumbers',
  ({ account, bot }: BotAction<$Exact<{ account: string }>>) =>
    api.loadBotPhoneNumbers(account, bot),
);
const fetchBotVersions = createAsyncThunk(
  'bots/fetchBotVersions',
  ({ account, bot }: BotAction<$Exact<{ account: string }>>) =>
    api.fetchVersionsForBot(account, bot),
);
const fetchChatApiKey = createAsyncThunk(
  'bots/fetchChatApiKey',
  ({ account, bot }: BotAction<$Exact<{ account: string }>>) => api.fetchChatApiKey(account, bot),
);
// Consumed directly by components using unwrap
const getEntitySample = createAsyncThunk(
  'bots/getEntitySample',
  ({ account, bot, entityMask }, { getState }) => {
    const { bots } = getState();
    const match: ?Entity = _values(bots.entities[bot].config.entities).find(
      entity => entity.entityMask === entityMask,
    );
    if (match && !systemAssets.entities[match.referential.id]) {
      return customersAssetsApi.getRandomValueEntityReferential(account, match.id);
    }

    return Promise.resolve();
  },
);
// this probably should not be there
// TODO : consume directly by components
const handleProductTourCookie = createAsyncThunk(
  'bots/handleProductTourCookie',
  ({ bot }, { getState }) => {
    const state = getState();
    const botTemplateId: ?string = state.bots.entities[bot]?.config?.botTemplate?.id;
    let botTemplateName;
    if (botTemplateId) {
      const botTemplatesOptions = optionsSelectors.selectOption(state, { option: 'bot-templates' });
      const botTemplate: { id: string, name: string } = botTemplatesOptions.find(
        ({ id }: { id: string }) => id === botTemplateId,
      );
      if (botTemplate) {
        botTemplateName = botTemplate.name;
      }
    }
    if (botTemplateName) {
      const currProductTourCookie: ?ProductTourCookie = getProductTourCookie();
      setProductTourCookie({
        ...(currProductTourCookie ? { ...currProductTourCookie } : {}),
        template: botTemplateName,
      });
    } else {
      removeProductTourCookie();
    }
  },
);

const { actions, reducer } = createSlice({
  name: 'bots',
  initialState,
  reducers: {
    ...botsReducer,
    ...entitiesReducer,
    ...graphsReducer,
    ...intentsReducer,
    ...settingsReducer,
    ...statsReducer,
  },
  extraReducers: builder =>
    builder
      /* CHAT API KEY */
      .addCase(fetchChatApiKey.pending, (state, { meta }: { meta: ActionMeta }) => {
        state.entities[meta.arg.bot].status.chatApiKey = 'IN_PROGRESS';
      })
      .addCase(
        fetchChatApiKey.fulfilled,
        (state, { payload, meta }: { meta: ActionMeta } & PayloadAction<BotAction<string>>) => {
          state.entities[meta.arg.bot].status.chatApiKey = 'SUCCESS';
          state.entities[meta.arg.bot].chatApiKey.value = payload;
        },
      )
      .addCase(
        fetchChatApiKey.rejected,
        (state, { error, meta }: ActionError & { meta: ActionMeta }) => {
          state.entities[meta.arg.bot].status.chatApiKey = 'ERROR';
          state.entities[meta.arg.bot].chatApiKey.error = error;
        },
      )
      .addCase(
        createChatApiKey.fulfilled,
        (state, { meta, payload }: PayloadAction<BotAction<string>> & { meta: ActionMeta }) => {
          state.entities[meta.arg.bot].chatApiKey.value = payload;
          state.entities[meta.arg.bot].status.chatApiKey = 'SUCCESS';
        },
      )
      .addCase(
        createChatApiKey.rejected,
        (state, { error, meta }: ActionError & { meta: ActionMeta }) => {
          state.entities[meta.arg.bot].chatApiKey.error = error;
          state.entities[meta.arg.bot].status.chatApiKey = 'ERROR';
        },
      )
      /* BOT VERSIONS */
      .addCase(fetchBotVersions.pending, (state, { meta }: { meta: ActionMeta }) => {
        state.entities[meta.arg.bot].status.versions = 'IN_PROGRESS';
      })
      .addCase(
        fetchBotVersions.fulfilled,
        (state, { payload, meta }: { meta: ActionMeta } & PayloadAction<BotAction<Version[]>>) => {
          state.entities[meta.arg.bot].status.versions = 'SUCCESS';
          state.entities[meta.arg.bot].versions = payload;
        },
      )
      .addCase(
        fetchBotVersions.rejected,
        (state, { meta, error }: ActionError & { meta: ActionMeta }) => {
          state.entities[meta.arg.bot].status.versions = 'ERROR';
          state.entities[meta.arg.bot].errors.versions = error;
        },
      )
      /* PHONE NUMBERS */
      .addCase(fetchBotPhoneNumbers.pending, (state, { meta }: { meta: ActionMeta }) => {
        state.entities[meta.arg.bot].status.phoneNumbers = 'IN_PROGRESS';
        state.entities[meta.arg.bot].phoneNumbers = EMPTY_BOT_PHONE_NUMBERS;
      })
      .addCase(
        fetchBotPhoneNumbers.fulfilled,
        (
          state,
          { payload, meta }: { meta: ActionMeta } & PayloadAction<BotAction<BotPhoneNumbers>>,
        ) => {
          state.entities[meta.arg.bot].status.phoneNumbers = 'SUCCESS';

          const sortedBotPhoneNumbers: BotPhoneNumbers = Object.keys(payload).reduce(
            (botPhoneNumbersAcc: BotPhoneNumbers, env: string) => {
              const envPhoneNumbers: AvailablePhoneNumbers = payload[env];
              // $FlowFixMe NOTE: envPhoneNumbers can't be null since we loop over it
              const sortedEnvPhoneNumbers = Object.keys(envPhoneNumbers).reduce(
                (envPhoneNumbersAcc: AvailablePhoneNumbers, category: string) => ({
                  ...envPhoneNumbersAcc,
                  // NOTE: Sort is mutable so we make a copy first
                  [category]: [...envPhoneNumbers[category]].sort(sortFunction),
                }),
                {},
              );

              return {
                ...botPhoneNumbersAcc,
                [env]: sortedEnvPhoneNumbers,
              };
            },
            {},
          );

          state.entities[meta.arg.bot].phoneNumbers = sortedBotPhoneNumbers;
        },
      )
      .addCase(
        fetchBotPhoneNumbers.rejected,
        (state, { meta, error }: ActionError & { meta: ActionMeta }) => {
          state.entities[meta.arg.bot].status.phoneNumbers = 'ERROR';
          state.entities[meta.arg.bot].errors.phoneNumbers = error;
        },
      ),
});

/* SELECTORS */
const botsSelectors = rootAdapter.getSelectors((state: RootState) => state.bots);

const selectors = {
  ...botsRootSelectors,
  ...entitiesSelectors,
  ...graphsSelectors,
  ...intentsSelectors,
  ...settingsSelectors,
  ...statsSelectors,
};

// Export des thunks
actions.createBotSaga = createBotSaga;
actions.createChatApiKey = createChatApiKey;
actions.deleteBotSaga = deleteBotSaga;
actions.duplicateBotSaga = duplicateBotSaga;
actions.fetchBotPhoneNumbers = fetchBotPhoneNumbers;
actions.fetchBotVersions = fetchBotVersions;
actions.fetchChatApiKey = fetchChatApiKey;
actions.getEntitySample = getEntitySample;
actions.handleProductTourCookie = handleProductTourCookie;
actions.startCheckBotSaga = startCheckBotSaga;
actions.startGetBotConfigSaga = startGetBotConfigSaga;
actions.startGetBotsSaga = startGetBotsSaga;
actions.startImportIntentsBotSaga = startImportIntentsBotSaga;
actions.startPublishBotSaga = startPublishBotSaga;
actions.startSaveBotSaga = startSaveBotSaga;
actions.startValidateBotSaga = startValidateBotSaga;

/* EXPORTS */
export { actions, rootAdapter as botsAdapter, botsSelectors, selectors };
export default reducer;
