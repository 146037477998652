// @flow

import type { BotConfig } from '@state/ducks/bots/types';
import type { Exercise, Steps, ValidatorData } from '@state/ducks/onboarding/types';
import type { AvailableOptions } from '@state/ducks/options/types';

import sorting from '../sorting';
import validateQuests from './validate-quests';

/**
 * Run validators functions on each steps
 */
function validateSteps(
  exerciseId: string,
  category: string,
  botConfig: BotConfig,
  progression: Exercise,
  validatorData: ValidatorData,
  availableOptions: AvailableOptions,
): Steps {
  const steps: Steps = progression.categories[category]?.steps || {};

  return Object.keys(steps)
    .sort(sorting(steps))
    .reduce(
      (newSteps: Steps, step: string) => ({
        ...newSteps,
        [step]: {
          ...steps[step],
          quests: validateQuests(
            exerciseId,
            category,
            step,
            botConfig,
            progression,
            validatorData,
            availableOptions,
          ),
        },
      }),
      {},
    );
}

export default validateSteps;
