// @flow

import type {
  BotAction,
  BotSayAction,
  ChatResponseSuccess,
  ConversationState,
  EndPointStub,
  ExecuteApiResponse,
  ExecuteBody,
  ThinkApiResponse,
  TraceItem,
} from './types';

import request from '@amplify/api/request';
import logger from '@assets/js/calldesk-app-util/logger';

import { buildBotResponseMessage, fallbackTraceFormatter } from './util';

// TODO : explain why we use this ?
// TODO : use a Proxy object maybe ?
const LAST_RESPONSE = {
  _value: undefined,
  // eslint-disable-next-line no-underscore-dangle
  get: () => LAST_RESPONSE._value,
  set: (val: ExecuteApiResponse) => {
    // eslint-disable-next-line no-underscore-dangle
    LAST_RESPONSE._value = val;
  },
};

/**
 * Request /think endpoint of bot-api
 * NOTE: Used only by chat function
 */
function think(
  selectedAccount: string,
  botName: string,
  botVersion: string,
  state: ConversationState | null,
  query?: string,
  debugMode?: boolean,
): Promise<ThinkApiResponse> {
  logger.info('state/chat/api [think]');

  return request.botApi({
    path: `/accounts/${selectedAccount}/bots/${botName}/versions/${botVersion}/think`,
    method: 'POST',
    body: {
      state,
      queries: query === null ? [] : [query],
      ...(debugMode && { intentDebugMode: true }),
    },
  });
}

/**
 * Request /execute endpoint of bot-api
 */
async function execute(
  selectedAccount: string,
  botName: string,
  botVersion: string,
  body: ExecuteBody,
  botResponseMessage: string = '',
): Promise<ExecuteApiResponse[]> {
  logger.info('state/chat/api [execute]');

  let newBotResponseMessage: string = botResponseMessage;

  try {
    const response: ExecuteApiResponse = await request.botApi({
      path: `/accounts/${selectedAccount}/bots/${botName}/versions/${botVersion}/execute`,
      method: 'POST',
      body,
    });
    LAST_RESPONSE.set(response);
    let executeResponses = [response];
    if (response.actions.length > 0) {
      const newBody: ExecuteBody = {
        state: response.state,
        actions: response.actions,
        intentMatch: body.intentMatch,
      };

      // cast response.actions as BotAction[] to BotSayAction[]
      const sayActions = ((response.actions: any): BotSayAction[]);
      newBotResponseMessage = `${botResponseMessage} ${buildBotResponseMessage(
        sayActions,
      )}`.trimLeft();

      executeResponses = executeResponses.concat(
        await execute(selectedAccount, botName, botVersion, newBody, newBotResponseMessage),
      );
      return executeResponses;
    }

    response.botResponseMessage = newBotResponseMessage;

    return executeResponses;
  } catch (error) {
    if (LAST_RESPONSE.get() !== {}) {
      error.newState = LAST_RESPONSE.get();
      LAST_RESPONSE.set({});
    }

    // We are forced to do this because Bot-api returns a different trace shape when it fails
    if (body.actions.length > 1) {
      const formattedActions: TraceItem<any>[] = body.actions
        .map(fallbackTraceFormatter)
        .map((action: BotAction) => ({
          node: action,
          nodeId: action.id,
          timestamp: Date.now(),
        }));
      const newState = { ...body.state, trace: formattedActions };
      error.newState = newState;
    }

    throw error;
  }
}

const chat = async (
  selectedAccount: string,
  botName: string,
  botVersion: string,
  state: ConversationState,
  query?: string,
  actionCalls?: EndPointStub[],
  apiCallId?: string,
): Promise<ChatResponseSuccess[]> => {
  const thinkResp: ThinkApiResponse = await think(
    selectedAccount,
    botName,
    botVersion,
    state,
    query,
    true,
  );

  try {
    const executeResponses: ExecuteApiResponse[] = await execute(
      selectedAccount,
      botName,
      botVersion,
      {
        state,
        intentMatch: thinkResp.intentMatch,
        actions: thinkResp.actions,
        actionCalls,
        apiCallId,
      },
    );

    const responses: ChatResponseSuccess[] = executeResponses.map(executeResp => ({
      newState: executeResp.state,
      thinkResponse: {
        debug: thinkResp.debug,
        intentMatch: thinkResp.intentMatch,
        timestamp: thinkResp.resultTimestamp,
      },
    }));

    return responses;
  } catch (error) {
    error.thinkResponse = {
      debug: thinkResp.debug,
      intentMatch: thinkResp.intentMatch,
      timestamp: thinkResp.resultTimestamp,
    };
    throw error;
  }
};

export { chat, execute, LAST_RESPONSE, think };
