// @flow

/* eslint complexity: ['error', 14] */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SaveOutlined } from '@ant-design/icons';
import ClearIcon from '@material-ui/icons/Clear';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import i18next from 'i18next';

import { CDKButton, CDKMenu } from '@calldesk/components';

import type { Status } from '@state/ducks/app/types';
import { operations as botsOperations, selectors as botsSelectors } from '@state/ducks/bots';
import { operations as uiOperations, selectors as uiSelectors } from '@state/ducks/ui';

import DeployPopover from '../DeployPopover';
import SavePopover from '../SavePopover';

import './persist-actions.css';

type Props = {
  accountInUrl: string,
  botInUrl: string,
  showSaveButton: boolean,
  showDeployButton: boolean,
};

const getDeployButtonText = (status: ?Status) => {
  if (status === 'IN_PROGRESS') return ['Deploying...'];
  if (status === 'SUCCESS') return ['Deployed'];
  if (status === 'ERROR') {
    return [
      i18next.t('input.label.Deploy'),
      <ClearIcon key="deploy-error" className="anticon cdkicon" />,
    ];
  }

  return [
    i18next.t('input.label.Deploy'),
    <CloudUploadIcon key="deploy-default" className="anticon cdkicon" />,
  ];
};

const getCheckButtonText = (status: ?Status) => {
  if (status === 'IN_PROGRESS') return ['Checking...'];
  if (status === 'ERROR') {
    return [
      i18next.t('input.label.Check'),
      <ClearIcon key="check-error" className="anticon cdkicon" />,
    ];
  }
  if (status === 'SUCCESS' || status === 'WARNING') {
    return [i18next.t('input.label.Checked')];
  }

  return [];
};

const getSaveButtonText = (status: ?Status) => {
  if (status === 'IN_PROGRESS') {
    return ['Saving...'];
  }

  if (status === 'ERROR') {
    return [
      i18next.t('input.label.save'),
      <ClearIcon key="save-error" className="anticon cdkicon" />,
    ];
  }

  if (status === 'SUCCESS') {
    return [i18next.t('input.label.saved')];
  }

  return [
    i18next.t('input.label.save'),
    <SaveOutlined key="save-default" className="anticon cdkicon" />,
  ];
};

/**
 * Persist actions container
 */
function PersistActionsContainer({
  accountInUrl,
  botInUrl,
  showSaveButton,
  showDeployButton,
}: Props) {
  const dispatch = useDispatch();

  const [showDeployModal, setShowDeployModal] = useState(false);

  const botStatus = useSelector(state => botsSelectors.getBotStatus(state, botInUrl)) ?? {};
  const lastSnapshotId = useSelector(state => botsSelectors.getLoadedSnapshotId(state, botInUrl));
  const showSavePopover = useSelector(state => uiSelectors.getShowSavePopover(state));

  const resetCheckBotStatus = (bot: string) => dispatch(botsOperations.resetCheckBotStatus(bot));
  const resetDeployBotStatus = (bot: string) => dispatch(botsOperations.resetDeployBotStatus(bot));
  const resetSaveBotStatus = (bot: string) => dispatch(botsOperations.resetSaveBotStatus(bot));
  const setShowSavePopover = (isShown: boolean) => dispatch(uiOperations.showSavePopover(isShown));

  useEffect(() => {
    if (botInUrl && botStatus?.deploy === 'SUCCESS') {
      setTimeout(() => {
        resetDeployBotStatus(botInUrl);
        resetCheckBotStatus(botInUrl);
      }, 10000);
    }
  }, [botStatus.deploy]);

  useEffect(() => {
    if (botInUrl && botStatus?.save === 'SUCCESS') {
      setTimeout(() => {
        resetSaveBotStatus(botInUrl);
      }, 20000);
    }
  }, [botStatus.save]);

  const isBotLoading: boolean = botStatus.load !== 'SUCCESS';
  const isSaveInProgress: boolean = botStatus.save === 'IN_PROGRESS';
  const canCheck: boolean = botStatus.check === 'ERROR' && botStatus.save !== 'IN_PROGRESS';
  const isDeployButtonEnabled: boolean =
    canCheck || (botStatus.save === 'SUCCESS' && !!lastSnapshotId && !isBotLoading);
  const normalize = (toCamelize: ?string, defaultValue: string): string =>
    toCamelize ? toCamelize.toLowerCase() : defaultValue;
  const [saveText, saveIcon] = getSaveButtonText(botStatus.save);
  const [deployText, deployIcon] =
    botStatus.check !== 'NONE'
      ? getCheckButtonText(botStatus.check)
      : getDeployButtonText(botStatus.deploy);

  return (
    <>
      {showSaveButton && (
        <CDKMenu.Item eventKey="save" key="save" style={{ padding: '0' }}>
          <CDKButton
            type="primary"
            className={normalize(botStatus.save, 'NONE')}
            onClick={() => !isSaveInProgress && setShowSavePopover(!showSavePopover)}
            id="SaveButton"
            disabled={isBotLoading}
            icon={saveIcon}
          >
            {saveText}
          </CDKButton>
        </CDKMenu.Item>
      )}

      {showDeployButton && (
        <CDKMenu.Item eventKey="deploy" key="deploy" style={{ padding: '0' }}>
          <CDKButton
            type="primary"
            icon={deployIcon}
            className={`
              ${!isDeployButtonEnabled ? 'disabled' : ''}
              ${normalize(
                botStatus.check !== 'NONE' ? botStatus.check : botStatus.deploy,
                'NONE',
              )}`}
            onClick={() => setShowDeployModal(true)}
            id="DeployButton"
            disabled={!isDeployButtonEnabled}
          >
            {deployText}
          </CDKButton>
        </CDKMenu.Item>
      )}
      <SavePopover
        key="save:popover"
        visible={showSavePopover}
        account={accountInUrl}
        bot={botInUrl}
        close={() => setShowSavePopover(false)}
        lastSnapshotId={lastSnapshotId}
      />
      {showDeployModal && botInUrl && (
        <DeployPopover
          key="deploy:popover"
          account={accountInUrl}
          bot={botInUrl}
          checkStatus={botStatus.check}
          close={() => setShowDeployModal(false)}
        />
      )}
    </>
  );
}

export default PersistActionsContainer;
