// @flow

import type { State } from '../app/types';
import {
  // $FlowFixMe
  createAsyncThunk,
  // $FlowFixMe
  createEntityAdapter,
  // $FlowFixMe
  createSlice,
} from '@reduxjs/toolkit';
import _isEmpty from 'lodash/isEmpty';

import type { StorageSettings } from '@state/ducks/app/types';

import { fetchTranscript as fetchTranscriptAPI } from './api';
import * as types from './types';

/* APIs Handlers */
const transcriptsAdapter = createEntityAdapter({
  selectId: (transcript: types.Transcript) => transcript.sessionId,
});
const initialState = transcriptsAdapter.getInitialState({
  status: 'idle',
});
const fetchTranscript = createAsyncThunk(
  'transcripts/FETCH_TRANSCRIPT',
  async (
    {
      account,
      bot,
      sessionId,
      startTime,
      storageSettings,
    }: {
      account: string,
      bot: string,
      sessionId: string,
      startTime: number,
      storageSettings: StorageSettings,
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await fetchTranscriptAPI(
        account,
        bot,
        sessionId,
        startTime,
        storageSettings,
      );
      return { sessionId, data: response };
    } catch (error) {
      return rejectWithValue(sessionId);
    }
  },
);

/* ACTIONS & REDUCERS */
const { actions, reducer } = createSlice({
  name: 'transcripts',
  initialState,
  reducers: {
    toggleTranscriptModal(state: types.Transcripts, { payload }) {
      const update = {
        id: payload,
        changes: {
          isFetching: false,
          show: !state.entities[payload].show,
          error: null,
        },
      };
      transcriptsAdapter.updateOne(state, update);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchTranscript.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchTranscript.fulfilled, (state, action) => {
        const newTranscript = {
          data: action.payload.data,
          sessionId: action.payload.sessionId,
          isFetching: false,
          show: false,
          error: null,
        };
        state.status = 'success';
        transcriptsAdapter.upsertOne(state, newTranscript);
      })
      .addCase(fetchTranscript.rejected, (state, action) => {
        const newTranscript = {
          isFetching: false,
          show: false,
          error: action.error,
          sessionId: action.payload,
        };
        state.status = 'error';
        transcriptsAdapter.upsertOne(state, newTranscript);
      });
  },
});

/* SELECTORS */
const transcriptSelectors = transcriptsAdapter.getSelectors((state: State) => state.transcripts);

const shouldFetchTranscript = (sessionId?: string) => (state: State) => {
  const transcript: ?types.Transcript = transcriptSelectors.selectById(state, sessionId);
  return _isEmpty(transcript);
};

/* EXPORTS */
const selectors = {
  getTranscriptById: transcriptSelectors.selectById,
  getAll: transcriptSelectors.selectAll,
  shouldFetchTranscript,
};

actions.fetchTranscript = fetchTranscript;

export { actions, fetchTranscript, selectors };
export default reducer;
