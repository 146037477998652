// @flow

import type { Transcripts } from './types';

import type { State } from '@state/ducks/app/types';
import { SENSITIVE_DATA } from '@state/monitoring-enhancer/index.type';

const transcriptsStateMonitoring = (state: State): Transcripts => ({
  ...state.transcripts,
  entities: Object.fromEntries(
    Object.keys(state.transcripts.entities).map(sessionId => [
      sessionId,
      {
        ...state.transcripts.entities[sessionId],
        data: SENSITIVE_DATA,
      },
    ]),
  ),
});

export default transcriptsStateMonitoring;
