// @flow
/* eslint complexity: ['error', 13] */
/* eslint-disable max-lines */

import type {
  APIRequestOptions,
  APIRequestTest,
  AutoPilotStats,
  AvailablePhoneNumbers,
  BotApiError,
  BotConfig,
  BotPhoneNumbers,
  CheckResponse,
  Environment,
  HttpAuth,
  HttpBody,
  HttpConfig,
  HttpHeader,
  HttpParam,
  LoadedBotConfig,
  PhoneNumber,
  Version,
} from './types';

import type {
  BotApiRequestError,
  BotApiRequestResponse,
  StorageSettings,
} from '@state/ducks/app/types';
import type { Steps } from '@state/ducks/flashMessages/types';
import type { IntentsOptionsMap } from '@state/ducks/options/types';
import request from '@amplify/api/request';
import logger from '@assets/js/calldesk-app-util/logger';
import { fetchAvailableOptions } from '@state/ducks/options/api';

import { formatBotConfigForFront, formatBotPhoneNumbersForFront } from './utils';

const performApiTestRequest = async (
  accountId: string,
  apiRequestOptions: {
    ...$Shape<APIRequestOptions>,
    httpBody?: HttpBody,
    config?: HttpConfig,
    httpHeaders?: HttpHeader[],
    httpParams?: HttpParam[],
    httpAuth?: HttpAuth,
  },
): Promise<?APIRequestTest> => {
  const { apiUrl, httpHeaders, httpParams, ...apiRequestOptionsRest } = apiRequestOptions;

  try {
    const response: ?BotApiRequestResponse<APIRequestTest> = await request.botApi({
      method: 'POST',
      path: `/accounts/${accountId}/test/api`,
      body: {
        ...apiRequestOptionsRest,
        url: apiUrl,
        headers: (httpHeaders || []).reduce(
          (acc, { name, value }) => ({ ...acc, [name]: value }),
          {},
        ),
        params: (httpParams || []).reduce(
          (acc, { name, value }) => ({ ...acc, [name]: value }),
          {},
        ),
      },
    });
    logger.info('state/bots/api [performApiTestRequest] Retrieved api response', response);
    if (response && response.apiResponse) {
      return response.apiResponse;
    }
    return null;
  } catch (err) {
    logger.info('state/bots/api [performApiTestRequest] Error while retrieving api response', err);
    if (err && err.response) {
      const responseObj = {};
      if (err.response.error) responseObj.body = err.response.error;
      if (err.response.duration) responseObj.elapsedTime = err.response.duration;
      if (err.status) responseObj.statusCode = err.status;
      return responseObj;
    }
    return err;
  }
};

const fetchBotsForAccount = (accountId: string): Promise<string[]> =>
  request
    .studioApi({
      method: 'GET',
      path: `/accounts/${accountId}/bots`,
    })
    .then(botApiResponse => {
      logger.info('state/bots/api [fetchBotsForAccount] Retrieved bots', botApiResponse);
      return Promise.resolve(botApiResponse.botNames);
    })
    .catch((error: BotApiRequestError) => {
      logger.info('state/bots/api [fetchBotsForAccount] Error while retrieving bots', error);
      return Promise.reject(error);
    });

const fetchVersionsForBot = (accountId: string, botName: string): Promise<Version[]> =>
  request
    .studioApi({
      method: 'GET',
      path: `/accounts/${accountId}/bots/${botName}/versions`,
    })
    .then((botApiResponse: { botVersions: Version[] }) => {
      logger.info('state/bots/api [fetchVersionsForBot] Retrieved versions', botApiResponse);
      return Promise.resolve(botApiResponse.botVersions);
    })
    .catch((error: BotApiRequestError) => {
      logger.info('state/bots/api [fetchVersionsForBot] Error while retrieving versions', error);
      return Promise.reject(error);
    });

const getFlashMessageSteps = (
  accountId: string,
  botName: string,
  snapshotId: string,
): Promise<Steps> =>
  request
    .botApi({
      method: 'GET',
      path: `/accounts/${accountId}/bots/${botName}/snapshots/${snapshotId}/flash-message-steps`,
    })
    .then((response: Object) => {
      const { steps: flashMessageSteps } = response;

      logger.info(
        'state/bots/api [getFlashMessageNodesSteps] Retrieved flash message nodes steps',
        flashMessageSteps,
      );

      return Promise.resolve(flashMessageSteps);
    })
    .catch(errorMessage => {
      logger.info(
        'state/flashMessages/api [getFlashMessages] Error while retrieving flash message nodes steps',
        errorMessage,
      );
      return Promise.reject(errorMessage);
    });

const loadBotFromApi = async (
  selectedAccount: string,
  bot: string,
  snapshotId: string,
): Promise<{ config: BotConfig, loadedSnapshotId: string }> => {
  try {
    const loadResponse = await request.studioApi({
      method: 'GET',
      path: `/accounts/${selectedAccount}/bots/${bot}/snapshots/${snapshotId}`,
    });
    const availableOptions: { intents?: IntentsOptionsMap } = await fetchAvailableOptions({
      accountId: selectedAccount,
      botName: bot,
      filters: { optionsName: ['intents'] },
    });
    return {
      config: formatBotConfigForFront(loadResponse.config, availableOptions.intents ?? {}),
      loadedSnapshotId: loadResponse.loadedSnapshotId || snapshotId,
    };
  } catch (error) {
    logger.info('state/bots/api [loadBotFromApi] Error while loading bot config > ', error);
    throw error;
  }
};

const loadBotPhoneNumbers = (accountId: string, bot: string): Promise<BotPhoneNumbers> =>
  request
    .phoneNumbersApi({
      method: 'GET',
      path: `/accounts/${accountId}/bots/${bot}/phoneNumbers`,
    })
    .then(response => {
      const loadResponse: { botPhoneNumbers: PhoneNumber[] } = response.payload;
      logger.info('state/bots/api [loadBotPhoneNumbers] Bot phone numbers loaded', loadResponse);

      return Promise.resolve(formatBotPhoneNumbersForFront(loadResponse.botPhoneNumbers));
    })
    .catch((error: BotApiRequestError) => {
      logger.info(
        'state/bots/api [loadBotPhoneNumbers] Error while loading bot phone numbers > ',
        error,
      );
      return Promise.reject(error);
    });

const loadAvailablePhoneNumbers = (
  accountId: string,
  bot: string,
): Promise<AvailablePhoneNumbers> =>
  request
    .phoneNumbersApi({
      method: 'GET',
      path: `/accounts/${accountId}/bots/${bot}/listAvailablePhoneNumbers`,
    })
    .then(response => {
      const loadResponse: { availablePhoneNumbers: AvailablePhoneNumbers } = response.payload;
      logger.info(
        'state/bots/api [loadAvailablePhoneNumbers] Available phone numbers loaded',
        loadResponse,
      );

      return Promise.resolve(loadResponse.availablePhoneNumbers);
    })
    .catch((error: BotApiRequestError) => {
      logger.info(
        'state/bots/api [loadAvailablePhoneNumbers] Error while loading available phone numbers > ',
        error,
      );
      return Promise.reject(error);
    });

const assignPhoneNumber = (
  accountId: string,
  bot: string,
  environment: string,
  newPhoneNumber: string,
  newBotVersion: ?string,
): Promise<boolean> =>
  request
    .phoneNumbersApi({
      method: 'POST',
      path: `/accounts/${accountId}/bots/${bot}/phoneNumbers/${newPhoneNumber}`,
      body: {
        phoneNumberConfiguration: {
          environment,
          // This is temporary until the day we handle it with a view
          customHeaders: { actionsStage: environment === 'prod' ? 'prod' : 'dev' },
          ...(newBotVersion ? { botVersion: newBotVersion } : {}),
        },
      },
    })
    .then(response => {
      const assignResponse: { edited: boolean } = response.payload;
      logger.info('state/bots/api [assignPhoneNumber] new phone number assigned', assignResponse);
      // Is it an error if bot-api returns { edited: false } ?
      return Promise.resolve(assignResponse.edited);
    })
    .catch((error: BotApiRequestError) => {
      logger.info(
        'state/bots/api [assignPhoneNumber] Error while assigning new phone number > ',
        error,
      );
      return Promise.reject(error);
    });

const updatePhoneNumber = (
  accountId: string,
  bot: string,
  phoneNumber: string,
  additionnalConfig: $Shape<PhoneNumber>,
): Promise<boolean> =>
  request
    .phoneNumbersApi({
      method: 'PUT',
      path: `/accounts/${accountId}/bots/${bot}/phoneNumbers/${phoneNumber}`,
      body: {
        phoneNumberConfiguration: additionnalConfig,
      },
    })
    .then(response => {
      const updateResponse: { updated: boolean } = response.payload;
      logger.info(
        'state/bots/api [updatePhoneNumber] phone number successfully updated',
        updateResponse,
      );
      return Promise.resolve(updateResponse.updated);
    })
    .catch((error: BotApiRequestError) => {
      logger.info('state/bots/api [updatePhoneNumber] Error while updating phone number > ', error);
      return Promise.reject(error);
    });

const unassignPhoneNumber = (
  accountId: string,
  bot: string,
  phoneNumber: string,
): Promise<boolean> =>
  request
    .phoneNumbersApi({
      method: 'DELETE',
      path: `/accounts/${accountId}/bots/${bot}/phoneNumbers/${phoneNumber}`,
    })
    .then(response => {
      const unassignResponse: { unassigned: boolean } = response.payload;
      logger.info('state/bots/api [unassignPhoneNumber] phone number unassigned', unassignResponse);
      return Promise.resolve(unassignResponse.unassigned);
    })
    .catch((error: BotApiRequestError) => {
      logger.info(
        'state/bots/api [unassignPhoneNumber] Error while unassigning phone number > ',
        error,
      );
      return Promise.reject(error);
    });

/**
 * Method which request bot-api to run all verifications before deploy
 */
const runAllChecks = (
  accountId: string,
  botName: string,
  botVersion: string,
  snapshotId: string,
  environment: Environment,
): Promise<CheckResponse> =>
  request.botApi({
    path: `/accounts/${accountId}/bots/${botName}/versions/${botVersion}/check`,
    method: 'POST',
    body: {
      snapshotId,
      environment,
    },
  });

const validateBotConfig = (
  currentAccountId: string,
  bot: string,
  formattedBotConfig: BotConfig,
): Promise<{ hints: BotApiError[] }> =>
  request
    .botApi({
      method: 'POST',
      path: `/accounts/${currentAccountId}/bots/${bot}/validate`,
      body: {
        config: formattedBotConfig,
      },
    })
    .then(validationResponse => {
      logger.info(
        `Found ${validationResponse.hints.length} hints in graph(s) or its configuration`,
        validationResponse.hints,
      );
      return Promise.resolve({
        hints: validationResponse.hints,
      });
    })
    .catch((error: BotApiRequestError) => {
      logger.info('Error while validating bot-config > ', error);
      return Promise.reject(error);
    });

const saveBotOnApi = (
  currentAccountId: string,
  bot: string,
  formattedBotConfig: BotConfig,
  description: string,
  parentSnapshotId: string,
): Promise<string> => {
  logger.info('Saving bot >', formattedBotConfig, description);
  logger.info('Saving bot > ', 'currentAccountId: ', currentAccountId, 'bot: ', bot);
  logger.info('Saving bot > ', 'parentSnapshotId: ', parentSnapshotId);

  return request
    .studioApi({
      method: 'POST',
      path: `/accounts/${currentAccountId}/bots/${bot}/save`,
      body: {
        config: formattedBotConfig,
        description,
        parentSnapshotId,
      },
    })
    .then(saveInfo => {
      logger.info('Saved bot successfully >', saveInfo);
      return Promise.resolve(saveInfo.snapshotId);
    })
    .catch((error: BotApiRequestError) => {
      logger.info('Error while saving bot-config > ', error);
      return Promise.reject(error);
    });
};

const deleteBotOnApi = (currentAccountId: string, bot: string): Promise<string> => {
  logger.info(
    `Delete bot > Start delete bot request with currentAccountId = ${currentAccountId} & bot = ${bot}`,
  );
  return request
    .studioApi({
      method: 'DELETE',
      path: `/accounts/${currentAccountId}/bots/${bot}`,
    })
    .then(deleteInfo => {
      logger.info('Delete bot successfully >', deleteInfo.info);
      return Promise.resolve(deleteInfo);
    })
    .catch((error: BotApiRequestError) => {
      logger.info('Error while delete bot > ', error);
      return Promise.reject(error);
    });
};

const duplicateBotOnApi = (
  from: { accountId: string, botName: string, botTemplate?: { id: string } },
  to: { accountId: string, botName: string },
  settings?: {
    timezone?: string,
    language?: string,
    storageSettings?: StorageSettings,
  },
): Promise<void> => {
  const { botTemplate } = from;

  const body: {
    to: { accountId: string, botName: string },
    botTemplate?: { id: string },
    timezone?: string,
    language?: string,
    storageSettings?: StorageSettings,
  } = {
    to,
    botTemplate,
  };

  logger.info('Duplicating bot >', from.botName);
  logger.info('Duplicate from bot > ', 'AccountId: ', from.accountId, 'bot: ', from.botName);
  logger.info('Duplicate to bot > ', 'AccountId: ', to.accountId, 'bot: ', to.botName);
  if (botTemplate)
    logger.info('Duplicate with bot template > ', 'Bot template id: ', botTemplate.id);
  if (settings?.timezone) {
    logger.info('Duplicate with timezone > ', settings.timezone);
    body.timezone = settings.timezone;
  }
  if (settings?.language) {
    logger.info('Duplicate with language > ', settings.language);
    body.language = settings.language;
  }

  if (settings?.storageSettings) {
    logger.info('Duplicate with storage settings > ', settings.storageSettings);
    body.storageSettings = settings.storageSettings;
  }

  return request
    .studioApi({
      method: 'POST',
      path: `/accounts/${from.accountId}/bots/${from.botName}/duplicate`,
      body,
    })
    .then(() => {
      logger.info('Duplicated bot successfully >', to.botName);
      return Promise.resolve();
    })
    .catch((errors: BotApiError) => {
      logger.info('Error while duplicating bot > ', errors);
      return Promise.reject(errors);
    });
};

const deploySnapshotOnApi = (
  currentAccountId: string,
  bot: string,
  botVersion: string,
  environment: Environment,
  snapshotId: string,
  description: string,
): Promise<Object> => {
  logger.info(
    `Builder [publish] PUBLISHING bot on version ${botVersion} and environment ${environment}`,
    description,
  );
  return request
    .botApi({
      method: 'POST',
      path: `/accounts/${currentAccountId}/bots/${bot}/versions/${botVersion}/publish`,
      body: {
        snapshotId,
        description,
        environment,
      },
    })
    .then((publishInfo: Object) => {
      logger.info('Deployed bot successfully >', publishInfo);
      return Promise.resolve(publishInfo);
    })
    .catch((error: BotApiRequestError) => {
      logger.info('Error while deploying bot-config > ', error);
      return Promise.reject(error);
    });
};

const addSipProxyTrustedIps = (account: string, ips: string[]) =>
  request
    .studioApi({
      method: 'POST',
      path: `/accounts/${account}/sip-proxy-trusted-ips`,
      body: ips,
    })
    .then(res => {
      logger.info('state/bots/api [addSipProxyTrustedIps] Retrieved bots', res);
      return Promise.resolve();
    })
    .catch(error => {
      logger.info('state/bots/api [addSipProxyTrustedIps] Error:', error);
      return Promise.reject(error);
    });

const fetchChatApiKey = async (accountId: string, botName: string): Promise<string> =>
  request
    .studioApi({
      path: `/accounts/${accountId}/bots/${botName}/chatApiKey`,
      method: 'GET',
    })
    .then(studioApiResponse => studioApiResponse.apiKey);

const createChatApiKey = async (accountId: string, botName: string): Promise<string> =>
  request
    .studioApi({
      path: `/accounts/${accountId}/bots/${botName}/chatApiKey`,
      method: 'POST',
    })
    .then(studioApiResponse => studioApiResponse.apiKey);

const generateConfigFromDiscussions = (
  accountId: string,
  formattedBotConfig: LoadedBotConfig,
  files: File[],
): Promise<{ config: LoadedBotConfig, stats: AutoPilotStats }> => {
  logger.info('bots/api/generateConfigFromDiscussions > files: ', files);
  return request
    .studioApi({
      method: 'POST',
      path: `/accounts/${accountId}/autopilot`,
      body: {
        config: formattedBotConfig,
        discussions: files,
      },
    })
    .then(res => {
      logger.info('state/bots/api [generateConfigFromDiscussions] got stats', res.stats);
      return Promise.resolve(res);
    })
    .catch(error => {
      logger.info('state/bots/api [generateConfigFromDiscussions] Error:', error);
      return Promise.reject(error);
    });
};

export {
  addSipProxyTrustedIps,
  assignPhoneNumber,
  createChatApiKey,
  deleteBotOnApi,
  deploySnapshotOnApi,
  duplicateBotOnApi,
  fetchBotsForAccount,
  fetchChatApiKey,
  fetchVersionsForBot,
  generateConfigFromDiscussions,
  getFlashMessageSteps,
  loadAvailablePhoneNumbers,
  loadBotFromApi,
  loadBotPhoneNumbers,
  performApiTestRequest,
  runAllChecks,
  saveBotOnApi,
  unassignPhoneNumber,
  updatePhoneNumber,
  validateBotConfig,
};
