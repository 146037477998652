// @flow

import type { Validators } from '@state/ducks/onboarding/types';

import createSimpleBotWithTransfer from './createSimpleBotWithTransfer';

const VALIDATORS_MAP: Validators = {
  createSimpleBotWithTransfer,
};

export default VALIDATORS_MAP;
