import { Amplify } from '@aws-amplify/core';

const amplifyConfigInit = ({ cognito, endpoints }) => {
  Amplify.configure({
    Auth: cognito,
    API: {
      endpoints,
    },
  });
};

export default amplifyConfigInit;
