/* eslint-disable max-lines */
// @flow

import type {
  ABTestBranch,
  BargeInSettings,
  Bot,
  BotConfig,
  BotLanguage,
  BotPhoneNumbers,
  BotType,
  BotWithoutConfig,
  Environment,
  GraphData,
  Graphs,
  HangUpSettings,
  LoadedBotConfig,
  LogRetentionSettings,
  Node,
  NodeAction,
  PhoneNumber,
  PhoneNumberProviderLevel,
  PostTransferRecordSettings,
  StatsCheckpointsConfig,
  Transition,
  TransitionIntentContent,
  Tts,
} from './types';
import flatten from 'flat';
import _cloneDeep from 'lodash/cloneDeep';
import _entries from 'lodash/entries';
import _every from 'lodash/every';
import _find from 'lodash/find';
import _flatten from 'lodash/flatten';
import _includes from 'lodash/includes';
import _isEqual from 'lodash/isEqual';
import _keys from 'lodash/keys';
import _pick from 'lodash/pick';
import _some from 'lodash/some';
import _startCase from 'lodash/startCase';
import _uniq from 'lodash/uniq';

import type { StorageSettings } from '@state/ducks/app/types';
import type { TtsVoice } from '@state/ducks/bots/types';
import type { ContextUpdate } from '@state/ducks/chat/types';
import type { BotIntent, Intent, IntentsMap } from '@state/ducks/intents/types';
import type { IntentsOptions, IntentsOptionsMap, VoicesOptions } from '@state/ducks/options/types';
import ability from '@assets/js/calldesk-app-util/casl/ability';
import minimalScript from '@resources/bots/scripts/minimal.json';
import tacticalScriptEnGB from '@resources/bots/scripts/tactical.en-GB.json';
import tacticalScriptEnUS from '@resources/bots/scripts/tactical.en-US.json';
import tacticalScriptFrFR from '@resources/bots/scripts/tactical.fr-FR.json';
import tacticalScriptItIT from '@resources/bots/scripts/tactical.it-IT.json';
import tacticalScriptNlNL from '@resources/bots/scripts/tactical.nl-NL.json';
import { SYSTEM_ENTITIES } from '@state/ducks/bots/types';

import { isIntentModelV1, transformOldIntentModel } from './utils/intents';
import {
  AB_TEST_NODE_NAME,
  COMMENT_NODE_NAME,
  DONE_NODE_NAME,
  GENERIC_NODE_NAME,
  MODULE_NODE_NAME,
  SAY_NODE_NAME,
  SET_NODE_NAME,
  STATS_CHECKPOINT_NODE_NAME,
  TRANSFER_NODE_NAME,
  UPDATE_NODE_NAME,
} from './types';

const getTacticalIntentsForLanguage = (
  language: string,
  availableIntents: IntentsOptionsMap,
): IntentsMap => {
  const defaultIntentNames: IntentsOptions =
    availableIntents?.[language] ?? availableIntents?.['en-US'];
  // eslint-disable-next-line no-underscore-dangle
  return (defaultIntentNames?._defaultIntents || [])?.reduce(
    (prevIntent, intentName) => ({
      ...prevIntent,
      [intentName]: {
        ...defaultIntentNames[intentName],
        id: intentName,
        type: 'system',
        scope: 'bot',
      },
    }),
    {},
  );
};

const getTacticalScriptForLanguage = (language?: string): GraphData => {
  const tacticalScripts = {
    'fr-FR': tacticalScriptFrFR,
    'en-US': tacticalScriptEnUS,
    'en-GB': tacticalScriptEnGB,
    'it-IT': tacticalScriptItIT,
    'nl-NL': tacticalScriptNlNL,
  };

  return (language && tacticalScripts[language]) || minimalScript;
};

const AVAILABLE_LANGUAGES_FOR_ZEN_2: string[] = ['fr-FR', 'en-US', 'en-GB'];
const DEFAULT_NLU_CONFIG = { engine: 'zen-2', confidenceCutoff: 50 };
const FALLBACK_NLU_CONFIG = { engine: 'zen', confidenceCutoff: 50 };
const DEFAULT_LOG_RETENTION_SETTINGS: LogRetentionSettings = {
  recordings: {
    ttl: 180,
  },
  discussions: {
    ttl: 180,
  },
  callsData: {
    ttl: 180,
    contextKeysToDelete: [],
  },
};
const DEFAULT_HANG_UP_SETTINGS: HangUpSettings = {
  auto: false,
  delay: 10000,
};

const DEFAULT_BARGE_IN_SETTINGS: BargeInSettings = {
  enabled: false,
  delay: 2000, // in ms
};

const DEFAULT_RECORD_AFTER_TRANSFER_SETTINGS: PostTransferRecordSettings = {
  enabled: false,
};

const DEFAULT_ACTIONS_WEBHOOK: { [language: string]: string } = {
  'fr-FR': 'actions-calldesk-generic-v0-execute:$LATEST',
  'en-US': 'actions-calldesk-generic-v0-us-west-2-execute:$LATEST',
  'en-GB': 'actions-calldesk-generic-v0-us-west-2-execute:$LATEST',
  'nl-NL': '',
};

const DEFAULT_STATS_CHECKPOINTS: StatsCheckpointsConfig = {
  steps: {},
  statuses: {},
  reasons: {},
};

const CUSTOM_ENTITY_PARSER_URL: string = 'parser-generic-entity-pattern-v1-parse:$LATEST';

const botConfigByBotTypeMap = {
  ivr: {
    asrQuality: 'medium',
    type: 'ivr',
  },
  voicebot: {
    asrQuality: 'high',
    type: 'voicebot',
  },
};

const ENVIRONMENTS: Environment[] = ['dev', 'internalTest', 'clientTest', 'prod'];

const DEFAULT_TTS_VOICES: VoicesOptions = {
  'en-US': {
    'en-US-Wavenet-C': { provider: 'googleTts', name: 'Google - Wavenet C (Female)' },
  },
  'fr-FR': {
    manon22k: { provider: 'acapela', name: 'Acapela - Manon (Français)' },
  },
};

const getTtsConfig = (
  language: string,
  availableVoices?: VoicesOptions = DEFAULT_TTS_VOICES,
): Tts => {
  const languageVoices = Object.entries(
    availableVoices?.[language] || availableVoices?.['en-US'] || {},
  ).map(([voiceCode, voice]) => ({
    // $FlowFixMe Object.entries returns mixed type https://github.com/facebook/flow/issues/5838
    provider: voice.provider,
    voice: voiceCode,
  }));
  const primaryVoice: TtsVoice = languageVoices[0];
  // The backup voice must be from a different provider
  const backupVoice: ?TtsVoice = languageVoices.find(
    voice => voice.provider !== primaryVoice.provider,
  );

  return { voices: (backupVoice && [primaryVoice, backupVoice]) || [primaryVoice] };
};

const getEmptyBotConfig = ({
  availableOptions,
  botLanguage,
  botName,
  botStorageSettings,
  botTimeZone,
  botType,
}: {
  botTimeZone: string,
  availableOptions: { voices: VoicesOptions, intents: IntentsOptionsMap },
  botLanguage: BotLanguage,
  botName: string,
  botStorageSettings: StorageSettings,
  botType: BotType,
}): BotConfig => ({
  actions: DEFAULT_ACTIONS_WEBHOOK[botLanguage] || '',
  banner: {},
  bargeInSettings: DEFAULT_BARGE_IN_SETTINGS,
  postTransferRecordSettings: DEFAULT_RECORD_AFTER_TRANSFER_SETTINGS,
  contextColumns: {},
  graphs: {
    [botName]: { data: minimalScript },
    tactical: { data: getTacticalScriptForLanguage(botLanguage) },
  },
  hangUpSettings: DEFAULT_HANG_UP_SETTINGS,
  entities: {},
  intents: Object.keys(getTacticalIntentsForLanguage(botLanguage, availableOptions?.intents)),
  language: botLanguage,
  logRetentionSettings: DEFAULT_LOG_RETENTION_SETTINGS,
  nlu: AVAILABLE_LANGUAGES_FOR_ZEN_2.includes(botLanguage)
    ? DEFAULT_NLU_CONFIG
    : FALLBACK_NLU_CONFIG,
  parsers: {},
  phrases: [],
  stats: { data: {} },
  timezone: botTimeZone,
  tts: getTtsConfig(botLanguage, availableOptions?.voices),
  statsCheckpoints: DEFAULT_STATS_CHECKPOINTS,
  storageSettings: botStorageSettings,
  ...botConfigByBotTypeMap[botType],
});

const getDefaultBot = (botName: string): BotWithoutConfig => ({
  id: botName,
  autoPilotStats: null,
  calls: [],
  chatApiKey: {
    error: null,
    value: null,
  },
  errors: {
    check: { list: [] }, // errors for serenizer
    evaluate: null,
    importIntents: null,
    intents: { last: null, list: [] }, // errors when adding utterances
    load: { list: [] }, // errors when loading a bot
    phoneNumbers: null,
    publish: { list: [] }, // Should be useless now that we make everything in the check
    save: { list: [] }, // errors when saving a bot
    validate: { buildingErrors: [], list: [] }, // errors for builder helper
    versions: null,
  },
  loadedSnapshotId: '',
  phoneNumbers: {
    dev: null,
    clientTest: null,
    internalTest: null,
    prod: null,
  },
  snapshots: [],
  status: {
    chatApiKey: 'NONE',
    check: 'NONE',
    deploy: 'NONE',
    duplicate: 'NONE',
    evaluate: 'NONE',
    importFolder: 'NONE',
    importIntents: 'NONE',
    load: 'NONE',
    phoneNumbers: 'NONE',
    save: 'NONE',
    validate: 'NONE',
    versions: 'NONE',
  },
  versions: [],
  lastConfigSaved: {},
});

const getNewBot = ({
  availableOptions,
  botLanguage,
  botName,
  botStorageSettings,
  botTimeZone,
  botType,
}: {
  botType: BotType,
  availableOptions: { voices: VoicesOptions, intents: IntentsOptionsMap },
  botLanguage: BotLanguage,
  botName: string,
  botStorageSettings: StorageSettings,
  botTimeZone: string,
}): Bot => {
  const newBotWithoutConfig: BotWithoutConfig = getDefaultBot(botName);
  const newBot: Bot = {
    ...newBotWithoutConfig,
    config: getEmptyBotConfig({
      availableOptions,
      botName,
      botType,
      botLanguage,
      botTimeZone,
      botStorageSettings,
    }),
  };
  return newBot;
};

/**
 * Check if user is authorized to deploy on at least one env
 */
const canDeploy = (): boolean => ENVIRONMENTS.some(env => ability.can(`${env}`, 'builder.deploy'));

const formatTransitionIntentFromOldIntentInterface = (
  oldTransitionIntents: string[],
  newIntents: IntentsMap,
): TransitionIntentContent[][] =>
  oldTransitionIntents.map((intent: string) =>
    intent.split('+').map((intentPart: string) => {
      const [intentWithoutEntities] = intentPart.split('[');
      const match: ?[string, BotIntent] = _entries(newIntents).find(
        ([, newIntent]) => newIntent.label === intentWithoutEntities,
      );

      const intentId: string = match?.[0] || intentWithoutEntities;
      const entities = _uniq(_flatten(match?.[1].entities ?? []));
      return { id: intentId, entities };
    }),
  );

const formatIntentsForBotConfig = ({
  intentsListFromBotsDuck,
  intentsMapFromIntentsDuck,
  additionalPropsForAccountScope = [],
}: {
  intentsListFromBotsDuck: string[],
  intentsMapFromIntentsDuck: IntentsMap,
  additionalPropsForAccountScope?: string[],
}): IntentsMap =>
  intentsListFromBotsDuck.reduce((intentsMapAcc: IntentsMap, intentId: string) => {
    const intentToFormat: Intent = intentsMapFromIntentsDuck[intentId];
    const formattedIntentForBotConfig: Intent =
      intentToFormat.scope === 'account'
        ? _pick(intentToFormat, ['id', 'scope', ...additionalPropsForAccountScope])
        : intentToFormat;

    return {
      ...intentsMapAcc,
      [intentId]: formattedIntentForBotConfig,
    };
  }, {});

const formatBotConfig = (botConfig: BotConfig): LoadedBotConfig => {
  const formattedBotConfig = _pick(botConfig, [
    'actions',
    'asrQuality',
    'banner',
    'bargeInSettings',
    'botTemplate',
    'chatAPIKey',
    'contextColumns',
    'entities',
    'hangUpSettings',
    'intents',
    'language',
    'logRetentionSettings',
    'nlu',
    'parsers',
    'phrases',
    'postTransferRecordSettings',
    'stats',
    'statsCheckpoints',
    'storageSettings',
    'timezone',
    'tts',
    'type',
  ]);
  const domains = Object.keys(botConfig.graphs);

  formattedBotConfig.graphs = {};
  domains.forEach(domain => {
    formattedBotConfig.graphs[domain] = { ...botConfig.graphs[domain].data };
  });

  if (!botConfig.logRetentionSettings) {
    formattedBotConfig.logRetentionSettings = DEFAULT_LOG_RETENTION_SETTINGS;
  }

  formattedBotConfig.stats = { ...botConfig.stats.data };

  if (!formattedBotConfig.type) formattedBotConfig.type = 'voicebot';

  return formattedBotConfig;
};

const formatBotConfigForFront = (
  botConfig: LoadedBotConfig,
  availableIntents: IntentsOptionsMap,
): BotConfig => {
  const formattedBotConfig: Object = { ...botConfig };
  const domains = Object.keys(botConfig.graphs);

  // Formatting 'stats' key in BotConfig
  formattedBotConfig.stats = {
    ui: {
      statMode: false,
      showModal: false,
      selectedNodeId: null,
    },
    data: {},
  };

  // Force NLU settings to default value if not set
  if (!formattedBotConfig.nlu) {
    formattedBotConfig.nlu = DEFAULT_NLU_CONFIG;
  }

  if (!formattedBotConfig.statsCheckpoints) {
    formattedBotConfig.statsCheckpoints = DEFAULT_STATS_CHECKPOINTS;
  }

  const statBlocks = botConfig.stats ? Object.keys(botConfig.stats) : [];
  if (statBlocks.length !== 0) {
    formattedBotConfig.stats.data = { ...botConfig.stats };
  }

  // Set a default value in case old config doesn't have hangUpSettings attribute yet
  formattedBotConfig.hangUpSettings = formattedBotConfig.hangUpSettings || {
    auto: false,
    delay: 10000,
  };

  formattedBotConfig.type = formattedBotConfig.type || 'voicebot';

  // Format intent if bot config has old intents interface
  if (formattedBotConfig.intents) {
    if (isIntentModelV1(formattedBotConfig)) {
      formattedBotConfig.intents = transformOldIntentModel({
        intents: formattedBotConfig.intents,
        graphs: formattedBotConfig.graphs,
        language: formattedBotConfig.language,
        availableIntents,
      });
      // Format transition intent if bot config has old intents interface
      domains.forEach((domain: string) => {
        formattedBotConfig.graphs[domain].transitions
          .filter((transition: Transition) => transition.intents)
          .forEach((transition: Transition) => {
            const formattedTransitionIntents = formatTransitionIntentFromOldIntentInterface(
              // $FlowFixMe : we know that in this case it's the previous model
              transition.intents,
              formattedBotConfig.intents,
            );
            // eslint-disable-next-line no-param-reassign
            transition.intents = formattedTransitionIntents;
          });
      });
    }
  }

  // Format entities in order to remove timezone from referentials settings
  if (formattedBotConfig.entities) {
    Object.keys(formattedBotConfig.entities).forEach((entityId: string) => {
      // Old entities had a timezone attribute in referential settings. Now sent by bot-api on run
      if (formattedBotConfig.entities[entityId].referential.settings?.timezone)
        delete formattedBotConfig.entities[entityId].referential.settings.timezone;
    });
  }

  // Formatting 'graphs' key in BotConfig
  domains.forEach(domain => {
    formattedBotConfig.graphs[domain] = {
      data: botConfig.graphs[domain],
    };
  });

  const returnedConfig: BotConfig = _cloneDeep(formattedBotConfig);

  return returnedConfig;
};

// TODO: fix the inconsistency in *phone-number-api*
// between listAvailablePhoneNumbers and get
// about primary/backup division
// - done with provider in front end for get
// - done in the backend for listAvailablePhoneNumbers
// sounds better to let this decision to back-end
const getProviderLevel = (phoneNumber: PhoneNumber): PhoneNumberProviderLevel => {
  if (phoneNumber.capabilities?.sms) return 'sms';
  return phoneNumber.provider === 'voxbone' ? 'primary' : 'backup';
};

const formatBotPhoneNumbersForFront = (phoneNumbersResponse: PhoneNumber[]): BotPhoneNumbers => {
  // TODO: this object is duplicated in
  // /src/App/views/Bot/views/Builder/views/Settings
  // /components/PhoneNumbersSettings/PhoneNumbersSettings.component.jsx
  // as well, see if it's not possible to use the same def
  const formattedPhoneNumbers: BotPhoneNumbers = {
    dev: { primary: [], backup: [], sms: [] },
    internalTest: { primary: [], backup: [], sms: [] },
    clientTest: { primary: [], backup: [], sms: [] },
    prod: { primary: [], backup: [], sms: [] },
  };

  for (let index = 0; index < phoneNumbersResponse.length; index += 1) {
    const phoneNumber = phoneNumbersResponse[index];
    // providerLevel logic will need updates the day we have more providers
    const providerLevel: PhoneNumberProviderLevel = getProviderLevel(phoneNumber);
    const existInEnum = !!ENVIRONMENTS.find(val => val === phoneNumber.environment);
    // We check if the environment should be added, based on ENVIRONMENTS list
    if (phoneNumber.environment && existInEnum) {
      formattedPhoneNumbers[phoneNumber.environment][providerLevel].push({
        ...phoneNumber,
        // first letter in uppercase
        providerLevel: `${providerLevel.charAt(0).toUpperCase()}${providerLevel.slice(1)}`,
      });
    }
  }

  return formattedPhoneNumbers;
};

const AB_TEST_DEFAULT_NODE_NAME: string = 'A/B Test';
const AB_TEST_DEFAULT_BRANCHES: ABTestBranch[] = [
  { label: 'branchA', load: 50 },
  { label: 'branchB', load: 50 },
];

const getNodeAction = (partialNode: $Shape<Node>, userEmail: string): NodeAction => {
  const nodeTemplates = {
    [AB_TEST_NODE_NAME]: {
      name: AB_TEST_DEFAULT_NODE_NAME,
      branches: AB_TEST_DEFAULT_BRANCHES,
    },
    [COMMENT_NODE_NAME]: { lastEditor: userEmail, updatedAt: Date.now() },
    [DONE_NODE_NAME]: { success: true },
    [GENERIC_NODE_NAME]: {},
    [MODULE_NODE_NAME]: { target: { accountId: '', botName: '', botVersion: '' } },
    [SAY_NODE_NAME]: { templates: [] },
    [SET_NODE_NAME]: { key: '', valueType: 'string', value: '' },
    [TRANSFER_NODE_NAME]: { to: '' },
    [UPDATE_NODE_NAME]: { produces: [], deletes: [] },
    [STATS_CHECKPOINT_NODE_NAME]: { params: {} },
  };

  // $FlowFixMe NOTE: It doesnt evaluate GENERIC_NODE_NAME constant into corresponding string...
  const additionalTemplate = nodeTemplates[partialNode.action] || nodeTemplates[GENERIC_NODE_NAME];
  return {
    ...additionalTemplate,
    ...partialNode,
  };
};

/**
 * Remove the specified intent from the transitions of all domains.
 * @param {Graph} graphs
 * @param {string} intentId
 * @returns {Graph} the graph without the deleted intent in transitions
 */
const deleteIntentFromTransitions = (graphs: Graphs, intentId: string): Graphs => {
  const graphsClone: Graphs = { ...graphs };
  const domains: string[] = Object.keys(graphsClone);
  domains.forEach((domain: string) => {
    const { transitions } = graphs[domain].data;
    const newTransitions: Transition[] = transitions.map((transition: Transition) => {
      if (transition.intents) {
        const intents: TransitionIntentContent[][] = transition.intents
          .map((intentClause: TransitionIntentContent[]) =>
            intentClause.filter(intent => intent.id !== intentId),
          )
          .filter((intentClause: TransitionIntentContent[]) => intentClause.length > 0);

        const newTransition = {
          ...transition,
        };
        if (intents.length > 0) {
          newTransition.intents = intents;
        } else {
          delete newTransition.intents;
        }
        return newTransition;
      }
      return transition;
    });

    // Copy value in order to avoid error "Cannot assign to read only property 'transitions' of object '#<Object>'"
    // Set the property of the objects to writeable as they are set to non writeable.
    graphsClone[domain] = {
      ...graphsClone[domain],
      data: {
        ...graphsClone[domain].data,
        transitions: newTransitions,
      },
    };
  });
  return graphsClone;
};

/**
 * This function takes a path as an input and return a list of all path's parts.
 * so 'A.B.C' becomes => ['A', 'A.B', 'A.B.C'] (check its unit tests)
 */
function getAllPartsFromPath(path: string): string[] {
  return path.split('.').reduce((allParts: string[], key: string, index: number) => {
    const currentPart: string = index === 0 ? key : `${allParts[allParts.length - 1]}.${key}`;
    return [...allParts, currentPart];
  }, []);
}

/**
 * Compute set & unset values between 2 context.
 * NOTE: could be optimised with only 1 loop ?
 */
function getContextUpdates(initialContext: Object, newContext: Object): ContextUpdate {
  const initialContextFlat: { [path: string]: any } = flatten(initialContext);
  const newContextFlat: { [path: string]: any } = flatten(newContext);

  // extract all keys from both contexts in order to compare them
  // initialCtxPaths and newCtxPaths now look like this: [A, B.B', B.B"...]
  const initialCtxPaths: string[] = _keys(initialContextFlat);
  const newCtxPaths: string[] = _keys(newContextFlat);

  // first, check if some keys have been unset
  const unset: Array<{ path: string }> = initialCtxPaths.reduce(
    (unsetTmp: Array<{ path: string }>, ctxPath: string) => {
      const allPartialPaths: string[] = getAllPartsFromPath(ctxPath);

      // check all partial paths from ctxPath beginning by outermost part
      // for instance if ctxPath = 'A.B.C', first check if 'A' has been unset, then 'A.B' etc...
      // NOTE: it stops @ first success, returns undefined if none found
      const unsetPath: ?string = _find(allPartialPaths, (partialPath: string) => {
        const pathRegExp: RegExp = new RegExp(`^(${partialPath})\\.(.+)$`);

        // check if every paths in newCtxPaths:
        // are different from partialPath (from initialContext),
        // and, do NOT contain partialPath (from initialContext),
        const pathHasBeenUnset = _every(
          newCtxPaths,
          (newPath: string) => newPath !== partialPath && newPath.match(pathRegExp) === null,
        );

        // if pathHasBeenUnset, return partialPath's value
        return pathHasBeenUnset && partialPath;
      });

      // if unsetPath is defined AND has not already been added to `unset` array
      if (unsetPath && !_some(unsetTmp, ({ path }) => path === unsetPath)) {
        // ctxPath (or a parent part) has been unset
        return [...unsetTmp, { path: unsetPath }];
      }
      return unsetTmp;
    },
    [],
  );

  // then, check if some paths have been set OR updated
  const set: Array<{ path: string, value: any }> = newCtxPaths.reduce(
    (setTmp: Array<{ path: string, value: any }>, ctxPath: string) => {
      if (!_includes(initialCtxPaths, ctxPath)) {
        // initialCtxPaths does NOT contain ctxPath from newContext, thus ctxPath has been set
        return [...setTmp, { path: ctxPath, value: newContextFlat[ctxPath] }];
      }

      if (!_isEqual(initialContextFlat[ctxPath], newContextFlat[ctxPath])) {
        // initialCtxPaths contains ctxPath from newContext,
        // but values are different, thus ctxPath has been updated
        return [...setTmp, { path: ctxPath, value: newContextFlat[ctxPath] }];
      }

      return setTmp;
    },
    [],
  );

  return { set, unset };
}

const formatIntentFromId = (intentLabel: string, entities: string[]): string =>
  entities ? `${intentLabel}[${entities.join(',')}]` : `${intentLabel}[]`;

const formatTransitionLabelWithIntents = (
  transitionsIntents: TransitionIntentContent[],
  intents: Intent,
): string =>
  transitionsIntents
    .map((intent: TransitionIntentContent) =>
      // if intent is removed from intent show intent id
      intents[intent.id]
        ? formatIntentFromId(intents[intent.id].label, intent.entities)
        : formatIntentFromId(intent.id, intent.entities),
    )
    .join('+');

const checkIsSystemEntity = (referentialId: string): boolean =>
  SYSTEM_ENTITIES.includes(referentialId);

const getCheckpointsFromNodes = (graphs: Graphs): StatsCheckpointsConfig =>
  Object.values(graphs).reduce(
    (acc, domain) => {
      // $FlowFixMe
      domain.data.nodes.forEach(node => {
        if (node?.action !== 'statsCheckpoint') {
          return;
        }

        acc.statuses[node?.params?.statsCheckpoint_status] = {
          id: node?.params?.statsCheckpoint_status,
          name: _startCase(node?.params?.statsCheckpoint_status),
        };

        acc.steps[node?.params?.statsCheckpoint_step] = {
          id: node?.params?.statsCheckpoint_step,
          name: _startCase(node?.params?.statsCheckpoint_step?.replace(/^(\d*\.)/g, '')),
          rank: node?.params?.statsCheckpoint_step?.match(/^(\d*)/g)?.[0] ?? '00',
        };

        acc.reasons[node?.params?.statsCheckpoint_reason] = {
          id: node?.params?.statsCheckpoint_reason,
          name: _startCase(node?.params?.statsCheckpoint_reason),
          parentStatusId: node?.params?.statsCheckpoint_status,
        };
      });

      return acc;
    },
    { statuses: {}, steps: {}, reasons: {} },
  );

export {
  botConfigByBotTypeMap,
  canDeploy,
  checkIsSystemEntity,
  CUSTOM_ENTITY_PARSER_URL,
  DEFAULT_ACTIONS_WEBHOOK,
  DEFAULT_BARGE_IN_SETTINGS,
  DEFAULT_HANG_UP_SETTINGS,
  DEFAULT_LOG_RETENTION_SETTINGS,
  DEFAULT_NLU_CONFIG,
  DEFAULT_RECORD_AFTER_TRANSFER_SETTINGS,
  DEFAULT_STATS_CHECKPOINTS,
  DEFAULT_TTS_VOICES,
  deleteIntentFromTransitions,
  ENVIRONMENTS,
  FALLBACK_NLU_CONFIG,
  formatBotConfig,
  formatBotConfigForFront,
  formatBotPhoneNumbersForFront,
  formatIntentFromId,
  formatIntentsForBotConfig,
  formatTransitionIntentFromOldIntentInterface,
  formatTransitionLabelWithIntents,
  getAllPartsFromPath,
  getCheckpointsFromNodes,
  getContextUpdates,
  getDefaultBot,
  getEmptyBotConfig,
  getNewBot,
  getNodeAction,
  getProviderLevel,
  getTacticalIntentsForLanguage,
  getTtsConfig,
  isIntentModelV1,
  transformOldIntentModel,
};
