// @flow

import type { Intent, IntentMatch, SayMessage, SayMessageContentItem } from '../../types';

import { REGEXP_ENTITY_MASK, REGEXP_WORDS_IN_SENTENCE } from '@assets/js/calldesk-app-util/format';

const ThinkItemToSayFactory = () => {
  const formatter = (intentMatch: IntentMatch, language: string, timestamp: number): SayMessage => {
    const bold: boolean = false;
    const highlight: boolean = false;
    const italic: boolean = false;

    const templateParsed: string = intentMatch.intents
      .map((intent: Intent) => intent.parsed)
      .filter(Boolean)
      .join(' ');
    const templateText: ?string = intentMatch?.text;

    const templateParsedSplitted: string[] = (
      intentMatch?.parsedNotUnderstood || templateParsed
    ).split(REGEXP_WORDS_IN_SENTENCE);

    // Merge templateParsedPart if templateParsedSplitted contains two concecutive entity masks
    const templateParsedSplittedFormatted: string[] = templateParsedSplitted.reduce(
      (acc: string[], templateParsedPart: string, index: number) => {
        if (
          index + 1 <= templateParsedSplitted.length &&
          REGEXP_ENTITY_MASK.test(templateParsedPart)
        ) {
          const nextTemplateParsedPart: string = templateParsedSplitted[index + 1];
          if (REGEXP_ENTITY_MASK.test(nextTemplateParsedPart)) {
            acc.push(`${templateParsedPart},${nextTemplateParsedPart}`);
            templateParsedSplitted.splice(index + 1, 1);
            return acc;
          }
        }
        acc.push(templateParsedPart);
        return acc;
      },
      [],
    );

    /**
      Format each segment of the splittedUserMessage
    */
    const formattedMessageItems: SayMessageContentItem[] = templateParsedSplittedFormatted.reduce(
      (acc: SayMessageContentItem[], templateParsedPart: string, index: number) => {
        const isLastWord: boolean = templateParsedSplitted.length === index + 1;
        const isEntityMask: boolean = REGEXP_ENTITY_MASK.test(templateParsedPart);

        if (isEntityMask && templateText) {
          /**
          Create a regex with all strings from the previous array that are not entity masks
          ex: (my name is | ,hello!)
          */
          const sentenceWithoutEntities: string = templateParsedSplittedFormatted
            .filter((item: string) => item !== '' && !item.match(/{[a-zA-Z0-9_.-]+}/))
            .join('|');

          /* negative lookahead | lookbehind used with chars sets because \b flag does not work with latin alphabet in JS.
          @see https://stackoverflow.com/questions/2449779/why-cant-i-use-accented-characters-next-to-a-word-boundary/62215898#62215898
          There still might be a problem with arabic characters in the future. Maybe we'll need a library to help us with that subject if that's the case
          */
          const singleWordsInSentenceRegExp = new RegExp(
            `(?<![A-Za-zÀ-ÖØ-öø-ÿ])(${sentenceWithoutEntities})(?![A-Za-zÀ-ÖØ-öø-ÿ])`,
          );

          const entityMaskValues: string[] = sentenceWithoutEntities
            ? templateText
                .split(singleWordsInSentenceRegExp)
                .map((item: string) => item.replace(/(^\s)|(\s$)/g, ''))
                .filter(Boolean)
            : [templateText.replace(/(^\s)|(\s$)/g, '')];

          return [
            ...acc,
            {
              bold: true,
              highlight,
              italic,
              item: isLastWord ? entityMaskValues[index] : entityMaskValues[index].concat(' '),
              tooltip: templateParsedPart,
            },
          ];
        }
        return [
          ...acc,
          {
            bold,
            highlight,
            italic,
            item: isLastWord ? templateParsedPart : templateParsedPart.concat(' '),
          },
        ];
      },
      [],
    );

    return {
      sender: 'user',
      id: `user-${timestamp}`,
      nodeId: `user-${timestamp}`,
      content: formattedMessageItems,
      timestamp,
    };
  };

  return {
    formatter,
  };
};

export default ThinkItemToSayFactory;
