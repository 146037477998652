// @flow

import type { ActionMessage, ActionNode, TraceItem, TraceItemFactory } from '../../types';

import type { ConversationState } from '@state/ducks/chat/types';
import { GHOST_NODES } from '@state/ducks/bots/types';

import { getId, stateFormattedForActionMessage } from '../../util';

const Factory = (): TraceItemFactory<ActionNode> => {
  const formatter = (
    traceItem: TraceItem<ActionNode>,
    state: $Shape<ConversationState>,
  ): ActionMessage => {
    const newState: $Shape<ConversationState> = stateFormattedForActionMessage(state);
    return {
      content: traceItem.node.name ?? traceItem.node.action,

      nodeId: traceItem.node.id,
      params: traceItem.node?.params,
      sender: 'action',
      state: newState,
      timestamp: traceItem.timestamp,
      type: 'ghost',
      id: getId({ timestamp: traceItem.timestamp, nodeId: traceItem.node.id }),
    };
  };

  const validator = (traceItem: TraceItem<any>): boolean =>
    GHOST_NODES.includes(traceItem.node.action) || GHOST_NODES.includes(traceItem.node.name);

  return { formatter, validator };
};

export default Factory;
