// @flow

import _set from 'lodash/set';

import type { BotApiError } from '@state/ducks/bots/types';

type Response = {
  apiVersion: String,
  duration: number,
  errors: BotApiError[],
  solution: string,
};

/**
 * BotApi error on API request
 */
class BotApiRequestError extends Error {
  message: string;

  name: string; // Error type

  status: number;

  solution: string;

  response: Response;

  /**
   *
   */
  constructor(status: number, response?: Object) {
    let formattedResp = response;
    // NOTE: bot-api is not consistent in error responses
    // Sometimes it contains an array of errors, and sometimes
    // a flat error objet. That's why we have to standardize
    // the response to handle the same format everywhere in
    // console-dashboard
    let message: string = 'An unknown server error occured.'; // default message
    let solution: string = 'Contact an administrator.'; // default solution
    if (formattedResp) {
      if (formattedResp.errors && formattedResp.errors.length) {
        // If bot-api returned an array of error
        message = formattedResp.errors[0].message || message;
        solution = formattedResp.errors[0].solution || solution;
      } else {
        // If bot-api returned a flat error message
        message = formattedResp.message || message;
        solution = formattedResp.solution || solution;
      }
    }
    super(message);
    this.name = 'BotApiRequestError';
    this.status = status;
    this.solution = solution;
    if (!(formattedResp && formattedResp.errors)) {
      // If bot-api returned a flat error message, we manually construct an
      // array of errors to be sure that console-dashboard handles the same
      // format in the code
      formattedResp = _set(formattedResp, 'errors', [{ message, solution }]);
    }
    this.response = formattedResp;
  }

  /**
   * Returns a string representation of an object.
   * @returns the string representation of the error
   */
  toString() {
    return `[${this.name}] status: "${this.status.toString()}", message: "${
      this.message
    }", solution: "${this.solution}"`;
  }
}

export default BotApiRequestError;
