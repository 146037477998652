/* eslint-disable max-lines */
/* eslint complexity: ['error', 13] */

// @flow

import type { PayloadAction } from '@reduxjs/toolkit';
import type { Saga } from 'redux-saga';
import i18n from 'i18next';
import _flatten from 'lodash/flatten';
import _isEqual from 'lodash/isEqual';
import _uniqBy from 'lodash/uniqBy';
import _values from 'lodash/values';
import { all, call, put, select, take } from 'redux-saga/effects';

import type { Action } from '@state/ducks/app/types';
import type { Entities } from '@state/ducks/bots/types';
import type {
  Asset,
  EntityAssetContent,
  EntityContentType,
} from '@state/ducks/customers-assets/types';
import type { Intent, IntentsMap } from '@state/ducks/intents/types';
import type {
  Answer,
  AnswerChanged,
  EntityTurn,
  IntentTurn,
  Turn,
  TurnSelected,
} from '@state/ducks/training/types';
import logger from '@assets/js/calldesk-app-util/logger';
import uuid from '@assets/js/calldesk-app-util/uuid';
import { operations as botsOperations, selectors as botsSelectors } from '@state/ducks/bots';
import { api as customersAssetsApi } from '@state/ducks/customers-assets';
import {
  operations as intentsOperations,
  selectors as intentsSelectors,
} from '@state/ducks/intents';
import {
  api as trainingApi,
  operations,
  selectors as trainingSelectors,
} from '@state/ducks/training';

import {
  answersListToAnswersChangedList,
  entitiesToListForTurn,
  entityTurnsListToEntitiesAssetsContentList,
  getExpressionsByIntentTurn,
  getNotifMessageSaveTrainingAnswers,
} from './utils';

const {
  clearCurrentTurn,
  clearTurns,
  fetchAnswersByTurnFailure,
  fetchAnswersByTurnStart,
  fetchAnswersByTurnSuccess,
  fetchTurnsFailure,
  fetchTurnsStart,
  fetchTurnsSuccess,
  getTrainingScore,
  setCurrentTurn,
  setNotification,
  updateEntityMasksInUtteranceForIntentsTurn,
} = operations;

/**
 * Retrieve all turns
 */
function* fetchTurnsSaga({ payload }: PayloadAction<{ account: string, bot: string }>): Saga<any> {
  yield put(fetchTurnsStart());

  try {
    const { account, bot } = payload;

    const from: number = yield select(trainingSelectors.selectFrom);
    const turnsList: Turn[] = yield call(trainingApi.getTurns, account, bot, from);
    const botConfigIntentsIds: string[] = yield select(botsSelectors.getBotConfigIntents, bot);
    const botConfigIntents: IntentsMap = yield select(
      intentsSelectors.selectIntentsMapByIds,
      botConfigIntentsIds,
    );
    // NOTE: Kind of overkill because we'll also retrieve account intents that are not used in BotConfig
    const allIntents: Intent[] = yield select(intentsSelectors.selectAll);

    // 1. Map over each BotConfig intents in order to convert to IntentTurn object
    const botConfigIntentsFormatted: IntentTurn[] = _values(botConfigIntents).map(
      (intent: Intent) => ({
        intentName: intent.label,
        type: 'other',
        domain: 'none',
        entities: _flatten(intent.entities).map(entity => ({
          entityMask: entity,
        })),
        scope: intent.scope,
      }),
    );

    // 2. For each turn, we'll do
    const formattedTurns: Turn[] = turnsList.map((turn: Turn) => {
      // 2.1. Enrich each intents with scope found in store
      const turnIntents: IntentTurn[] = turn.intents.map((turnIntent: IntentTurn) => {
        // NOTE: Since intent id is not present in IntentTurn, we've to compare by name
        const foundIntent: ?Intent = allIntents.find(
          (intent: Intent) => intent.label === turnIntent.intentName,
        );

        return {
          ...turnIntent,
          scope: foundIntent?.scope || 'bot',
        };
      });

      // 2.2. Add BotConfig intents to turn intents in order to suggest them in Training
      const turnIntentsWithBotConfigIntents: IntentTurn[] = _uniqBy(
        [...turnIntents, ...botConfigIntentsFormatted],
        'intentName',
      );

      return {
        ...turn,
        intents: turnIntentsWithBotConfigIntents,
      };
    });

    yield put(fetchTurnsSuccess(formattedTurns));
  } catch (error) {
    yield put(fetchTurnsFailure(error.message));
    yield put(
      setNotification({
        message: i18n.t('notif.failureRetrieve-var', {
          value: i18n.t('other.Questions'),
        }),
        type: 'error',
      }),
    );
  }
}

/**
 * Retrieve turn answers
 *
 * @param {(Action & { account: string, bot: string })} action
 * @returns {Saga<any>}
 */
function* fetchAnswersByTurnSaga(
  action: Action & {
    payload: {
      account: string,
      bot: string,
      turnId: string,
    },
  },
): Saga<any> {
  yield put(fetchAnswersByTurnStart());
  try {
    const { account, bot, turnId } = action.payload;

    const from: number = yield select(trainingSelectors.selectFrom);
    const result: { after: string, items: Answer[] } = yield call(
      trainingApi.getAnswersByTurnId,
      account,
      bot,
      { from, turnId },
    );

    yield put(fetchAnswersByTurnSuccess(result));
  } catch (error) {
    yield put(fetchAnswersByTurnFailure(error.message));
    yield put(
      setNotification({
        message: i18n.t('notif.failureRetrieve-var', {
          value: i18n.t('other.answers'),
        }),
        type: 'error',
      }),
    );
  }
}

/**
 * Set selected entity in current turn
 * Has the possibility to fetch the referential based on `fetchingReferential` arg
 *
 * @param {(Action & { account: string, newEntityTurn: EntityTurn, fetchingReferential?: boolean })} action
 * @returns {Saga<any>}
 */
function* setCurrentEntityTurnSaga(
  action: Action & {
    payload: { account: string, newEntityTurn: ?EntityTurn, fetchingReferential?: boolean },
  },
): Saga<any> {
  const { account, newEntityTurn, fetchingReferential } = action.payload;
  let hasEntitiesLoadingError: boolean = false;

  const currentTurn: TurnSelected = yield select(trainingSelectors.selectCurrentTurn);
  const turnEntities: EntityTurn[] = yield select(trainingSelectors.selectTurnEntitiesList);

  if (newEntityTurn) {
    let entityAsset: ?Asset<EntityAssetContent, EntityContentType>;

    if (fetchingReferential) {
      yield put(setCurrentTurn({ ...currentTurn, fetchingEntities: true }));
      try {
        entityAsset = yield call(customersAssetsApi.getAsset, {
          accountId: account,
          assetId: newEntityTurn.id,
          assetType: 'entities',
        });
      } catch (err) {
        yield put(
          setNotification({
            message: i18n.t('notif.failureRetrieve-var', {
              value: i18n.t('coreConcept.referential'),
            }),
            type: 'error',
          }),
        );
        yield put(
          setCurrentTurn({
            ...currentTurn,
            fetchingEntities: false,
          }),
        );
        hasEntitiesLoadingError = true;
      }
    }

    const currentEntityIndex: number = turnEntities.findIndex(
      (entity: EntityTurn) => entity.entityMask === newEntityTurn.entityMask,
    );
    const entities: EntityTurn[] = [...turnEntities];

    const currentReferential: EntityAssetContent = entityAsset ? entityAsset.content : {};
    const newReferential: EntityAssetContent = { ...currentReferential, ...newEntityTurn.content };
    const isChanged: boolean = !_isEqual(currentReferential, newReferential);

    entities[currentEntityIndex] = {
      ...newEntityTurn,
      content: {
        ...newReferential,
      },
      changed: isChanged,
    };

    yield put(
      setCurrentTurn({
        ...currentTurn,
        entities,
        currentEntityIndex,
        fetchingEntities: false,
        hasEntitiesLoadingError,
      }),
    );
  }
}

/**
 * Set current answer
 *
 * @param {(Action & { newAnswer: Answer })} action
 * @returns {Saga<any>}
 */
function* setCurrentAnswerSaga(
  action: Action & {
    payload: { newAnswer: Answer },
  },
): Saga<any> {
  const { newAnswer } = action.payload;

  const currentTurn: TurnSelected = yield select(trainingSelectors.selectCurrentTurn);

  const answersList: Answer[] = yield select(trainingSelectors.selectAnswersList);
  const currentAnswerIndex: number = answersList.findIndex(
    (answer: Answer) => answer.answerId === newAnswer.answerId,
  );
  const answers: Answer[] = [...answersList];
  answers[currentAnswerIndex] = {
    ...newAnswer,
  };

  yield put(
    setCurrentTurn({
      ...currentTurn,
      answers,
    }),
  );
}

/**
 * Set current entity and current answer
 * NOTE: Should be refactored into multiple and more precise actions
 */
function* setCurrentEntityTurnCurrentAnswerSaga(
  action: Action & {
    payload: {
      account: string,
      newEntityTurn: ?EntityTurn,
      newAnswer: ?Answer,
      fetchingReferential?: boolean,
    },
  },
): Saga<any> {
  const {
    payload: { account, newEntityTurn, newAnswer, fetchingReferential },
    type,
  } = action;

  if (newEntityTurn) {
    yield call(setCurrentEntityTurnSaga, {
      type,
      payload: { account, newEntityTurn, fetchingReferential },
    });
  }

  if (newAnswer) {
    yield call(setCurrentAnswerSaga, {
      type,
      payload: { newAnswer },
    });

    yield put(updateEntityMasksInUtteranceForIntentsTurn(newAnswer.answerId));
  }
}

/**
 * Get entities from bot config then fetch answers
 *
 * @param {(Action & {
 *   account: string,
 *   bot: string,
 *   turn: TurnSelected | Turn,
 *  })} action
 * @returns {Saga<any>}
 */
function* fetchEntitiesAndAnswersByTurnSaga(
  action: Action & {
    payload: {
      account: string,
      bot: string,
      turn: TurnSelected | Turn,
    },
  },
): Saga<any> {
  const { account, bot, turn } = action.payload;

  const entities: Entities = yield select(botsSelectors.getEntities, bot);
  const turnEntities: EntityTurn[] = entitiesToListForTurn(entities);

  yield put(setCurrentTurn({ ...turn, entities: turnEntities }));

  yield call(fetchAnswersByTurnSaga, {
    ...action,
    payload: { account, bot, turnId: turn.id },
  });
}

/**
 * Save training
 * Entities refentials are saved with customers-assets-api
 * Answers are saved with training-api
 *
 * @param {(Action & {
 *   account: string,
 *   bot: string,
 *  })} action
 * @returns {Saga<any>}
 */
function* saveTrainingSaga(
  action: Action & {
    payload: { account: string, bot: string, refresh: ?boolean },
  },
): Saga<any> {
  const { account, bot, refresh } = action.payload;

  const isUpdated: boolean = yield select(trainingSelectors.selectIsUpdated);

  logger.info('Save training saga start');

  // NOTE: If no answers has been trained, there's nothing to do
  if (!isUpdated) return;

  // Save changed entities referentials
  const turnChangedEntities: EntityTurn[] = yield select(
    trainingSelectors.selectTurnChangedEntitiesList,
  );

  if (turnChangedEntities.length > 0) {
    const entitiesAssetList: Asset<EntityAssetContent, EntityContentType>[] =
      entityTurnsListToEntitiesAssetsContentList(turnChangedEntities);

    try {
      yield all(
        entitiesAssetList.map(entityAsset =>
          // $FlowFixMe is not ambiguous
          call(customersAssetsApi.updateAsset, {
            accountId: account,
            assetId: entityAsset.id,
            assetType: 'entities',
            content: entityAsset.content,
          }),
        ),
      );
      yield put(
        setNotification({
          message: i18n.t('notif.saveSuccess-var', {
            value: i18n.t('coreConcept.Referential'),
          }),
          type: 'success',
        }),
      );
    } catch (error) {
      yield put(
        setNotification({
          message: i18n.t('notif.failureSave-var', {
            value: i18n.t('coreConcept.referential'),
          }),
          type: 'error',
        }),
      );
    }
  }

  // Save changed intents
  const turnChangedIntentsList: IntentTurn[] = yield select(
    trainingSelectors.selectTurnChangedIntentsList,
  );

  if (turnChangedIntentsList.length > 0) {
    let botConfigIntentsIds: string[] = yield select(botsSelectors.getBotConfigIntents, bot);
    let intents: Intent[] = yield select(intentsSelectors.selectAllFromIds, botConfigIntentsIds);

    // 1. Create new intents in state
    const newIntents: IntentTurn[] = turnChangedIntentsList.filter(
      (intentTurn: IntentTurn) =>
        intents.findIndex((intent: Intent) => intent.label === intentTurn.intentName) === -1,
    );

    if (newIntents.length > 0) {
      const newIntentsMap: IntentsMap = newIntents.reduce(
        (intentsMap: IntentsMap, newIntent: IntentTurn) => {
          const newIntentId: string = uuid();

          return {
            ...intentsMap,
            [newIntentId]: {
              id: newIntentId,
              label: newIntent.intentName,
              description: '',
              entities: [],
              utterances: [],
            },
          };
        },
        {},
      );

      // Add new intents ids in bots slice BotConfig
      yield put(
        botsOperations.upsertIntents({
          bot,
          intents: Object.keys(newIntentsMap),
        }),
      );

      // Add new intents in intents slice
      yield put(intentsOperations.upsertIntents(newIntentsMap));

      // Refresh intents list in order to retrieve new ones
      botConfigIntentsIds = yield select(botsSelectors.getBotConfigIntents, bot);
      intents = yield select(intentsSelectors.selectAllFromIds, botConfigIntentsIds);
    }

    try {
      // 2. Fill existing intents in BotConfig with new expressions
      // NOTE: No need to update account intents directly on S3 here since user must save the bot
      // and that will triger the update of the account intents
      yield all(
        turnChangedIntentsList.map((intentTurn: IntentTurn) => {
          const foundIntent: ?Intent = intents.find(
            intent => intent.label === intentTurn.intentName,
          );

          // NOTE: This case should not be possible since we create new intents before
          if (!foundIntent) {
            throw new Error(
              i18n.t('Bot.Builder.views.Training.AnswerTraining.save.errors.intentNotFound', {
                ns: 'views',
              }),
            );
          }

          return put(
            intentsOperations.addExpressionsToIntent({
              intentId: foundIntent.id,
              expressions: getExpressionsByIntentTurn(intentTurn),
            }),
          );
        }),
      );
    } catch (error) {
      yield put(
        setNotification({
          message: i18n.t('notif.failureSave-var', {
            value: i18n.t('other.Intents'),
          }),
          description: i18n.t('notif.updatedFailure-var', {
            value: i18n.t('other.Intents'),
            message: error.message,
          }),
          type: 'error',
        }),
      );
    }
  }

  // Save trained answers
  const currentTurn: TurnSelected = yield select(trainingSelectors.selectCurrentTurn);
  const answersList: Answer[] = yield select(trainingSelectors.selectAnswersChangedList);
  const changedAnswersList: AnswerChanged[] = answersListToAnswersChangedList(answersList);

  if (changedAnswersList.length > 0) {
    try {
      yield call(trainingApi.putAnswersChanged, account, bot, currentTurn.id, changedAnswersList);

      yield put(
        setNotification({
          message: getNotifMessageSaveTrainingAnswers(changedAnswersList),
          type: 'success',
        }),
      );
    } catch (error) {
      yield put(
        setNotification({
          message: i18n.t('notif.failureSave-var', {
            value: i18n.t('other.Answers'),
          }),
          type: 'error',
        }),
      );
    }

    if (refresh)
      yield put(operations.fetchEntitiesAndAnswersByTurn({ account, bot, turn: currentTurn }));

    yield put(botsOperations.startValidateBotSaga({ bot }));
  }
}

/**
 * Change turn and fetch new turn answers
 * NOTE: Useless saga. Replace by logic in component
 */
function* changeTurnSaga(
  action: Action & {
    payload: { account: string, bot: string, turnId: string },
  },
): Saga<any> {
  const { account, bot, turnId } = action.payload;
  const previousTurnSelected: ?TurnSelected = yield select(trainingSelectors.selectCurrentTurn);

  if (previousTurnSelected && previousTurnSelected.answers && previousTurnSelected.entities) {
    yield call(saveTrainingSaga, {
      ...action,
      payload: { account, bot },
    });
  }

  yield put(clearCurrentTurn());

  // TODO: temporary, should get turn data from request like "getTurnById" ?
  const turnsList: Turn[] = yield select(trainingSelectors.selectTurnsList);
  const currentTurn: ?Turn = turnsList.find((turn: Turn) => turn.id === turnId);

  if (currentTurn) {
    // $FlowFixMe: payload has properties
    yield call(fetchEntitiesAndAnswersByTurnSaga, {
      ...action,
      payload: { account, bot, turn: currentTurn },
    });
  }
}

/**
 * Initialize training
 */
function* initTrainingSaga({
  payload: { turnId, ...payload },
  ...action
}: PayloadAction<{ account: string, bot: string, turnId?: string }>): Saga<any> {
  yield put(setNotification({ message: '', type: '' }));
  yield put(clearCurrentTurn());
  yield put(clearTurns());
  yield call(fetchTurnsSaga, { ...action, payload });

  const turnsList: Turn[] = yield select(trainingSelectors.selectTurnsList);

  if (turnsList.length > 0) {
    const currentTurn: Turn = turnsList[0];

    yield call(changeTurnSaga, {
      ...action,
      payload: { ...payload, turnId: turnId || currentTurn.id },
    });

    // Required to test thunk called in sagas
    const getTrainingScoreThunk = yield call(getTrainingScore, payload);

    yield put(getTrainingScoreThunk);
    yield take(getTrainingScore.fulfilled);
  }
}

export {
  changeTurnSaga,
  fetchAnswersByTurnSaga,
  fetchEntitiesAndAnswersByTurnSaga,
  fetchTurnsSaga,
  initTrainingSaga,
  saveTrainingSaga,
  setCurrentAnswerSaga,
  setCurrentEntityTurnCurrentAnswerSaga,
  setCurrentEntityTurnSaga,
};
