// @flow

import type {
  FormattedIntentEvaluation,
  IntentEvaluation,
  UtteranceEvaluation,
  UtterancesEvaluationsMap,
} from '../types';

import getSHA256Hash from '@assets/js/calldesk-app-util/hash';

/**
 * Transform Intent type to EvaluatedIntent type
 * NOTE: Build a hash map for utterances for fast access
 */
function formatIntentEvaluation(intentEvaluation: IntentEvaluation): FormattedIntentEvaluation {
  const utterancesEvaluationsMap: UtterancesEvaluationsMap = {};
  let count: number = 0;

  intentEvaluation.utterances.forEach((utteranceEvaluation: UtteranceEvaluation) => {
    const utteranceHash: string = getSHA256Hash(utteranceEvaluation.utterance);

    utterancesEvaluationsMap[utteranceHash] = utteranceEvaluation;
    count += 1;
  });

  const formattedIntentEvaluation: FormattedIntentEvaluation = {
    confusionMatrix: intentEvaluation.confusionMatrix,
    count,
    evaluatedOn: Date.now(),
    score: intentEvaluation.intentScore,
    utterances: utterancesEvaluationsMap,
  };

  return formattedIntentEvaluation;
}

export default formatIntentEvaluation;
