// @flow

import type { Categories, Exercise, Quests, Steps } from '@state/ducks/onboarding/types';

type Sorter = (itemKeyA: string, itemKeyB: string) => number;

/**
 * Return sort function
 */
function sorting(items: Exercise | Categories | Steps | Quests): Sorter {
  const sortFunction: Sorter = (itemKeyA: string, itemKeyB: string) => {
    if (!items[itemKeyA] || !items[itemKeyA].order) {
      return 1;
    }
    if (!items[itemKeyB] || !items[itemKeyB].order) {
      return -1;
    }
    return items[itemKeyA].order < items[itemKeyB].order ? -1 : 1;
  };

  return sortFunction;
}

export default sorting;
