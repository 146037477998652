// @flow
import type {
  AsrQuality,
  Banner,
  BargeInSettings,
  BotConfig,
  BotLanguage,
  BotType,
  ContextColumns,
  HangUpSettings,
  LogRetentionSettings,
  Nlu,
  Parsers,
  PostTransferRecordSettings,
  Tts,
  TtsVoice,
} from './types';
// $FlowFixMe
import { createSelector } from '@reduxjs/toolkit';

import type { StorageSettings } from '@state/ducks/app/types';
import { DEFAULT_STORAGE_SETTINGS } from '@state/ducks/app/types';

import { getBotConfig } from './bots.selectors';
import {
  DEFAULT_BARGE_IN_SETTINGS,
  DEFAULT_LOG_RETENTION_SETTINGS,
  DEFAULT_NLU_CONFIG,
  DEFAULT_RECORD_AFTER_TRANSFER_SETTINGS,
} from './utils';

const getTtsConfig = createSelector(getBotConfig, (config: ?BotConfig): ?Tts => config?.tts);

const getPrimaryVoice = createSelector(getTtsConfig, (tts: ?Tts): ?string => tts?.voices[0]?.voice);

const getFallbackVoice = createSelector(
  getTtsConfig,
  (tts: ?Tts): ?string => tts?.voices[1]?.voice,
);

const getCurrentTtsVoice = createSelector(
  getTtsConfig,
  (tts: ?Tts): ?TtsVoice => tts?.voices[0] ?? {},
);

const getActionWebhook = createSelector(
  getBotConfig,
  (config: ?BotConfig): ?string => config?.actions,
);

const getLanguage = createSelector(
  getBotConfig,
  (config: ?BotConfig): ?BotLanguage => config?.language,
);

const getTimezone = createSelector(getBotConfig, (config: ?BotConfig): ?string => config?.timezone);

const getBotType = createSelector(getBotConfig, (config: ?BotConfig): ?BotType => config?.type);

const getAsrQuality = createSelector(
  getBotConfig,
  (config: ?BotConfig): ?AsrQuality => config?.asrQuality,
);

const getHangUpSettings = createSelector(
  getBotConfig,
  (config: ?BotConfig): ?HangUpSettings => config?.hangUpSettings,
);

const getParsersConfig = createSelector(
  getBotConfig,
  (config: ?BotConfig): ?Parsers => config?.parsers ?? {},
);

const getContextColumns = createSelector(
  getBotConfig,
  (config: ?BotConfig): ?ContextColumns => config?.contextColumns,
);

const getBannerConfig = createSelector(
  getBotConfig,
  (config: ?BotConfig): ?Banner => config?.banner,
);

const getBargeIn = createSelector(
  getBotConfig,
  (config: ?BotConfig): BargeInSettings => config?.bargeInSettings || DEFAULT_BARGE_IN_SETTINGS,
);

const getPostTransferRecord = createSelector(
  getBotConfig,
  (config: ?BotConfig): PostTransferRecordSettings =>
    config?.postTransferRecordSettings || DEFAULT_RECORD_AFTER_TRANSFER_SETTINGS,
);

const getNluConfig = createSelector(
  getBotConfig,
  (config: ?BotConfig): Nlu => config?.nlu || DEFAULT_NLU_CONFIG,
);

const getLogRetentionSettings = createSelector(
  getBotConfig,
  (config: ?BotConfig): ?LogRetentionSettings =>
    config?.logRetentionSettings || DEFAULT_LOG_RETENTION_SETTINGS,
);

const selectStorageSettings = createSelector(
  getBotConfig,
  (config: ?BotConfig) => config?.storageSettings || DEFAULT_STORAGE_SETTINGS,
);

const getBotSettings = createSelector(
  getActionWebhook,
  getAsrQuality,
  getBannerConfig,
  getBargeIn,
  getBotType,
  getContextColumns,
  getFallbackVoice,
  getHangUpSettings,
  getLanguage,
  getLogRetentionSettings,
  getNluConfig,
  getParsersConfig,
  getPostTransferRecord,
  getPrimaryVoice,
  getTimezone,
  selectStorageSettings,
  (
    actions: ?string,
    asrQuality: ?AsrQuality,
    banner: ?Banner,
    bargeInSettings: ?BargeInSettings,
    type: ?BotType,
    contextColumns: ?ContextColumns,
    fallbackVoice: ?string,
    hangUpSettings: ?HangUpSettings,
    language: ?BotLanguage,
    logRetentionSettings: ?LogRetentionSettings,
    nlu: Nlu,
    parsers: ?Parsers,
    postTransferRecordSettings: PostTransferRecordSettings,
    primaryVoice: ?string,
    timezone: ?string,
    storageSettings: StorageSettings,
  ) => ({
    actions,
    asrQuality,
    banner,
    bargeInSettings,
    contextColumns,
    fallbackVoice,
    hangUpSettings,
    language,
    logRetentionSettings,
    nlu,
    parsers,
    postTransferRecordSettings,
    primaryVoice,
    storageSettings,
    timezone,
    type,
  }),
);

export {
  getActionWebhook,
  getAsrQuality,
  getBannerConfig,
  getBargeIn,
  getBotSettings,
  getBotType,
  getContextColumns,
  getCurrentTtsVoice,
  getFallbackVoice,
  getHangUpSettings,
  getLanguage,
  getLogRetentionSettings,
  getNluConfig,
  getParsersConfig,
  getPostTransferRecord,
  getPrimaryVoice,
  getTimezone,
  getTtsConfig,
  selectStorageSettings,
};
