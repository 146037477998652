// @flow

import type {
  AsrQuality,
  Banner,
  BargeInSettings,
  BotAction,
  Bots as State,
  BotType,
  ContextColumn,
  ContextColumns,
  LogRetentionSettings,
  Nlu,
  Parser,
  Parsers,
  PostTransferRecordSettings,
  StatsCheckpointReason,
  StatsCheckpointStatus,
  StatsCheckpointStep,
  TtsVoice,
} from './types';
import type { PayloadAction } from '@reduxjs/toolkit';
import _entries from 'lodash/entries';

import { availableEntitiesByIntent } from '@resources/options/available.json';
import { extractEntitiesFromIntents } from '@state/ducks/intents/utils';

import { botConfigByBotTypeMap } from './utils';

const reducers = {
  updateBotConfigActionsWebhook: (
    state: State,
    { payload }: PayloadAction<BotAction<$Exact<{ actionsWebhook: string }>>>,
  ) => {
    state.entities[payload.bot].config.actions = payload.actionsWebhook;
  },
  updateBotConfigPrimaryVoice: (
    state: State,
    { payload }: PayloadAction<BotAction<$Exact<{ newVoice: TtsVoice }>>>,
  ) => {
    const fallback = state.entities[payload.bot].config.tts.voices[1] ?? {
      provider: 'polly',
      voice: 'Celine',
    };
    state.entities[payload.bot].config.tts.voices = [payload.newVoice, fallback];
  },
  updateBotConfigFallbackVoice: (
    state: State,
    { payload }: PayloadAction<BotAction<$Exact<{ newVoice: TtsVoice }>>>,
  ) => {
    state.entities[payload.bot].config.tts.voices.splice(1, 1, payload.newVoice);
  },
  createBotConfigParser: (
    state: State,
    { payload }: PayloadAction<BotAction<$Exact<{ entityName: string, newParser: Parser }>>>,
  ) => {
    state.entities[payload.bot].config.parsers[payload.entityName] = payload.newParser;
  },
  createBotConfigParserByIntent: (
    state: State,
    { payload }: PayloadAction<BotAction<$Exact<{ intentId: string }>>>,
  ) => {
    if (availableEntitiesByIntent[payload.intentId]) {
      const { parsers } = state.entities[payload.bot].config;
      availableEntitiesByIntent[payload.intentId].forEach((entity: string) => {
        if (!parsers[entity] || !parsers[entity].parse) {
          const { latest, versions, wait } = payload.parsers[entity];

          parsers[entity] = {
            parse: versions[latest].parse,
            wait,
          };
        }
      });
    }
  },
  setDefaultParsers: (state: State, { payload }: PayloadAction<BotAction<void>>) => {
    const { parsers, intents } = state.entities[payload.bot].config;
    const entities = extractEntitiesFromIntents(intents);
    entities.forEach((entity: string) => {
      if (!parsers[entity]) {
        parsers[entity] = { init: '', parse: '' };
      }
    });
  },
  updateParserInit: (
    state: State,
    { payload }: PayloadAction<BotAction<$Exact<{ parser: string, init: string }>>>,
  ) => {
    state.entities[payload.bot].config.parsers[payload.parser].init = payload.init;
  },
  updateParserWait: (
    state: State,
    { payload }: PayloadAction<BotAction<$Exact<{ parser: string, wait: number }>>>,
  ) => {
    state.entities[payload.bot].config.parsers[payload.parser].wait = payload.wait;
  },
  updateParserParse: (
    state: State,
    { payload }: PayloadAction<BotAction<$Exact<{ parser: string, parse: string }>>>,
  ) => {
    state.entities[payload.bot].config.parsers[payload.parser].parse = payload.parse;
  },
  deleteBotConfigParser: (
    state: State,
    { payload }: PayloadAction<BotAction<$Exact<{ entityName: string }>>>,
  ) => {
    delete state.entities[payload.bot].config.parsers[payload.entityName];
  },
  addParsers: (
    state: State,
    { payload }: PayloadAction<BotAction<$Exact<{ parsers: Parsers }>>>,
  ) => {
    state.entities[payload.bot].config.parsers = {
      ...payload.parsers,
      ...state.entities[payload.bot].config.parsers,
    };
  },
  deleteBotConfigParserByIntent: (
    state: State,
    { payload }: PayloadAction<BotAction<$Exact<{ intentId: string }>>>,
  ) => {
    if (availableEntitiesByIntent[payload.intentId]) {
      const { parsers, intents } = state.entities[payload.bot].config;
      // TODO : Instead of checking every utterance, check with entities property
      availableEntitiesByIntent[payload.intentId].forEach((entity: string) => {
        const crossIntentsEntity = Object.keys(intents)
          .filter(intentId => intentId !== payload.intentId && intents[intentId]?.utterances)
          .some((intentId: string) =>
            intents[intentId]?.utterances.some((expression: string) =>
              expression.includes(`{${entity}}`),
            ),
          );

        if (!crossIntentsEntity) delete parsers[entity];
      });
    }
  },
  updateBotConfigAsrQuality: (
    state: State,
    { payload }: PayloadAction<BotAction<$Exact<{ asrQuality: AsrQuality }>>>,
  ) => {
    state.entities[payload.bot].config.asrQuality = payload.asrQuality;
  },
  updateBotConfigLanguage: (
    state: State,
    { payload }: PayloadAction<BotAction<$Exact<{ language: string }>>>,
  ) => {
    state.entities[payload.bot].config.language = payload.language;
    const { parsers, intents } = state.entities[payload.bot].config;
    const entities = extractEntitiesFromIntents(intents);
    entities.forEach((entity: string) => {
      parsers[entity] = { init: '', parse: '' };
    });
  },
  createBotConfigContextColumn: (
    state: State,
    {
      payload,
    }: PayloadAction<BotAction<$Exact<{ contextKey: string, contextColumn: ContextColumn }>>>,
  ) => {
    state.entities[payload.bot].config.contextColumns[payload.contextKey] = payload.contextColumn;
  },
  updateBotConfigAllContextColumns: (
    state: State,
    { payload }: PayloadAction<BotAction<$Exact<{ contextColumns: ContextColumns }>>>,
  ) => {
    state.entities[payload.bot].config.contextColumns = payload.contextColumns;
  },
  deleteBotConfigContextColumn: (
    state: State,
    { payload }: PayloadAction<BotAction<$Exact<{ contextKey: string }>>>,
  ) => {
    delete state.entities[payload.bot].config.contextColumns[payload.contextKey];
  },
  updateBotConfigBanner: (
    state: State,
    { payload }: PayloadAction<BotAction<$Exact<{ banner: Banner }>>>,
  ) => {
    state.entities[payload.bot].config.banner = payload.banner;
  },
  updateBargeInSettings: (
    state: State,
    { payload }: PayloadAction<BotAction<$Exact<{ settings: BargeInSettings }>>>,
  ) => {
    state.entities[payload.bot].config.bargeInSettings = payload.settings;
  },
  updatePostTransferRecordSettings: (
    state: State,
    { payload }: PayloadAction<BotAction<$Exact<{ settings: PostTransferRecordSettings }>>>,
  ) => {
    state.entities[payload.bot].config.postTransferRecordSettings = payload.settings;
  },
  updateBotConfigNlu: (
    state: State,
    { payload }: PayloadAction<BotAction<$Exact<{ nlu: Nlu }>>>,
  ) => {
    state.entities[payload.bot].config.nlu = payload.nlu;
  },
  updateBotConfigTimezone: (
    state: State,
    { payload }: PayloadAction<BotAction<$Exact<{ timezone: string }>>>,
  ) => {
    state.entities[payload.bot].config.timezone = payload.timezone;
  },
  updateBotConfigType: (
    state: State,
    { payload }: PayloadAction<BotAction<$Exact<{ type: BotType }>>>,
  ) => {
    Object.assign(state.entities[payload.bot].config, botConfigByBotTypeMap[payload.type]);
  },
  updateBotConfigLogRetention: (
    state: State,
    { payload }: PayloadAction<BotAction<$Exact<{ settings: LogRetentionSettings }>>>,
  ) => {
    Object.assign(state.entities[payload.bot].config.logRetentionSettings, payload.settings);
  },
  statsCheckpointsDeleteStep: (
    state: State,
    { payload }: PayloadAction<BotAction<$Exact<{ stepId: string }>>>,
  ) => {
    const { [payload.stepId]: removeSteps, ...steps } =
      state.entities[payload.bot].config.statsCheckpoints.steps;

    const updatedSteps = Object.fromEntries(
      _entries(steps).map(([key, step], index) => [
        key,
        { ...step, rank: (index + 1).toString(10).padStart(2, '0') },
      ]),
    );
    state.entities[payload.bot].config.statsCheckpoints.steps = updatedSteps;
  },
  statsCheckpointsUpdateStep: (
    state: State,
    { payload }: PayloadAction<BotAction<$Exact<{ step: StatsCheckpointStep }>>>,
  ) => {
    const { steps } = state.entities[payload.bot].config.statsCheckpoints;
    const { [payload.step.id]: step } = steps;
    steps[payload.step.id] = { ...step, ...payload.step };
  },
  statsCheckpointsUpdateAllSteps: (
    state: State,
    {
      payload,
    }: PayloadAction<BotAction<$Exact<{ steps: { [stepId: string]: StatsCheckpointStep } }>>>,
  ) => {
    state.entities[payload.bot].config.statsCheckpoints.steps = payload.steps;
  },
  statsCheckpointsUpdateReason: (
    state: State,
    { payload }: PayloadAction<BotAction<$Exact<{ reason: StatsCheckpointReason }>>>,
  ) => {
    state.entities[payload.bot].config.statsCheckpoints.reasons[payload.reason.id] = {
      ...state.entities[payload.bot].config.statsCheckpoints.reasons[payload.reason.id],
      ...payload.reason,
    };
  },
  statsCheckpointsDeleteReason: (
    state: State,
    { payload }: PayloadAction<BotAction<$Exact<{ reasonId: string }>>>,
  ) => {
    delete state.entities[payload.bot].config.statsCheckpoints.reasons[payload.reasonId];
  },
  statsCheckpointsUpdateStatus: (
    state: State,
    { payload }: PayloadAction<BotAction<$Exact<{ status: StatsCheckpointStatus }>>>,
  ) => {
    const { statuses } = state.entities[payload.bot].config.statsCheckpoints;
    const { [payload.status.id]: status } = statuses;
    statuses[payload.status.id] = { ...status, ...payload.status };
  },
  toggleHangUpAuto: (state: State, { payload: bot }: PayloadAction<string>) => {
    const hasConfig: boolean = !!state.entities[bot].config;
    if (hasConfig) {
      const auto: boolean = state.entities[bot]?.config.hangUpSettings.auto;
      state.entities[bot].config.hangUpSettings.auto = !auto;
    }
  },
  updateHangUpDelay: (
    state: State,
    { payload: { bot, newDelay } }: PayloadAction<BotAction<$Exact<{ newDelay: number }>>>,
  ) => {
    const hasConfig: boolean = !!state.entities[bot]?.config;
    if (hasConfig) {
      state.entities[bot].config.hangUpSettings.delay = newDelay;
    }
  },
};

export default reducers;
