// @flow

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { CDKButton, CDKCollapse, CDKList, CDKTypography } from '@calldesk/components';

import type { NodeModule } from '@state/ducks/bots/types';
import { selectors as botsSelectors } from '@state/ducks/bots';
import { operations as uiOperations } from '@state/ducks/ui';

import './modules-list.css';

/**
 * Modules List Component
 */
function ModulesList() {
  const match = useRouteMatch('/accounts/:account/bots/:bot');
  const dispatch = useDispatch();

  const { bot } = { bot: match?.params.bot ?? '' };

  const moduleNodes: NodeModule[] = useSelector(state =>
    botsSelectors.getBotGraphsModuleNodes(state, bot),
  );
  const devModuleNodesCount: number = useSelector(state =>
    botsSelectors.getBotGraphsDevModuleNodesCount(state, bot),
  );

  const setSelectedNodeId = (nodeId: ?string) => dispatch(uiOperations.setSelectedNodeId(nodeId));

  return (
    <CDKCollapse ghost>
      <CDKCollapse.Panel
        header={
          <CDKTypography.Title level={5} strong>
            {moduleNodes.length} included modules{' '}
            {devModuleNodesCount > 0 && (
              <CDKTypography.Text type="warning">
                <small>({devModuleNodesCount} in dev)</small>
              </CDKTypography.Text>
            )}
          </CDKTypography.Title>
        }
      >
        <CDKList
          bordered={false}
          itemLayout="horizontal"
          dataSource={moduleNodes}
          renderItem={item => (
            <CDKList.Item>
              <CDKTypography.Text type={item.target.botVersion === 'dev' ? 'warning' : 'success'}>
                {item.target.accountId} - {item.target.botName} - {item.target.botVersion}
                <CDKButton icon={<VisibilityIcon />} onClick={() => setSelectedNodeId(item.id)} />
              </CDKTypography.Text>
            </CDKList.Item>
          )}
        />
      </CDKCollapse.Panel>
    </CDKCollapse>
  );
}

export default ModulesList;
