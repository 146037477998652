// @flow

import type { StepsMap } from '../utils/index.type';

import onboarding from './onboarding-steps';
import selfcare from './selfcare-steps';

const stepsMap = (
  setSelectedNodeId?: (nodePath: ?string) => void,
  mainDomain?: string,
): StepsMap => ({
  onboarding: onboarding(),
  selfcare: selfcare(setSelectedNodeId, mainDomain),
});

export default stepsMap;
