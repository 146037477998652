// @flow

import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

import { CDKButton, CDKInput } from '@calldesk/components';

import * as cognito from '@api/cognito';
import LoadingSpinner from '@assets/js/calldesk-components/molecules/LoadingSpinner';

import './reset-password.css';

type Props = $Exact<{
  history: Object,
  location: Object,
}>;

/**
 * @description ResetPassword component
 * @param {Object} props - Properties
 */
function ResetPassword(props: Props) {
  const [canSubmit, setCanSubmit] = useState(false);
  const [confirmationPassword, setConfirmationPassword] = useState('');
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [showConfirmationPassword, setShowConfirmationPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(props.location.search);

    if (!urlParams.get('code')) props.history.push('/login');
  }, [props.location]);

  useEffect(() => {
    if (isLoading) {
      setErrorMessage(null);
      setSuccessMessage(null);
    }
  }, [isLoading]);

  useEffect(() => {
    setCanSubmit(
      password &&
        password.trim() &&
        confirmationPassword &&
        confirmationPassword.trim() &&
        password === confirmationPassword,
    );
  }, [password, confirmationPassword]);

  const handleSubmit = async () => {
    const urlParams = new URLSearchParams(props.location.search);
    const code = urlParams.get('code');
    if (code) {
      setIsLoading(true);
      try {
        await cognito.confirmForgotPassword(email, password, code);
        setIsLoading(false);
        setSuccessMessage('Your password has been updated.');
      } catch (err) {
        setIsLoading(false);
        setErrorMessage(err.message);
      }
    }
  };

  return (
    <div className="ResetPasswordForm">
      <h2>Create new password</h2>
      <div className="ResetPasswordFormField">
        <CDKInput
          autoComplete="new-password"
          placeholder="Email address"
          onChange={event => setEmail(event.target.value)}
          value={email}
        />
      </div>
      <div className="ResetPasswordFormField">
        <CDKInput
          autoComplete="new-password"
          type={showPassword ? 'text' : 'password'}
          placeholder="Password"
          onChange={event => setPassword(event.target.value)}
          value={password}
          suffix={
            showPassword ? (
              <EyeInvisibleOutlined onClick={() => setShowPassword(false)} />
            ) : (
              <EyeOutlined onClick={() => setShowPassword(true)} />
            )
          }
        />
      </div>
      <div className="ResetPasswordFormField">
        <CDKInput
          type={showConfirmationPassword ? 'text' : 'password'}
          placeholder="Confirm password"
          onChange={event => setConfirmationPassword(event.target.value)}
          value={confirmationPassword}
          suffix={
            showConfirmationPassword ? (
              <EyeInvisibleOutlined onClick={() => setShowConfirmationPassword(false)} />
            ) : (
              <EyeOutlined onClick={() => setShowConfirmationPassword(true)} />
            )
          }
        />
      </div>
      <div className="ResetPasswordFormActions">
        {successMessage && <div className="success-message">{successMessage}</div>}
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        {successMessage ? (
          <NavLink to="/login">
            <CDKButton>Login</CDKButton>
          </NavLink>
        ) : (
          <CDKButton type="primary" onClick={handleSubmit} disabled={!canSubmit}>
            {isLoading ? <LoadingSpinner size={20} /> : 'Validate'}
          </CDKButton>
        )}
      </div>
    </div>
  );
}

export default ResetPassword;
