// @flow

/** NOTE
 * The store is the object that brings actions and reducers together.
 * It has the following responsibilities:
 *  - holds application state,
 *  - allows access to state via getState(),
 *  - allows state to be updated via dispatch(action),
 *  - registers listeners via subscribe(listener),
 *  - handles unregistering of listeners via the function returned by subscribe(listener).
 *
 * For details, see:
 *  - http://redux.js.org/docs/basics/Store.html
 */

import type { Action, State } from './ducks/app/types';
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import reducers from './ducks';
import listeners from './listeners';
import monitoringEnhancer from './monitoring-enhancer';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore<State, Action>({
  devTools: process.env.NODE_ENV === 'development',
  middleware: getDefaultMiddleware =>
    // $FlowFixMe : there are numerous example of getDefaultMiddleware with args : https://redux-toolkit.js.org/api/getDefaultMiddleware#customizing-the-included-middleware
    getDefaultMiddleware({
      // immutableCheck: false,
      serializableCheck: {
        // Ignore these action types
        // ignoredActions: ['your/action/type'],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['payload.history', 'payload.location', 'payload.match', 'history'],
        // Ignore these paths in the state
        // ignoredPaths: ['items.dates'],
      },
    })
      // $FlowFixMe middlewares can be prepened as shown in https://redux-toolkit.js.org/api/createListenerMiddleware#basic-usage
      .prepend(listeners.middleware)
      .concat(sagaMiddleware),
  reducer: reducers,
  enhancers: [monitoringEnhancer],
});

sagaMiddleware.run(sagas);

// Infer the `RootState` and `AppDispatch` types from the store itself
type RootState = $Call<typeof store.getState>;
// Inferred type: State
type AppDispatch = typeof store.dispatch;

export type { AppDispatch, RootState };
export default store;
