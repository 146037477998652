// @flow

/**
 * Add only exist properties into source object to avoid undefined property (e.g { key1: undefined })
 */
function addPropertiesIfExist<K, V>(sourceObject: { ...K }, values: { ...V }): { ...K, ...V } {
  const targetObject: $Shape<{ ...K, ...V }> = { ...sourceObject };

  Object.keys(values).forEach((key: string) => {
    if (values[key]) targetObject[key] = values[key];
  });

  return targetObject;
}

export default addPropertiesIfExist;
