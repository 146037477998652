// @flow

import type { Answer, ChunkTrained, EntityTurn, IntentTurn, IntentTurnExpression } from '../types';

import { appendEntityMasksInUtterance } from './entityMaskUtils';

/**
 * Update expression for each intent turn, according entitymask in entities.
 *
 * Specific cases:
 * - If Intent was created with entity mask in expression (origin) before its Entity related,
 * then this Entity is removed, the entity mask stay in expression of Intent.
 * - If Intent was created without entity mask in expression (origin) before its Entity related,
 * then this Entity is removed, the entity mask is removed in expression of Intent.
 *
 * @params {IntentTurn[]} intentsTurnList - List of intents turn will be updated
 * @params {EntityTurn[]} entitiesTurnList - List of entities turn containing entity masks
 *
 * @returns {IntentTurn[]} - Return list of intents turn updated
 */
const changeExpressionInTurnIntents = (
  turnIntents: IntentTurn[],
  turnEntities: EntityTurn[],
): IntentTurn[] =>
  turnIntents.reduce((acc: IntentTurn[], intentTurn: IntentTurn) => {
    const expressionsWithEntityMasks: IntentTurnExpression[] = (
      intentTurn.expressions || []
    ).reduce((accExpressions: IntentTurnExpression[], expression: IntentTurnExpression) => {
      const stillExistEntityMasksInExpressionOrigin: boolean =
        expression.origin.search(/{(.*)}/) >= 0
          ? !!turnEntities.find(
              (entityTurn: EntityTurn) =>
                expression.origin.search(`{${entityTurn.entityMask}}`) >= 0,
            )
          : true;
      if (stillExistEntityMasksInExpressionOrigin) {
        const expressionWithEntityMasks: string = appendEntityMasksInUtterance(
          expression.origin,
          turnEntities,
        );
        const stillEntityMasksAfterFormatting: boolean =
          expressionWithEntityMasks.search(/{(.*)}/) >= 0;
        if (stillEntityMasksAfterFormatting) {
          return [
            ...accExpressions,
            {
              withEntityMasks: expressionWithEntityMasks,
              origin: expression.origin,
              answerId: expression.answerId,
            },
          ];
        }
      }
      return [...accExpressions, { origin: expression.origin, answerId: expression.answerId }];
    }, []);
    return [
      ...acc,
      {
        ...intentTurn,
        expressions: expressionsWithEntityMasks,
        changed: expressionsWithEntityMasks.length > 0,
      },
    ];
  }, []);

/**
 * Update result in each answer, according intents turn updated
 *
 * @params {string} answerId - ID of answer related by the changes
 * @params {Answer[]} answersList - List of answers will be updated
 * @params {IntentTurn[]} intentsTurnList - List of intents turn
 * @params {EntityTurn[]} entitiesTurnList - List of entities turn
 *
 * @returns {Answer[]} - Return list of answers updated
 */
const changeResultInAnswersAccordingIntentsTurn = (
  answerId: string,
  answersList: Answer[],
  intentsTurnList: IntentTurn[],
  entitiesTurnList: EntityTurn[],
): Answer[] =>
  answersList.reduce((acc: Answer[], answer: Answer) => {
    if (answer.answerId === answerId && answer.result) {
      const { result } = answer;
      if (result.intentsTurnTrained && result.intentsTurnTrained.length > 0) {
        const { intentsTurnTrained } = result;

        const intentsTurnListUpdated: ChunkTrained[] = intentsTurnList.reduce(
          (accIntentsChunk: ChunkTrained[], intentTurn: IntentTurn) => {
            const intentChunkRelated: ?ChunkTrained = intentsTurnTrained.find(
              (intentTurnTrained: ChunkTrained) => intentTurnTrained.mask === intentTurn.intentName,
            );
            if (intentChunkRelated) {
              const intentsChunkUpdated: ChunkTrained[] = (intentTurn.expressions || [])
                .filter((expression: IntentTurnExpression) => expression?.answerId === answerId)
                .map((expression: IntentTurnExpression) => ({
                  ...intentChunkRelated,
                  utterance: appendEntityMasksInUtterance(
                    expression.withEntityMasks || expression.origin,
                    entitiesTurnList,
                  ),
                }));
              return [...accIntentsChunk, ...intentsChunkUpdated];
            }

            return accIntentsChunk;
          },
          [],
        );
        return [
          ...acc,
          {
            ...answer,
            result: {
              ...result,
              intentsTurnTrained: intentsTurnListUpdated,
            },
          },
        ];
      }
    }

    return [...acc, answer];
  }, []);

export { changeExpressionInTurnIntents, changeResultInAnswersAccordingIntentsTurn };
