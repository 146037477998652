// @flow

import type { ActionMessage, ActionNode, TraceItem, TraceItemFactory } from '../../types';

import type { ConversationState } from '@state/ducks/chat/types';
import { CLASSIC_ACTION_NODES } from '@state/ducks/bots/types';

import { getId, stateFormattedForActionMessage } from '../../util';

const Factory = (): TraceItemFactory<ActionNode> => {
  const formatter = (
    traceItem: TraceItem<ActionNode>,
    state: $Shape<ConversationState>,
  ): ActionMessage => {
    const newState: $Shape<ConversationState> = stateFormattedForActionMessage(state);
    return {
      content: traceItem.node.action,
      nodeId: traceItem.node.id,
      sender: 'action',
      state: newState,
      timestamp: traceItem.timestamp,
      id: getId({ timestamp: traceItem.timestamp, nodeId: traceItem.node.id }),
      type: 'classic',
    };
  };

  const validator = (traceItem: TraceItem<any>): boolean =>
    Object.values(CLASSIC_ACTION_NODES).includes(traceItem?.node?.action);

  return { formatter, validator };
};
export default Factory;
